<template>
  <div class="container mt-4">
    <v-row>
      <v-spacer></v-spacer>
      <!-- <v-col md="3"> -->
      <v-switch
        v-model="processurl"
        :label="processurltext"
        @change="onchangeurl($event)"
      ></v-switch>
      <!-- </v-col> -->
      <v-col cols="12" sm="3" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="daterange"
              label="Date Range"
              append-icon="mdi-calendar"
              readonly
              outlined
              dense
              @click:append="on.click"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            scrollable
            range
            :max="nowDate"
          >
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="menu = false"
              >Cancel
            </v-btn>
            <v-btn dark @click="$refs.menu.save(dates), dateRangeText()"
              >OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear
          indeterminate
          v-if="loadingHistory"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card height="120">
          <v-row class="no-gutters card-bg" style="height: 120px">
            <div class="imgcl">
              <v-img :src="require('@/assets/dashboard.png')"> </v-img>
            </div>
            <div class="pa-3 py-4">
              <div class="text-truncate text-capitalize dashtitle">
                Total Transactions
              </div>
              <div
                v-if="loadingHistory == false && leadHistory"
                class="dashnum"
              >
                {{ leadHistory.length }}
              </div>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="9" cols="sm" class="pb-2">
        <v-card height="120">
          <v-row class="no-gutters" style="height: 100px">
            <div class="col pa-3 py-4">
              <div class="text-truncate text-capitalize dashtitle">
                Successful Vs Unsuccessful Transactions
              </div>
              <div class="row">
                <div class="col-8">
                  <v-progress-linear
                    :value="process"
                    height="24"
                    class="mt-3"
                    color="#8BC34A"
                    background-color="#F3F3F4"
                  >
                    <strong>{{ Math.ceil(process) }}%</strong>
                  </v-progress-linear>
                </div>
                <div class="col-2">
                  <div class="row">
                    <div class="col-auto">
                      <div
                        class="fill-height"
                        style="background-color: #8bc34a"
                      >
                        &nbsp;
                      </div>
                    </div>
                    <div class="col">
                      <div class="text-capitalize textprog">
                        {{ Math.ceil(process) }}%
                      </div>
                      <div class="textsub">Successful</div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="row">
                    <div class="col-auto">
                      <div
                        class="fill-height"
                        style="background-color: #f3f3f4"
                      >
                        &nbsp;
                      </div>
                    </div>
                    <div class="col" v-if="leadHistory">
                      <div
                        v-if="leadHistory.length > 0"
                        class="text-capitalize textprog"
                      >
                        {{ Math.floor(100 - process) }}%
                      </div>
                      <div class="text-capitalize textprog" v-else>0%</div>
                      <div class="textsub">Unsuccessful</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card class="mx-auto text-center">
          <v-card-title class="dashtitle">
            Successful Vs Business Exception</v-card-title
          >
          <v-card-text>
            <DoughnutChart
              :chartData="chartDataExp"
              :chartOptions="chartOptionsExp"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="9" cols="sm" class="pb-2">
        <v-card class="mx-auto text-center">
          <v-card-title class="dashtitle"> Process Trend</v-card-title>
          <v-card-text>
            <BarChart :chartData="chartData" :chartOptions="chartOptions" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col lg="6" cols="sm" class="pb-2">
        <v-card class="mx-auto text-center">
          <v-card-title class="primary--text"> Process Trend</v-card-title>
          <v-card-text>
            <BarChart :chartData="chartData" :chartOptions="chartOptions" />
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col lg="12" cols="sm" class="pb-2">
        <v-card class="mx-auto text-center">
          <v-card-title class="dashtitle">
            Busy and Idle Time Across Agent</v-card-title
          >
          <v-card-text>
            <BarChart
              :chartData="chartDataBot"
              :chartOptions="chartOptionsBot"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" cols="sm" class="pb-2">
        <v-card class="mx-auto text-center">
          <v-card-title class="dashtitle">
            Process Failed Vs Success</v-card-title
          >
          <v-card-text>
            <BarChart
              :chartData="chartDataProcess"
              :chartOptions="chartOptionsProcess"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="dialogpopup" scrollable>
      <!-- <v-sheet class="d-flex flex-column align-end" height="800px"> -->

      <v-card outlined>
        <!-- <div class="container"> -->
        <v-toolbar flat class="container">
          <v-toolbar-title class="toolbarclass" v-if="reason.length > 0"
            >{{ reason[0].processName }} -
            {{ reason[0].status }}</v-toolbar-title
          >
          <v-spacer> </v-spacer>

          <v-btn icon @click="closepopup()" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider class="container"></v-divider>
        <v-progress-linear
          class="container"
          indeterminate
          v-if="loadingHistory"
        ></v-progress-linear>

        <div class="header container">
          <v-icon small color="#009688" class="pr-2"
            >mdi-information-outline</v-icon
          >Process Activity
        </div>

        <v-card-text class="container">
          <v-data-table
            dense
            :headers="headersProcess"
            :items="reason"
            item-key="name"
            class="elevation-1 mt-4"
            :loading="loadingHistory"
            :sort-by="['processStartDate']"
            :sort-desc="[true]"
            :footer-props="{
              'items-per-page-options': [10],
            }"
          >
            <template v-slot:[`item.exception`]="{ item }">
              <p class="text-justify" v-if="item.exception">
                {{ truncateText(item.exception) }}
              </p>
            </template>
          </v-data-table>
        </v-card-text>

        <!-- </div> -->
      </v-card>
      <!-- </v-sheet> -->
    </v-bottom-sheet>
  </div>
</template>
<script>
import BarChart from "../components/Bar.vue";
import DoughnutChart from "../components/Doughnut.vue";
import uiPathService from "../services/uiPath.service.js";
export default {
  components: {
    BarChart,
    DoughnutChart,
  },
  computed: {
    chartOptionsBot() {
      return {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: {
            grid: {
              display: false,
            },
          },
          yAxes: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (v) {
                let duration = v * 1000;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return hours + ":" + minutes + ":" + seconds;
              },
              stepSize: 30 * 60,
            },
            display: true,
            title: {
              display: true,
              text: "Hours",
              font: {
                size: 20,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
        },

        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                let duration = tooltipItems.parsed.y * 1000;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return hours + ":" + minutes + ":" + seconds;
              },
            },
          },
        },
      };
    },

    chartOptionsProcess() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: {
            grid: {
              display: false,
            },
          },
          xAxes: {
            grid: {
              display: false,
            },
            // ticks: {
            //   callback: function (value) {
            //     console.log("value", this.chartDataProcess);
            //     return value;
            //   },
            // },
          },
        },
        onClick: this.ClickHandler,
      };
    },
  },
  data: () => ({
    processurl: false,
    processurltext: "Old URL",
    leadHistory: [],
    loadingHistory: false,
    daterange:
      new Date().toISOString().slice(0, 10) +
      " - " +
      new Date().toISOString().slice(0, 10),
    nowDate: new Date().toISOString().slice(0, 10),
    dates: [
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10),
    ],
    fromdate: new Date().toISOString().slice(0, 10),
    todate: new Date().toISOString().slice(0, 10),
    menu: false,
    process: 0,
    verifyStatus: "",
    chartDataExp: {
      labels: ["Successful", "Successful with Bussiness Exception"],
      datasets: [
        {
          backgroundColor: ["#3F51B5", "#FBC02D"],
          data: [],
        },
      ],
    },

    chartOptionsExp: {
      responsive: true,
      maintainAspectRatio: false,
    },
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Failure",
          backgroundColor: "#00BCD4",
          borderColor: "#00BCD4",
          borderWidth: 1,
          maxBarThickness: 50,
          data: [],
        },
        {
          label: "Successful",
          backgroundColor: "#3F51B5",
          borderColor: "#3F51B5",
          borderWidth: 1,
          maxBarThickness: 50,
          data: [],
        },
      ],
    },
    chartOptions: {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          grid: {
            display: false,
          },
        },
        xAxes: {
          grid: {
            display: false,
          },
        },
      },
    },
    chartDataProcess: {
      labels: [],
      datasets: [
        {
          label: "Failure",
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
          borderWidth: 1,
          maxBarThickness: 50,
          data: [],
        },
        {
          label: "Successful",
          backgroundColor: "#009688",
          borderColor: "#009688",
          borderWidth: 1,
          maxBarThickness: 50,
          data: [],
        },
      ],
    },
    chartDataDateVsProcess: {
      labels: [],
      datasets: [],
    },
    chartOptionsDateVsProcess: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          grid: {
            display: false,
          },
        },
        xAxes: {
          grid: {
            display: false,
          },
        },
      },
    },

    reason: [],
    chartDataBot: {
      labels: [],
      datasets: [
        {
          label: "Idle",
          backgroundColor: "#33ABA0",
          borderColor: "#33ABA0",
          borderWidth: 1,
          maxBarThickness: 50,
          data: [],
        },
        {
          label: "Active",
          backgroundColor: "#3F51B5",
          borderColor: "#3F51B5",
          borderWidth: 1,
          maxBarThickness: 50,
          data: [],
        },
      ],
    },
    headersProcess: [
      { text: "Transaction Number", value: "transactionNumber" },

      { text: "Process Status", value: "status" },
      { text: "Exception Type", value: "exceptionType" },
      { text: "Message", value: "exception" },
    ],
    dialogpopup: false,
  }),
  created() {
    this.getLeadHistory("", this.fromdate, this.todate);
  },

  methods: {
    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }

        this.getLeadHistory("", this.fromdate, this.todate);
      } else {
        this.fromdate = new Date().toISOString().slice(0, 10);
        this.todate = new Date().toISOString().slice(0, 10);
      }
    },
    onchangeurl() {
      if (this.processurl) {
        this.processurltext = "Elastic Search";
        this.getLeadHistory("", this.fromdate, this.todate);
      } else {
        this.processurltext = "Old URL";
        this.getLeadHistory("", this.fromdate, this.todate);
      }
    },
    async getLeadHistory(item, fromdate, todate) {
      this.reason = [];
      this.leadHistory = [];
      this.loadingHistory = true;
      // let result = await uiPathService.getProcessHistory({
      //   StartDate: fromdate,
      //   EndDate: todate,
      //   ProcessId: item,
      // });
      let result;
      if (this.processurl) {
        result = await uiPathService.getProcessHistoryElastic({
          processStartDate: fromdate,
          processEndDate: todate,
          processUniqueID: item,
        });
      } else {
        result = await uiPathService.getProcessHistory({
          StartDate: fromdate,
          EndDate: todate,
          ProcessId: item,
        });
      }
      this.loadingHistory = false;
      this.leadHistory = result.data;
      const arr = this.leadHistory.filter((t) => t.status === "Successful");
      if (this.leadHistory.length > 0) {
        this.process = (100 * arr.length) / result.data.length;
      } else {
        this.process = 0;
      }

      this.chartData.datasets[1].data = [];
      this.chartData.datasets[0].data = [];
      this.chartData.labels = [];
      this.chartDataBot.datasets[1].data = [];
      this.chartDataBot.datasets[0].data = [];
      this.chartDataBot.labels = [];

      this.chartDataProcess.datasets[1].data = [];
      this.chartDataProcess.datasets[0].data = [];
      this.chartDataProcess.labels = [];

      this.chartDataDateVsProcess.datasets = [];
      this.chartDataDateVsProcess.labels = [];

      this.chartDataExp.datasets[0].data = [];

      let currentDate = fromdate;
      let currentDate1 = new Date(currentDate);
      let dd = 0;
      while (currentDate <= todate) {
        currentDate1 = new Date(currentDate);
        this.chartData.labels.push(currentDate);

        const arr = this.leadHistory.filter(
          (t) =>
            new Date(t.processStartDate).toLocaleDateString() ===
            new Date(currentDate).toLocaleDateString()
        );
        const arr1 = arr.filter((t) => t.status === "Successful");
        this.chartData.datasets[1].data.push(arr1.length);
        this.chartData.datasets[0].data.push(arr.length - arr1.length);

        currentDate1.setDate(currentDate1.getDate() + 1);
        dd = dd + 1;

        currentDate = currentDate1.toISOString().slice(0, 10);
      }
      this.leadHistory.forEach((item) => {
        var val = this.chartDataBot.labels.find((t) => t === item.botName);
        if (!val) {
          this.chartDataBot.labels.push(item.botName);
          const arr = this.leadHistory.filter(
            (t) => t.botName === item.botName
          );
          let tt = 0;
          arr.forEach((item) => {
            tt = tt + this.diff(item.processStartDate, item.processEndDate);
          });
          let diffT = this.diffTime(tt);

          this.chartDataBot.datasets[1].data.push(diffT);
          var idl = Math.floor(24 * dd * 60 * 60);

          let diffT1 = this.diffTime(idl - tt);
          this.chartDataBot.datasets[0].data.push(diffT1);
        } else {
          return;
        }
      });

      this.leadHistory.forEach((item) => {
        if (item.processName != null) {
          var val = this.chartDataProcess.labels.find(
            (t) => t === item.processName
          );
          if (!val) {
            this.chartDataProcess.labels.push(item.processName);
            const arr = this.leadHistory.filter(
              (t) => t.processName === item.processName
            );

            const arr1 = arr.filter((t) => t.status === "Successful");
            this.chartDataProcess.datasets[1].data.push(arr1.length);
            this.chartDataProcess.datasets[0].data.push(
              arr.length - arr1.length
            );
          } else {
            return;
          }
        } else {
          return;
        }
      });

      let success = this.leadHistory.filter((process) => {
        return (
          (process.status === "Successful" && process.exceptionType === null) ||
          (process.status === "Successful" && process.exceptionType === "")
        );
      });
      let successExp = this.leadHistory.filter((process) => {
        return (
          process.status === "Successful" &&
          process.exceptionType === "Business Exception"
        );
      });
      this.chartDataExp.datasets[0].data.push(success.length);
      this.chartDataExp.datasets[0].data.push(successExp.length);
    },
    diff(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      let dif = this.diff1(toDate - fromDate);
      return dif;
    },
    diff1(duration) {
      var seconds = parseInt(duration / 1000);
      return seconds;
    },

    diffTime(duration) {
      return duration;
    },
    diffTime1(duration) {
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },

    closepopup() {
      this.dialogpopup = false;
    },
    ClickHandler: function (event, point) {
      if (point.length > 0) {
        this.dialogpopup = true;
        this.loadingHistory = true;

        let processName = this.leadHistory.filter((process) => {
          return (
            process.processName ===
              this.chartDataProcess.labels[point[0].index] &&
            process.status ===
              this.chartDataProcess.datasets[point[0].datasetIndex].label
          );
        });
        this.reason = [];
        this.reason = processName;
        this.loadingHistory = false;
      }
    },
    truncateText: (text) => {
      if (text.length > 125) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep.v-date-picker-table {
  height: 100% !important;
}
#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}
.v-card {
  box-shadow: none !important;
  background: #ffffff;
  /* Grey/100 */

  border: 1px solid #f3f3f4 !important;
  border-radius: 4px !important;
}
.card-bg {
  box-sizing: border-box;

  background: #e2e5f4;
  border: 1px solid #c6ccef;
  border-radius: 6px;
}
.imgcl {
  position: absolute;
  right: 0%;
  top: 0%;
}
.dashtitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #17181d;
}
.dashnum {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.textprog {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;

  color: #17181d;
}
.textsub {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #7f8083;
}
.v-card {
  box-shadow: none !important;
}

::v-deep .v-toolbar__content {
  padding: 4px 0px !important;
}
/deep/.v-date-picker-table {
  height: 100% !important;
}
</style>

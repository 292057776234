<template>
  <div class="container">
    <p class="header0">
      get in touch
      <label class="header2"> with us.</label>
    </p>
    <p class="normaltexttitle text-justify pb-8">
      We'd love to hear from you. Here's how you can reach us...
    </p>
    <v-row class="mt-4 pb-8 cardbackg">
      <v-col md="6" lg="6" sm="12">
        <v-card class="elevation-0" color="white">
          <v-card-text>
            <v-avatar size="64" color="#CCEAE7" class="cardicon">
              <v-icon size="36" color="secondary"> mdi-phone </v-icon>
            </v-avatar>
            <p class="header3 mt-3">Talk to Sales</p>
            <p class="normaltextcard">
              Just pick up the phone to chat with a member of our sales team.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" lg="6" sm="12">
        <v-card class="elevation-0" color="white">
          <v-card-text>
            <v-avatar size="64" color="#CCEAE7" class="cardicon">
              <v-icon size="36" color="secondary"> mdi-lifebuoy </v-icon>
            </v-avatar>
            <p class="header3 mt-3">Customer Support</p>
            <p class="normaltextcard">
              Sometimes you need a little help from your friends, or support
              representative.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <p class="headercontact">
      Contact <label class="headercontactb">us.</label>
    </p>
    <p class="contacttext text-justify pb-8">
      We're here to help and answer any question you might have. Feel free to
      <label class="contacttextb"
        ><a href="https://app.contactlink.com/to/Rajesh.Patil/" target="_blank">
          chat with us
        </a></label
      >or
      <label class="contacttextb"
        ><a href="https://app.contactlink.com/to/Rajesh.Patil/" target="_blank"
          >send message</a
        ></label
      >
    </p>
    <v-row class="mt-4 pb-8 cardbackg">
      <v-col md="6" lg="6" sm="12">
        <v-card class="elevation-0" color="white">
          <v-card-text>
            <v-avatar size="56" color="#F3F3F4" class="cardicon">
              <v-icon size="34" color="secondary"> mdi-map-marker </v-icon>
            </v-avatar>
            <p class="contacttitle mt-3">Location</p>
            <p class="contactdata">California, USA</p>
          </v-card-text>
        </v-card>
        <v-row class="mt-4 pb-8">
          <v-col md="6" lg="6" sm="12">
            <v-card class="elevation-0" color="white">
              <v-card-text>
                <v-avatar size="56" color="#F3F3F4" class="cardicon">
                  <v-icon size="34" color="secondary"> mdi-phone </v-icon>
                </v-avatar>
                <p class="contacttitle mt-3">Phone</p>
                <p class="contactdata">408-859-3121</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6" lg="6" sm="12">
            <v-card class="elevation-0" color="white">
              <v-card-text>
                <v-avatar size="56" color="#F3F3F4" class="cardicon">
                  <v-icon size="34" color="secondary"> mdi-email </v-icon>
                </v-avatar>
                <p class="contacttitle mt-3">Email</p>
                <p class="contactdata">support@digitalalign.com</p>
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
      </v-col>
      <v-col md="6" lg="6" sm="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row">
            <div class="col-lg-6 pb-0">
              <v-text-field
                solo
                v-model="firstName"
                :rules="[
                  (v) => !!v || 'First Name is required',
                  (v) => (v && !!v.trim()) || 'First Name cannot be blank',
                ]"
                required
                @keypress="onlyCharater($event)"
                v-on:keyup.enter="validate"
                validate-on-blur
              >
                <template #label> First Name </template>
              </v-text-field>
            </div>
            <div class="col-lg-6 pb-0">
              <v-text-field
                solo
                v-model="lastName"
                :rules="[
                  (v) => !!v || 'Last Name is required',
                  (v) => (v && !!v.trim()) || 'Last Name cannot be blank',
                ]"
                required
                v-on:keyup.enter="validate"
                @keypress="onlyCharater($event)"
                validate-on-blur
              >
                <template #label> Last Name </template>
              </v-text-field>
            </div>
            <div class="col-12 py-0">
              <v-text-field
                class="mt-1"
                solo
                v-model="emailId"
                :rules="emailRules"
                required
                validate-on-blur
                v-on:keyup.enter="validate"
              >
                <template #label> Email Id </template>
              </v-text-field>
            </div>
            <div class="col-12 py-0">
              <v-textarea
                solo
                validate-on-blur
                v-model="message"
                :rules="descrule"
                label="Your Message "
                v-on:keyup.enter="validate"
                required
              ></v-textarea>
            </div>
          </div>

          <v-btn
            dark
            x-large
            color="buttonColor"
            class="btn-block mt-2"
            @click="validate"
          >
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import userService from "@/services/user.service.js";
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  data: () => ({
    status: "",
    valid: false,
    firstName: "",
    lastName: "",
    emailId: "",
    message: "",
    descrule: [
      (v) => !!v || "Message is required",
      (v) =>
        (v || "").length <= 800 || "Message must be 800 characters or less",
      (v) => (v && !!v.trim()) || "Message cannot be blank",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    onlyCharater($event) {
      if (
        $event.charCode === 0 ||
        /^[A-Za-z ]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.contactUs({
          emailId: this.emailId,
          firstName: this.firstName,
          lastName: this.lastName,
          message: this.message,
          source: "Marketplace",
        });
        this.reset();
        this.resetValidation();
      }
    },

    async contactUs(input) {
      let result = await userService.contactUs(input);
      if (result.status == 200) {
        this.status = "Thank you for contacting us";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";
.header0 {
  font-weight: 300;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: #17181d;
}
.header1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: #17181d;
}
.header2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: $primary;
}
.header3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.headercontact {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.headercontactb {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: $primary;
}
.normaltext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  /* Secondary/500 */

  color: #17181d;
}
.contacttext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #17181d;
}
.contacttextb {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $primary;
}
.contacttitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #7f8083;
}
.contactdata {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  /* Secondary/500 */

  color: #17181d;
}
.normaltextcard {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  /* Secondary/400 */

  color: #17181d;
}
.normaltexttitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  /* Secondary/400 */

  color: #45464a;
}
.links {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #009688;
}
.cardicon {
  // background: #cceae7 !important;
  border-radius: 4px !important;
  padding: 8px !important;
}
.cardbackg {
  filter: drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.05));
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","content-class":'custom-dialog-scrollbar'},model:{value:(_vm.toogle),callback:function ($$v) {_vm.toogle=$$v},expression:"toogle"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.__orgName)+"  Process History ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){(_vm.toogle = false), (_vm.statusSwitch = false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider'),_c('v-card-title',[_c('v-spacer'),_c('v-switch',{attrs:{"inset":"","label":_vm.statusSwitch ? 'Successful' : 'Failed'},model:{value:(_vm.statusSwitch),callback:function ($$v) {_vm.statusSwitch=$$v},expression:"statusSwitch"}}),_c('v-text-field',{staticClass:"col-lg-3 col-md-3 col-12 ml-2",attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":"","single-line":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.selectedItem?.processHistory != null
              ? _vm.selectedItem?.processHistory?.filter((ele) =>
                  _vm.statusSwitch
                    ? ele.status == 'Successful'
                    : ele.status == 'Failure' || ele.status == 'Failed'
                )
              : [],"footer-props":{
            'items-per-page-options': [-1],
          },"hide-default-footer":"","sort-by":['status'],"sort-desc":[false],"group-by":['processUniqueID'],"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:`item.processStartDate`,fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(new Date(item.processStartDate).toLocaleString("en-US", _vm.options))+" ")])]}},{key:`item.processEndDate`,fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(new Date(item.processEndDate).toLocaleString("en-US", _vm.options))+" ")])]}},{key:`item.lastHeartBeatDT`,fn:function({ item }){return [_c('td',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.__getTimeDiff(item.lastHeartBeatDT) > 1 ? '#E64A19' : '#4caf50cc'}},[_vm._v(" mdi-heart-pulse ")]),_vm._v(" "+_vm._s(item.lastHeartBeatDT)+" ")],1)]}},{key:`item.status`,fn:function({ item }){return [_c('td',[_c('v-chip',{attrs:{"label":"","small":"","color":_vm.getColor(item.status),"text-color":_vm.getTextColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
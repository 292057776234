<template>
  <div class="container-fluid">
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>

              <div class="col-lg-2 col-sm-4">
                <v-btn
                  dark
                  class="mt-lg-4 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Client</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items-per-page="10"
        :search="filter.searchKey"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :sort-by="['createdTime']"
        :sort-desc="[true]"
        :items="clientList"
        :loading="loading"
        class="elevation-1"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
        @item-expanded="loadDetails"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.isActive"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.createdTime`]="{ item }">
          <td>{{ new Date(item.createdTime).toLocaleString() }}</td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              :headers="headersChild"
              :items="item.products"
              item-key="id"
              :sort-by="['id']"
              :sort-desc="[true]"
              class="elevation-1 grey lighten-3"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Products</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    class="ma-3"
                    @click="
                      dialogProduct = true;
                      editedProductIndex = -1;
                      editedProduct.clientId = item.id;
                      getproducts(item);
                    "
                  >
                    <v-icon dark> mdi-plus </v-icon> Add Product</v-btn
                  >
                </v-toolbar>
              </template>
              <template #[`item.startDate`]="{ item }">
                <td v-if="item.startDate">
                  {{ new Date(item.startDate).toDateString() }}
                </td>
                <td v-else class="red--text">Edit Start date</td>
              </template>
              <template #[`item.endDate`]="{ item }">
                <td v-if="item.endDate">
                  {{ new Date(item.endDate).toDateString() }}
                </td>
                <td v-else class="red--text">Edit End date</td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="edit(item, clientId)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="remove(item, clientId)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <td class="font-weight-bold">
            {{ item.name }}
          </td>
        </template>
      </v-data-table>
    </v-card>
    <!---Add or Update the process -->
    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select
              class="company-size-dropdown"
              :items="gridData"
              item-text="name"
              item-value="id"
              v-model="editedItem.organizationId"
              label="Client"
              required
              return-object
              :rules="[(v) => !!v || 'Client is required']"
            >
              <template #label>
                Client <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-text-field
              v-model="editedItem.emailId"
              label="Email Id"
              required
              :rules="[
                (v) => !!v || 'Email Id is required',
                (v) => /.+@.+\..+/.test(v) || 'Email Id must be valid',
                (v) => (v && !!v.trim()) || ' Email Id cannot be blank',
                (v) =>
                  (v && !clientList.find((t) => t.email.trim() == v.trim())) ||
                  'Duplicate Email Id',
              ]"
              validate-on-blur
              @keypress.native="noSpace($event)"
            >
              <template #label>
                Email Id
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="editedItem.requestedBy"
              required
              :rules="[
                (v) => !!v || 'Requested By is required',
                (v) => (v && !!v.trim()) || 'Requested By cannot be blank',
              ]"
            >
              <template #label>
                Requested By <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-select
              :items="productList.data"
              item-text="name"
              item-value="code"
              v-model="editedItem.products"
              multiple
              required
              :rules="[(v) => !!v || 'Product is required']"
              ><template #label>
                Products <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="onUpdate"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to
            {{ activationStatus == true ? "Activate" : "Deactivate" }}
            this Client?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->
    <v-dialog v-model="dialogProduct" persistent max-width="500px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6"> {{ formTitleProd }}</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid" lazy-validation>
          <v-card-text>
            <v-select
              v-if="editedProductIndex == -1"
              :items="gridDataProduct"
              item-text="name"
              item-value="code"
              v-model="editedProduct.code"
              required
              :rules="[(v) => !!v || 'Product is required']"
              ><template #label>
                Product <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-menu
              ref="menustart"
              v-model="menustart"
              :close-on-content-click="false"
              :return-value.sync="editedProduct.startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedProduct.startDate"
                  label="Start Date"
                  append-icon="mdi-calendar"
                  @click:append="on.click"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Start Date Required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editedProduct.startDate"
                no-title
                scrollable
                :max="editedProduct.endDate ? editedProduct.endDate : nowDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menustart = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menustart.save(editedProduct.startDate),
                      startdateText()
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menuend"
              v-model="menuend"
              :close-on-content-click="false"
              :return-value.sync="editedProduct.endDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedProduct.endDate"
                  label="End Date"
                  append-icon="mdi-calendar"
                  readonly
                  @click:append="on.click"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'End Date Required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editedProduct.endDate"
                no-title
                scrollable
                :max="nowDate"
                :min="editedProduct.startDate ? editedProduct.startDate : ''"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuend = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menuend.save(editedProduct.endDate), enddateText()
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-if="editedProductIndex == -1"
              v-model="editedProduct.requestedBy"
              required
              :rules="[
                (v) => !!v || 'Requested By is required',
                (v) => (v && !!v.trim()) || 'Requested By cannot be blank',
              ]"
            >
              <template #label>
                Requested By <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeProductDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onAddProduct"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProductDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title
            >Are you sure you want to delete this Product?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="dialogProductDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteProductConfirm"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogContact" max-width="500px">
      <v-card
        height="auto"
        class="text-center justify-center"
        v-if="displayCodeItem.data"
      >
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-justify1">
          <v-card-subtitle class="text-center texttitle"
            >Secret Code Details</v-card-subtitle
          >
          <div>
            <div class="font-weight-normal">
              <div class="text-center textsubtitle">Client ID</div>
              <strong class="mr-1">{{ displayCodeItem.data.clientId }}</strong>
              <v-tooltip bottom v-if="displayCodeItem.data.clientId">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-clipboard:copy="displayCodeItem.data.clientId"
                    size="50"
                    small
                    class="mr-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy Client ID</span>
              </v-tooltip>
            </div>
            <div>
              <div class="text-center textsubtitle mt-2">Client Secret</div>
              <strong class="mr-1">{{
                displayCodeItem.data.clientSecret
              }}</strong>
              <v-tooltip bottom v-if="displayCodeItem.data.clientSecret">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-clipboard:copy="displayCodeItem.data.clientSecret"
                    size="50"
                    small
                    class="mr-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy Client Secret</span>
              </v-tooltip>
            </div>
            <div class="text-center textsubtitle red--text mt-3">
              *Make a note of client secret code details
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="text-capitalize grey lighten-2" @click="closeContact"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script>
import alignAPIService from "@/services/alignApi.service.js";
import orgService from "@/services/organization.service.js";
import uiPathService from "@/services/uiPath.service.js";
export default {
  computed: {
    gridData() {
      if (!(this.regOrganization && this.clientList)) return [];

      let res = [];
      res = this.regOrganization.filter((el) => {
        return !this.clientList.find((element) => {
          return element.name === el.name;
        });
      });
      return res;
    },
    gridDataProduct() {
      if (!this.productList.data) return [];

      let res = [];
      res = this.productList.data.filter((el) => {
        return !this.client.find((element) => {
          return element.code === el.code;
        });
      });
      return res;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Client" : "Edit Client";
    },
    formTitleProd() {
      return this.editedProductIndex === -1
        ? "New Product"
        : "Edit Product " + this.editedProduct.code;
    },
  },
  data: () => ({
    menustart: false,

    menuend: false,

    nowDate: new Date().toISOString().slice(0, 10),
    dialogDelete: false,
    dialogProductDelete: false,
    valid: false,
    dialog: false,
    expanded: [],
    removeId: -1,
    editedProductIndex: -1,
    client: [],
    clientId: 0,
    editedProduct: {
      id: 0,
      code: "",
      clientId: 0,
      startDate: "",
      endDate: "",
      requestedBy: "",
      startdate: "",
      enddate: "",
    },
    defaultProductItem: {
      id: 0,
      code: "",
      clientId: 0,
      startDate: "",
      endDate: "",
      requestedBy: "",
      startdate: "",
      enddate: "",
    },
    singleExpand: true,
    headers: [
      { text: "Name", value: "name", sortable: false, width: "20%" },
      {
        text: "Email ID",
        value: "email",
        sortable: false,
      },

      { text: "requestedBy", value: "requestedBy", sortable: false },
      { text: "Created On", value: "createdTime", sortable: false },
      { text: "Status", value: "isActive", sortable: false },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
      },
    ],
    headersChild: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Code",
        value: "code",
        sortable: false,
      },
      {
        text: "Description",
        sortable: false,
        value: "description",
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
      },
      {
        text: "Start Date",
        sortable: false,
        value: "startDate",
      },
      {
        text: "End Date",
        value: "endDate",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    activationStatus: true,
    dialogProduct: false,
    filter: {
      searchKey: "",
      categoryId: "",
    },
    dialogContact: false,
    regOrganization: [],
    editedItem: {
      id: 0,
      requestedBy: "",
      organizationId: {},
      emailId: "",
      products: [],
    },
    defaultItem: {
      id: 0,
      requestedBy: "",
      organizationId: {},
      emailId: "",
      products: [],
    },
    clientList: [],
    productList: {},
    loading: false,
    product: {},
    displayCodeItem: {},
  }),
  created() {
    this.getAllClient();
    this.getAllProducts();
    this.getOrganization();
  },

  methods: {
    truncate(source, size) {
      if (source == null) {
        return source;
      } else {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
    },
    remove(item, id) {
      // this.clientId = item.id;
      var valObj = this.clientList.filter(function (elem) {
        if (elem.id == id) return elem;
      });
      if (valObj.length > 0) {
        this.client = valObj[0].products;
      }
      if (this.client.length > 1) {
        this.removeId = item.id;
        this.dialogProductDelete = true;
      } else {
        this.statusE = "Atleast 1 Product is Mandatory";
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    deleteProductConfirm() {
      this.deleteProduct({ id: this.removeId });
      this.dialogProductDelete = false;
    },
    async deleteProduct(item) {
      let result = await alignAPIService.deleteClientProduct(item);
      if (result.status == 200) {
        this.status = "Product deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllClient();
      }
    },

    startdateText() {
      if (this.editedProduct.startDate) {
        this.editedProduct.startdate = new Date(
          this.editedProduct.startDate
        ).toISOString();
      } else {
        this.editedProduct.startdate = "";
      }
    },

    enddateText() {
      if (this.editedProduct.endDate) {
        this.editedProduct.enddate = new Date(
          this.editedProduct.endDate
        ).toISOString();
      } else {
        this.editedProduct.enddate = "";
      }
    },
    edit(item, id) {
      var valObj = this.clientList.filter(function (elem) {
        if (elem.id == id) return elem;
      });
      if (valObj.length > 0) {
        this.client = valObj[0].products;
      }
      this.editedProductIndex = this.client.indexOf(item);
      this.editedProduct = Object.assign({}, item);
      if (item.startDate) {
        this.editedProduct.startDate = item.startDate.slice(0, 10);

        this.editedProduct.startdate = item.startDate;
      } else {
        this.editedProduct.startDate = "";
      }
      if (item.endDate) {
        this.editedProduct.endDate = item.endDate.slice(0, 10);
        // .toISOString()
        // .slice(0, 10);
        this.editedProduct.enddate = item.endDate;
      } else {
        this.editedProduct.endDate = "";
      }

      this.dialogProduct = true;
    },

    closeDialog() {
      this.dialogDelete = false;
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    deleteItem(item) {
      this.activationStatus = item.isActive;
      this.editedIndex = this.clientList.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.clientList[this.editedIndex].id;

      this.delete({ id: id, status: this.activationStatus });

      this.dialogDelete = false;
    },
    resetStatus() {
      this.clientList[this.editedIndex].isActive =
        this.clientList[this.editedIndex].isActive == true ? false : true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
      this.editedProductIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },

    loadDetails({ item, value }) {
      if (value) {
        this.client = item.products;
        this.clientId = item.id;
      }
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let formData = {
          name: this.editedItem.organizationId.name,
          emailId: this.editedItem.emailId,
          requestedBy: this.editedItem.requestedBy,
          organizationId: this.editedItem.organizationId.id,
          products: this.editedItem.products,
        };
        this.create(formData);

        this.closeDialog();
      }
    },

    async create(item) {
      let result = await uiPathService.register(item);

      if (result.status == 200) {
        if (result.data.statusCode == 200) {
          this.status = "Client Registered successfully";
          this.displayCodeItem = result.data.value;
          this.dialogContact = true;

          setTimeout(() => {
            this.status = "";
          }, 2000);
          this.getAllClient();
        } else {
          this.statusE = result.data.value;
          setTimeout(() => {
            this.statusE = "";
          }, 2000);
        }
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await alignAPIService.update(item, id);
      if (result.status == 200) {
        this.status = "Process updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await alignAPIService.disableOrEnable(item);
      if (result.status == 200) {
        if (item.status == true) {
          this.status = "Client activated successfully";
        } else {
          this.status = "Client deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    async getAllClient() {
      this.loading = true;
      let result = await alignAPIService.getAllClient();
      this.loading = false;
      this.clientList = result.data;
    },
    getproducts(item) {
      this.client = item.products;
      this.clientId = item.id;
    },
    async getAllProducts() {
      let result = await alignAPIService.getAllProducts();

      this.productList = result;
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    onAddProduct() {
      let valid = this.$refs.form1.validate();

      if (valid) {
        if (this.editedProductIndex == -1) {
          this.createProduct({
            productCode: this.editedProduct.code,
            clientId: this.editedProduct.clientId,
            startDate: this.editedProduct.startdate,
            endDate: this.editedProduct.enddate,
            requestedBy: this.editedProduct.requestedBy,
          });
        } else {
          this.editProduct({
            productCode: this.editedProduct.code,
            clientId: this.clientId,
            startDate: this.editedProduct.startdate,
            endDate: this.editedProduct.enddate,
            requestedBy: this.editedProduct.requestedBy,
          });
        }
        this.closeProductDialog();
      }
    },

    async createProduct(code) {
      let result = await alignAPIService.createClientProduct(code);

      if (result.status == 200) {
        this.status = "Product added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.editedProductIndex = -1;
        this.editedProductItem = Object.assign({}, this.defaultProductItem);
        this.getAllClient();
      }
    },
    async editProduct(code) {
      let result = await alignAPIService.editClientProduct(code);

      if (result.status == 200) {
        this.status = "Product updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.editedProductIndex = -1;
        this.editedProductItem = Object.assign({}, this.defaultProductItem);
        this.getAllClient();
      }
    },
    closeContact() {
      this.dialogContact = false;

      this.displayCodeItem = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.custom-bg {
  color: gray;
}
/deep/.style-1 {
  background: #e4e7ea !important;
  opacity: 0.5;
}
.textsubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Grey/700 */

  color: #7f8083;

  transform: matrix(1, 0, 0, 1, 0, 0);
}
.texttitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* Secondary/500 */

  color: #17181d;

  transform: matrix(1, 0, 0, 1, 0, 0);
}
/deep/.v-date-picker-table {
  height: 100% !important;
}
</style>

<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title class="tabtitle">
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          dense
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-if="license"
        :headers="headers"
        :server-items-length="license.length"
        :items-per-page="10"
        :items="license"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.isActive"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>

        <template v-slot:[`item.addedOn`]="{ item }">
          <td>{{ new Date(item.addedOn).toLocaleDateString() }}</td>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <td>
            {{ new Date(item.endDate).toLocaleDateString() }}
          </td>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <td>{{ new Date(item.startDate).toLocaleDateString() }}</td>
        </template>
        <template v-slot:[`item.expiry`]="{ item }">
          <v-chip
            v-if="item.expiry"
            label
            small
            :color="getColorStatus(item.expiry)"
            :text-color="getTextColor(item.expiry)"
            dark
          >
            <span v-if="item.expiry >= 0 && item.expiry <= 7"
              >About to Expire</span
            >
            <span v-else-if="item.expiry < 0">Expired</span>
            <span v-else>In Use</span>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import licenseService from "@/services/licenseExpiry.service.js";

export default {
  data: () => ({
    licenseList: {},
    license: [],
    loading: false,

    headers: [
      {
        text: "License key",
        value: "licenseKey",
        sortable: false,
      },
      {
        text: "License Type",
        value: "licenseType",
        sortable: false,
      },
      {
        text: "Start Date",
        sortable: false,
        value: "startDate",
      },
      {
        text: "End Date",
        value: "endDate",
        sortable: false,
      },
      {
        text: "Alert",
        value: "expiry",
        sortable: false,
      },
    ],

    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    searchKey: "",
  }),

  methods: {
    // getTextColor(item) {
    //   if (item >= 0 && item <= 7) return "orange--text";
    //   else if (item < 0) return "red--text";
    //   else return "black--text";
    // },
    getTextColor(item) {
      if (item >= 0 && item <= 7) return "#FFA500";
      else if (item < 0) return "#E64A19";
      else return "#00796B";
    },
    getColorStatus(item) {
      if (item >= 0 && item <= 7) return "#fff6e6";
      else if (item < 0) return "#FDEDE8";
      else return "#E6F2F1";
    },
    calculate(date) {
      var date1 = new Date();
      var date2 = new Date(date);
      date1.setDate(date2.getDate() - 7);
      var date3 = new Date();
      const diffTime = date2 - date3;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log("diffTime", date2 - date3);
      // date > Date.now()
      // console.log(diffDays + " days");
      if (diffDays > 0) {
        return diffDays;
      } else {
        return -1;
      }
    },

    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      this.loading = true;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
      };
      this.getAllLicense(data);
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.selectedOrg,
      };
      this.getAllLicense(data);
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      this.loading = true;
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.selectedOrg,
      };
      this.getAllLicense(data);
    },

    async getAllLicense(input) {
      this.loading = true;
      let result = await licenseService.getAllLicense(input);
      this.loading = false;
      if (result.records.length > 0) {
        let j = 0;
        for (j = 0; j < result.records.length; j++) {
          if (result.records[j].license.length > 0) {
            let i = 0;
            for (i = 0; i < result.records[j].license.length; i++) {
              result.records[j].license[i].expiry = this.calculate(
                result.records[j].license[i].endDate
              );
            }
          }
          // result.records[j].expiry = this.calculate(result.records[j].endDate);
        }
        // console.log("resp.data", result);
      }

      this.licenseList = result;
      this.license = result.records[0].license;
      // console.log(" this.license", this.license);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/colors.scss";
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}

::v-deep .v-toolbar__content {
  padding: 4px 0px !important;
}
.textsubtitle {
  font-weight: 500;
  font-size: 20px !important;
  line-height: 23px;
  /* Secondary/500 */
  color: $secondary !important;
}

td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-sm-6 col-md-4"},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":"","append-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"click:append":_vm.search,"click:clear":_vm.clear},model:{value:(_vm.filter.searchKey),callback:function ($$v) {_vm.$set(_vm.filter, "searchKey", $$v)},expression:"filter.searchKey"}})],1),_c('div',{staticClass:"col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0"},[_c('v-btn',{staticClass:"mt-lg-3 mt-0",attrs:{"dark":""},on:{"click":function($event){_vm.dialog = true;
                  _vm.editedIndex = -1;}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add Role")],1)],1)])])])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"server-items-length":_vm.roleList.total,"items-per-page":10,"items":_vm.roleList.records,"footer-props":{
        'items-per-page-options': [10],
      },"loading":_vm.loading},on:{"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"size":"50","small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"25","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Role")])])]}},{key:`item.modifiedOn`,fn:function({ item }){return [(item.modifiedOn)?_c('td',[_vm._v(_vm._s(new Date(item.modifiedOn).toDateString()))]):_vm._e()]}},{key:`item.addedOn`,fn:function({ item }){return [(item.addedOn)?_c('td',[_vm._v(_vm._s(new Date(item.addedOn).toDateString()))]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":"auto"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"required":"","rules":[
              (v) => !!v || 'Name is required',
              (v) => (v && !!v.trim()) || 'Name cannot be blank',
            ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.editedItem.roleName),callback:function ($$v) {_vm.$set(_vm.editedItem, "roleName", $$v)},expression:"editedItem.roleName"}}),_c('v-textarea',{attrs:{"required":"","rules":[
              (v) => !!v || 'Description is required',
              (v) => (v && !!v.trim()) || 'Description cannot be blank',
            ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize grey lighten-2",on:{"click":_vm.closeDialog}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"black","depressed":""},on:{"click":_vm.onUpdate}},[_vm._v(" Submit ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{staticClass:"text-center",attrs:{"height":"auto"}},[_c('img',{staticClass:"mt-7",attrs:{"src":require('@/assets/logoDA.svg')}}),_c('v-card-text',{staticClass:"text-center"},[_c('v-card-title',{staticClass:"text-justify1"},[_vm._v("Are you sure you want to delete this Role?")])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize grey lighten-2",on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"black","depressed":""},on:{"click":_vm.deleteConfirm}},[_vm._v("OK")])],1)],1)],1),(_vm.status)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e(),(_vm.statusE)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.statusE)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
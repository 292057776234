<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="getOList"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  @change="onChange"
                ></v-select>
              </div>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="daterange"
                      label="Date Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="clearDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    no-title
                    scrollable
                    range
                    :max="nowDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize grey lighten-2"
                      @click="menu = false"
                      >Cancel
                    </v-btn>
                    <v-btn dark @click="$refs.menu.save(dates), dateRangeText()"
                      >OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="allLeads.records"
        :items-per-page="10"
        :server-items-length="allLeads.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :single-expand="singleExpand"
        show-expand
        @item-expanded="loadDetails"
      >
        <template v-slot:[`item.imageURL`]="{ item }">
          <img :src="item.imageURL" width="50px" />
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toDateString() }}</td>
        </template>

        <template v-slot:[`item.modifiedOn`]="{ item }">
          <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td
                class="cursor-point"
                @click="statusItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  class="
                    cursor-point
                    block
                    font-weight-black
                    text-capitalize
                    pa-0
                  "
                  plain
                >
                  {{ newStatus(item.status) }}
                  <v-icon right> mdi-dots-vertical </v-icon>
                </v-btn>
              </td>
            </template>
            <span>Edit Status</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="contactItem(item.user)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-phone
              </v-icon>
            </template>
            <span>Customer Contact Information</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.historyactions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="historyItem(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-page-previous
              </v-icon>
            </template>
            <span>Lead History</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-0 py-0" :colspan="headers.length">
            <div class="col-12 offset-lg-0 offset-xs-0 text-right">
              <v-btn
                dark
                class="ma-3"
                @click="
                  dialog = true;
                  editedNoteIndex = -1;
                  editedNote.clientProcessId = item.id;
                "
              >
                <v-icon dark> mdi-plus </v-icon> Add Note</v-btn
              >
            </div>

            <v-data-table
              :headers="headersChild"
              :items="allNotes"
              item-key="id"
              class="elevation-1 grey lighten-3"
            >
              <template #[`item.modifiedOn`]="{ item }">
                <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="remove(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogStatus" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Status</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card-text>
            <v-container>
              <div d-flex d-flex-column>
                <v-select
                  :items="statuslist"
                  item-text="name"
                  item-value="value"
                  v-model="editedStatus.status"
                  label="Select Status"
                  :clearable="false"
                ></v-select>
                <v-text-field
                  class="mt-1"
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.releaseProcessName"
                  :rules="[
                    (v) => !!v || 'Release name is required',
                    (v) => (v && !!v.trim()) || 'Release name cannot be blank',
                  ]"
                  required
                >
                  <template #label>
                    Release name
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  class="mt-1"
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.serverProcessId"
                  :rules="[
                    (v) => !!v || 'Process Id is required',
                    (v) => (v && !!v.trim()) || 'Process Id cannot be blank',
                  ]"
                  required
                >
                  <template #label>
                    Process Id
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
              </div>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize grey lighten-2" @click="close">
            Cancel
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogContact" max-width="500px">
      <v-card height="auto" class="text-center justify-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-justify1">
          <v-card-title class="text-center">Contact Details</v-card-title>
          <div>
            <div class="font-weight-normal">
              <strong>{{ editedItem.name }}</strong>
            </div>
            <div>
              <a :href="'mailto:' + editedItem.emailId">{{
                editedItem.emailId
              }}</a>
            </div>
            <div>{{ editedItem.mobileNo }}</div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeContact"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHistory" max-width="500px">
      <v-card height="auto" class="text-center justify-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-justify1">
          <v-card-title class="text-center">Lead History</v-card-title>
          <v-progress-linear
            indeterminate
            v-if="loadingHistory"
          ></v-progress-linear>
          <div v-if="leadHistory.length > 0">
            <v-list>
              <template v-for="(item, index) in leadHistory">
                <v-list-item :key="item.id">
                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{
                      newStatus(item.status)
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="
                        new Date(
                          new Date(item.createdOn).getTime() -
                            new Date(item.createdOn).getTimezoneOffset() * 60000
                        ).toLocaleString()
                      "
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="index < leadHistory.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
          <div v-else-if="loadingHistory == false">No record found</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeHistory"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-textarea
              v-model="editedNote.message"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Note is required',
                (v) => (v && !!v.trim()) || 'Note cannot be blank',
                (v) =>
                  (v || '').length <= 100 ||
                  'Note must be 100 characters or less',
              ]"
            >
              <template #label>
                Note <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title
            >Are you sure you want to delete this note?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import orgService from "@/services/organization.service.js";
import leadService from "@/services/lead.service.js";
import EventBus from "../plugins/eventBus";

export default {
  created() {
    this.getOrganization();
    this.editedStatus.status = this.statusNo;

    EventBus.on("status", (x) => {
      if (x == "Cancelled") {
        this.updatePagination({ page: this.pageIndex });
      }
    });
  },

  computed: {
    getOList() {
      var arr = Object.assign([], this.allOrganization);
      arr.unshift({ id: "", name: "All" });

      return arr;
    },
    formTitle() {
      return this.editedNoteIndex === -1 ? "New Note" : "Edit Note";
    },

    headers() {
      let headers = [
        {
          text: "Lead ID",
          sortable: false,
          value: "id",
        },
        {
          text: "Organization",
          align: "start",
          sortable: false,
          value: "organization",
        },
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "imageURL",
        },
        { text: "Name", value: "name", width: "200px", sortable: false },

        { text: "Contact", value: "actions", sortable: false },
        { text: "History", value: "historyactions", sortable: false },
        {
          text: "Ordered on",
          value: "createdOn",
          sortable: false,
        },
        {
          text: "Modified on",
          value: "modifiedOn",
          sortable: false,
        },
        { text: "Status", value: "status", sortable: false },
      ];

      headers.push({
        text: "",
        value: "data-table-expand",
        sortable: false,
      });

      return headers;
    },
  },
  watch: {
    dialogContact(val) {
      val || this.closeContact();
    },
    dialogHistory(val) {
      val || this.closeHistory();
    },
    dialogStatus(val) {
      val || this.close();
    },
  },
  destroyed() {
    EventBus.off("status");
  },
  data: () => ({
    statusNo: "Cancelled",
    allOrganization: [],
    allLeads: {},
    loading: false,
    loadingHistory: false,
    leadHistory: [],
    daterange: "",
    nowDate: new Date().toISOString().slice(0, 10),
    dates: [],
    fromdate: "",
    todate: "",
    menu: false,
    singleExpand: true,
    dialogContact: false,
    dialogHistory: false,
    dialog: false,
    dialogStatus: false,
    dialogDelete: false,
    orderid: "",
    selectedOrg: "",
    valid: false,
    valid1: false,
    status: "",
    noteid: 0,
    items: "",
    releaseName: "",
    statuslist: [
      { name: "New", value: "Pending" },

      { name: "In Progress", value: "InProgress" },
      { name: "Confirmed", value: "Confirmed" },
      { name: "UAT", value: "UAT" },
      { name: "Implemented", value: "Implemented" },
      { name: "Cancelled", value: "Cancelled" },
    ],
    filter: {
      searchKey: "",
      sortBy: "",
    },
    expanded: [],

    headersChild: [
      {
        text: "Notes",
        align: "start",
        sortable: false,
        value: "message",
      },
      {
        text: "Created On",
        value: "modifiedOn",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],

    selectedBot: {},
    pageIndex: 0,
    editedIndex: -1,
    editedNoteIndex: -1,
    editedStatusIndex: -1,
    editedItem: {
      name: "",
      mobileNo: "",
      emailId: "",
    },
    editedNote: {
      id: 0,
      message: "",
      clientProcessId: 0,
    },
    removeId: -1,
    allNotes: [],
    defaultItem: {
      name: "",
      mobileNo: "",
      emailId: "",
    },
    editedStatus: {
      id: "",
      status: "",
      releaseProcessName: "",
      serverProcessId: "",
    },
    defaultStatus: {
      id: "",
      status: "",
      releaseProcessName: "",
      serverProcessId: "",
    },
  }),

  methods: {
    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }
        let data = {
          Skip: 0,
          Take: 10,
          SearchKey: this.filter.searchKey,
          Status: this.statusNo,
          OrganizationId: this.selectedOrg,
          FromDate: this.fromdate,
          ToDate: this.todate,
        };
        this.getAllLeads(data);
      } else {
        this.fromdate = "";
        this.todate = "";
      }
    },
    onChange() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },

    save() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.updateStatus({
          status: this.editedStatus.status,
          clientProcessId: this.editedStatus.id,
        });
        if (this.editedStatus.status == "Implemented") {
          this.updateReleaseName(
            {
              releaseProcessName: this.editedStatus.releaseProcessName,
              serverProcessId: this.editedStatus.serverProcessId,
            },
            this.editedStatus.id
          );
        }

        this.close();
      }
    },

    async updateStatus(input) {
      let result = await leadService.changeStatus(input);
      if (result.status == 200) {
        this.status = "Status updated successfully";

        EventBus.emit("status", input.status);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    async updateReleaseName(input, id) {
      let result = await leadService.updateReleaseName(input, id);
      if (result.status == 200) {
        this.status = "Release name added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    cancel() {},
    search() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      this.filter.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    clearDate() {
      this.dates = [];
      this.fromdate = "";
      this.todate = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    contactItem(item) {
      this.editedIndex = this.allLeads.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogContact = true;
    },
    closeContact() {
      this.dialogContact = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async historyItem(item) {
      this.loadingHistory = true;
      let result = await leadService.getLeadHistory({ clientProcessId: item });
      this.loadingHistory = false;
      this.leadHistory = result;
      this.dialogHistory = true;
    },
    closeHistory() {
      this.dialogHistory = false;
    },
    loadDetails({ item, value }) {
      if (value) {
        this.noteid = item.id;
        this.getAllNotes(item.id);
      }
    },
    async getAllNotes(id) {
      this.allNotes = [];
      let result = await leadService.getAllNotes({ clientProcessId: id });
      this.allNotes = result;
    },
    editItem(item) {
      this.editedNoteIndex = this.allNotes.indexOf(item);
      this.editedNote = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedNoteIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    onUpdate() {
      let valid = this.$refs.form.validate();

      if (valid) {
        if (this.editedNoteIndex == -1) {
          this.createNote(
            this.editedNote.message,
            this.editedNote.clientProcessId
          );
        } else {
          const id = this.allNotes[this.editedNoteIndex].id;

          this.editedNote.id = id;
          this.updateNote(this.editedNote);
        }
        this.closeDialog();
      }
    },

    async createNote(note, id) {
      let result = await leadService.create(note, id);

      if (result.status == 200) {
        this.status = "Note added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },
    async updateNote(item) {
      let result = await leadService.update(item);
      if (result.status == 200) {
        this.status = "Note updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },
    async deleteNote(item) {
      let result = await leadService.delete(item);
      if (result.status == 200) {
        this.status = "Note deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },

    remove(item) {
      this.removeId = item.id;
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.deleteNote({ id: this.removeId });
      this.dialogDelete = false;
    },
    statusItem(item) {
      this.editedStatusIndex = this.allLeads.records.indexOf(item);
      this.editedStatus = Object.assign({}, item);

      this.dialogStatus = true;
    },
    close() {
      this.dialogStatus = false;

      this.editedStatusIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "Pending") {
        newstatus = "New";
      } else if (id == "Implemented") {
        newstatus = "Implemented";
      } else if (id == "Confirmed") {
        newstatus = "Confirmed";
      } else if (id == "InProgress") {
        newstatus = "In Progress";
      } else if (id == "UAT") {
        newstatus = "UAT";
      } else if (id == "Cancelled") {
        newstatus = "Cancelled";
      }
      return newstatus;
    },

    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.allOrganization = result;
    },
    async getAllLeads(input) {
      this.loading = true;
      let result = await leadService.getAllLeads(input);
      this.loading = false;
      this.allLeads = result;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.v-card__title {
  display: block !important;
}
.cursor-point {
  cursor: pointer;
}
/deep/.v-date-picker-table {
  height: 100% !important;
}
</style>
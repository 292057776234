<template>
  <div class="container mb-5">
    <v-progress-linear indeterminate v-if="loading1"></v-progress-linear>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <div
      class="text-center justify-center emptyCart"
      v-if="cartlist.length == 0 && loading1 == false"
    >
      <!-- <img v-if="logo" :src="logo" height="80" width="80" />
      <img
        v-else
        :src="require('@/assets/logoDA.svg')"
        height="80"
        width="80"
      /> -->
      <img :src="require('@/assets/emptycart.svg')" height="180" width="180" />
      <h2 class="mt-5 carttext">Your cart is currently empty.</h2>
      <v-btn
        color="primary"
        class="white--text mt-5 text-capitalize"
        large
        depressed
        to="/processes"
      >
        Add workflows to cart
        <!-- <v-avatar right size="18" class="ml-2 text-right">
          <img src="../assets/Arrow.png" />
        </v-avatar> -->
      </v-btn>
    </div>
    <div class="row" v-else>
      <!-- <div class="col-12" v-if="loading1 == false">
        <h3>{{ organizationName }} Cart</h3>
      </div> -->
      <div class="col-12 col-md-8">
        <v-list class="vlistcolor">
          <v-list-item
            class="py-2 list-box"
            v-for="item in cartlist"
            :key="item.id"
          >
            <v-list-item-avatar size="52" class="rounded-lg">
              <v-img class="rounded-lg" :src="item.imageUrl" contain></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="cartitem"
                >{{ item.name }} <br class="d-md-none" />
                <v-chip
                  v-if="item.categoryName != ''"
                  label
                  small
                  color="#E6F2F1"
                  text-color="#00796B"
                  dark
                  class="ml-2"
                >
                  {{ item.categoryName }}
                </v-chip>
                <!-- <span class="gree-icon" v-if="item.categoryName != ''">{{
                  item.categoryName
                }}</span> -->
              </v-list-item-title>

              <v-list-item-subtitle class="cartdesc">{{
                truncateText(item.description)
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="remove(item)">
                <v-icon size="20" color="red lighten-1"
                  >mdi-minus-circle-outline</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <div class="col-12 col-md-4">
        <div class="cart-box mt-2" v-if="loading1 == false">
          <h4 class="text-left carttotal">Cart Total</h4>
          <v-divider class="my-3"></v-divider>
          <div class="row" v-for="item in cartlist" :key="item.id">
            <small class="col-10 cartvalue">
              {{ item.name }}
            </small>
            <small class="col-2 font-weight-bold text-end"> 1 </small>
          </div>
          <div v-if="cartlist.length == 3" style="height: 2px"></div>
          <div v-if="cartlist.length == 2" style="height: 50px"></div>
          <div v-if="cartlist.length == 1" style="height: 100px"></div>

          <div class="proceedToCheckout">
            <v-divider class="mb-2"></v-divider>
            <div class="row">
              <div class="col-10 carttol">
                <b>Total Items</b>
              </div>
              <div class="col-2 totalItemVal text-end">
                <b> {{ cartlist.length }}</b>
              </div>
            </div>
            <div class="row mb-0 ml-0 mr-0">
              <v-btn
                large
                @click="proceed"
                color="primary"
                class="btn-block mt-5 text-capitalize text-center"
              >
                Proceed to Checkout
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <!-- <img class="ma-4" v-if="logo" :src="logo" height="80" width="80" /> -->
        <img
          class="ma-4"
          :src="require('@/assets/logoDA.svg')"
          height="80"
          width="80"
        />
        <!-- <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to proceed this item?</v-card-title
          >
          
        </v-card-text> -->
        <v-card-text class="justify-center">
          <v-card-title class="alert"
            >Are you sure you want to proceed this item?</v-card-title
          >
        </v-card-text>
        <v-card-text class="text-left">
          <v-list>
            <v-list-item
              class="py-2 list-box"
              v-for="item in cartlist"
              :key="item.id"
            >
              <v-list-item-avatar size="52" class="rounded-lg">
                <v-img class="rounded-lg" :src="item.imageUrl" contain></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="cartitem" style="white-space: normal"
                  >{{ item.name }} <br class="d-md-none" />
                  <v-chip
                    v-if="item.categoryName != ''"
                    label
                    small
                    color="#E6F2F1"
                    text-color="#00796B"
                    dark
                    class="ml-2"
                  >
                    {{ item.categoryName }}
                  </v-chip>
                </v-list-item-title>

                <v-list-item-subtitle class="cartdesc">{{
                  truncateText(item.description)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogConfirm = false"
            >No</v-btn
          >
          <v-btn
            color="primary"
            class="text-capitalize"
            depressed
            @click="confirmProceed"
            >Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cartService from "@/services/cart.service.js";
import orderService from "@/services/order.service.js";
import EventBus from "../plugins/eventBus";
import authService from "@/services/auth.service.js";
export default {
  computed: {},
  created() {
    this.getAllCart();
  },
  data: () => ({
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    organizationName: authService.authData
      ? authService.authData.organization
      : undefined,
    dialogDelete: false,
    dialogConfirm: false,
    removeId: -1,
    cartlist: [],
    loading1: false,
    loading: false,
  }),
  methods: {
    async getAllCart() {
      this.loading1 = true;
      let result = await cartService.getAll();
      this.loading1 = false;

      this.cartlist = result;
      EventBus.emit("cart_changes", "");
    },
    remove(item) {
      this.removeId = item.id;
      this.dialogDelete = true;
    },
    deleteConfirm() {
      let val = [];
      val.push(this.removeId);
      this.deleteCart(val, 0);
      this.dialogDelete = false;
    },
    async deleteCart(item, i) {
      let result = await cartService.deleteCart(item);
      if (result.status == 200) {
        this.status = "Cart item removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        if (i == 0) {
          this.getAllCart();
        } else {
          this.getAllCart();
          this.$router.push(`/order/${i}`);
        }
      }
    },
    proceed() {
      this.dialogConfirm = true;
    },
    confirmProceed() {
      this.create(this.cartlist.map((t) => t.processId));
      this.dialogConfirm = false;
    },
    async create(item) {
      this.loading = true;
      let result = await orderService.create(item);
      this.loading = false;
      if (result.status == 200) {
        this.status = "Request sent successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.deleteCart(
          this.cartlist.map((t) => t.id),
          result.data
        );
      }
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea;
  border-radius: 8px;
  /* margin-right: 10px; */
  margin-bottom: 10px;
}
.cart-box {
  // background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  padding: 24px 24px;
  min-height: 350px;
  position: relative;
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -4px;
}
.v-list-item .v-list-item__title {
  line-height: 2;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 8px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.proceedToCheckout {
  margin-top: 75px;
}
.totalItemVal {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #009688;
}
.carttol {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #17181d;
}
.v-btn.v-size--x-large {
  justify-content: space-between;
  border-radius: 8px !important;
}
.v-avatar {
  justify-content: right;
}
@media (min-width: 239px) and (max-width: 320px) {
  /deep/.v-btn.v-size--x-large {
    padding: 0px;
  }
  /deep/.v-btn:not(.v-btn--round).v-size--x-large {
    padding: 4px;
  }
  /deep/.v-btn:not(.v-btn--round).v-size--large {
    padding: 5px;
  }
  .cart-box {
    padding: 5px 5px;
  }
}
.emptyCart {
  padding: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.cartitem {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
.cartdesc {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #5f6463 !important;
}
.carttotal {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #000000 !important;
}
.cartvalue {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #17181d;
}
.vlistcolor {
  background-color: transparent !important;
}
</style>
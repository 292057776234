<template>
  <div class="container emptyCart">
    <div class="container text-center" v-if="verifyStatus == 'success'">
      <img :src="require('@/assets/success.svg')" height="180" width="180" />
      <h2 class="mt-5 carttext">Congrats! Your Email is Verified.</h2>
      <p class="orderdesc mt-2">
        You will be notified once the Admin Approves your request.
      </p>

      <h2 class="mt-5">Thank You.</h2>
    </div>
    <div v-if="verifyStatus == 'invalid'" class="container text-center">
      <img :src="require('@/assets/error.svg')" height="180" width="180" />
      <h2 class="mt-5 mb-5 carttext">Sorry Invalid Link</h2>
    </div>
  </div>
</template>
<script lang="ts">
import authService from "../services/auth.service.js";
export default {
  computed: {},
  data: () => ({
    verifyStatus: "",
  }),
  created() {
    this.verify({ code: this.$route.params.id });
  },

  methods: {
    async verify(val) {
      let result = await authService.verify(val);
      if (result.status == 200) {
        this.verifyStatus = "success";
      } else {
        this.verifyStatus = "invalid";
      }
    },
  },
};
</script>
<style scoped>
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.orderdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #45464a;
}
.emptyCart {
  padding: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>

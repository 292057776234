var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"tabtitle"},[_c('v-text-field',{staticClass:"col-md-4 col-12",attrs:{"label":"Search","single-line":"","hide-details":"","append-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"click:append":_vm.search,"click:clear":_vm.clear},model:{value:(_vm.filter.searchKey),callback:function ($$v) {_vm.$set(_vm.filter, "searchKey", $$v)},expression:"filter.searchKey"}}),(_vm.userType == 'admin')?_c('v-spacer'):_vm._e(),(_vm.userType == 'admin')?_c('div',{staticClass:"col-lg-4 col-sm-12 col-md-6"},[_c('v-select',{attrs:{"items":_vm.regOrganization,"item-text":"name","item-value":"id","label":"Select Organization","clearable":true},on:{"change":_vm.onChange},model:{value:(_vm.selectedOrg),callback:function ($$v) {_vm.selectedOrg=$$v},expression:"selectedOrg"}})],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"server-items-length":_vm.userList.total,"items-per-page":10,"items":_vm.userList.records,"footer-props":{
        'items-per-page-options': [10],
      },"loading":_vm.loading},on:{"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-switch',{attrs:{"false-value":"Blocked","true-value":"Active","inset":""},on:{"change":function($event){return _vm.deleteUser(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:`item.organization`,fn:function(props){return [(_vm.userType == 'admin')?_c('v-edit-dialog',{attrs:{"return-value":props.item.organizationId,"large":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "organizationId", $event)},"update:return-value":function($event){return _vm.$set(props.item, "organizationId", $event)},"save":function($event){return _vm.save(props.item, _vm.orgid)},"open":function($event){_vm.orgid = props.item.organizationId},"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("Update Organization")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"required":"","items":_vm.regOrganization,"item-text":"name","item-value":"id","label":"Select Organization","clearable":false,"rules":[
                  (v) => (!!v && v != -1) || 'Organization is required',
                  (v) =>
                    _vm.email
                      ? (v &&
                          props.item.emailId.substring(
                            props.item.emailId.indexOf('@') + 1
                          ) == _vm.email.substring(_vm.email.indexOf('@') + 1)) ||
                        'Domain must match'
                      : (!!v && v != -1) || 'Organization is required',
                ]},on:{"change":function($event){return _vm.orgChange($event)}},model:{value:(_vm.orgid),callback:function ($$v) {_vm.orgid=$$v},expression:"orgid"}})],1)]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(props.item.organization))])]):_c('div',[_vm._v(_vm._s(props.item.organization))])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{staticClass:"text-center pa-4",attrs:{"height":"auto"}},[_c('v-card-title',{staticClass:"d-flex flex-column align-center black--text"},[_c('v-btn',{staticClass:"elevation-0 mb-6",attrs:{"fab":"","color":"red"}},[_c('v-icon',{attrs:{"color":"white","size":"30"}},[_vm._v("mdi-minus-circle-outline")])],1),_vm._v(" Are you sure you want to deactivate this Account? ")],1),_c('v-divider',{staticClass:"my-4 mx-11"}),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"border"},on:{"click":function($event){_vm.dialogDelete = false;
            _vm.resetStatus();}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"red","depressed":""},on:{"click":_vm.deleteConfirm}},[_vm._v("Yes")])],1)],1)],1),(_vm.status)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
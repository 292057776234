import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);


const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#009688',
        secondary: '#17181D',
        greyText: '#7F8083',
        blackText: '#090E18',
        buttonColor: '#3F51B5',
        $background: '#D5D5D5',
        $white: '#FFFFFF',
        $border: '#F3F3F4',
        $header: '#aeaeb0',
        $getstarttext: '#45464A',
        $yellow: '#EEC200',
        $secheader: '#45464a'
      },
    },
  },
})

export default vuetify
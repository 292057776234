<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field v-model="filter.searchKey" label="Search" single-line hide-details
                  v-on:keyup.enter="search" append-icon="mdi-magnify" clear-icon="mdi-close-circle" clearable
                  @click:append="search" @click:clear="clear"></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select :items="getOList" item-text="name" item-value="id" v-model="selectedOrg"
                  label="Select Organization" @change="onChange"></v-select>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4" v-if="selectedOrg > 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="tabtitle2 text-capitalize mx-2" @click="refreshLiveState(), (loader = 'loading4')"
                      v-bind="attrs" v-on="on">
                      Refresh <v-icon small>mdi-refresh</v-icon>
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table :headers="headers" :items="mergeddata" :items-per-page="10"
        :server-items-length="botClientList.total" :loading="loading" @update:options="updatePagination" :footer-props="{
          'items-per-page-options': [10],
        }">
        <template v-slot:[`item.botImageURL`]="{ item }">
          <img v-if="item.botImageURL" :src="item.botImageURL" width="50px" />
        </template>
        <template v-slot:[`item.organizationName`]="{ item }">
          <td>{{ item.organizationName }}</td>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td v-if="item.name != null">{{ item.name }}</td>
          <td v-else class="red--text">Agent name not selected by Cleint</td>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <v-chip v-if="item.state" small :color="getColor(item.state)" dark>
            {{ item.state }}
          </v-chip>
        </template>
        <template #[`item.elapse`]="{ item }">
          <td v-if="item.state == 'Busy'" :class="getColor1(item)">
            {{ item.elapse }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="editItem(item)" v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status != 'Inprogress'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="25" small class="mr-3" @click="deleteBotLicense(item)" v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom v-if="
            item.name != null && item.serverType == 'Cloud' && selectedOrg > 0
          ">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="botDetails(item)" v-bind="attrs" v-on="on">
                mdi-robot
              </v-icon>
            </template>
            <span>Agent details</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!---Agent Data --->
      <v-dialog v-model="dialogBotData" width="1400" scrollable transition="dialog-bottom-transition">
        <v-card height="auto">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Agent Data</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeLogs()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear indeterminate v-if="loadingbot"></v-progress-linear>
          <v-card-text>
            <div class="container" v-if="botData.data">
              <div class="row my-4">
                <v-card class="col-lg-6 col-sm-12 text-center">
                  <div v-if="getImage == '' && loadingbot == false">
                    <v-img :src="require('@/assets/no-data.jpg')" height="200" contains></v-img>
                    <h4 class="text-center">Sorry, no image found!</h4>
                  </div>
                  <div v-else>
                    <img :src="getImage" height="200" />
                  </div>
                </v-card>
                <div class="col-lg-6 col-sm-12">
                  <v-list-item>
                    <v-list-item-content v-if="botData.data">
                      <v-list-item-title class="primary--text">Status</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip small :color="getColor(botData.data.state)" dark>
                          {{ botData.data.state }}
                        </v-chip></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content v-if="botData.data">
                      <v-list-item-title class="primary--text">Agent Name</v-list-item-title>
                      <v-list-item-subtitle>{{
                        selectedBot.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="botData.data">
                    <v-list-item-content v-if="botData.data.state == 'Busy'">
                      <v-list-item-title class="primary--text">Process Name</v-list-item-title>
                      <v-list-item-subtitle v-if="processName != ''">{{
                        this.processName
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-card v-if="botData.data">
                <v-data-table v-if="roboLogsData && botData.data.state == 'Busy'" dense :headers="headersLogs"
                  :items="roboLogsData" item-key="name" class="elevation-1 ttl" :loading="loadingbotlog">
                  <template v-slot:top>
                    <v-toolbar flat color="primary ">
                      <v-toolbar-title class="white--text">Process Log</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="white" @click="refresh()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>Refresh</span>
                      </v-tooltip>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.timeStamp`]="{ item }">
                    <td>{{ new Date(item.timeStamp).toLocaleString() }}</td>
                  </template></v-data-table>
              </v-card>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div v-if="botData.data" class="mr-1">
              <v-btn v-if="botData.data.state == 'Busy'" dark color="black" class="text-capitalize" depressed
                @click="killProcess()">Kill Process</v-btn>
            </div>
            <v-btn class="text-capitalize grey lighten-2" @click="closeLogs()">
              Close
            </v-btn>
          </v-card-actions>
          <v-overlay :value="overlay" :absolute="absolute">
            <v-progress-circular indeterminate size="110">Terminating...</v-progress-circular>
          </v-overlay>
        </v-card>
      </v-dialog>

      <!----Edit Agent ---->
      <v-dialog v-model="dialogEdit" transition="dialog-bottom-transition" max-width="500px">
        <v-card height="auto">
          <v-card-title>
            <span class="text-h6">Edit License</span>
          </v-card-title>
          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-card-text>
              <div class="mb-3 text-center">
                <v-avatar size="150px" v-ripple class="mb-3 text-center" v-if="editlicense.status == 'Inprogress'">
                  <img :src="editlicense.botImageURL" alt="avatar" />
                </v-avatar>
              </div>
              <v-text-field v-if="editlicense.status == 'Inprogress'" v-model="editlicense.name" label="Agent Name"
                :disabled="editlicense.status == 'Inprogress' ? true : false">
                <template #label>
                  Agent Name <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>

              <v-text-field v-model="editlicense.license" label="License" required :rules="[
                (v) => !!v || 'License is required',
                (v) => (v && !!v.trim()) || 'License cannot be blank',
                (v) =>
                  (v &&
                    !botNames.find(
                      (t) =>
                        editlicense.id != t.id &&
                        t.license.toLowerCase() == v.toLowerCase()
                    )) ||
                  'License already exists',
              ]">
                <template #label>
                  License <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>

              <v-text-field v-model="editlicense.serverName" label="Server Name" required maxlength="20">
              </v-text-field>
              {{ editlicense.serverType }}
              <v-radio-group v-model="editlicense.serverType" @change="serverTypeChange"
                :rules="[(v) => !!v || 'Type is required']">
                <template v-slot:label>
                  <label class="v-label v-label--active">Orchestrator type<span class="red--text"><strong>*
                      </strong></span></label>
                </template>
                <v-radio label="On - Premises" value="On - Premises"></v-radio>
                <v-radio label="Cloud" value="Cloud"></v-radio>
              </v-radio-group>
              <v-text-field v-if="editlicense.serverType == 'Cloud'" v-model="editlicense.tenatName" label="Tenant Name"
                validate-on-blur required :rules="[
                  (v) => !!v || 'Tenant Name is required',
                  (v) => (v && !!v.trim()) || 'Tenant Name cannot be blank',
                ]">
                <template #label>
                  Tenant Name <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
              <v-text-field v-if="editlicense.serverType == 'Cloud'" v-model="editlicense.folderId" label="Folder ID"
                validate-on-blur required @keypress="onlyNumber($event)" :rules="[
                  (v) => !!v || 'Folder ID is required',
                  (v) =>
                    /^[1-9]\d*$/.test(v) || 'Folder ID cannot start with 0',
                  (v) =>
                    (v && !!v.toString().trim()) || 'Folder ID cannot be blank',
                ]">
                <template #label>
                  Folder ID <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="text-capitalize grey lighten-2" @click="closeDialogEdit">
                Close
              </v-btn>
              <v-btn dark color="black" class="text-capitalize" depressed @click="onUpdateLicense">
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!---Agent Kill --->
      <v-dialog v-model="dialogKill" max-width="500px">
        <v-card height="auto" class="text-center">
          <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
          <v-card-text class="justify-center" v-if="botData.data">
            <v-card-title>Are you sure you want to kill this Process?</v-card-title>
            <div>
              <div>Process Name</div>
              <div class="font-weight-normal" v-if="botData.data.jobs.length > 0">
                <strong>{{ runningProcess.releaseName }}</strong>
              </div>
              <div>Start Time</div>
              <div class="font-weight-normal" v-if="botData.data.jobs.length > 0">
                <strong>{{
                  new Date(runningProcess.startTime).toLocaleString()
                }}</strong>
              </div>
              <div>Status</div>
              <div class="font-weight-normal" v-if="botData.data.jobs.length > 0">
                <strong>{{ runningProcess.state }}</strong>
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn class="text-capitalize grey lighten-2" @click="dialogKill = false">Cancel</v-btn>
            <v-btn dark color="black" class="text-capitalize" depressed @click="killConfirm">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card height="auto" class="text-center">
          <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
          <v-card-text class="justify-center">
            <v-card-title>Are you sure you want to delete this license?</v-card-title>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn class="text-capitalize grey lighten-2" @click="dialogDelete = false">Cancel</v-btn>
            <v-btn dark color="black" class="text-capitalize" depressed @click="deleteConfirm">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import EventBus from "../plugins/eventBus";
import orgService from "@/services/organization.service.js";
import clientBotService from "@/services/clientBot.service.js";
import uiPathService from "@/services/uiPath.service.js";
import leadService from "@/services/lead.service.js";
import botImagesService from "@/services/botImages.service.js";
import data from "../../public/config/preferences.json";
export default {
  created() {
    this.getOrganization();
    this.getLicenseNames();
    EventBus.on("exist_bot_changes", () => {
      this.closeDialog();

      this.updatePagination({ page: this.pageIndex });
    });
    EventBus.on("license_add_delete", () => {
      this.getLicenseNames();
    });
  },
  beforeDestroy() {
    //clearInterval(this.getting);
  },
  destroyed() {
    EventBus.off("exist_bot_changes");

    //clearInterval(this.getting);
  },
  computed: {
    getOList() {
      var arr = Object.assign([], this.regOrganization);
      arr.unshift({ id: "", name: "All" });
      return arr;
    },
    getImage() {
      if (this.botData.data) {
        if (!this.botdet) {
          return "";
        } else if (this.botdet.childrens.length > 0) {
          let typ = this.botData.data.state.toLowerCase();
          if (typ == "free") {
            typ = "happy";
          } else {
            typ = this.botData.data.state.toLowerCase();
          }
          var val = this.botdet.childrens.find((t) => t.type === typ);

          if (val) {
            return val.imageURL;
          } else return "";
        } else {
          return "";
        }
      }
      return "";
    },

    headers() {
      let headers = [
        { text: "", sortable: false, value: "botImageURL" },
        { text: "Agent Name", sortable: false, value: "name" },
        { text: "Organization", value: "organizationName", sortable: false },
        { text: "License", value: "license", sortable: false },
        { text: "Server Name", value: "serverName", sortable: false },
        { text: "Agent Selected", value: "botSelectedOn", sortable: false },
        { text: "Created By", value: "createdBy", sortable: false },
        { text: "Created On", value: "createdOn", sortable: false },
        // {
        //   text: "Status",
        //   value: "state",
        //   sortable: false,
        // },
        // {
        //   text: "Elapsed Time",
        //   value: "elapse",
        //   sortable: false,
        // },
        // { text: "Actions", value: "actions", sortable: false, width: "130px" },
      ];
      if (this.selectedOrg > 0) {
        headers.push({
          text: "Status",
          value: "state",
          sortable: false,
        });
        headers.push({
          text: "Elapsed Time",
          value: "elapse",
          sortable: false,
        });
      }
      headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "130px",
      });
      return headers;
    },
  },
  data: () => ({
    refreshTime: data.refreshTime,
    avgtime: "",
    machineStatus: {},
    botClientList: {},
    regOrganization: [],
    botData: [],
    roboLogsData: [],
    loading: false,
    loadingbot: false,
    botNames: [],
    mergeddata: [],
    allProcesses: [],
    getting: "",
    timer: null,
    overlay: false,
    absolute: true,
    killstatus: "",
    selectedOrg: "",
    dialogEdit: false,
    dialogAdd: false,
    dialogBotData: false,
    dialogImage: false,
    valid: false,
    status: "",
    statusbot: "",
    selectedBot: {},
    selectedImage: "",
    bot: {
      botPictureId: 0,
    },
    runningProcess: {},
    botdet: {},
    snack: false,
    snackColor: "",
    snackText: "",
    editedIndex: -1,
    dialogDelete: false,
    dialogKill: false,
    valid1: false,
    jobId: "",
    editlicense: {
      id: 0,
      license: "",
      status: 1,
      serverName: "",
      serverType: "",
      tenatName: "",
      deleteId: 0,
      name: "",
      botImageURL: "",
      folderId: "",
    },
    filter: {
      searchKey: "",
      sortBy: "",
    },
    searchKey: "",
    headersLogs: [
      { text: "Time", sortable: false, value: "timeStamp" },
      { text: "Level", value: "level", sortable: false },
      { text: "Message", value: "message", sortable: false, width: "40%" },
    ],
    pageIndex: 0,
    editedItemProcess: {},
    editedIndexb: -1,
    processName: "",
  }),

  methods: {
    refreshLiveState() {
      this.loading4 = true;
      if (this.botClientList.records) {
        if (this.botClientList.records.length > 0) {
          if (
            this.botClientList.records[0].folderId &&
            this.botClientList.records[0].tenatName
          ) {
            this.getData();
          } else {
            this.loading4 = false;
          }
        } else {
          this.loading4 = false;
        }
      } else {
        this.loading4 = false;
      }
    },
    onChange() {
      //clearInterval(this.getting);
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    updatePagination(pagination) {
      //clearInterval(this.getting);
      this.pageIndex = pagination.page || 1;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);
      this.loading = false;

      this.botClientList = result;
      if (this.selectedOrg > 0) {
        if (this.botClientList.records) {
          if (this.botClientList.records.length > 0) {
            this.mergeddata = this.botClientList.records;
            this.getData();
          }
        }
      } else {
        this.mergeddata = this.botClientList.records;
      }
    },
    closeDialog() {
      this.dialogAdd = false;
      this.bot.botPictureId = 0;
      this.selectedImage = "";
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    getRunningProcessName() {
      if (this.botData.data.jobs.length > 0) {
        let runningJobs = this.botData.data.jobs.find(
          (c) => c.state === "Running"
        );
        if (runningJobs) {
          this.runningProcess = runningJobs;
          this.getProcessList(
            { orgnaizationId: this.selectedBot.organizationId },
            runningJobs.releaseName
          );
        }
      }
      return "";
    },
    botDetails(item) {
      if (item.folderId) {
        this.getBotData({
          name: item.name,
          folderId: item.folderId,
          tenant: item.tenatName,
          orgId: this.selectedOrg,
        });
        this.dialogBotData = true;
        this.selectedBot = item;
        this.getById(item.botImageId);
      } else {
        this.status = "Please enter folder ID";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    async getById(input) {
      let result = await botImagesService.getById(input);
      this.botdet = result[0];
    },
    editItem(item) {
      this.editlicense = Object.assign({}, item);
      this.dialogEdit = true;
    },
    deleteConfirm() {
      this.deleteLicense({ clientBotId: this.deleteId });
      this.dialogDelete = false;
    },
    async deleteLicense(item) {
      let result = await clientBotService.delete(item);
      if (result.status == 200) {
        this.status = "License deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        EventBus.emit("license_add_delete", "deleted");
        this.updatePagination({ page: this.pageIndex });
      }
    },
    killConfirm() {
      //clearInterval(this.getting);
      this.killBotProcess({
        jobId: this.jobId,
        folderId: this.selectedBot.folderId,
        tenant: this.selectedBot.tenatName,
        orgId: this.selectedOrg,
      });
      this.dialogKill = false;
    },
    deleteBotLicense(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },
    killProcess() {
      if (this.botData.data.jobs.length > 0) {
        if (this.runningProcess == "Running") {
          this.jobId = this.runningProcess.id;
        } else {
          this.jobId = "";
        }
      }
      if (this.jobId) {
        this.dialogKill = true;
      } else {
        this.status = "Process Terminating...";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    closeDialogEdit() {
      this.dialogEdit = false;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    search() {
      //clearInterval(this.getting);
      this.searchKey = this.escapeHtml(this.filter.searchKey);

      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    clear() {
      //clearInterval(this.getting);
      this.filter.searchKey = "";
      this.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    onUpdateLicense() {
      let data = {};

      data = {
        license: this.editlicense.license,
        serverName: this.editlicense.serverName,
        serverType: this.editlicense.serverType,
        tenatName: this.editlicense.tenatName,
        folderId: parseInt(this.editlicense.folderId),
      };

      let valid = this.$refs.form1.validate();
      if (valid) {
        this.updateLicenseselectBot(data, this.editlicense.id);

        this.dialogEdit = false;
      }
    },
    async updateLicenseselectBot(item, id) {
      let result = await clientBotService.update(item, id);

      if (result.status == 200) {
        this.status = "License updated successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        EventBus.emit("license_add_delete", "Updated successfully");
        this.updatePagination({ page: this.pageIndex });

        this.deleteId = 0;
      }
    },
    serverTypeChange() {
      if (this.bot.serverType != "Cloud") {
        this.bot.tenatName = "";
        this.bot.folderId = "";
      }
      if (this.editlicense.serverType != "Cloud") {
        this.editlicense.tenatName = "";
        this.editlicense.folderId = "";
      }
    },

    refresh() {
      if (this.botData.data.state == "Busy") {
        if (this.botData.data.jobs.length > 0) {
          let runningJobs = this.botData.data.jobs.find(
            (c) => c.state === "Running"
          );
          if (runningJobs) {
            this.getRobotLogs({
              processName: runningJobs.releaseName,
              folderId: this.selectedBot.folderId,
              tenant: this.selectedBot.tenatName,
              orgId: this.selectedOrg,
            });
          }
        }
      }
    },
    getData() {
      this.loading = true;
      let j = 0;
      for (j = 0; j < this.botClientList.records.length; j++) {
        if (
          this.botClientList.records[j].folderId &&
          this.botClientList.records[j].tenatName
        ) {
          this.getMachineStatus(
            {
              folderId: this.botClientList.records[j].folderId,
              tenant: this.botClientList.records[j].tenatName,
              orgId: this.selectedOrg,
            },
            this.botClientList.records[j]
          );
        }
      }
      this.loading = false;
      // this.getting = setInterval(() => {
      //   let i = 0;
      //   for (i = 0; i < this.botClientList.records.length; i++) {
      //     if (
      //       this.botClientList.records[i].folderId &&
      //       this.botClientList.records[i].tenatName
      //     ) {
      //       this.getMachineStatus(
      //         {
      //           folderId: this.botClientList.records[i].folderId,
      //           tenant: this.botClientList.records[i].tenatName,
      //           orgId: this.selectedOrg,
      //         },
      //         this.botClientList.records[i]
      //       );
      //     }
      //   }
      // }, this.refreshTime);
    },
    closeLogs() {
      this.dialogBotData = false;
      // //clearInterval(this.getting);
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();
      this.regOrganization = result;
    },
    async getLicenseNames() {
      let result = await clientBotService.getLicenseNames();
      this.botNames = result;
    },
    async getMachineStatus(data, item) {
      let result = await uiPathService.getMachineStatus(data);
      console.log("getMachineStatus", item);
      this.machineStatus = result;
      this.mergeddata = this.mergeddata.map((subject) => {
        let t = this.machineStatus.data.find(
          (element) => element.name === subject.name
        );

        return { ...subject, ...t };
      });
      this.loading = false;
      let j = 0;
      for (j = 0; j < this.mergeddata.length; j++) {
        if (this.mergeddata[j].state == "Busy") {
          if (this.mergeddata[j].jobs.length > 0) {
            let runningJobs = this.mergeddata[j].jobs.find(
              (c) => c.state === "Running"
            );

            if (runningJobs) {
              this.getProcessLiveData(
                {
                  name: runningJobs.releaseName,
                  folderId: this.mergeddata[j].folderId,
                  tenant: this.mergeddata[j].tenatName,
                  orgId: this.selectedOrg,
                },
                this.mergeddata[j]
              );
            }
          }
        }
      }
    },
    async getProcessLiveData(data, item) {
      let result = await uiPathService.getProcessData(data);
      this.editedIndexb = this.mergeddata.indexOf(item);
      this.editedItemProcess = Object.assign({}, item);
      if (result.data.jobs.length > 0) {
        let runningJobs = result.data.jobs.find((c) => c.state === "Running");
        if (runningJobs) {
          let date1 = new Date();
          let date2 = "";
          date2 = new Date(runningJobs.startTime);

          const duration = date1 - date2;
          var seconds = parseInt((duration / 1000) % 60),
            minutes = parseInt((duration / (1000 * 60)) % 60),
            hours = parseInt((duration / (1000 * 60 * 60)) % 24),
            days = parseInt(duration / (1000 * 60 * 60 * 24));

          var hoursDays = parseInt(days * 24);
          hours += hoursDays;
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.editedItemProcess.elapse = hours + ":" + minutes + ":" + seconds;
          this.editedItemProcess.seconds = duration / 1000;
          this.editedItemProcess.live_status = "Running";
          this.editedItemProcess.runningJobs = runningJobs;
        } else {
          this.editedItemProcess.live_status = "Free";
        }
      } else {
        this.editedItemProcess.live_status = "Free";
      }
      this.editedItemProcess.avgTime = result.data.averageTime;
      Object.assign(this.mergeddata[this.editedIndexb], this.editedItemProcess);
      var bar = Array.from(this.mergeddata);
      this.mergeddata = [];
      this.mergeddata = bar;
    },
    async getBotData(data) {
      this.botData = [];
      this.loadingbot = true;
      let result = await uiPathService.getBotData(data);

      this.botData = result;
      if (result && this.dialogBotData) {
        this.getRunningProcessName();
      }

      this.loadingbot = false;
      let botstatus = "Killed Successfully";
      if (this.killstatus == botstatus && this.botData.data) {
        if (this.botData.data.state.toLowerCase() == "free") {
          this.status = botstatus;
          setTimeout(() => {
            this.status = "";
            this.killstatus = "";
          }, 2000);
        }
      }

      this.refresh();
    },
    async getRobotLogs(data) {
      this.roboLogsData = [];
      this.loadingbotlog = true;
      let result = await uiPathService.getRobotLogs(data);
      this.loadingbotlog = false;
      this.roboLogsData = result.value.data;
    },
    async killBotProcess(data) {
      let result = await uiPathService.killBotProcess(data);

      console.log("rec", result);
      this.killstatus = "Killed Successfully";
      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;
        this.getBotData({
          name: this.selectedBot.name,
          folderId: this.selectedBot.folderId,
          tenant: this.selectedBot.tenatName,
          orgId: this.selectedOrg,
        });
      }, 6000);
    },
    getColor(status) {
      if (status == "Busy") return "green";
      else if (status == "Free") return "orange";
      else return "orange";
    },
    async getProcessData(data, startTime) {
      let result = await uiPathService.getProcessData(data);

      let date1 = new Date();
      let date2 = "";
      this.avgtime = result.data.averageTime;
      date2 = new Date(startTime).toISOString().slice(0, 10);

      this.diff(date2, date1);
    },
    diff(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },

    diff1(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      let sec = duration / 1000;
      if (sec >= this.avgtime) return "green--text";
      else return "orange--text";
    },
    getElapsedTime(item) {
      if (item.state == "Busy") {
        if (item.jobs.length > 0) {
          let runningJobs = item.jobs.find((c) => c.state === "Running");
          if (runningJobs) {
            if (!this.avgtime) {
              this.getProcessData(
                {
                  name: runningJobs.releaseName,
                  folderId: this.selectedBot.folderId,
                  tenant: this.selectedBot.tenatName,
                  orgId: this.selectedOrg,
                },
                runningJobs.startTime
              );
            } else {
              let date1 = new Date();
              let date2 = "";
              date2 = new Date(runningJobs.startTime)
                .toISOString()
                .slice(0, 10);

              this.diff(date2, date1);
            }
          }
        }
      }
    },
    getColor1(item) {
      if (item.avgTime >= item.elapse) return "green--text";
      else return "red--text";
    },
    getclr(item) {
      if (item.state == "Busy") {
        if (item.jobs.length > 0) {
          let runningJobs = item.jobs.find((c) => c.state === "Running");
          if (runningJobs) {
            if (this.avgtime) {
              let date1 = new Date();
              let date2 = "";
              date2 = new Date(runningJobs.startTime)
                .toISOString()
                .slice(0, 10);

              this.diff1(date2, date1);
            }
          }
        }
      }
    },
    async getProcessList(data, name) {
      let result = await leadService.getAllProcess(data);
      this.allProcesses = result;
      if (this.allProcesses) {
        if (this.allProcesses.length > 0) {
          let processName = this.allProcesses.find(
            (c) => c.releaseProcessName === name
          );
          if (processName) {
            // return processName.name;

            this.processName = processName.name;
          } else {
            // return name;
            this.processName = "";
          }
        } else {
          // return name;
          this.processName = "";
        }
      } else {
        // return name;
        this.processName = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box-list {
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}

.active {
  border-color: #1a8ccf;
  border-width: 5px;
}

/deep/.ttl.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}

/deep/.ttl.v-data-table .v-data-table-header tr th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}
</style>
import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/ClientProcess/order/details`, {
      params: input,
    });
    return result.data;

  },

  async getAllOrders() {
    let result = await mainAxios.get(`/ClientProcess/order`);
    return result.data;
  },


  async create(input) {
    let result = await mainAxios.post(`/ClientProcess/order`, input);
    return result;
  },

  async adminCreateOrder(orgid, input) {
    let result = await mainAxios.post(`/ClientProcess/order?organizationId=${orgid}`, input);
    return result;
  },

}
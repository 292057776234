<template>
  <div class="container">
    <p class="header1 pb-6">
      Privacy Policy for
      <label class="header2">Alignxcel Marketplace</label>
    </p>
    <p class="normaltext text-justify pb-6">
      At Alignxcel Marketplace, accessible from alignxcelmarketplace.com, one of
      our main priorities is the privacy of our visitors. This Privacy Policy
      document contains types of information that is collected and recorded by
      Alignxcel Marketplace and how we use it.
    </p>

    <p class="normaltext text-justify pb-6">
      If you have additional questions or require more information about our
      Privacy Policy, do not hesitate to contact us.
    </p>

    <p class="normaltext text-justify pb-8">
      This Privacy Policy applies only to our online activities and is valid for
      visitors to our website with regards to the information that they shared
      and/or collect in Alignxcel Marketplace. This policy is not applicable to
      any information collected offline or via channels other than this website.
    </p>
    <p class="header3 pb-6">Consent</p>
    <p class="normaltext text-justify pb-8">
      By using our website, you hereby consent to our Privacy Policy and agree
      to its terms.
    </p>
    <p class="header3 pb-6">
      CCPA Privacy Rights (Do Not Sell My Personal Information)
    </p>
    <p class="normaltext text-justify pb-6">
      Under the CCPA, among other rights, California consumers have the right
      to:
    </p>
    <p class="normaltext text-justify pb-6">
      Request that a business that collects a consumer's personal data disclose
      the categories and specific pieces of personal data that a business has
      collected about consumers.
    </p>
    <p class="normaltext text-justify pb-6">
      Request that a business delete any personal data about the consumer that a
      business has collected.
    </p>
    <p class="normaltext text-justify pb-6">
      Request that a business that sells a consumer's personal data, not sell
      the consumer's personal data.
    </p>
    <p class="normaltext text-justify pb-8">
      If you make a request, we have one month to respond to you. If you would
      like to exercise any of these rights, please contact us.
    </p>
    <p class="header3 pb-6">GDPR Data Protection Rights</p>
    <p class="normaltext text-justify pb-6">
      We would like to make sure you are fully aware of all of your data
      protection rights. Every user is entitled to the following:
    </p>
    <p class="normaltext text-justify pb-6">
      The right to access - You have the right to request copies of your
      personal data. We may charge you a small fee for this service.
    </p>
    <p class="normaltext text-justify pb-6">
      The right to rectification - You have the right to request that we correct
      any information you believe is inaccurate. You also have the right to
      request that we complete the information you believe is incomplete.
    </p>
    <p class="normaltext text-justify pb-6">
      The right to erasure - You have the right to request that we erase your
      personal data, under certain conditions.
    </p>
    <p class="normaltext text-justify pb-6">
      The right to restrict processing - You have the right to request that we
      restrict the processing of your personal data, under certain conditions.
    </p>
    <p class="normaltext text-justify pb-6">
      The right to object to processing - You have the right to object to our
      processing of your personal data, under certain conditions.
    </p>
    <p class="normaltext text-justify pb-6">
      The right to data portability - You have the right to request that we
      transfer the data that we have collected to another organization, or
      directly to you, under certain conditions.
    </p>
    <p class="normaltext text-justify pb-8">
      If you make a request, we have one month to respond to you. If you would
      like to exercise any of these rights, please contact us.
    </p>

    <p class="header3 pb-6">Children's Information</p>
    <p class="normaltext text-justify pb-6">
      Another part of our priority is adding protection for children while using
      the internet. We encourage parents and guardians to observe, participate
      in, and/or monitor and guide their online activity.
    </p>
    <p class="normaltext text-justify pb-8">
      Alignxcel Marketplace does not knowingly collect any Personal Identifiable
      Information from children under the age of 13. If you think that your
      child provided this kind of information on our website, we strongly
      encourage you to contact us immediately and we will do our best efforts to
      promptly remove such information from our records.
    </p>

    <p class="header3 pb-6">Information you make public or give to others</p>
    <p class="normaltext text-justify pb-6">
      If you make your personal information available to other people, we can’t
      control or accept responsibility for the way they will use or manage that
      data. There are lots of ways that you can find yourself providing
      information to other people, like when you post a public message on a
      forum thread, share information via social media, or make contact with
      another user (such as a third party Author) whether via our Sites or
      directly via email.
    </p>
    <p class="normaltext text-justify pb-8">
      Before making your information publicly available or giving your
      information to anyone else, think carefully. If giving information to
      another user via our Sites, ask them how they will handle your
      information. If you’re sharing information via another website, check the
      privacy policy for that site to understand its information management
      practices as this privacy policy will not apply.
    </p>

    <p class="header3 pb-6">How long we keep your personal information</p>
    <p class="normaltext text-justify pb-6">
      Please note that if you request the erasure of your personal information
      we may retain information from deleted accounts as necessary for our
      legitimate business interests, to comply with the law, prevent fraud,
      collect fees, resolve disputes, troubleshoot problems, assist with
      investigations or requests by government, a court of law, or law
      enforcement authorities, enforce the terms of service and take other
      actions permitted by law.
    </p>
    <p class="normaltext text-justify pb-8">
      Any information we retain will be handled in accordance with this Privacy
      Policy.
    </p>

    <p class="header3 pb-6">
      Alignxcel Marketplace Privacy Policy v1.1, effective date 28 September
      2022
    </p>
    <p class="normaltext text-justify pb-6">
      Please note that if you request the erasure of your personal information
      we may retain information from deleted accounts as necessary for our
      legitimate business interests, to comply with the law, prevent fraud,
      collect fees, resolve disputes, troubleshoot problems, assist with
      investigations or requests by government, a court of law, or law
      enforcement authorities, enforce the terms of service and take other
      actions permitted by law.
    </p>
    <p class="normaltext text-justify pb-8">
      Any information we retain will be handled in accordance with this Privacy
      Policy.
    </p>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.header1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.header2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.header3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.normaltext {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Secondary/500 */

  color: #17181d;
}
</style>
<template>
  <div class="container-fluid">
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <!-- <div class="col-lg-4 col-sm-6">
                <v-select
                  :items="categoryList.records"
                  v-model="filter.categoryId"
                  item-text="name"
                  item-value="id"
                  label="Category"
                  :clearable="true"
                  @change="modifyOthers"
                ></v-select>
              </div> -->
              <!-- <v-spacer></v-spacer> -->
              <!-- <div > -->
              <div class="col-lg-3 col-sm-6">
                <v-select
                  :items="categoryList"
                  v-on:change="categoryChange"
                  item-text="name"
                  item-value="value"
                  v-model="filter.category"
                >
                </v-select>
              </div>
              <div class="col-lg-2 col-sm-4">
                <v-btn
                  dark
                  class="mt-lg-4 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add FAQ</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :server-items-length="faqList.total"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :items="faqList.records"
        :loading="loading"
        @update:options="updatePagination"
        class="elevation-1"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.isActive"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.isApplicableToAllClients`]="{ item }">
          <v-switch
            @change="deleteItem1(item, $event)"
            v-model="item.isApplicableToAllClients"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.addedOn`]="{ item }">
          <td>{{ new Date(item.addedOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-2"
                @click="features(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dialpad
              </v-icon>
            </template>
            <span>Map Clients</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="deleteClient(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete Clients</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.answer`]="{ item }">
          <td class="text-justify">
            {{ truncate(item.answer, 200) }}
          </td>
        </template>
      </v-data-table>
    </v-card>
    <!---Add or Update the process -->
    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="editedItem.question"
              required
              :rules="[
                (v) => !!v || 'Question is required',
                (v) => (v && !!v.trim()) || 'Question cannot be blank',
              ]"
            >
              <template #label>
                Question <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="editedItem.answer"
              required
              :rules="[
                (v) => !!v || 'Answer is required',
                (v) => (v && !!v.trim()) || 'Answer cannot be blank',
              ]"
            >
              <template #label>
                Answer <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <!-- <v-select
              :items="gridData"
              item-text="name"
              item-value="id"
              v-model="editedItem.categoryId"
              required
              :rules="[(v) => !!v || 'Category is required']"
              ><template #label>
                Category <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select> -->
            <v-select
              :items="category"
              item-text="name"
              item-value="value"
              v-model="editedItem.category"
              required
              :rules="[(v) => !!v || 'Category is required']"
              ><template #label>
                Category <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>

            <v-select
              :items="applicableTo"
              item-text="name"
              item-value="value"
              v-model="editedItem.applicableTo"
              required
              :rules="[(v) => !!v || 'Applicable To is required']"
              ><template #label>
                Applicable To <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-switch
              v-model="editedItem.isApplicableToAllClients"
              :false-value="false"
              :true-value="true"
              inset
              label="Is Applicable To All Clients"
              class="offset-4 offset-lg-0"
            ></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="onUpdate"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to deactivate this FAQ?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this Client?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDeleteConfirm = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirmClient"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteClient" scrollable max-width="500px">
      <v-card>
        <v-card-title>Unmap Client</v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 400px">
          <v-list class="vlistcolor" dense>
            <v-list-item
              class="list-box"
              v-for="item in client.mapping"
              :key="item.id"
              :disabled="item.clientId == '1' ? true : false"
            >
              <v-list-item-content>
                <v-list-item-title class="cartitem"
                  >{{ item.name }} <br class="d-md-none" />
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="remove(item)">
                  <v-icon size="20" color="red lighten-1"
                    >mdi-minus-circle-outline</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="text-capitalize grey lighten-2"
            @click="dialogDeleteClient = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->
    <v-dialog
      v-if="dialogFeatures"
      v-model="dialogFeatures"
      persistent
      max-width="800px"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <!-- <v-btn icon dark @click="dialogFeatures = false">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
          <v-toolbar-title>Add Clients</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialogFeatures = false"> Close </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-card-text>
          <v-form ref="form1" v-model="valid" lazy-validation>
            <v-card-text>
              <v-select
                class="company-size-dropdown"
                :items="gridData"
                item-text="name"
                item-value="id"
                v-model="editedClient.clientId"
                label="Client"
                multiple
                required
                :rules="[(v) => !!v || 'Client is required']"
              >
                <template #label>
                  Client <span class="red--text"><strong>* </strong></span>
                </template>
              </v-select>
              <!-- <v-switch
                v-model="editedClient.isApplicableToAllClients"
                :false-value="false"
                :true-value="true"
                inset
                label="Is Applicable To All Clients"
                class="offset-4 offset-lg-0"
              ></v-switch> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize grey lighten-2"
                depressed
                @click="closeClientDialog"
              >
                Close
              </v-btn>
              <v-btn
                dark
                color="black"
                class="text-capitalize"
                depressed
                @click="onAddClient"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script>
import faqService from "@/services/faq.service.js";
import orgService from "@/services/organization.service.js";
export default {
  components: {},
  computed: {
    // gridData() {
    //   if (!this.categoryList.records) return [];
    //   return this.categoryList.records.map((t) => {
    //     return {
    //       name: t.status == false ? t.name + "(Deactivated)" : t.name,
    //       id: t.id,
    //       disabled: t.status == false ? true : false,
    //     };
    //   });
    // },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    gridData() {
      if (!(this.regOrganization && this.client)) return [];

      let res = [];
      res = this.regOrganization.filter((el) => {
        return !this.client.mapping.find((element) => {
          return element.clientId === el.id;
        });
      });
      return res;
    },
  },
  data: () => ({
    dialogDelete: false,
    dialogFeatures: false,
    dialogDeleteConfirm: false,
    dialogDeleteClient: false,
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Question",
        align: "start",
        sortable: false,
        value: "question",
      },
      { text: "Answer", value: "answer", sortable: false, width: "40%" },
      {
        text: "Category",
        value: "category",
        sortable: false,
      },

      // { text: "Description", value: "description", sortable: false },
      { text: "addedOn", value: "addedOn", sortable: false },

      { text: "Status", value: "isActive", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    editedIndexClient: 0,
    pageIndex: 0,
    status: "",
    statusE: "",
    activationStatus: 1,
    filter: {
      searchKey: "",
      category: "",
    },
    category: [
      { name: "Process", value: "Process" },
      { name: "Agent", value: "Bot" },
      { name: "Logs", value: "Logs" },
      { name: "Marketplace", value: "Marketplace" },
      { name: "Alignxcel", value: "Alignxcel" },
    ],
    categoryList: [
      { name: "All Categories", value: "" },
      { name: "Process", value: "Process" },
      { name: "Agent", value: "Bot" },
      { name: "Logs", value: "Logs" },
      { name: "Marketplace", value: "Marketplace" },
      { name: "Alignxcel", value: "Alignxcel" },
    ],
    applicableTo: [
      { name: "Marketplace", value: "MP" },
      { name: "Alignxcel", value: "AX" },
      { name: "All", value: "All" },
    ],
    editedItem: {
      id: 0,
      question: "",
      answer: "",
      category: "",
      applicableTo: "",
      isApplicableToAllClients: true,
    },
    defaultItem: {
      id: 0,
      question: "",
      answer: "",
      category: "",
      applicableTo: "",
      isApplicableToAllClients: true,
    },
    editedClient: {
      id: 0,

      clientId: 0,
      // isApplicableToAllClients: true,
    },
    faqList: {},
    // categoryList: {},
    loading: false,
    client: {},
    regOrganization: [],
    editedClientIndex: -1,
    removeId: 0,
  }),
  created() {
    // this.getAllCategory({ skip: 0, take: 25000 });
    this.getOrganization();
  },

  methods: {
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    truncate(source, size) {
      if (source == null) {
        return source;
      } else {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
    },
    editItem(item) {
      this.editedIndex = this.faqList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    closeDialog() {
      this.dialogDelete = false;
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    deleteItem(item) {
      this.activationStatus = item.isActive;
      this.editedIndex = this.faqList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItem1(item) {
      this.activationStatus = item.isActive;
      this.editedIndex = this.faqList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.faqList.records[this.editedIndex].id;

      this.delete({ isActive: false }, id);

      this.dialogDelete = false;
    },
    resetStatus() {
      this.faqList.records[this.editedIndex].isActive =
        this.faqList.records[this.editedIndex].isActive == true ? false : true;
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: (pagination.page - 1) * 10,
        take: 10,
        isActive: true,
        category: this.filter.category,
        question: this.searchKey,
      });
      // console.log("this.editedIndexClient", this.editedIndexClient);
      this.loading = false;
      this.faqList = result;
      if (this.faqList.records.length > 0) {
        this.client = this.faqList.records[this.editedIndexClient];
      }
    },
    features(item) {
      this.editedIndexClient = this.faqList.records.indexOf(item);
      // console.log("this.editedIndexClient", this.editedIndexClient);
      this.editedIndex = item.id;
      this.client = item;
      this.dialogFeatures = true;
    },
    deleteClient(item) {
      this.editedIndexClient = this.faqList.records.indexOf(item);
      this.editedIndex = item.id;
      this.client = item;
      this.dialogDeleteClient = true;
    },
    closeClientDialog() {
      this.dialogFeatures = false;
      this.editedClientIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    async categoryChange() {
      this.pageIndex = 0;
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: true,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    onUpdate() {
      // let valid = this.$refs.form.validate();
      // if (valid) {
      //   let formData = new FormData();
      //   formData.append("question", this.editedItem.question);
      //   formData.append("answer", this.editedItem.answer);
      //   formData.append("category", this.editedItem.category);
      //   formData.append("applicableTo", this.editedItem.applicableTo);

      //   if (this.editedIndex == -1) {
      //     this.create(formData);
      //   } else {
      //     const id = this.faqList.records[this.editedIndex].id;

      //     this.update(formData, id);
      //   }
      //   this.closeDialog();
      // }
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          this.create({
            question: this.editedItem.question,
            answer: this.editedItem.answer,
            category: this.editedItem.category,
            applicableTo: this.editedItem.applicableTo,
            isApplicableToAllClients: this.editedItem.isApplicableToAllClients,
          });
        } else {
          const id = this.faqList.records[this.editedIndex].id;
          this.editedItem.id = id;
          this.update(
            {
              question: this.editedItem.question,
              answer: this.editedItem.answer,
              category: this.editedItem.category,
              applicableTo: this.editedItem.applicableTo,
              isApplicableToAllClients:
                this.editedItem.isApplicableToAllClients,
              isActive: true,
            },
            this.editedItem.id
          );
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await faqService.create(item);

      if (result.status == 200) {
        this.status = "FAQ created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await faqService.update(item, id);
      if (result.status == 200) {
        this.status = "FAQ updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item, id) {
      let result = await faqService.update(item, id);
      if (result.status == 200) {
        if (item.isActive == true) {
          this.status = "FAQ activated successfully";
        } else {
          this.status = "FAQ deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: true,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    async clear() {
      this.searchKey = "";
      this.filter.searchKey = "";
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: true,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    async modifyOthers() {
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: true,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    // async getAllCategory(input) {
    //   let result = await catService.getAll(input);

    //   this.categoryList = result;
    // },

    newStatus(id) {
      let newstatus = "";
      if (id == "0") {
        newstatus = "Low";
      } else if (id == "1") {
        newstatus = "Medium";
      } else if (id == "2") {
        newstatus = "High";
      }
      return newstatus;
    },
    onAddClient() {
      let valid = this.$refs.form1.validate();

      if (valid) {
        if (this.editedClientIndex == -1) {
          // console.log("ids", this.editedClient.clientId);
          // var list = [];
          // for (var i = 0; i < this.editedClient.clientId.length; i++) {
          // list.push({
          //   clientId: this.editedClient.clientId[i],
          //   isApplicableToAllClients:
          //     this.editedClient.isApplicableToAllClients,
          // });
          // list.push(this.editedClient.clientId[i])
          // }
          // console.log("list", list);
          this.createClient(
            {
              mapping: this.editedClient.clientId,
            },
            this.client.id
          );
        }
        this.closeClientDialog();
      }
    },
    async createClient(item, id) {
      let result = await faqService.createClient(item, id);
      if (result.status == 200) {
        this.status = "FAQ Mapping successfull";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    remove(item) {
      this.removeId = item.id;
      this.dialogDeleteConfirm = true;
    },
    deleteConfirmClient() {
      this.ConfirmClient(this.removeId);
      this.dialogDeleteConfirm = false;
    },
    async ConfirmClient(item) {
      let result = await faqService.deleteConfirmClient(item);
      if (result.status == 200) {
        this.status = "Client removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.custom-bg {
  color: gray;
}
/deep/.style-1 {
  background: #e4e7ea !important;
  opacity: 0.5;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 8px !important;
  margin-bottom: 10px;
}
</style>

import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/Organization`, {
      params: input,
    });
    return result.data;

  },
  async getAllOrg() {
    let result = await mainAxios.get(`/Organization/list`);
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Organization`, input);

    return result;
  },
  async update(id, input) {
    let result = await mainAxios.put(`/Organization`, input, {
      params: { organizationId: id },
    });
    return result;
  },

  async updateLogo(input, id) {
    let result = await mainAxios.put(`/Organization/logo`, input, { params: { organizationId: id } });

    return result;
  },

}
<template>
  <div class="container">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      class="mb-3"
    >
      <v-tab v-for="item in tabItems" :key="item" @click="getStatus(item)">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" height="auto">
      <v-tab-item
        key="New"
        height="auto"
        title="Processes purchased by customers which are are yet to reviwed by DA Admin"
      >
        <leads-by-status :statusNo="stuatusno"></leads-by-status>
      </v-tab-item>
      <v-tab-item
        key="In Progress"
        height="auto"
        title="Processes that are marked as in Progress by DA Admin"
      >
        <leads-by-status :statusNo="stuatusno"></leads-by-status>
      </v-tab-item>
      <v-tab-item
        key="Cancelled"
        height="auto"
        title="Processes that are marked as cancelled by DA Admin"
      >
        <leads-by-status :statusNo="stuatusno"></leads-by-status>
      </v-tab-item>
      <v-tab-item
        key="Implemented"
        height="auto"
        title="Processes that are Implemented for the clients"
      >
        <leads-by-status :statusNo="stuatusno"></leads-by-status>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import LeadsByStatus from "../components/LeadsByStatus.vue";

const { mapGetters: mapGettersAuth } = createNamespacedHelpers("auth");
export default {
  components: {
    LeadsByStatus,
  },
  computed: {
    ...mapGettersAuth(["isLoggedIn", "userType", "userId"]),
  },
  data: () => ({
    tab: null,
    tabItems: ["New", "In Progress", "Cancelled", "Implemented"],

    stuatusno: 0,
    userItem: {
      id: 0,
      firstName: "",
      lastName: "",
      organization: "",
      emailId: "",
      mobileNo: "",
    },
    defaultItem: {
      id: 0,
      firstName: "",
      lastName: "",
      organization: "",
      emailId: "",
      mobileNo: "",
    },
  }),
  created() {},

  methods: {
    deleteItem(item) {
      this.userItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.approve({ userId: this.userItem.id, status: -1 });
      this.closeDelete();
    },
    acceptItemConfirm() {
      this.approve({ userId: this.userItem.id, status: 1 });
      this.close();
    },
    close() {
      this.dialog = false;
      this.userItem = Object.assign({}, this.defaultItem);
    },

    closeDelete() {
      this.dialogDelete = false;

      this.userItem = Object.assign({}, this.defaultItem);
    },
    getStatus(item) {
      if (item == "In Progress") {
        this.stuatusno = 2;
      } else if (item == "New") {
        this.stuatusno = 0;
      } else if (item == "Cancelled") {
        this.stuatusno = -1;
      } else if (item == "Implemented") {
        this.stuatusno = 1;
      }
      let data = {
        skip: 0,
        take: 10,
        searchKey: "",
        status: this.stuatusno,
      };
      this.getAllLeads(data);
    },
  },
};
</script>



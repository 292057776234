<template>
  <div class="container mt-5 mb-12">
    <v-card outlined>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          outlined
          dense
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="tabtitle2 text-capitalize mx-2"
              @click="processIssueOrEnhance()"
              v-bind="attrs"
              v-on="on"
            >
              Raise New Ticket
              <v-icon small class="ml-1">mdi-alert-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Report Issue/Enhancement or Feedback</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="allTickets.records"
        :items-per-page="10"
        :server-items-length="allTickets.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>
            {{ new Date(item.createdOn).toDateString() }}
          </td>
        </template>
        <template v-slot:[`item.source`]="{ item }">
          <td v-if="item.source == 'UiPathBot'">
            {{ item.source }}
          </td>
          <td v-else>Marketplace</td>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <td>{{ truncateText(item.description) }}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            bottom
            v-if="item.status == 'New' && item.type != 'Feedback'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="processIssueOrEnhance(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Report Issue/Enhancement or Feedback</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.type != 'Feedback'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="getFiles(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file
              </v-icon>
            </template>
            <span>View Issue/Enhancement Files</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.processName`]="{ item }">
          <td class="font-weight-bold text-justify">
            {{ item.processName }}
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td
            class="font-weight-bold text-justify"
            v-if="item.type != 'Feedback'"
          >
            {{ newStatus(item.status) }}
          </td>
        </template>
        <template #[`item.type`]="{ item }">
          <v-chip
            v-if="item.type"
            label
            small
            :color="getColorStatus(item)"
            :text-color="getTextColor(item)"
            dark
          >
            {{ item.type }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <!-- <v-card-title> Report Issue or Request Enhancement </v-card-title> -->
        <v-card-title class="justify-left">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-left pl-0">
              Report Issue or Request Enhancement or send a Feedback
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select
              v-if="editedIndex == -1"
              required
              :rules="[(v) => !!v || 'Process is required']"
              :items="allProcesses"
              item-text="name"
              item-value="id"
              v-model="selectedProcesses"
              label="Select Process"
              :clearable="false"
              ><template #label>
                Process <span class="red--text"><strong>* </strong></span>
              </template></v-select
            >
            <v-text-field
              v-model="editedItem.name"
              required
              :rules="[
                (v) => !!v || 'Summary is required',
                (v) => (v && !!v.trim()) || 'Summary cannot be blank',
              ]"
            >
              <template #label>
                Summary <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-select
              :items="type"
              item-text="name"
              item-value="value"
              v-model="editedItem.type"
              required
              :rules="[(v) => !!v || 'Type is required']"
              ><template #label>
                Type <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="border"
            class="text-capitalize"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            depressed
            @click="onUpdate"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogfiles"
      width="1000"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="justify-left">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-left pl-0">
              <v-btn
                v-if="task.type == 'Issue'"
                color="red"
                text
                class="toolbarclass"
              >
                <!-- <v-avatar left> -->
                <v-icon left small>mdi-alert-circle</v-icon>
                <!-- </v-avatar> -->

                {{ task.type }} - {{ task.id }}
              </v-btn>
              <v-btn
                v-else-if="task.type == 'Enhancement'"
                color="green"
                text
                class="toolbarclass"
              >
                <!-- <v-avatar left> -->
                <v-icon left small>mdi-bookmark-plus</v-icon>
                <!-- </v-avatar> -->

                {{ task.type }} - {{ task.id }}
              </v-btn>
              <v-btn v-else color="buttonColor" text class="toolbarclass">
                <!-- <v-avatar left> -->
                <v-icon left small>mdi-comment-quote</v-icon>
                <!-- </v-avatar> -->

                {{ task.type }} - {{ task.id }}
              </v-btn></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialogfiles">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <!-- <div class="row col-md-12 mb-0"> -->
        <!-- <div class="col-md-8"> -->
        <!-- <v-card-text> -->
        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-card-title class="cartitem">
              {{ tickets.processName }}
              <v-chip
                v-if="tickets.status != ''"
                label
                small
                color="#E6F2F1"
                text-color="#00796B"
                dark
                class="ml-2"
              >
                {{ newStatus(tickets.status) }}
              </v-chip>
            </v-card-title>
            <v-card-title class="primary--text mr-4 creatortext"
              ><span class="grey--text creatortext mr-2"> Created by </span>
              {{ tickets.createdBy }}</v-card-title
            >
          </div>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="ordertextb">
            {{ tickets.name }}
          </v-card-title>
          <!-- <v-card-text class="cartdesc">Description</v-card-text> -->
          <v-card-text class="cartdesc"> {{ tickets.description }}</v-card-text>

          <v-card-text>
            <v-card v-if="fileInfos.length > 0" class="mx-auto" outlined>
              <v-card-title class="ordertextb">List of Files</v-card-title>
              <div class="d-flex flex-wrap">
                <div
                  class="border text-center align-self-center pa-5 ma-2"
                  v-for="(file, index) in fileInfos"
                  :key="index"
                >
                  <div class="d-flex justify-center">
                    <v-img
                      height="50"
                      width="50"
                      v-if="typeimage(file.name)"
                      :src="file.fileURL"
                      contain
                      :alt="file.name"
                    ></v-img>
                    <v-img
                      height="50"
                      width="50"
                      class="align-self-center"
                      v-else-if="typedoc(file.name)"
                      :src="require('@/assets/word.png')"
                      contain
                      :alt="file.name"
                    >
                    </v-img>
                    <v-img
                      height="50"
                      width="50"
                      v-else-if="typeexcel(file.name)"
                      :src="require('@/assets/excel.png')"
                      contain
                      :alt="file.name"
                    >
                    </v-img>
                    <v-img
                      height="50"
                      width="50"
                      v-else-if="typepdf(file.name)"
                      :src="require('@/assets/pdf.png')"
                      contain
                      :alt="file.name"
                    >
                    </v-img>
                  </div>
                  <h5 class="text-center" v-if="(fname = strtrunk(file.name))">
                    <a :href="file.fileURL" target="_blank">{{ fname }}</a>
                  </h5>
                </div>
              </div>
            </v-card>
            <div class="pt-10">
              <div class="ordertextb">Comments</div>
              <v-divider class="mt-2"></v-divider>
              <Comment
                @commentUpdate="onClickSubmit"
                isCreate
                :comment="{
                  user: '',
                  message: 'Add a comment...',
                }"
              />
              <Comment
                @commentUpdate="onClickSubmit"
                @commentDelete="onClickDelete"
                :comment="comment"
                v-for="comment in comments"
                :key="comment.id"
              />
            </div>
          </v-card-text>
        </v-card-text>
        <!-- </div> -->
        <!-- <div class="col-md-4">
            <v-card-text>Status</v-card-text>
            <v-card-title class="font-weight-bold">
              {{ newStatus(tickets.status) }}
            </v-card-title>
          </div> -->
        <!-- </div> -->
      </v-card>
    </v-dialog>
    <v-alert v-if="statuss" type="success">
      {{ statuss }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script >
import ticketService from "@/services/ticket.service.js";
import Comment from "../components/Comment.vue";
import leadService from "@/services/lead.service.js";
export default {
  components: {
    Comment,
  },
  created() {
    this.getProcessList();
  },
  data: () => ({
    allProcesses: [],
    loading: false,
    allTickets: {},
    pageIndex: 0,

    statusE: "",
    statuss: "",
    filter: {
      searchKey: "",
    },
    headers: [
      { text: "Summary", value: "name", sortable: false, width: "10%" },
      {
        text: "Process Name",
        value: "processName",
        width: "10%",
        sortable: false,
      },

      {
        text: "Ordered on",
        value: "createdOn",
        width: "10%",
        sortable: false,
      },
      { text: "Created By", value: "createdBy", sortable: false },
      { text: "Created From", value: "source", sortable: false },
      {
        text: "Description",
        value: "description",
        sortable: false,
        width: "25%",
      },
      { text: "Type", value: "type", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    selectedProcesses: "",
    editedIndex: -1,
    fileInfos: [],
    comments: [],
    dialogfiles: false,
    tickets: {},
    valid: false,
    dialog: false,
    editedItem: {
      id: 0,
      clientProcessId: 0,
      name: "",
      description: "",
      type: "",
    },
    task: {},
    defaultItem: {
      id: 0,
      clientProcessId: 0,
      name: "",
      description: "",
      type: "",
    },
    type: [
      { name: "Issue", value: "Issue" },
      { name: "Enhancement", value: "Enhancement" },
      { name: "Feedback", value: "Feedback" },
    ],
  }),

  computed: {},
  methods: {
    getColorStatus(item) {
      if (item.type == "Issue") return "#FDEDE8";
      else if (item.type == "Enhancement") return "#E6F2F1";
      else return "#f0f4ff";
    },
    getTextColor(item) {
      if (item.type == "Issue") return "#E64A19";
      else if (item.type == "Enhancement") return "#00796B";
      else return "#3F51B5";
    },
    typeimage(item) {
      var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    typedoc(item) {
      var _validFileExtensions = [".doc", ".docx", "txt"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    typepdf(item) {
      var _validFileExtensions = [".pdf"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    typeexcel(item) {
      var _validFileExtensions = [".xlsx", ".csv"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    async getProcessList() {
      let result = await leadService.getAllProcess();

      this.allProcesses = result;
    },
    strtrunk(n) {
      var len = 7;
      var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
      var filename = n.replace("." + ext, "");
      if (filename.length <= len) {
        return n;
      }
      filename = filename.substr(0, len) + (n.length > len ? "..." : "");
      return filename + "." + ext;
    },
    processIssueOrEnhance(item) {
      this.editedIndex = this.allTickets.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.selectedProcesses = "";
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          this.createNewTicket({
            clientProcessId: this.selectedProcesses,
            name: this.editedItem.name,
            description: this.editedItem.description,
            type: this.editedItem.type,
          });
        } else {
          this.create(
            {
              clientProcessId: this.editedItem.clientProcessId,
              name: this.editedItem.name,
              description: this.editedItem.description,
              type: this.editedItem.type,
            },
            this.editedItem.id
          );
        }

        this.closeDialog();
      }
    },

    async create(item, id) {
      let result = await ticketService.update(item, id);

      if (result.status == 200) {
        this.statuss = "Request updated successfully";
        setTimeout(() => {
          this.statuss = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async createNewTicket(item) {
      let result = await ticketService.create(item);

      if (result.status == 200) {
        this.statuss = "Request sent successfully";
        setTimeout(() => {
          this.statuss = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    onClickDelete(value) {
      this.deleteComments(value.id);
    },
    async deleteComments(id) {
      let result = await ticketService.deleteComment(id);
      if (result.status == 200) {
        this.status = "Comment deleted successfully";

        this.getById(this.task.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
      };
      this.getAllTickets(data);
    },

    async getAllTickets(input) {
      this.loading = true;
      let result = await ticketService.getAllTickets(input);
      this.loading = false;
      this.allTickets = result;
    },
    search() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
      };
      this.getAllTickets(data);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      this.filter.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
      };
      this.getAllTickets(data);
    },

    getFiles(item) {
      this.task = item;
      this.getById(item.id);
    },
    async getById(input) {
      let result = await ticketService.getTicketById(input);

      if (result.status == 200) {
        this.tickets = result.data;
        this.fileInfos = result.data.file;
        this.comments = result.data.comments;
        this.comments = result.data.comments.sort((a, b) => {
          return parseInt(b.id) - parseInt(a.id);
        });
        this.dialogfiles = true;
      }
    },
    closeDialogfiles() {
      this.dialogfiles = false;
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "New") {
        newstatus = "New";
      } else if (id == "Implemented") {
        newstatus = "Implemented";
      } else if (id == "InProgress") {
        newstatus = "In Progress";
      } else if (id == "UAT") {
        newstatus = "Under Testing";
      }
      return newstatus;
    },
    onClickSubmit(value) {
      if (value.type) {
        let data = {
          message: value.newComment,
        };
        this.submitComments(data, this.task.id);
      } else {
        let data = {
          message: value.newComment,
        };
        this.updateComments(data, value.id);
      }
    },
    async submitComments(input, id) {
      let result = await ticketService.createComment(input, id);
      if (result.status == 200) {
        this.status = "Comment added successfully";

        this.getById(this.task.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    async updateComments(input, id) {
      let result = await ticketService.updateComment(input, id);
      if (result.status == 200) {
        this.status = "Comment updated successfully";

        this.getById(this.task.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
/deep/.v-toolbar__content {
  padding: 0px;
}
.cartitem {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
.creatortext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}
.cartdesc {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #5f6463 !important;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.dateval {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  color: #5f6463;
}
.ordertextb {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.border {
  // background: #fbfbf9;
  /* Secondary/500 */

  border: 2px solid $border;
  border-radius: 6px;
  cursor: pointer;
}
.tabtitle2 {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* identical to box height */
  /* Primary/Teal */
  color: $primary !important;
}
</style>


<template>
  <div class="container mt-2">
    <div class="col-12 px-0">
      <p class="processheader">Customer Reviews</p>
    </div>
    <div class="row mb-2">
      <v-col class="col-md-4">
        <v-row>
          <v-col>
            <p class="reviewtext ml-1 mb-0">{{ review.length }} Reviews</p>
            <v-rating
              v-model="summary.avgtotal"
              color="amber"
              dense
              half-increments
              readonly
              size="20"
              background-color="#ccc"
              class="mt-0"
            ></v-rating>
          </v-col>
          <v-col class="d-flex">
            <h1 class="totalavg align-self-end">{{ summary.avgtotal }}</h1>
            <b class="ml-2 align-self-end textoutof">out of 5</b>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="col-md-8 text-right"
        v-if="isLoggedIn != '' && userType != 'admin'"
      >
        <div class="float-right">
          <v-btn
            large
            outlined
            color="primary "
            @click="dialog = true"
            class="text-capitalize"
          >
            Write Product Review
          </v-btn>
        </div>
      </v-col>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <div class="row">
          <h5 class="col-2 rattext align-items-center">5 Star</h5>

          <div class="col-7">
            <v-progress-linear
              :value="summary.star5"
              height="20"
              rounded
              color="amber"
              background-color="#F0F2F2"
            >
            </v-progress-linear>
          </div>
          <h5 class="col-3 rattext align-items-center">
            {{ summary.star5 }} %
          </h5>
          <!---four star-->
          <h5 class="col-2 rattext align-items-center">4 Star</h5>
          <div class="col-7">
            <v-progress-linear
              :value="summary.star4"
              height="20"
              color="amber"
              rounded
              background-color="#F0F2F2"
            >
            </v-progress-linear>
          </div>
          <!---three star-->
          <h5 class="col-3 rattext align-items-center">
            {{ summary.star4 }} %
          </h5>
          <h5 class="col-2 rattext align-items-center">3 Star</h5>
          <div class="col-7">
            <v-progress-linear
              :value="summary.star3"
              height="20"
              color="amber"
              rounded
              background-color="#F0F2F2"
            >
            </v-progress-linear>
          </div>
          <h5 class="col-3 rattext align-items-center">
            {{ summary.star3 }} %
          </h5>
          <!---two star-->
          <h5 class="col-2 rattext align-items-center">2 Star</h5>
          <div class="col-7">
            <v-progress-linear
              :value="summary.star2"
              height="20"
              color="amber"
              rounded
              background-color="#F0F2F2"
            >
            </v-progress-linear>
          </div>
          <h5 class="col-3 rattext align-items-center">
            {{ summary.star2 }} %
          </h5>
          <!---one star-->
          <h5 class="col-2 rattext align-items-center">1 Star</h5>
          <div class="col-7">
            <v-progress-linear
              :value="summary.star1"
              height="20"
              color="amber"
              rounded
              background-color="#F0F2F2"
            >
            </v-progress-linear>
          </div>
          <h5 class="col-3 rattext align-items-center">
            {{ summary.star1 }} %
          </h5>
        </div>
      </div>

      <!---Comments section -->
      <div class="col-md-8">
        <!-- <div class="row mb-3 review-box" v-for="item in review" :key="item.id">
          <div class="col-lg-2 col-md-2 reviewRatingCol col-4">
            <v-rating
              :value="item.rating"
              background-color="orange lighten-3"
              half-increments
              color="orange"
              readonly
              size="13"
            ></v-rating>
          </div>
          <div class="col-lg-8 col-md-8 col-6 py-lg-3 py-md-3 px-0 rewname">
            <h4>{{ item.firstName }}</h4>
          </div>

          <div
            class="
              col-lg-2 col-md-2 col-12
              d-lg-flex d-md-flex
              text-end
              py-lg-3 py-md-3 py-0 py-sm-3
              reviewDateCol
            "
          >
            <div class="text-sm date">
              {{ new Date(item.createdOn).toDateString() }}
            </div>
            <div class="ms-auto">
              <div v-if="parse(userId) == parse(item.userId)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3 float-right"
                      @click="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3 float-right"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </div>
            </div>
          </div>

          <h6 class="col-lg-12 col-sm-12 col-md-12 pl-4 largetext">
            {{ item.message }}
          </h6>
        </div> -->
        <div v-for="(item, index) in review" :key="index">
          <div class="d-flex">
            <v-rating
              dense
              :value="item.rating"
              background-color="orange lighten-3"
              half-increments
              color="orange"
              readonly
              size="13"
            ></v-rating>
            <h4 class="rewname ml-3">{{ item.firstName }}</h4>
            <div class="ml-auto">
              <div class="d-flex">
                <div class="align-self-center date">
                  at {{ new Date(item.createdOn).toDateString() }}
                </div>
                <div class="align-self-center">
                  <div v-if="parse(userId) == parse(item.userId)" class="ml-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="red"
                          class="float-right"
                          @click="deleteItem(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-3 float-right"
                          @click="editItem(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="pl-1 mt-3 largetext text-justify">
            {{ item.message }}
          </p>
          <v-divider v-if="index < review.length - 1" class="mb-3"></v-divider>
        </div>
      </div>
    </div>

    <!---Add or update comment -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card height="auto" class="text-center">
        <v-card-title class="justify-center">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-center">
              {{ formTitle }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text class="text-center pa-4">
              <img
                class="ma-4"
                :src="require('@/assets/logoDA.svg')"
                height="80"
                width="80"
              />

              <div class="justify-center w-100">
                <v-input
                  class="centered-input"
                  :value="editedItem.rating"
                  required
                  :rules="[(v) => !!v || 'Please rate this Process']"
                >
                  <v-rating
                    class="text-center"
                    v-model="editedItem.rating"
                    background-color="orange lighten-3"
                    half-increments
                    color="orange"
                    large
                  ></v-rating>
                </v-input>
              </div>
              <v-textarea
                v-model="editedItem.message"
                required
                :rules="[(v) => !!v || 'Comment is required']"
                label="Comment"
              >
                <template #label>
                  Comment <span class="red--text"><strong>* </strong></span>
                </template>
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mr-1 text-capitalize border" @click="closeDialog">
                Close
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                type="button"
                depressed
                @click="onUpdate"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!---Delete confirmation -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this Review?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";
import reviewService from "@/services/review.service.js";
import EventBus from "../plugins/eventBus";
export default {
  props: {
    productId: Number,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Comment" : "Edit Comment";
    },
  },
  created() {
    this.getReviewById({ processId: this.productId });
  },
  data: () => ({
    review: [],
    summary: {},
    isLoggedIn: authService.isLoggedIn ? true : undefined,
    userType: authService.authData.userType,
    userId: authService.authData.id,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    valid: false,
    status: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: 0,
      productId: 0,
      rating: 0,
      message: "",
    },
  }),
  methods: {
    parse(item) {
      return parseInt(item);
    },
    async getReviewById(input) {
      let result = await reviewService.getAll(input);
      this.review = result.review;
      this.summary = result.summary;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.editedItem.rating = 0;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    deleteItem(item) {
      this.editedIndex = this.review.indexOf(item);
      this.dialogDelete = true;
    },
    async create(item, id) {
      let result = await reviewService.create(item, id);

      if (result.status == 200) {
        this.status = "Comment added successfully";
        EventBus.emit("comment_changes", "Comment added successfully");
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getReviewById({ processId: this.productId });
      }
    },
    async update(item, id) {
      let result = await reviewService.update(item, id);
      if (result.status == 200) {
        this.status = "Comment updated successfully";
        EventBus.emit("comment_changes", "Comment updated successfully");
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getReviewById({ processId: this.productId });
      }
    },
    async delete(item) {
      let result = await reviewService.delete(item);
      if (result.status == 200) {
        this.status = "Comment deleted successfully";
        EventBus.emit("comment_changes", "Comment deleted successfully");
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getReviewById({ processId: this.productId });
      }
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.rating = Number(this.editedItem.rating);
      this.editedIndex = this.review.indexOf(item);
      this.dialog = true;
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let model = {
          message: this.editedItem.message,
          rating: this.editedItem.rating,
        };
        if (this.editedIndex == -1) {
          this.create(model, this.productId);
        } else {
          let id = this.review[this.editedIndex].id;

          this.update(model, id);
        }
        this.closeDialog();
      }
    },
    deleteConfirm() {
      let id = this.review[this.editedIndex].id;
      this.delete({ commentId: id });
      this.dialogDelete = false;
      this.editedIndex = -1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";
.review-box {
  border-bottom: 1px solid #f9f6f6;
}
.date {
  // font-size: 12px;
  // color: grey;
  // align-self: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #45464a;
}
.blue-text {
  color: #007185;
}
.processheader {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: $secondary;
}
.reviewtext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: $secondary;
}
.starttext {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $secondary;
}
.rattext {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: $secondary !important;
}
.rewname {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  // line-height: 16px !important;

  color: $secondary !important;
}
// .container {
//   border-top: 1px solid #dbd9d9;
// }
.btn-white {
  color: #000 !important;
  font-size: 11px !important;
}
h6 {
  font-size: 14px;
}
.largetext {
  white-space: initial;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $secondary;
}
/deep/ .centered-input input {
  text-align: center;
}
/deep/.v-input__slot {
  display: block !important;
}
@media (min-width: 767px) and (max-width: 912px) {
  .review-box .col-4 {
    max-width: 20%;
  }
  .review-box .col-6 {
    max-width: 60%;
  }
  .review-box .col-12 {
    max-width: 30%;
  }
}
@media (min-width: 240px) and (max-width: 250px) {
  .review-box .col-4 {
    max-width: 50%;
  }
  .review-box .col-6 {
    max-width: 50%;
  }
  .review-box .col-12 {
    max-width: 100%;
  }
}
.textoutof {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 38px !important;
  color: $secondary !important;
}
.totalavg {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 38px !important;
  color: $secondary !important;
}
</style>
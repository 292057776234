<template>
  <div class="mt-5 mb-5" v-if="process">
    <div height="auto">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <v-carousel v-model="model" hide-delimiters>
              <v-carousel-item
                v-for="(item, i) in getImages"
                :key="i"
                :src="item.fileURL"
                reverse-transition="fade-transition"
                transition="fade-transition"
                @click.native="my_image_url(i)"
              >
              </v-carousel-item>
            </v-carousel>
            <div class="row mt-lg-3 thumnailRow">
              <div class="col-12 d-lg-flex d-md-flex d-flex">
                <div v-for="(item, i) in getImages" :key="i">
                  <img
                    :src="item.fileURL"
                    height="80px"
                    width="80px"
                    @click="model = i"
                    class="corner border mr-1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7 col-sm-12">
            <div class="row mb-1">
              <v-col md="8" sm="6" class="mt-3">
                <h3 class="productName">{{ product.name }}</h3>
                <v-chip class="text-capitalize btnclr-flow textclr-flow mt-2">
                  {{ product.categoryName }}
                </v-chip>
              </v-col>
              <v-col md="4" sm="6">
                <v-row class="col-12 pr-0">
                  <v-col cols="8" class="px-0 align-items-center">
                    <h5 class="rattext align-items-center ml-1">
                      {{ review.length }} Reviews
                    </h5>
                    <v-rating
                      :value="summary.avgtotal"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="20"
                      background-color="#ccc"
                    ></v-rating>
                  </v-col>
                  <v-col cols="4" class="ratingCol">
                    <h1 class="ratingAvg">{{ summary.avgtotal }}</h1>
                  </v-col>
                </v-row>
              </v-col>
            </div>
            <v-divider></v-divider>
            <div class="descriptionSection mt-2" v-if="product.description">
              <p class="text-justify desc" v-if="isReadMore == false">
                {{ product.description.substring(0, 250) }}
                <v-btn
                  style="height: 0; justify-content: initial"
                  :ripple="false"
                  text
                  plain
                  color="black"
                  class="px-0 py-0 m-0 text-capitalize"
                  v-if="isReadMore == false && product.description.length > 250"
                  @click="readMoreStateChange()"
                  >More</v-btn
                >
              </p>

              <p class="text-justify desc" v-if="isReadMore">
                {{ product.description }}
                <v-btn
                  :ripple="false"
                  text
                  plain
                  color="black"
                  class="px-0 py-0 text-start text-capitalize"
                  v-if="isReadMore && product.description.length > 250"
                  @click="readMoreStateChange()"
                  style="height: 0; justify-content: initial"
                  >Less</v-btn
                >
              </p>
            </div>

            <v-card dark class="div-wrapper py-0">
              <div class="d-flex div-wrapper">
                <v-avatar size="32" tile class="align-self-center mx-5">
                  <v-img :src="require('@/assets/icons.svg')"></v-img>
                </v-avatar>

                <div class="align-self-center" v-if="product.complexity">
                  <span class="title1">Complexity :</span>
                  <span class="title2">{{
                    newStatus(product.complexity)
                  }}</span>
                </div>
                <div
                  class="ml-auto align-self-center mr-lg-5"
                  v-if="product.noImplemented > 0"
                >
                  <span class="title1">
                    {{ imp }}
                  </span>
                </div>
              </div>
            </v-card>
            <v-card
              class="my-0 mt-lg-10 mt-4 d-flex align-center"
              v-if="userType != 'admin'"
              outlined
            >
              <v-btn
                color=" text-capitalize textsize"
                class="ma-2"
                :disabled="!(!product.isPurchaed && checkCart)"
                @click="add"
              >
                <div v-if="product.isPurchaed && checkCart">
                  Already purchased
                </div>
                <div v-if="!product.isPurchaed && checkCart">Add To Cart</div>
                <div v-if="!checkCart && !product.isPurchaed">
                  Already in cart
                </div>
              </v-btn>
              <v-badge overlap color="primary" :content="count.toString()">
                <v-avatar tile size="25">
                  <v-icon color="secondary" @click="cart()"> mdi-cart </v-icon>
                  <!-- <v-img
                  :src="require('@/assets/Cart.png')"
                  @click="cart()"
                ></v-img> -->
                </v-avatar>
              </v-badge>
            </v-card>
          </div>
        </div>
      </div>
      <v-divider class="mt-5"></v-divider>
      <div class="container">
        <p class="processheader px-0">More Informations</p>

        <!-- <v-card-text height="auto" class="px-3"> -->
        <div class="row mt-2">
          <div
            v-for="item in getFeatures"
            :key="item.id"
            :class="item.className == 'null' ? 'col-12' : item.className"
            class="py-1"
          >
            <v-img :src="item.fileURL" v-if="item.type == 'image'" contain>
            </v-img>
            <iframe
              v-if="item.type == 'video'"
              class="full"
              x-ref="player"
              :src="item.fileURL"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
            <p v-if="item.type == 'text'" class="rattext">
              {{ item.description }}
            </p>
            <h5 class="featureHeader" v-if="item.type == 'header'">
              {{ item.description }}
            </h5>
          </div>
        </div>
        <!-- </v-card-text> -->
      </div>
      <v-divider></v-divider>
      <!-- <div class="row"> -->
      <Review :productId="getProductId"></Review>
      <!-- </div> -->
    </div>

    <v-dialog
      v-model="dialog"
      width="1400"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <div class="row col-md-12 mb-0" id="inspire">
          <div class="col-md-6">
            <v-carousel v-model="model1" hide-delimiters>
              <v-carousel-item
                v-for="(item, i) in getImages"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
                :src="item.fileURL"
              >
              </v-carousel-item>
            </v-carousel>
          </div>
          <div class="col-md-6">
            <div class="row mt-5">
              <div v-for="(item, i) in getImages" :key="i" class="mr-2 border">
                <img
                  :src="item.fileURL"
                  height="75px"
                  width="75px"
                  @click="model1 = i"
                  class="corner"
                />
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import processService from "@/services/process.service.js";
import EventBus from "../plugins/eventBus";
import cartService from "@/services/cart.service.js";
import reviewService from "@/services/review.service.js";
import Review from "../components/Review.vue";
import authService from "@/services/auth.service.js";
export default {
  components: {
    Review,
  },
  created() {
    this.getById(this.$route.params.id);
    this.getReviewById({ processId: this.$route.params.id });
    if (this.isLoggedIn != "" && this.userType != "admin") {
      this.getAllCart();
    }
    EventBus.on("comment_changes", () => {
      this.getReviewById({ processId: this.$route.params.id });
    });
  },
  destroyed() {
    EventBus.off("comment_changes");
  },
  computed: {
    getProductId() {
      return parseInt(this.$route.params.id);
    },
    getImages() {
      const arr = this.features.filter((t) => t.type === "crimage");
      let img = {
        type: "crimage",
        fileURL: this.product.imageURL,
        order: 0,
      };
      arr.unshift(img);
      return arr;
    },
    getFeatures() {
      return this.features.filter((t) => t.type !== "crimage");
    },
    checkPurchase() {
      return (
        !this.purchased.filter((t) => t === parseInt(this.$route.params.id))
          .length > 0
      );
    },

    checkCart() {
      return (
        !this.cartlist.filter(
          (t) => t.processId === parseInt(this.$route.params.id)
        ).length > 0
      );
    },
  },

  data: () => ({
    review: [],
    summary: {},
    process: [],
    cartlist: [],
    count: "",
    product: {},
    features: [],
    model: 0,
    model1: 0,
    dialog: false,
    isReadMore: false,
    isLoggedIn: authService.isLoggedIn ? true : undefined,
    userType: authService.authData.userType,
    imp: "",
  }),
  methods: {
    add() {
      this.create({ processId: this.$route.params.id });
    },
    async create(item) {
      let result = await cartService.create(item);

      if (result.status == 200) {
        this.status = "Item added to cart successfully";
        this.$router.push("/cart");
        setTimeout(() => {
          this.status = "";
        }, 2000);
      } else {
        this.status = result.data;
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    async getById(input) {
      let result = await processService.getProcessById(input);

      this.process = result;
      this.features = result[0].features;
      this.product = result[0];
      if (this.product.noImplemented == 1) {
        this.imp = "Implemented : " + this.product.noImplemented + " customer";
      } else {
        this.imp = "Implemented : " + this.product.noImplemented + " customers";
      }
    },
    async getReviewById(input) {
      let result = await reviewService.getAll(input);
      this.review = result.review;
      this.summary = result.summary;
    },
    async getAllCart() {
      let result = await cartService.getAll();
      this.cartlist = result;
      this.count = result.length;
    },
    parse(item) {
      return Number(item);
    },
    my_image_url(item) {
      (this.model1 = item), (this.dialog = true);
    },
    cart() {
      this.$router.push("/cart");
    },
    readMoreStateChange() {
      this.isReadMore = !this.isReadMore;
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "0") {
        newstatus = "Low";
      } else if (id == "1") {
        newstatus = "Medium";
      } else if (id == "2") {
        newstatus = "High";
      }
      return newstatus;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";
h3 {
  padding: 0;
  font-size: 24px;
  color: #000;
}
h4 {
  font-size: 26px;
  color: #000;
  margin-top: 30px;
}
.full {
  width: 100%;
  display: block;
  min-height: 50vh;
}
.text-justify {
  text-align: justify;
  font-size: 16px !important;
  padding-bottom: 20px !important;
}
.bg {
  height: 125px;
  background: transparent linear-gradient(263deg, #090e18 0%, #464c58 100%) 0%
    0% no-repeat;
  opacity: 1;
}
.title {
  color: #20d2b6;
  font-size: 16px !important;
}
// .bot {
//   height: 100%;
//   padding-left: 10px;
//   margin-top: -15px;
//   position: relative;
//   width: 100%;
//   opacity: 1;
// }
.group {
  width: auto;
  height: 90%;
  padding: 0px !important;
  margin: 0px !important;
}
.hgt {
  height: 100% !important;
}
.border {
  background: #fbfbf9;
  /* Secondary/500 */

  border: 2px solid #17181d;
  border-radius: 6px;
  cursor: pointer;
}
.corner {
  border-radius: 6px;
}
/deep/.v-image__image--cover {
  background-size: contain !important;
}
.div-wrapper {
  position: relative;
  height: 60px;
  background: $secondary;
  opacity: 1;
}
// .div-wrapper img {
//   height: 100%;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   opacity: 1;
// }
.textpos {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ffffff !important;
}
/deep/.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
  border-radius: 8px;
}
/deep/.v-btn {
  border-radius: 8px;
}
/deep/.v-toolbar__content {
  float: right;
}
.featureHeader {
  font-size: 16px;
}
.descriptionSection {
  min-height: 320px;
}
/deep/.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
  border-radius: 8px;
}
/deep/.v-btn {
  border-radius: 8px;
}
/deep/.v-toolbar__content {
  float: right;
}
@media (min-width: 320px) and (max-width: 321px) {
  /deep/.v-card__subtitle,
  .v-card__text,
  .v-card__title {
    margin-top: 20px;
    padding: 5px;
  }
  /deep/.v-card__title + .v-card__subtitle {
    margin-top: -10px;
  }
}
@media (min-width: 242px) and (max-width: 319px) {
  .v-application .title {
    font-size: 17px !important;
  }

  /deep/.v-card__title + .v-card__subtitle {
    margin-top: -10px;
  }
}
.productName {
  width: 100%;
  word-wrap: break-word;
  table-layout: fixed;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 23px !important;

  color: #090e18 !important;
}
@media (min-width: 479px) and (max-width: 767px) {
  .thumnailRow {
    margin-top: 5px;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  .thumnailRow {
    margin-top: 15px;
  }
}
@media (min-width: 821px) and (max-width: 912px) {
  .thumnailRow {
    margin-top: 30px;
  }
}
@media (min-width: 320px) and (max-width: 413px) {
  .ratingAvg {
    margin-left: 20px;
    margin-top: -4px;
  }
  .ratingCol {
    padding-top: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 414px) and (max-width: 415px) {
  .ratingAvg {
    margin-left: 20px;
    margin-top: -10px;
  }
  .ratingCol {
    padding-top: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 279px) and (max-width: 281px) {
  /deep/.v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 8px;
  }
}
@media (min-width: 239px) and (max-width: 241px) {
  // .textpos {
  //   margin-top: 30px;
  // }

  .v-application .title {
    font-size: 15px !important;
  }

  /deep/.v-card__title + .v-card__subtitle {
    margin-top: -10px;
  }
}
@media (min-width: 1023px) and (max-width: 1280px) {
  .thumnailRow {
    margin-top: 10px;
  }
  .ratingCol {
    padding: 0px;
    margin-top: -6px;
  }
}
.btnclr-flow {
  justify-content: center;
  align-items: center;
  padding: 2px 6px !important;
  gap: 2px !important;

  height: 20px !important;

  background: #e2e5f4 !important;
}
.textclr-flow {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 10px !important;
  // display: flex;
  align-items: center;
  color: #3f51b5 !important;
}
.desc {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  /* or 150% */

  /* Secondary/500 */

  color: #17181d !important;
}
.ratingAvg {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 43px !important;
  line-height: 50px !important;

  color: #17181d !important;
}
.rattext {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #17181d !important;
}
.title1 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ffffff !important;
}
.title2 {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #20d2b6 !important;
}
.textsize {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.25px !important;

  color: #ffffff !important;

  padding: 13px 20px !important;
  gap: 8px !important;

  background: #009688 !important;
  border-radius: 4px !important;
}
.processheader {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: $secondary;
}
</style>
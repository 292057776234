<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4 col-12">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <!---Add New Organization -->
              <div
                class="col-lg-3 col-sm-6 col-md-3 col-12 text-lg-left text-center"
              >
                <v-btn
                  dark
                  class="mt-3"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Organization</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <!---Data Table -->
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items-per-page="10"
        :items="organizationList.records"
        :server-items-length="organizationList.total"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="addExpenditure(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-timer-sand
              </v-icon>
            </template>
            <span>Add Cost</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="viewExpenditure(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file-document-outline
              </v-icon>
            </template>
            <span>View Cost</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!---Organization update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedItem.name"
              label="Organization"
              required
              :rules="[
                (v) => !!v || 'Organization is required',
                (v) => (v && !!v.trim()) || 'Organization cannot be blank',
              ]"
            >
              <template #label>
                Organization <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              label="Number of Admins for Alignxcel"
              v-model="editedItem.noOfAdminUsers"
              @keypress="onlyNumber($event)"
              ><template #label> Number of Admins</template></v-text-field
            >
            <v-text-field
              label="Number of Users for Alignxcel"
              v-model="editedItem.noOfNormalUsers"
              @keypress="onlyNumber($event)"
              ><template #label> Number of Users</template></v-text-field
            >
            <v-text-field
              label="Implementation Cost"
              v-model="editedItem.implementationCost"
              prefix="$"
              @keypress="onlyNumber($event)"
              ><template #label> Implementation Cost</template></v-text-field
            >
            <v-text-field
              label="Total Implementation Hours"
              v-model="editedItem.totalImplementationHours"
              @keypress="onlyNumber($event)"
              ><template #label>
                Total Implementation Hours</template
              ></v-text-field
            >
            <v-text-field
              label="Support Cost"
              v-model="editedItem.supportCost"
              prefix="$"
              @keypress="onlyNumber($event)"
              ><template #label> Support Cost</template></v-text-field
            >
            <v-text-field
              label="Total Support Hours"
              v-model="editedItem.totalSupportHours"
              @keypress="onlyNumber($event)"
              ><template #label> Total Support Hours</template></v-text-field
            >
            <v-text-field
              label="Term"
              v-model="editedItem.term"
              :suffix="parseInt(editedItem.term) > 1 ? 'months' : 'month'"
              @keypress="onlyNumber($event)"
              ><template #label> Term</template></v-text-field
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogExp" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">Add Cost</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card-text>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Pick month and year"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template #label>
                    Month and Year
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
              </template>
              <v-date-picker v-model="date" type="month" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="editedItemExp.actualSpentImplementationHours"
              label=" Spent Implementation Hours"
              @keypress="onlyNumber($event), noSpace($event)"
              required
              suffix="Hours"
              :rules="[
                (v) => !!v || 'Spent Implementation Hours is required',
                (v) =>
                  (v && !!v.toString().trim()) ||
                  'Spent Implementation Hours cannot be blank',
              ]"
            >
              <template #label>
                Spent Implementation Hours
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="editedItemExp.actualSpentSupportHours"
              label="Spent Support Hours"
              @keypress="onlyNumber($event), noSpace($event)"
              required
              suffix="Hours"
              :rules="[
                (v) => !!v || 'Spent Support Hours is required',
                (v) =>
                  (v && !!v.toString().trim()) ||
                  'Spent Support Hours cannot be blank',
              ]"
            >
              <template #label>
                Spent Support Hours
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="editedItemExp.comments"
              required
              :rules="[
                (v) => !!v || 'Comments is required',
                (v) => (v && !!v.trim()) || 'Comments cannot be blank',
              ]"
            >
              <template #label>
                Comments <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              @click="closeDialogExp"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdateExp"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import orgService from "@/services/organization.service.js";
import serviceService from "@/services/service.service.js";
export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Organization" : "Edit Organization";
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    valid1: false,
    dialogExp: false,
    organizationList: {},
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Number of Alignxcel Admins",
        align: "start",
        sortable: false,
        value: "noOfAdminUsers",
      },
      {
        text: "Number of Alignxcel Users",
        align: "start",
        sortable: false,
        value: "noOfNormalUsers",
      },

      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedIndexexp: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    editedItem: {
      id: 0,
      name: "",
      implementationCost: 0,
      totalImplementationHours: 0,
      supportCost: 0,
      totalSupportHours: 0,
      noOfAdminUsers: 0,
      noOfNormalUsers: 0,
      term: 0,
    },
    editedItemExp: {
      clientId: 0,
      actualSpentImplementationHours: "",
      actualSpentSupportHours: "",
      comments: "",
      month: "",
      year: "",
    },

    loading: false,
  }),
  created() {},
  methods: {
    editItem(item) {
      this.editedIndex = this.organizationList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    addExpenditure(item) {
      this.editedIndexexp = this.organizationList.records.indexOf(item);
      this.dialogExp = true;
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await orgService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.organizationList = result;
    },
    async search() {
      this.loading = true;
      let result = await orgService.getAll({
        skip: 0,
        take: 10,

        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.organizationList = result;
    },
    async clear() {
      this.loading = true;
      this.filter.searchKey = "";
      let result = await orgService.getAll({
        skip: 0,
        take: 10,

        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.organizationList = result;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closeDialogExp() {
      this.dialogExp = false;
      this.editedIndexexp = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
      this.date = new Date().toISOString().substr(0, 7);
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          this.create(this.editedItem);
        } else {
          const id = this.organizationList.records[this.editedIndex].id;
          this.editedItem.id = id;
          let input = {
            name: this.editedItem.name,
            implementationCost: this.editedItem.implementationCost,
            totalImplementationHours: this.editedItem.totalImplementationHours,
            supportCost: this.editedItem.supportCost,
            totalSupportHours: this.editedItem.totalSupportHours,
            noOfAdminUsers: this.editedItem.noOfAdminUsers,
            noOfNormalUsers: this.editedItem.noOfNormalUsers,
            term: this.editedItem.term,
          };
          this.update(id, input);
        }
        this.closeDialog();
      }
    },
    onUpdateExp() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        const id = this.organizationList.records[this.editedIndexexp].id;
        this.editedItemExp.month = this.date.split("-")[1];
        this.editedItemExp.year = this.date.split("-")[0];
        this.editedItemExp.clientId = id;

        this.updateExp(this.editedItemExp);

        this.closeDialogExp();
      }
    },

    async create(item) {
      let result = await orgService.create(item);

      if (result.status == 200) {
        this.status = "Organization created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(id, input) {
      let result = await orgService.update(id, input);
      if (result.status == 200) {
        this.status = "Organization updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async updateExp(item) {
      let result = await serviceService.addExpenditure(item);
      if (result.status == 200) {
        this.status = "Cost added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
</style>

import { mainAxios } from '../axios-instance';
export default {

  async getAll(input) {
    let result = await mainAxios.get(`/Application`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Application`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/Application/${id}`, input);
    return result;
  },
  async createTask(input, id) {
    let result = await mainAxios.post(`/Application/task`, input, { params: { id: id } });
    return result;
  },
  async updateTask(input, id) {
    let result = await mainAxios.put(`/Application/task/${id}`, input);
    return result;
  },

  async delete(input) {
    let result = await mainAxios.delete(`/Application/${input.id}?isApplicationDelete=${input.isApplicationDelete}`);
    return result;
  },

}
<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-5 col-lg-4 mx-auto log-box">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <div class="flex mb-5 mx-4">
              <img src="../assets/Pattern.png" />
              <h3 class="">Reset Password</h3>
              <small>Do you want to change your password</small>
            </div>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                :type="show1 ? 'text' : 'password'"
                v-model="oldPassword"
                :rules="passwordRules"
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                @keypress.native="noSpace($event)"
                outlined
                dense
                ><template #label>
                  Old Password
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
              <v-text-field
                :type="showN ? 'text' : 'password'"
                v-model="password"
                :rules="passwordRules2"
                required
                maxlength="20"
                outlined
                dense
                :append-icon="showN ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showN = !showN"
                @keypress.native="noSpace($event)"
                ><template #label>
                  New Password
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >

              <v-text-field
                :type="showC ? 'text' : 'password'"
                v-model="confirm_password"
                maxlength="20"
                outlined
                dense
                :rules="[
                  (v) => !!v.trim() || 'Password is required',
                  (v) =>
                    (v.trim() && v.length <= 20) || 'Maximum 20 characters',
                  passwordConfirmationRule,
                  (v) => (v.trim() && v.length >= 5) || 'Minimum 5 characters',
                ]"
                required
                :append-icon="showC ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showC = !showC"
                @keypress.native="noSpace($event)"
                ><template #label>
                  Confirm Password
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
              <v-btn
                color="black"
                class="white--text btn-block mt-5 text-left"
                depressed
                @click="validate"
              >
                Reset
                <v-avatar right class="text-right">
                  <img src="../assets/Arrow.png" />
                </v-avatar>
              </v-btn>

              <div v-if="authStatus" class="text-center mt-3 error-text">
                Invalid Password
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("auth");
export default {
  computed: {
    ...mapGetters(["authStatus"]),
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },
  created() {
    this.resetStatus();
  },
  data: () => ({
    show1: false,
    showN: false,
    showC: false,
    valid: false,

    oldPassword: "",
    password: "",
    confirm_password: "",
    emailRules: [
      (v) => !!v.trim() || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v.trim() || "Password is required"],
    passwordRules1: [
      (v) => !!v.trim() || "Password is required",
      (v) => (v.trim() && v.length >= 6) || "Minimum 6 characters",
      (v) => (v.trim() && v.length <= 20) || "Maximum 20 characters",
    ],
    passwordRules2: [
      (v) => !!v.trim() || "Password is required",
      (v) => (v.trim() && v.length >= 6) || "Minimum 6 characters",
      (v) => (v.trim() && v.length <= 20) || "Maximum 20 characters",
    ],
  }),

  methods: {
    ...mapActions(["resetPasswordProfile", "resetStatus"]),

    validate() {
      let valid = this.$refs.form.validate();

      if (valid) {
        this.resetPasswordProfile({
          oldPassword: this.oldPassword,
          password: this.password,
        });
      }
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.login-box {
  margin: 10% 30%;
}
.log-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
.error-text {
  color: red;
}
.v-avatar {
  height: 24px !important;
  width: 24px !important;
}
.v-text-field.v-text-field--enclosed {
  margin: 16px !important;
}
</style>

<template>
  <div class="row mt-3">
    <div class="col-md-1 col-1">
      <v-avatar color="primary" size="36">
        <span class="white--text">{{ _initials() }}</span>
      </v-avatar>
    </div>
    <div class="pl-5 col-md-11 col-11">
      <div class="d-flex">
        <div v-if="!isCreate" class="mr-3 align-self-center">
          <h4 class="rewname">{{ comment.createdBy }}</h4>
        </div>

        <div v-if="!isCreate" class="date align-self-center">
          {{ new Date(comment.createdOn).toDateString() }}
        </div>
        <div v-if="!isCreate && !readOnly" class="ml-auto">
          <div
            class="
              mr-3
              inline-block
              py-1
              text-textMedium text-sm
              cursor-pointer
              select-none
              hover:underline
            "
          >
            <div class="ms-auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-3 float-right"
                    @click="isFormOpen = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-3 float-right"
                    @click="dialogDelete = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isFormOpen" class="mb-4">
        <v-textarea
          v-model="newComment"
          autofocus
          rows="2"
          placeholder="Add a comment..."
        />
        <div class="flex pt-2 items-center">
          <v-btn
            color="primary"
            class="mr-2 text-capitalize"
            variant="primary"
            type="submit"
            @click="handleSubmit"
          >
            Save
          </v-btn>
          <v-btn
            color="border"
            variant="empty"
            class="text-capitalize"
            @click="handleCancel"
          >
            Cancel
          </v-btn>
        </div>
      </div>
      <div v-else>
        <p v-if="isCreate" @click="isFormOpen = true" class="inlineTa mb-6">
          Add a comment...
        </p>
        <p v-else v-text="comment.message" class="rattext pb-2"></p>
      </div>
      <v-divider></v-divider>
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title
            >Are you sure you want to delete this comment?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Comment" : "Edit Comment";
    },
  },
  created() {
    this.newComment = this.isCreate ? "" : this.comment.message;
    this.readOnly = this.comment.userId == this.userId ? false : true;
  },
  data: () => ({
    currentUser: "",
    isFormOpen: false,
    isCommentDeleteConfirmOpen: false,

    isLoggedIn: authService.isLoggedIn ? true : undefined,
    userId: authService.authData.id,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    organizationName: authService.authData.organization,
    userName:
      authService.authData.firstName + " " + authService.authData.lastName,
    valid: false,
    status: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: 0,
      productId: 0,
      rating: 0,
      message: "",
    },
  }),
  methods: {
    // parse(item) {
    //   return parseInt(item);
    // },

    // closeDialog() {
    //   this.dialog = false;
    //   this.editedIndex = -1;
    //   this.editedItem.rating = 0;
    //   this.$refs.form.reset();
    //   this.$refs.form.resetValidation();
    // },
    // deleteItem(item) {
    //   this.editedIndex = this.review.indexOf(item);
    //   this.dialogDelete = true;
    // },

    // editItem(item) {
    //   this.editedItem = Object.assign({}, item);
    //   this.editedItem.rating = Number(this.editedItem.rating);
    //   this.editedIndex = this.review.indexOf(item);
    //   this.dialog = true;
    // },
    // onUpdate() {
    //   let valid = this.$refs.form.validate();
    //   if (valid) {
    //     let model = {
    //       message: this.editedItem.message,
    //       rating: this.editedItem.rating,
    //     };
    //     if (this.editedIndex == -1) {
    //       this.create(model, this.productId);
    //     } else {
    //       let id = this.review[this.editedIndex].id;

    //       this.update(model, id);
    //     }
    //     this.closeDialog();
    //   }
    // },
    // deleteConfirm() {
    //   let id = this.review[this.editedIndex].id;
    //   this.delete({ commentId: id });
    //   this.dialogDelete = false;
    //   this.editedIndex = -1;
    // },
    // handleKeyDown() {
    //   this.handleSubmit();
    // },
    deleteConfirm() {
      // this.deleteComment({ commentId: this.comment.id });
      this.dialogDelete = false;
      this.$emit("commentDelete", {
        id: this.comment.id,
      });
    },

    handleSubmit() {
      this.isFormOpen = false;

      this.$emit("commentUpdate", {
        type: this.isCreate,
        newComment: this.newComment,
        id: this.comment.id,
      });
      if (this.isCreate) {
        this.newComment = "";
      }
    },
    handleCancel() {
      this.newComment = this.isCreate ? "" : this.comment.message;
      this.isFormOpen = false;
    },
    _initials() {
      var names = "";
      if (this.isCreate) {
        if (this.userName) {
          names = this.userName.split(" ");
        }
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      } else {
        if (this.comment.createdBy) {
          names = this.comment.createdBy.split(" ");
        }
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/colors.scss";
.inlineTa {
  color: rgb(137, 147, 164);
  border: 1px solid rgb(223, 225, 230);
  cursor: text;
  user-select: none;
  padding: 12px 16px;
  border-radius: 4px;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  color: #5f6463;
}
.rewname {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  // line-height: 16px !important;

  color: $secondary !important;
}
.rattext {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: $secondary !important;
}
</style>
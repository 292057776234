<template>
  <div>
    <v-card>
      <!-- <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div
                class="col-lg-4 col-sm-6 col-md-4"
                v-if="userType == 'admin'"
              >
                <v-select
                  :items="regOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  :clearable="true"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title> -->
      <v-card-title class="tabtitle">
        <!-- <v-spacer v-if="userType !== 'admin'"></v-spacer> -->
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          dense
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
        <v-spacer v-if="userType == 'admin'"></v-spacer>
        <div class="col-lg-4 col-sm-12 col-md-6" v-if="userType == 'admin'">
          <v-select
            :items="regOrganization"
            item-text="name"
            item-value="id"
            v-model="selectedOrg"
            label="Select Organization"
            :clearable="true"
            @change="onChange"
          ></v-select>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items-per-page="10"
        :items="invitedUser.records"
        :server-items-length="invitedUser.total"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toDateString() }}</td>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogInvite" max-width="500px">
      <v-card height="auto" class="text-center">
        <img v-if="logo" :src="logo" height="80" width="80" class="ma-4" />
        <img
          v-else
          class="ma-4"
          :src="require('@/assets/logoDA.svg')"
          height="80"
          width="80"
        />
        <v-card-text class="justify-center">
          <v-card-title>Are you sure you want to Re Invite?</v-card-title>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogInvite = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="inviteConfirm"
            >Resend Invitation
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import inviteService from "@/services/invitation.service.js";
import orgService from "@/services/organization.service.js";
import authService from "@/services/auth.service.js";

export default {
  created() {
    if (this.userType == "admin") {
      this.getOrganization();
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "Email Id",
          align: "start",
          value: "emailId",
          sortable: false,
        },
        {
          text: "Invitor Organization",
          value: "organization",
          sortable: false,
        },

        {
          text: "Invited By",
          value: "createdBy",
          sortable: false,
        },
        {
          text: "Invited on",
          value: "createdOn",
          sortable: false,
        },
      ];
    },
  },
  data: () => ({
    regOrganization: [],
    loading: false,
    userType: authService.authData.userType,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    invitedUser: {},
    filter: {
      searchKey: "",
    },
    status: "",
    editedIndex: -1,
    selectedOrg: "",
    editedItem: {
      id: 0,
      status: 1,
    },
    dialogDelete: false,
    dialogInvite: false,
    pageIndex: 0,
  }),
  methods: {
    async updatePagination(pagination) {
      this.pageIndex = pagination.page || 0;
      this.loading = true;
      let result = await inviteService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.invitedUser = result;
    },
    async search() {
      this.loading = true;
      let result = await inviteService.getAll({
        skip: 0,
        take: 10,
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.invitedUser = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.loading = true;
      let result = await inviteService.getAll({
        skip: 0,
        take: 10,
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.invitedUser = result;
    },

    resendInvite(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.userInvite.indexOf(item);
      this.dialogInvite = true;
    },
    orgFilter(value) {
      if (!this.selectedOrg) {
        return true;
      }

      return value === this.selectedOrg;
    },
    async onChange() {
      this.loading = true;
      let result = await inviteService.getAll({
        skip: 0,
        take: 10,
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.invitedUser = result;
    },
    inviteConfirm() {
      const id = this.userInvite[this.editedIndex].id;
      this.editedItem.id = id;

      this.reInvite({
        emailId: this.editedItem.emailId,
      });

      this.dialogInvite = false;
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";
.box-list {
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}
.active {
  border-color: #1a8ccf;
  border-width: 5px;
}
// /deep/.v-card__title {
//   justify-content: center;
// }
.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}
</style>
<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-12 col-md-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="botList.records"
        :items-per-page="10"
        :server-items-length="botList.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.imageURL`]="{ item }">
          <img :src="item.imageURL" width="50px" />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom v-if="item.inUse == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.inUse == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                class="mr-3"
                @click="deleteBotItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                class="mr-3"
                @click="childBots(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dialpad
              </v-icon>
            </template>
            <span>Child Bots</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog
        v-if="dialogChildEdit"
        v-model="dialogChildEdit"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card height="auto">
          <v-toolbar color="white">
            <v-btn icon @click="dialogChildEdit = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Agent</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text @click="dialogChildEdit = false"> Done </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <BotEdit :botId="childId"></BotEdit>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card height="auto" class="text-center">
          <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
          <v-card-text class="text-center">
            <v-card-title class="justify-center"
              >Are you sure you want to delete this Agent?</v-card-title
            >
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              class="text-capitalize grey lighten-2"
              @click="dialogDelete = false"
              >Cancel</v-btn
            >
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="deleteConfirm"
              >OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="500px"
      >
        <v-card height="auto">
          <v-card-title>
            <span class="text-h6">Edit Main Agent Image</span>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-file-input
                v-if="!editMain.imageURL"
                v-model="editMain.image"
                accept="image/*"
                required
                ref="fileupload"
                :rules="[
                  (v) => !!v || 'Image is required',
                  (v) =>
                    (v &&
                      !adminBotNames.find(
                        (t) =>
                          t.imageName.toLowerCase().trim() ==
                          v.name.toLowerCase().trim()
                      )) ||
                    'Image already exists',
                ]"
              >
                <template #label>
                  Image file <span class="red--text"><strong>* </strong></span>
                </template></v-file-input
              >
              <v-file-input
                v-if="editMain.imageURL"
                v-model="editMain.image"
                accept="image/*"
                label="Image"
                ref="fileupload"
                v-on:change="botChange"
                :rules="
                  editMain.imageURL && !botrule
                    ? []
                    : [
                        (v) => !!v || 'Image is required',
                        (v) =>
                          (v &&
                            !adminBotNames.find(
                              (t) =>
                                editMain.id != t.value &&
                                t.imageName.toLowerCase().trim() ==
                                  v.name.toLowerCase().trim()
                            )) ||
                          'Image already exists',
                      ]
                "
              >
                <template #label>
                  Image file <span class="red--text"><strong>* </strong></span>
                </template></v-file-input
              >
              <v-text-field
                v-model="editMain.name"
                label="Item Order"
                required
                :rules="[
                  (v) => !!v || 'Name is required',
                  (v) => (v && !!v.trim()) || 'Name cannot be blank',
                  (v) =>
                    (v &&
                      !adminBotNames.find(
                        (t) =>
                          editMain.id != t.value &&
                          t.name.toLowerCase().trim() == v.toLowerCase().trim()
                      )) ||
                    'Name already exists',
                ]"
              >
                <template #label>
                  Name <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
              <v-textarea
                v-model="editMain.description"
                required
                :rules="[
                  (v) => !!v || 'Description is required',
                  (v) => (v && !!v.trim()) || 'Description cannot be blank',
                ]"
              >
                <template #label>
                  Description <span class="red--text"><strong>* </strong></span>
                </template>
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize grey lighten-2"
                @click="closeDialog"
              >
                Close
              </v-btn>
              <v-btn
                dark
                color="black"
                class="text-capitalize"
                depressed
                @click="onUpdate"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusbot" type="error">
      {{ statusbot }}
    </v-alert>
  </div>
</template>

<script>
import botImagesService from "@/services/botImages.service.js";
import EventBus from "../plugins/eventBus";

import BotEdit from "../components/BotEdit.vue";
export default {
  components: {
    BotEdit,
  },
  created() {
    this.getBotImageNames();

    EventBus.on("bot_changes", () => {
      this.getBotImageNames();
    });
  },
  destroyed() {
    EventBus.off("bot_changes");
  },
  data: () => ({
    editMain: {
      image: null,
      name: "",
      description: "",
      imageURL: "",
      type: "",
      id: 0,
    },
    botrule: false,
    childId: 0,
    pageIndex: 0,
    pageSize: 5,
    dialogDelete: false,
    dialog: false,
    dialogEdit: false,
    dialogChildEdit: false,
    filter: {
      searchKey: "",
      sortBy: "",
    },
    loading: false,
    searchKey: "",
    editedIndex: -1,
    attachment: null,
    type: "",
    status: "",
    statusbot: "",
    imageUrl: null,
    name: "",
    description: "",
    valid: false,
    valid1: false,
    botList: {},
    botImages: [],
    botTypes: [],
    botImgTypes: [],
    editedItem: {},
    adminBotNames: [],
    typeList: [
      { name: "Crying", value: "cry" },
      { name: "Happy", value: "happy" },
      { name: "Busy", value: "busy" },
      { name: "Sad", value: "sad" },
    ],
    headers: [
      { text: "", sortable: false, value: "imageURL" },
      { text: "Agent Image Name", sortable: false, value: "name" },
      { text: "Description", value: "description", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Agent" : "Edit Agent";
    },
  },
  methods: {
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await botImagesService.getAllBots({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.botList = result;
    },
    async getBotImageNames() {
      let result = await botImagesService.getAdminBotImageNames();

      this.adminBotNames = result;
    },
    deleteBotItem(item) {
      this.editedIndex = this.botList.records.indexOf(item);
      this.dialogDelete = true;
    },
    botChange(e) {
      if (e) {
        this.botrule = true;
      } else {
        this.botrule = false;
        this.editMain.image = undefined;
      }
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let formData = new FormData();
        if (this.editMain.image !== undefined) {
          formData.append("ImageFile", this.editMain.image);
        } else {
          formData.append("ImageUrl", this.editMain.imageURL);
        }
        formData.append("Name", this.editMain.name);
        formData.append("Description", this.editMain.description);
        formData.append("Type", "main");

        this.updatemain(formData, this.editMain.id);
        this.closeDialog();
      }
    },
    async updatemain(item, id) {
      let result = await botImagesService.update(item, id);

      if (result.status == 200) {
        this.status = "Agent updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.botrule = false;
        this.updatePagination({ page: this.pageIndex });
        EventBus.emit("bot_changes", "Updated Successfully");
      }
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      let result = await botImagesService.getAllBots({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.botList = result;
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await botImagesService.getAllBots({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.botList = result;
    },
    editItem(item) {
      this.dialog = true;
      this.editMain = Object.assign({}, item);
    },
    deleteConfirm() {
      const id = this.botList.records[this.editedIndex].id;
      this.deleteBot({ botImageId: id });
      this.dialogDelete = false;
    },
    async deleteBot(item) {
      let result = await botImagesService.delete(item);
      if (result.status == 200) {
        this.status = "Agent deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        EventBus.emit("bot_changes", "Updated Successfully");
        this.updatePagination({ page: this.pageIndex });
      }
    },
    closeDialog() {
      this.dialog = false;

      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    childBots(item) {
      this.childId = item.id;
      this.dialogChildEdit = true;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>
import { mainAxios } from '../axios-instance';
export default {



  async getAllLeads(input) {
    let result = await mainAxios.get(`/ClientProcess`, {
      params: input,
    });
    return result.data;

  },

  async getLeadHistory(input) {
    let result = await mainAxios.get(`/ClientProcess/logs`, { params: input });

    return result.data;
  },

  async getAllNotes(input) {
    let result = await mainAxios.get(`/ClientProcess/notes`, { params: input });

    return result.data;
  },
  async create(note, id) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let result = await mainAxios.post(`/ClientProcess/notes?clientProcessId=${id}`, note, config
    );
    return result;
  },
  async update(input) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let result = await mainAxios.put(`/ClientProcess/notes?noteId=${input.id}`, input.message, config);
    return result;
  },
  async delete(input) {
    let result = await mainAxios.delete(`/ClientProcess/notes?noteId=${input.id}`);
    return result;
  },
  async changeStatus(input) {
    let result = await mainAxios.put(`/ClientProcess/status?clientProcessId=${input.clientProcessId}&status=${input.status}`);
    return result;
  },
  async updateReleaseName(input, id) {
    let result = await mainAxios.put(`/ClientProcess?clientProcessId=${id}`, input);
    return result;
  },

  async getAllProcess(input) {
    let result
    if (input) {
      result = await mainAxios.get(`/ClientProcess/implemented-process?orgnaizationId=${input.orgnaizationId}`);
    } else {

      result = await mainAxios.get(`/ClientProcess/implemented-process`);
    }
    return result.data;

  },

  async createSub(input, id) {

    let result = await mainAxios.post(`/ClientProcess/${id}/sub-process`, input
    );
    return result;
  },
  async updateSub(input, id) {
    let result = await mainAxios.put(`/ClientProcess/sub-process/${id}`, input);
    return result;
  },
  async deleteSub(id) {
    let result = await mainAxios.delete(`/ClientProcess/sub-process/${id}`);
    return result;
  },
}
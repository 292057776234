<template>
  <div class="container mb-12">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      class="mb-3 tabborder"
    >
      <v-tab v-for="item in tabItems" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" height="auto">
      <v-tab-item key="My Bots" height="auto">
        <MyBots></MyBots>
      </v-tab-item>
      <v-tab-item
        key="Select New Agent"
        height="auto"
        v-if="userType == 'supuser'"
      >
        <h4
          class="text-center mb-4"
          v-if="botClientList.records && myBotData.length"
        >
          Click on the circle below to get a shiny new bot Image of your own
        </h4>
        <h4 class="text-center mb-4" v-else>
          There is no license assigned to you, Please contact DA admin
        </h4>
        <v-row v-if="botClientList.records">
          <v-col
            v-for="item in myBotData"
            :key="item.id"
            cols="12"
            md="4"
            class="text-center"
          >
            <div slot="activator">
              <v-avatar
                size="150px"
                v-ripple
                v-if="!item.botImageURL"
                class="grey lighten-3 mb-3"
                @[event]="generate(item)"
              >
                <span>Click and Reveal</span>
              </v-avatar>
              <v-avatar size="150px" v-ripple v-else class="mb-3">
                <img
                  :src="item.botImageURL"
                  alt="avatar"
                  @click="nameMyBot(item)"
                />
              </v-avatar>
              <p>
                <small v-if="item.botImageURL" class="red--text"
                  >Name your Agent</small
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title>Are you sure you want to delete this Agent?</v-card-title>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
          
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK
            <v-avatar right size="15" class="ml-4 float-right">
              <img src="../assets/Arrow.png" /> </v-avatar
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="botImageDialog" persistent max-width="600px">
      <v-card height="auto" class="text-center">
        <!-- <v-card-title>
          <span class="text-h6">Enter the Name for your Agent</span>
        </v-card-title> -->
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass">
              Enter the Name for your Agent
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closebotImageDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-avatar v-if="botImageData.botImageURL" size="150px" class="mb-3">
          <img :src="botImageData.botImageURL" alt="avatar" />
        </v-avatar>
        <v-form ref="form2" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              label="Enter the Name for your Agent"
              v-model="botName"
              required
              maxlength="20"
              :rules="[
                (v) => !!v || 'Name for Bots is required',
                (v) => (v && !!v.trim()) || 'Name cannot be blank',
                (v) =>
                  (v &&
                    !clientBotNames.find(
                      (t) =>
                        t.name.toLowerCase().trim() == v.toLowerCase().trim()
                    )) ||
                  'Name already exists',
              ]"
            >
              <template #label>
                Enter the Name for your Agent
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize mr-1 grey lighten-2"
              @click="closebotImageDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              depressed
              @click="uploadMyBot"
              class="text-capitalize"
              color="buttonColor"
            >
              Click here it make this yours
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusbot" type="error">
      {{ statusbot }}
    </v-alert>
  </div>
</template>
<script >
import EventBus from "../plugins/eventBus";
import botImagesService from "@/services/botImages.service.js";
import MyBots from "../components/MyBots.vue";
import authService from "@/services/auth.service.js";
import clientBotService from "@/services/clientBot.service.js";
export default {
  components: {
    MyBots,
  },
  created() {
    let data = {
      Skip: 0,
      Take: 10,
      status: "Pending",
    };
    this.getClientBot(data);
    this.getClientBotNames();
    this.getBotImageNames();
    if (this.userType == "supuser") {
      this.tabItems = ["My Bots", "Select New Agent"];
    } else {
      this.tabItems = ["My Bots"];
    }

    EventBus.on("select_changes", (x) => {
      this.status = x;
      this.getBotImages();
      let data = {
        Skip: 0,
        Take: 10,
        status: "Pending",
      };
      this.getClientBot(data);
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("select_changes1", (x) => {
      this.statusbot = x;
      setTimeout(() => {
        this.statusbot = "";
      }, 2000);
    });
    EventBus.on("select_image", (x) => {
      this.status = x;
      this.nameMyBot(this.selectedLicense);
      this.getBotImages();
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("select_image1", (x) => {
      this.status = x;
      this.clearMyBot(this.selectedLicense);
      this.getBotImages();
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
  },
  data: () => ({
    userType: authService.authData.userType,
    isShow: 1,
    tab: null,

    tabItems: [],
    pageIndex: 0,
    pageSize: 5,
    dialogDelete: false,
    botImageDialog: false,
    dialog: false,
    dialogEdit: false,
    avatar: null,
    saving: false,
    saved: false,
    editedIndex: -1,
    attachment: null,
    type: "",
    imageUrl: null,
    name: "",
    description: "",
    valid: false,
    valid1: false,
    myBotlist: [],
    myNewBotlist: [],
    selectedImage: null,
    selectedLicense: null,
    status: "",
    statusbot: "",
    botImageData: {},
    botName: "",
    serverName: "",
    botClientList: {},
    editedItem: {
      id: 0,
      status: 1,
    },
    clientBotNames: [],
    botImage: [],
  }),

  computed: {
    isMorePage() {
      return (this.pageIndex + 1) * this.pageSize < this.orderGrouplist.count;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Agent" : "Edit Agent";
    },
    event() {
      return this.loading ? null : "click";
    },
    myBotData() {
      var itemsCopy = [];
      const items = [];
      itemsCopy = this.botClientList.records.slice();
      for (var i = 0; i < itemsCopy.length; i++) {
        if (itemsCopy[i].status == "Pending" || itemsCopy[i].name == "") {
          items.push(itemsCopy[i]);
        }
      }
      return items;
    },
  },
  methods: {
    uploadImage() {
      this.saving = true;
      setTimeout(() => this.savedAvatar(), 1000);
    },
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },

    generate(item) {
      this.selectedLicense = item;
      if (this.botImage.length > 0) {
        this.selectedImage = this.randomItem(this.botImage);
        const index = this.botClientList.records.indexOf(item);
        this.botClientList.records[index].botImageURL = this.selectedImage.url;
        this.selectBotImage({
          clientProcessId: item.id,
          botPictureId: this.selectedImage.value,
        });
      } else {
        this.statusbot = "sorry there is no Free Bots Images";

        setTimeout(() => {
          this.statusbot = "";
        }, 2000);
      }
    },
    async selectBotImage(item) {
      let result = await clientBotService.selectBotImage(item);

      if (result.status == 200) {
        this.status = "Agent selected successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getBotImageNames();
        this.nameMyBot(this.selectedLicense);
      }
    },
    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);
      this.loading = false;
      this.botClientList = result;
    },
    myBots() {
      const items = [];
      for (let i = 0; i < this.botClientList.records.length; i++) {
        if (
          this.botClientList.records[i].status == 1 &&
          this.botClientList.records[i].name !== ""
        ) {
          items.push(this.botClientList.records[i]);
        }
      }
      this.myBotlist = JSON.parse(JSON.stringify(items));
      return items;
    },

    savedAvatar() {
      this.saving = false;
      this.dialog = true;
      this.editedIndex = -1;
    },
    nameMyBot(item) {
      const index = this.botClientList.records.indexOf(item);
      this.botImageData = this.botClientList.records[index];
      this.botImageDialog = true;
    },
    clearMyBot(item) {
      const index = this.botClientList.records.indexOf(item);
      this.botClientList.records[index].botImageURL = null;
    },

    uploadMyBot() {
      let valid = this.$refs.form2.validate();
      if (valid) {
        this.selectBot({
          clientProcessId: this.botImageData.id,
          name: this.botName,
        });
        this.selectBotStatus({
          clientBotId: this.botImageData.id,
          status: "Inprogress",
        });
      }
    },
    async selectBot(item) {
      let result = await clientBotService.selectBot(item);

      if (result.status == 200) {
        this.status = "Agent named successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getClientBotNames();
        this.closeDialogBot();
      }
    },
    async selectBotStatus(item) {
      let result = await clientBotService.selectBotStatus(item);

      if (result.status == 200) {
        this.status = "Agent selected successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        let data = {
          Skip: 0,
          Take: 10,
          status: "Pending",
        };
        this.getClientBot(data);
      }
    },
    closeDialog() {
      this.dialog = false;

      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closeDialogBot() {
      this.botImageDialog = false;

      this.selectedImage = null;
      this.selectedLicense = null;
      this.$refs.form2.reset();
      this.$refs.form2.resetValidation();
    },
    createImage(file) {
      const file1 = file;
      if (file1) {
        this.imageUrl = URL.createObjectURL(file1);
        URL.revokeObjectURL(file1);
      } else {
        this.imageUrl = null;
      }
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      this.editedIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
      this.name = "";
      this.description = "";
      this.botImages = [];
      this.botTypes = [];
      this.botImgTypes = [];
      this.avatar = null;
      this.saved = true;
    },
    closebotImageDialog() {
      this.botImageDialog = false;
      this.$refs.form2.reset();
      this.$refs.form2.resetValidation();
    },
    resetpass(id) {
      if (id == 1) {
        this.isreset = true;
      } else {
        this.isreset = false;
      }
    },
    async getClientBotNames() {
      let result = await clientBotService.getClientBotNames();

      this.clientBotNames = result;
    },
    async getBotImageNames() {
      let result = await botImagesService.getBotImageNames();

      this.botImage = result;
    },
  },
};
</script>
<style scoped lang="scss">
.margins {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom-black {
  background-color: black !important;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
}
.custom-white {
  background-color: white !important;
  color: black !important;
  border-radius: 10px;
  cursor: pointer;
}
.box {
  margin-top: 10%;
}
.mt-30 {
  margin-top: 60px;
}
.title {
  padding-top: 30px;
  text-align: left;
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 10px !important;
  margin-bottom: 10px;
  opacity: 1;
}
.header {
  border-bottom: 1px solid #e4e7ea;
  width: 100%;
  float: left;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #ffffff;
  background: transparent linear-gradient(265deg, #090e18 0%, #464c58 100%) 0%
    0% no-repeat padding-box;
  padding: 5px;
  .w-50 {
    width: 50%;
    float: left;
  }
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -4px;
}
.v-list {
  padding: 0px !important;
}
.border {
  border: 1px dashed #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: center;
}
.mid-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
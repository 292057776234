<template>
  <div class="container-fluid" style="overflow: hidden">
    <div id="cardBackground">
      <div class="row cardBackgroundRow">
        <div class="col-lg-4 col-1"></div>
        <div class="col-lg-4 col-10 mt-16 mb-16 log-box">
          <div class="card my-3 mx-5">
            <div class="card-body p-4 p-sm-5">
              <!-- <img src="../assets/Pattern.png" class="patternImg" /> -->
              <h2 class="mt-5 mb-2 mainLogin">Welcome to Marketplace! 👋🏻</h2>
              <small class="sec-login">Please sign-in to your account</small>
              <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
                <label class="title-login"
                  >Email Id<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  ></label
                >
                <v-text-field
                  v-model="emailId"
                  :rules="rules('EmailId', { required: true, email: true })"
                  label="Email Id"
                  validate-on-blur
                  v-on:keyup.enter="validate"
                  class="asterisk"
                  solo
                >
                </v-text-field>
                <label class="title-login"
                  >Password<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  ></label
                >
                <v-text-field
                  :type="show1 ? 'text' : 'password'"
                  v-model="password"
                  :rules="rules('Password', { required: true })"
                  label="Password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  v-on:keyup.enter="validate"
                  validate-on-blur
                  class="asterisk"
                  solo
                >
                </v-text-field>
                <div class="row">
                  <div
                    class="col-lg-8 col-md-6 col-sm-2 rememberMeChkCol"
                  ></div>
                  <div class="col-lg-4 col-md-6 col-sm-10 forgotPasswordCol">
                    <router-link
                      to="/forgot-password"
                      class="ForgotPasswordLink float-right"
                    >
                      Forgot Password?</router-link
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-12 loginBtnCol">
                    <v-btn
                      dark
                      x-large
                      color="buttonColor"
                      class="btn-block"
                      @click="validate"
                    >
                      Sign In
                    </v-btn>
                  </div>
                </div>

                <div class="mt-8 mb-4 text-center signUp">
                  Don't have an account?<router-link
                    to="/register"
                    class="SignUpLink"
                    >Sign Up</router-link
                  >
                </div>
                <div v-if="authStatus" class="text-center mt-3 error-text">
                  {{ authStatusMsg }}
                </div>
              </v-form>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";
import router from "../router";
import EventBus from "../plugins/eventBus";
import validations from "@/utils/validation";

export default {
  created() {
    this.checklogin();
  },
  data: () => ({
    isLoggedIn: authService.isLoggedIn,
    authStatus: "",
    authStatusMsg: "",
    show1: false,
    valid: false,
    emailId: "",
    password: "",
  }),
  methods: {
    rules(label, rules) {
      return validations(label, rules);
    },
    checklogin() {
      if (this.isLoggedIn) {
        this.$router.push("/home");
      }
    },
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.login({ emailId: this.emailId, password: this.password });
      }
    },
    async login(item) {
      let result = await authService.login(item);

      if (result.status) {
        this.status = "Welcome to AlignXcel MarketPlace";
        router.push("/");
        EventBus.emit("login_changes", "Logged In Successfully");
      } else {
        this.authStatus = "error";
        this.authStatusMsg = result.msg;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
@import "./src/colors.scss";
.login-box {
  margin: 10% 30%;
}
.error-text {
  color: red;
}
.log-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
small {
  font-size: 16px;
}
.v-btn {
  background: $buttonColor;
  height: 50px;
  text-transform: none;
  border-radius: 4px !important;
}
.signUp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $blackText;
}
.SignUpLink {
  color: $primary;
  padding-left: 4px;
}
.ForgotPasswordLink {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $primary;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.patternImg {
  margin-top: 10px;
}
.v-avatar {
  height: 24px !important;
  width: 24px !important;
  justify-content: right;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.error-text {
  color: red;
}
@media (min-width: 239px) and (max-width: 320px) {
  // .cardBackground {
  //   width: 320px;
  // }
  /deep/.v-btn:not(.v-btn--round).v-size--x-large {
    padding: 0px;
    font-size: 12px;
  }
  /deep/.v-avatar {
    height: 15px !important;
    width: 15px !important;
    justify-content: left;
  }
  .rememberMeChkCol {
    height: 0px;
  }
}
#passwordLBL,
#emailLBL {
  font-weight: bolder;
}
// #cardBackground {
//   background: transparent linear-gradient(259deg, #090e18 0%, #464c58 100%) 0%
//     0% no-repeat padding-box;
//   background-size: 100% 50%;
//   background-position: bottom;
// }
.Footer {
  background: #f7f8f9 0% 0% no-repeat padding-box;
  opacity: 1;
  left: 0;
  bottom: 0;
  margin-right: 0px;
  width: 100%;
  position: relative;
}
@media (min-width: 280px) and (max-width: 319px) {
  .loginBtnCol {
    width: 100%;
  }
  /deep/.v-btn:not(.v-btn--round).v-size--x-large {
    padding: 0px;
    font-size: 14px;
  }
  .cardBackgroundRow {
    margin: 0px;
  }
}
</style>
<template>
  <div class="container">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
      class="mb-3"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        @click="getStatus(item.name, index)"
        :href="'#tab-' + item.name"
        :title="item.title"
      >
        {{ item.name }}
      </v-tab>

      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center mr-4" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="(item, index) in more"
            :key="index"
            @click="addItem(item), getStatus(item.name, index)"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-tabs-items v-model="currentItem" height="auto">
      <v-tab-item key="New" value="tab-New" height="auto">
        <admin-request-by-status-new
          :statusNo="stuatusno"
          :index="index"
          :statusindex="0"
        ></admin-request-by-status-new>
      </v-tab-item>

      <v-tab-item key="In Progress" value="tab-In Progress" height="auto">
        <admin-request-by-status-in-progress
          :statusNo="stuatusno"
          :index="index"
          :statusindex="1"
        ></admin-request-by-status-in-progress>
      </v-tab-item>

      <v-tab-item key="Approved" value="tab-Approved" height="auto">
        <admin-request-by-status-approved
          :statusNo="stuatusno"
          :index="index"
          :statusindex="2"
        ></admin-request-by-status-approved>
      </v-tab-item>

      <v-tab-item key="Cancelled" value="tab-Cancelled" height="auto">
        <admin-request-by-status-cancelled
          :statusNo="stuatusno"
          :index="index"
          :statusindex="3"
        ></admin-request-by-status-cancelled>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AdminRequestByStatusNew from "../components/AdminRequestByStatusNew.vue";
import AdminRequestByStatusInProgress from "../components/AdminRequestByStatusInProgress.vue";
import AdminRequestByStatusApproved from "../components/AdminRequestByStatusApproved.vue";
import AdminRequestByStatusCancelled from "../components/AdminRequestByStatusCancelled.vue";

export default {
  components: {
    AdminRequestByStatusNew,
    AdminRequestByStatusInProgress,
    AdminRequestByStatusApproved,
    AdminRequestByStatusCancelled,
  },
  computed: {},
  data: () => ({
    tab: null,
    index: 0,
    stuatusno: "",
    currentItem: "tab-New",
    tabItems: [
      {
        name: "New",
        title: "Processes Requested by customers needs an Action plan",
      },

      {
        name: "In Progress",
        title: "Processes that are marked as in Progress by DA Admin",
      },
      {
        name: "Approved",
        title: "Processes that are Approved for the clients",
      },
      {
        name: "Cancelled",
        title: "Processes that are marked as cancelled by DA Admin",
      },
    ],
    tabs: [],
    more: [],
  }),
  created() {},
  methods: {
    getStatus(item, i) {
      if (item == "New") {
        this.stuatusno = "Pending";
      } else if (item == "Cancelled") {
        this.stuatusno = "Cancelled";
      } else if (item == "Approved") {
        this.stuatusno = "Approved";
      } else if (item == "In Progress") {
        this.stuatusno = "Inprogress";
      }
      this.index = i;
    },
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 1);
      this.more = temp.splice(0);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
</style>




import { mainAxios } from '../axios-instance';
export default {

  async getAll(input) {
    let result = await mainAxios.get(`/Roles`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Roles`, input);
    return result;
  },
  async update(input) {
    let result = await mainAxios.put(`/Roles`, input, { params: { roleId: input.roleId } });
    return result;
  },

  async delete(input) {
    let result = await mainAxios.delete(`/Roles?roleId=${input.roleId}`);
    return result;
  },

  async getAllRole() {
    let result = await mainAxios.get(`/Roles/all`);
    return result.data;

  },
  async updateRoles(input, id) {
    let result = await mainAxios.post(`/Roles/mapping/client`, input, { params: { processId: id } });
    return result;
  },
  async mapRole(input, id) {
    let result = await mainAxios.post(`/Roles/mapping/process/?processId=${id}`, input);
    return result;
  },
  async unmapRole(mappingId) {
    let result = await mainAxios.delete(`/Roles/mapping/process?mappingId=${mappingId}`);
    return result;
  },
  async mapRoleClient(input, id) {
    let result = await mainAxios.post(`/Roles/mapping/client/?processId=${id}`, input);
    return result;
  },
  async unmapRoleClient(mappingId) {
    let result = await mainAxios.delete(`/Roles/mapping/client?mappingId=${mappingId}`);
    return result;
  },
}
import { render, staticRenderFns } from "./ActiveFAQMaster.vue?vue&type=template&id=fe5b648c&scoped=true&"
import script from "./ActiveFAQMaster.vue?vue&type=script&lang=js&"
export * from "./ActiveFAQMaster.vue?vue&type=script&lang=js&"
import style0 from "./ActiveFAQMaster.vue?vue&type=style&index=0&id=fe5b648c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe5b648c",
  null
  
)

export default component.exports
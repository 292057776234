<template>
  <div class="container mt-5 mb-12">
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <div v-if="orderGrouplist" class="mb-6">
      <div
        v-if="orderGrouplist.length == 0 && loading == false"
        class="text-center justify-center emptyCart"
      >
        <!-- <img v-if="logo" :src="logo" height="80" width="80" class="ma-4" />
        <img
          v-else
          class="ma-4"
          :src="require('@/assets/logoDA.svg')"
          height="80"
          width="80"
        /> -->
        <img
          :src="require('@/assets/emptycart.svg')"
          height="180"
          width="180"
        />
        <h2 class="mt-5 carttext">There are no Workflows requested</h2>
        <!-- <h2 class="mt-5">There are no Processes requested</h2> -->
        <v-btn
          color="primary"
          class="white--text mt-5 text-capitalize"
          large
          depressed
          to="/processes"
        >
          Click here to find the Workflows that we have
          <!-- <v-avatar right size="18" class="ml-2 text-right">
          <img src="../assets/Arrow.png" />
        </v-avatar> -->
        </v-btn>
        <!-- <v-btn
          color="black"
          class="white--text mt-5 text-left text-capitalize"
          large
          depressed
          to="/processes"
        >
          Click here to find the processes that we have
          <v-avatar right size="18" class="ml-2 text-right">
            <img src="../assets/Arrow.png" />
          </v-avatar>
        </v-btn> -->
      </div>

      <div v-else>
        <v-card outlined>
          <v-card-title class="">
            <!-- <v-toolbar flat> -->
            <div class="mt-2">
              <p class="cartitem">Requested workflows by you</p>
              <p class="allinfo">All information related to workflows</p>
            </div>
            <!-- </v-toolbar> -->

            <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-list
            v-for="order in orderGrouplistTrancated"
            :key="order.id"
            class="list-box ma-4"
          >
            <div class="header d-flex">
              <div
                v-if="order.status == 'Implemented' || order.status == 'UAT'"
                class="text-left px-2 py-2 align-self-center"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="processIssueOrEnhance(order)"
                      class="text-none btnval"
                      v-bind="attrs"
                      v-on="on"
                      color="secondary"
                    >
                      <v-icon size="12" class="mr-2">
                        mdi-alert-circle-outline </v-icon
                      >Raise a Ticket
                    </v-btn>
                  </template>
                  <span>Report Issue or Enhancement</span>
                </v-tooltip>
              </div>
              <div class="ml-auto text-right px-2 align-self-center">
                <!-- <div class="d-flex"> -->
                <span class="ordertext"
                  >Order No <span class="ordertextb">{{ order.groupId }}</span>
                </span>
                <!-- </div> -->
              </div>
            </div>

            <v-list-item
              class="text-left"
              link
              @click="goTodetail(order.processId)"
            >
              <v-list-item-avatar size="70" class="rounded-lg">
                <v-img
                  :src="order.imageURL"
                  contain
                  max-height="52"
                  max-width="52"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="cartitem"
                  >{{ order.name }} <br class="d-md-none" />
                  <v-chip
                    v-if="order.status != ''"
                    label
                    small
                    color="#E6F2F1"
                    text-color="#00796B"
                    dark
                    class="ml-2"
                  >
                    {{ checkstate(order.status) }}
                  </v-chip>
                  <!-- <span class="gree-icon" v-if="item.categoryName != ''">{{
                  item.categoryName
                }}</span> -->
                </v-list-item-title>

                <v-list-item-subtitle class="cartdesc">{{
                  truncateText(order.description)
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-list-item-content class="text-left">
              <v-list-item-title class="font-weight-black text-left"
                >{{ order.name }} <br class="d-md-none" /><span
                  class="gree-icon ml-md-2"
                  >{{ checkstate(order.status) }}</span
                ></v-list-item-title
              >
              <v-list-item-subtitle>{{
                order.description
              }}</v-list-item-subtitle>
            </v-list-item-content> -->
              <v-list-item-action>
                <h5 class="dateval">
                  {{ new Date(order.createdOn).toDateString() }}
                </h5>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </div>
    <!-- <div class="row" v-if="loading == false && isMorePage">
      <div class="col-12 text-center">
        <v-btn
          v-if="!allLoaded"
          color="primary"
          @click="showMore"
          depressed
          dark
        >
          Load More</v-btn
        >
      </div>
    </div> -->

    <div class="container text-center" v-if="loading == false && isMorePage">
      <div class="seperator">
        <h5>
          <v-btn
            v-if="!allLoaded"
            class="black--text text-left text-capitalize"
            large
            depressed
            outlined
            @click="showMore"
          >
            Load More
          </v-btn>
        </h5>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <!-- <v-card-title> Report Issue or Request Enhancement </v-card-title> -->
        <v-card-title class="justify-center">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-center">
              Report Issue or Request Enhancement
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="editedItem.name"
              required
              :rules="[
                (v) => !!v || 'Summary is required',
                (v) => (v && !!v.trim()) || 'Summary cannot be blank',
              ]"
            >
              <template #label>
                Summary <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-select
              :items="type"
              item-text="name"
              item-value="value"
              v-model="editedItem.type"
              required
              :rules="[(v) => !!v || 'Type is required']"
              ><template #label>
                Type <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="border"
            class="text-capitalize"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            depressed
            @click="onUpdate"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert v-if="statuss" type="success">
      {{ statuss }}
    </v-alert>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";
import orderService from "@/services/order.service.js";
import ticketService from "@/services/ticket.service.js";
export default {
  computed: {
    isMorePage() {
      return this.pageSize < this.orderGrouplist.length;
    },

    orderGrouplistTrancated() {
      return this.orderGrouplist.slice(0, this.pageSize);
    },
  },
  created() {
    this.loadProduct();
  },
  data: () => ({
    allLoaded: false,
    pageIndex: 0,
    pageSize: 10,
    userType: authService.authData.userType,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    orderGrouplist: [],
    loading: false,
    valid: false,
    dialog: false,
    statuss: false,
    editedItem: {
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    editedProcess: {},
    defaultItem: {
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    type: [
      { name: "Issue", value: "Issue" },
      { name: "Enhancement", value: "Enhancement" },
    ],
  }),
  methods: {
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    processIssueOrEnhance(item) {
      this.editedItem.processId = item.id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.create({
          clientProcessId: this.editedItem.processId,
          name: this.editedItem.name,
          description: this.editedItem.description,
          type: this.editedItem.type,
        });

        this.closeDialog();
      }
    },

    async create(item) {
      let result = await ticketService.create(item);

      if (result.status == 200) {
        this.statuss = "Request sent successfully";
        setTimeout(() => {
          this.statuss = "";
        }, 2000);
      }
    },
    onLoadMore() {
      this.pageIndex = this.pageIndex + 1;
      this.loadProduct(this.pageIndex);
    },
    checkstate(i) {
      if (i == "Cancelled") {
        return "Cancelled";
      } else if (i == "Pending") {
        return "New";
      } else if (i == "Implemented") {
        return "Implemented";
      } else if (i == "UAT") {
        return "UAT";
      } else if (i == "Confirmed") {
        return "Confirmed";
      } else if (i == "InProgress") {
        return "In Progress";
      }
    },
    loadProduct() {
      this.getAll();
    },

    showMore() {
      this.pageSize += 10;

      if (this.pageSize >= this.orderGrouplist.length) {
        this.pageSize = this.orderGrouplist.length;
        this.allLoaded = true;
      }
    },
    goTodetail(prodId) {
      this.$router.push({ name: "ProductDetails", params: { id: prodId } });
    },
    async getAll() {
      this.loading = true;
      let result = await orderService.getAllOrders();
      this.loading = false;
      this.orderGrouplist = result;
    },
  },
};
</script>
<style scoped lang="scss">
.margins {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom-black {
  background-color: black !important;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
}
.custom-white {
  background-color: white !important;
  color: black !important;
  border-radius: 10px;
  cursor: pointer;
}
.box {
  margin-top: 10%;
}
.mt-30 {
  margin-top: 60px;
}
.title {
  padding-top: 30px;
  text-align: left;
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 10px !important;
  margin-bottom: 10px;
  opacity: 1;
}
.header {
  // border-bottom: 1px solid #e4e7ea;
  width: 100%;
  float: left;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background: #fafafa 0% 0% no-repeat padding-box;
  padding: 5px;
  // padding: 12px 16px;
  gap: 10px;
  height: 46px;
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -2px;
}

.v-list-item .v-list-item__title {
  line-height: 2;
}
/deep/.v-sheet.v-card,
.v-btn {
  border-radius: 8px;
}
.sideTabs {
  border-radius: 8px;
}
/deep/ .v-list-item__content {
  padding: 0px;
}
/deep/ td {
  padding: 10px;
}
.emptyCart {
  padding: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.v-list {
  padding: 0 !important;
}
.cartitem {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
.cartdesc {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #5f6463 !important;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.dateval {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  color: #5f6463;
}
.btnval {
  padding: 4px 9px 4px 6px !important;
  gap: 3px !important;
  height: 22px !important;

  background: #17181d;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  /* identical to box height */

  /* Text / White */

  color: #ffffff !important;
}
.ordertext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.ordertextb {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.seperator h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator h5::before,
.seperator h5::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #e3e4e8;
}

.seperator h5 .btnmargin {
  padding: 0 2em;
}
.allinfo {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #45464a;
}
/deep/.v-toolbar__content {
  padding: 0px;
}
</style>
<template>
  <v-select
    :items="pickerYear"
    :label="label"
    v-model="year"
    outlined
    dense
    :class="className"
    @change="handleChange()"
    :clearable="true"
  ></v-select>
</template>

<script>
export default {
  props: {
    label: String,
    className: String,
    value: Number,
  },

  created() {
    this.year = this.value || null;
  },
  computed: {
    pickerYear() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2017 },
        (value, index) => 2018 + index + 2
      );
    },
  },

  data() {
    return {
      year: "",
    };
  },
  methods: {
    handleChange() {
      this.$emit("input", this.year);
    },
  },
};
</script>

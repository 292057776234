<template>
  <div class="text-center">
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <div v-if="allRequest.records">
      <div
        v-if="allRequest.records.length == 0 && loading == false"
        class="text-center justify-center emptyCart"
      >
        <!-- <img v-if="logo" :src="logo" height="50" width="50" />
              <img
                v-else
                :src="require('@/assets/logoDA.svg')"
                height="50"
                width="50"
              /> -->
        <img
          :src="require('@/assets/emptycart.svg')"
          height="180"
          width="180"
        />
        <h2 class="mt-5 carttext">You have not Submitted any Requests.</h2>
        <!-- <h4 class="mt-5">You have not Submitted any Requests.</h4> -->
      </div>
      <div v-else>
        <v-card outlined>
          <v-card-title class="">
            <!-- <v-toolbar flat> -->
            <div class="mt-2">
              <p class="cartitem">Submitted requests and status</p>
              <p class="allinfo">All information related to new workflows</p>
            </div>
            <!-- </v-toolbar> -->

            <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-list
            v-for="item in allRequest.records"
            :key="item.id"
            class="list-box my-4 mx-4"
          >
            <div class="header d-flex">
              <div class="align-self-center px-2">
                <!-- Requested by:
                    <b>{{ item.createdBy }}</b> -->
                <v-icon small class="mr-1" color="secondary">
                  mdi-account
                </v-icon>
                <span class="ordertext"
                  >Requested by :
                  <span class="ordertextb">{{ item.createdBy }}</span>
                </span>
              </div>
            </div>

            <v-list-item>
              <v-list-item-content class="text-left">
                <v-list-item-title class="cartitem"
                  >{{ item.processName }} <br class="d-md-none" />
                  <!-- <span
                  class="gree-icon ml-md-2"
                  >{{ newStatus(item.status) }}</span
                > -->
                  <v-chip
                    v-if="item.status != ''"
                    label
                    small
                    color="#E6F2F1"
                    text-color="#00796B"
                    dark
                    class="ml-2"
                  >
                    {{ newStatus(item.status) }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle class="cartdesc">{{
                  truncateText(item.description)
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <h5 class="dateval">
                  at {{ new Date(item.createdOn).toDateString() }}
                </h5>

                <template>
                  <div class="row mt-2">
                    <v-tooltip
                      bottom
                      v-if="
                        item.status != 'Approved' && item.status != 'Cancelled'
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-3"
                          @click="editItem(item, 0)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-3"
                          @click="editItem(item, 1)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-view-sequential-outline
                        </v-icon>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12 text-center">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-btn
          v-if="isMorePage"
          color="primary"
          @click="onLoadMore"
          depressed
          dark
        >
          Load More</v-btn
        >
      </div>
    </div> -->

    <div class="container text-center" v-if="loading == false && isMorePage">
      <div class="seperator">
        <h5>
          <v-btn
            v-if="isMorePage"
            class="black--text text-left text-capitalize"
            large
            depressed
            outlined
            @click="onLoadMore"
          >
            Load More
          </v-btn>
        </h5>
      </div>
    </div>

    <!---Add or Update the process -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto" v-if="!viewprocess">
        <!-- <v-card-title>
          <span class="text-h6">Edit Process</span>
        </v-card-title> -->
        <v-card-title class="justify-center">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-center">
              Edit Process
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="editedItem.processName"
              :rules="nameRules"
              label="Name of the Process"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> Name of
                the Process</template
              ></v-text-field
            >

            <v-textarea
              v-model="editedItem.description"
              :rules="descrule"
              label="Description"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span>
                Description</template
              ></v-textarea
            >
            <v-text-field
              v-model="editedItem.role"
              label="Your Role"
              :rules="[
                (v) => !!v || 'Role is required',
                (v) => (v && !!v.trim()) || 'Role cannot be blank',
              ]"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> Your
                Role</template
              ></v-text-field
            >
            <v-text-field
              v-model="editedItem.department"
              label="Your Department"
              :rules="[
                (v) => !!v || 'Department is required',
                (v) => (v && !!v.trim()) || 'Department cannot be blank',
              ]"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> Your
                Department</template
              ></v-text-field
            >

            <v-text-field
              v-model="editedItem.manualDuration"
              :rules="[
                (v) => !!v || 'Duration is required',
                (v) =>
                  (v && !!v.toString().trim()) || 'Duration cannot be blank',
              ]"
              label="
    How long does it take to do it manually?"
              @keypress="onlyNumber($event)"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> How
                long does it take to do it manually?</template
              ></v-text-field
            >
            <v-select
              v-model="editedItem.manualDurationType"
              :items="items"
              :rules="[(v) => !!v || 'Type is required']"
              label="Manual Duration Type"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> Manual
                Duration Type</template
              ></v-select
            >

            <v-text-field
              v-model="editedItem.noRuns"
              :rules="[
                (v) => !!v || 'Duration is required',
                (v) =>
                  (v && !!v.toString().trim()) || 'Duration cannot be blank',
              ]"
              label="
    How many times a day/week/month you do this process? "
              @keypress="onlyNumber($event)"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> How
                many times a day/week/month you do this process?</template
              ></v-text-field
            >
            <v-select
              v-model="editedItem.runCountType"
              :items="itemstimes"
              :rules="[(v) => !!v || 'Type is required']"
              label="Duration Type"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span>
                Duration Type</template
              ></v-select
            >
            <v-text-field
              label="What is the average salary per month of a person doing this job manually? "
              v-model="editedItem.manualAverageCost"
              prefix="$"
              :rules="[
                (v) => !!v || 'Salary is required',
                (v) => (v && !!v.trim()) || 'Salary cannot be blank',
              ]"
              required
              @keypress="onlyNumber($event)"
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> What is
                the average salary per month of a person doing this job
                manually?</template
              ></v-text-field
            >
            <v-text-field
              v-model="editedItem.peopleCount"
              :rules="[
                (v) => !!v || 'Count is required',
                (v) => (v && !!v.toString().trim()) || 'Count cannot be blank',
              ]"
              label="
    How many people are performing this process manually? "
              @keypress="onlyNumber($event)"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> How
                many people are performing this process manually?
              </template></v-text-field
            >
            <v-select
              v-model="editedItem.riskType"
              :items="itemsrisk"
              :rules="[(v) => !!v || 'Type is required']"
              label="Is the risk HIGH/Medium/Low if this job is not performed? "
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> Is the
                risk HIGH/Medium/Low if this job is not performed?</template
              ></v-select
            >
            <v-select
              v-model="editedItem.bugetApproval"
              :items="itemsbudget"
              :rules="[(v) => !!v || 'Budget approval is required']"
              label="Do you have budget approval to automate this process?"
              required
              ><template #label>
                <span class="red--text"><strong>&nbsp;* </strong></span> Do you
                have budget approval to automate this process?</template
              ></v-select
            >
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="border" class="text-capitalize" @click="closeDialog">
            Close
          </v-btn>
          <v-btn color="primary" class="text-capitalize" @click="validate">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <!-- <v-card-title>Process Details</v-card-title> -->
        <v-card-title class="justify-center">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-center">
              Process Details
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog1" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="cartdesc py-2">
            Name of the Process : <strong>{{ editedItem.processName }}</strong>
          </div>
          <div class="cartdesc py-2">
            Description : <strong>{{ editedItem.description }}</strong>
          </div>
          <div class="cartdesc py-2">
            Your Role : <strong>{{ editedItem.role }}</strong>
          </div>
          <div class="cartdesc py-2">
            Your Department : <strong>{{ editedItem.department }}</strong>
          </div>
          <div class="cartdesc py-2">
            How long does it take to do it manually? :
            <strong
              >{{ editedItem.manualDuration }}
              {{ editedItem.manualDurationType }}</strong
            >
          </div>
          <div class="cartdesc py-2">
            How many times a day/week/month do you this process? :

            <strong
              >{{ editedItem.noRuns }} {{ editedItem.runCountType }}</strong
            >
          </div>
          <div class="cartdesc py-2">
            What is the average salary per month of a person doing this job
            manually? :

            <strong>${{ editedItem.manualAverageCost }}</strong>
          </div>
          <div class="cartdesc py-2">
            How many people are performing this process manually? :

            <strong>{{ editedItem.peopleCount }}</strong>
          </div>
          <div class="cartdesc py-2">
            Is the risk HIGH/Medium/Low if this job is not performed? :

            <strong>{{ editedItem.riskType }}</strong>
          </div>
          <div class="cartdesc py-2">
            Do you have budget approval to automate this process? :

            <strong>{{ editedItem.bugetApproval }}</strong>
          </div>
          <div class="cartdesc py-2">
            <strong>Status : </strong>
            <!-- <strong>{{ newStatus(editedItem.status) }}</strong> -->
            <v-chip
              v-if="editedItem.status != ''"
              label
              small
              color="#E6F2F1"
              text-color="#00796B"
              dark
              class="ml-2"
            >
              {{ newStatus(editedItem.status) }}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="border" class="text-capitalize" @click="closeDialog1">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script >
// import RequestNewProcess from "../components/RequestNewProcess.vue";
import processRequestService from "@/services/processRequest.service.js";
import authService from "@/services/auth.service.js";
import EventBus from "../plugins/eventBus";
export default {
  components: {
    // RequestNewProcess,
  },
  created() {
    EventBus.on("request_changes", (x) => {
      this.loadProduct();
      this.status = x;
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    this.loadProduct();
  },
  data: () => ({
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    pageIndex: 0,
    pageSize: 10,
    status: "",
    isShow: 1,
    viewprocess: false,
    dialog: false,
    editedIndex: -1,
    isreset: false,
    pageLimit: 10,
    allLoaded: false,
    dialogDelete: false,
    valid: false,
    nameRules: [
      (v) => !!v || "Process Name is required",
      (v) => (v && !!v.trim()) || "Process Name cannot be blank",
      (v) =>
        (v || "").length <= 30 || "Process Name must be 30 characters or less",
    ],
    descrule: [
      (v) => !!v || "Description is required",
      (v) =>
        (v || "").length <= 800 || "Description must be 800 characters or less",
      (v) => (v && !!v.trim()) || "Description cannot be blank",
    ],
    select: null,
    editedItem: {
      id: "",
      role: "",
      status: "",
      processName: "",
      description: "",
      manualDuration: "",
      manualDurationType: "",
      noRuns: "",
      runCountType: "",
      manualAverageCost: "",
      peopleCount: "",
      riskType: "",
      bugetApproval: "",
      department: "",
    },
    items: ["Minutes", "Hours", "Days"],
    itemstimes: ["Day", "Week", "Month"],
    itemsrisk: ["High", "Medium", "Low"],
    itemsbudget: ["Yes", "No"],
    allRequest: {},
    loading: false,
  }),
  computed: {
    isMorePage() {
      return (this.pageIndex + 1) * this.pageSize < this.allRequest.total;
    },
  },
  methods: {
    editItem(item, i) {
      if (i == 1) {
        this.viewprocess = true;
      } else {
        this.viewprocess = false;
      }
      this.editedIndex = this.allRequest.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closeDialog1() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    showtab(id) {
      if (id == 1) {
        this.isShow = 1;
      } else if (id == 2) {
        this.isShow = 2;
      }
    },
    resetpass(id) {
      if (id == 1) {
        this.isreset = true;
      } else {
        this.isreset = false;
      }
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "Pending") {
        newstatus = "New";
      } else if (id == "Approved") {
        newstatus = "Approved";
      } else if (id == "InProgress") {
        newstatus = "In Progress";
      } else if (id == "Cancelled") {
        newstatus = "Cancelled";
      }
      return newstatus;
    },
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let data = {
          processName: this.editedItem.processName,
          description: this.editedItem.description,
          manualDuration: this.editedItem.manualDuration,
          manualDurationType: this.editedItem.manualDurationType,
          noRuns: this.editedItem.noRuns,
          runCountType: this.editedItem.runCountType,
          manualAverageCost: this.editedItem.manualAverageCost,
          peopleCount: this.editedItem.peopleCount,
          riskType: this.editedItem.riskType,
          bugetApproval: this.editedItem.bugetApproval,
          department: this.editedItem.department,
          status: this.editedItem.status,
          role: this.editedItem.role,
        };
        this.updateProcessRequest(data, this.editedItem.id);
        this.closeDialog();
      }
    },
    async updateProcessRequest(input, id) {
      let result = await processRequestService.updateRequest(input, id);
      if (result.status == 200) {
        this.status = "Request for new Process updated successfully";
        this.loadProduct();
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    onLoadMore() {
      this.pageIndex = this.pageIndex + 1;
      this.loadProduct(this.pageIndex);
    },
    loadProduct(skip = 0) {
      this.getAll({
        skip: skip * this.pageSize,
        take: this.pageSize,
        isPage: this.pageIndex > 0 ? true : false,
      });
    },
    async getAll(input) {
      this.loading = true;
      let result = await processRequestService.getAllRequest(input);
      this.loading = false;
      this.allRequest = result;
    },
  },
};
</script>
<style scoped lang="scss">
.margins {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom-black {
  background-color: black !important;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
}
.custom-white {
  background-color: white !important;
  color: black !important;
  border-radius: 10px;
  cursor: pointer;
}
.box {
  margin-top: 10%;
}
.mt-30 {
  margin-top: 60px;
}
.title {
  padding-top: 30px;
  text-align: left;
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 10px !important;
  margin-bottom: 10px;
  opacity: 1;
}
.header {
  width: 100%;
  float: left;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background: #fafafa 0% 0% no-repeat padding-box;
  padding: 5px;
  // padding: 12px 16px;
  gap: 10px;
  height: 46px;
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -2px;
}
.v-list {
  padding-top: 0px !important;
}
.v-list-item .v-list-item__title {
  line-height: 2;
}
/deep/.v-sheet.v-card,
.v-btn {
  border-radius: 8px;
}
.sideTabs {
  border-radius: 8px;
}
/deep/ .v-list-item__content {
  padding: 0px;
}
/deep/ td {
  padding: 10px;
}
.emptyCart {
  padding: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.cartitem {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
.cartdesc {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #5f6463 !important;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.dateval {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  color: #5f6463;
}
.ordertext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.ordertextb {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.seperator h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator h5::before,
.seperator h5::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #e3e4e8;
}

.seperator h5 .btnmargin {
  padding: 0 2em;
}
.allinfo {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #45464a;
}
/deep/.v-toolbar__content {
  padding: 0px;
}
</style>
<template>
  <div class="container">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
      justify-center
      class="mb-3"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :href="'#tab-' + item"
        :key="item"
        @click="tabsval(index)"
      >
        {{ item }}
      </v-tab>
      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center mr-4" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item v-for="item in more" :key="item" @click="addItem(item)">
            {{ item }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <!-- <v-tabs-items v-model="currentItem" height="auto">
      <v-tab-item
        key="Add New License"
        height="auto"
        value="tab-Add New License"
      >
        <v-row>
          <v-col cols="12">
            <v-slide-x-transition>
              <div>
                <v-form ref="form1" v-model="valid1" lazy-validation>
                  <v-text-field
                    v-model="editedItem.license"
                    label="License Key-1"
                    required
                    maxlength="100"
                    :rules="[
                      (v) => !!v || 'License Key for Bots is required',
                      (v) => (v && !!v.trim()) || 'License Key cannot be blank',
                      (v) =>
                        (v &&
                          !botNames.find(
                            (t) =>
                              t.license.toLowerCase().trim() ==
                              v.toLowerCase().trim()
                          )) ||
                        'License Key already exists',
                    ]"
                  >
                    <template #label>
                      License Key - 1
                      <span class="red--text"><strong>* </strong></span>
                    </template>
                  </v-text-field>
                  <div
                    v-for="(textField, i) in textFields"
                    :key="i"
                    class="d-flex"
                  >
                    <v-text-field
                      :label="textField.label"
                      v-model="textField.value"
                      :append-icon="'mdi-delete'"
                      maxlength="100"
                      @click:append="remove(i)"
                      :rules="[
                        (v) => !!v || 'License Key for Bots is required',
                        (v) =>
                          (v && !!v.trim()) || 'License Key cannot be blank',
                        (v) =>
                          (v &&
                            !botNames.find(
                              (t) =>
                                t.license.toLowerCase().trim() ==
                                v.toLowerCase().trim()
                            )) ||
                          'License Key already exists',
                      ]"
                    >
                      <template #label>
                        License Key - {{ i + 2 }}
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                    </v-text-field>
                  </div>

                  <v-btn @click="add" class="grey lighten-2">Add</v-btn>
                  <v-select
                    :items="regOrganization"
                    item-text="name"
                    item-value="id"
                    v-model="editedItem.clientId"
                    required
                    :rules="[(v) => !!v || 'Client is required']"
                    ><template #label>
                      Client
                      <span class="red--text"><strong>* </strong></span>
                    </template></v-select
                  >
                  <v-radio-group
                    v-model="editedItem.serverType"
                    @change="serverTypeChange"
                    :rules="[(v) => !!v || 'Type is required']"
                  >
                    <template v-slot:label>
                      <label class="v-label v-label--active"
                        >Orchestrator type<span class="red--text"
                          ><strong>* </strong></span
                        ></label
                      >
                    </template>
                    <v-radio
                      label="On - Premises"
                      value="On - Premises"
                    ></v-radio>
                    <v-radio label="Cloud" value="Cloud"></v-radio>
                  </v-radio-group>
                  <v-text-field
                    v-if="editedItem.serverType == 'Cloud'"
                    v-model="editedItem.tenatName"
                    label="Tenant Name"
                    validate-on-blur
                    required
                    :rules="[
                      (v) => !!v || 'Tenant Name is required',
                      (v) => (v && !!v.trim()) || 'Tenant Name cannot be blank',
                    ]"
                  >
                    <template #label>
                      Tenant Name
                      <span class="red--text"><strong>* </strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.serverName"
                    label="Server Name"
                    required
                    maxlength="20"
                  >
                  </v-text-field>

                  <div class="text-right">
                    <v-btn
                      dark
                      :loading="loading"
                      :disabled="loading"
                      @click="uploadLicense"
                    >
                      Create License
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item
        key="View/Edit License"
        height="auto"
        value="tab-View/Edit License"
      >
        <ViewLicense></ViewLicense>
      </v-tab-item>

      <v-tab-item
        key="DeActivated License"
        height="auto"
        v-if="userType == 'admin'"
        value="tab-DeActivated License"
      >
        <DeActivatedBot></DeActivatedBot>
      </v-tab-item>

      <v-tab-item
        key="Add existing BOT"
        value="tab-Add existing BOT"
        height="auto"
        v-if="userType == 'admin'"
      >
        <AddExistingBot></AddExistingBot>
      </v-tab-item>
    </v-tabs-items> -->
    <div v-if="selectedTab === 0">
      <v-row>
        <v-col cols="12">
          <v-slide-x-transition>
            <div>
              <v-form ref="form1" v-model="valid1" lazy-validation>
                <v-text-field
                  v-model="editedItem.license"
                  label="License Key-1"
                  required
                  maxlength="100"
                  :rules="[
                    (v) => !!v || 'License Key for Bots is required',
                    (v) => (v && !!v.trim()) || 'License Key cannot be blank',
                    (v) =>
                      (v &&
                        !botNames.find(
                          (t) =>
                            t.license.toLowerCase().trim() ==
                            v.toLowerCase().trim()
                        )) ||
                      'License Key already exists',
                  ]"
                >
                  <template #label>
                    License Key - 1
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
                <div
                  v-for="(textField, i) in textFields"
                  :key="i"
                  class="d-flex"
                >
                  <v-text-field
                    :label="textField.label"
                    v-model="textField.value"
                    :append-icon="'mdi-delete'"
                    maxlength="100"
                    @click:append="remove(i)"
                    :rules="[
                      (v) => !!v || 'License Key for Bots is required',
                      (v) => (v && !!v.trim()) || 'License Key cannot be blank',
                      (v) =>
                        (v &&
                          !botNames.find(
                            (t) =>
                              t.license.toLowerCase().trim() ==
                              v.toLowerCase().trim()
                          )) ||
                        'License Key already exists',
                    ]"
                  >
                    <template #label>
                      License Key - {{ i + 2 }}
                      <span class="red--text"><strong>* </strong></span>
                    </template>
                  </v-text-field>
                </div>

                <v-btn @click="add" class="grey lighten-2">Add</v-btn>
                <v-select
                  :items="regOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.clientId"
                  required
                  :rules="[(v) => !!v || 'Client is required']"
                  ><template #label>
                    Client
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-select
                >
                <v-radio-group
                  v-model="editedItem.serverType"
                  @change="serverTypeChange"
                  :rules="[(v) => !!v || 'Type is required']"
                >
                  <template v-slot:label>
                    <label class="v-label v-label--active"
                      >Orchestrator type<span class="red--text"
                        ><strong>* </strong></span
                      ></label
                    >
                  </template>
                  <v-radio
                    label="On - Premises"
                    value="On - Premises"
                  ></v-radio>
                  <v-radio label="Cloud" value="Cloud"></v-radio>
                </v-radio-group>
                <v-text-field
                  v-if="editedItem.serverType == 'Cloud'"
                  v-model="editedItem.tenatName"
                  label="Tenant Name"
                  validate-on-blur
                  required
                  :rules="[
                    (v) => !!v || 'Tenant Name is required',
                    (v) => (v && !!v.trim()) || 'Tenant Name cannot be blank',
                  ]"
                >
                  <template #label>
                    Tenant Name
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="editedItem.serverName"
                  label="Server Name"
                  required
                  maxlength="20"
                >
                </v-text-field>

                <div class="text-right">
                  <v-btn
                    dark
                    :loading="loading"
                    :disabled="loading"
                    @click="uploadLicense"
                  >
                    Create License
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </div>
    <div v-if="selectedTab === 1">
      <ViewLicense></ViewLicense>
    </div>
    <div v-if="selectedTab === 2">
      <DeActivatedBot></DeActivatedBot>
    </div>
    <div v-if="selectedTab === 3">
      <AddExistingBot></AddExistingBot>
    </div>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import EventBus from "../plugins/eventBus";
import ViewLicense from "../components/ViewLicense.vue";
import DeActivatedBot from "../components/DeActivatedBot.vue";
import AddExistingBot from "../components/AddExistingBot.vue";
import orgService from "@/services/organization.service.js";
import authService from "@/services/auth.service.js";

import clientBotService from "@/services/clientBot.service.js";
export default {
  components: {
    ViewLicense,
    DeActivatedBot,
    AddExistingBot,
  },
  created() {
    this.getOrganization();
    this.getLicenseNames();
    EventBus.on("license_add_delete", () => {
      this.getLicenseNames();
    });
  },
  data: () => ({
    selectedTab: 0,
    tab: null,
    tabs: [],
    more: [],
    currentItem: "tab-Add New License",
    status: "",
    isreset: false,
    pageIndex: 0,
    pageSize: 10,
    editedIndex: -1,
    loading: false,
    valid: false,
    valid1: false,
    textFields: [],
    editedItem: {
      license: "",
      clientId: 0,
      serverName: "",
    },
    userType: authService.authData.userType,
    regOrganization: [],
    botNames: [],
  }),
  computed: {
    tabItems() {
      if (this.userType === "admin") {
        return [
          "Add New License",
          "View/Edit License",
          "DeActivated License",
          "Add existing BOT",
        ];
      }
      return ["Add New License", "View/Edit License"];
    },
  },
  methods: {
    uploadLicense() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.loading = true;
        let val = [];
        val.push(this.editedItem.license);
        let i = 0;
        for (i = 0; i < this.textFields.length; i++) {
          val.push(this.textFields[i].value);
        }
        this.addLicense({
          license: val,
          serverName: this.editedItem.serverName,
          serverType: this.editedItem.serverType,
          tenatName: this.editedItem.tenatName,
          organizationId: this.editedItem.clientId,
        });
      }
    },
    async addLicense(item) {
      let result = await clientBotService.createLicense(item);

      if (result.status == 200) {
        this.status = "License created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.clear();
        this.loading = false;
        EventBus.emit("license_add_delete", "Added successfully");
      }
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    add() {
      this.textFields.push({
        label: "License Key",
        value: "",
      });
    },
    remove(index) {
      this.textFields.splice(index, 1);
    },
    clear() {
      this.editedIndex = -1;
      this.textFields.splice(0);
      if (this.$refs.form1 != undefined) {
        this.$refs.form1.reset();
        this.$refs.form1.resetValidation();
      }
    },
    serverTypeChange() {
      if (this.editedItem.serverType != "Cloud") {
        this.editedItem.tenatName = "";
      }
    },
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 2);
      this.more = temp.splice(0);
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    async getLicenseNames() {
      let result = await clientBotService.getLicenseNames();

      this.botNames = result;
    },
    tabsval(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
<style scoped lang="scss">
.margins {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom-black {
  background-color: black !important;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
}
.custom-white {
  background-color: white !important;
  color: black !important;
  border-radius: 10px;
  cursor: pointer;
}
.box {
  margin-top: 10%;
}
.mt-30 {
  margin-top: 60px;
}
.title {
  padding-top: 30px;
  text-align: left;
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 10px !important;
  margin-bottom: 10px;
  opacity: 1;
}
.header {
  border-bottom: 1px solid #e4e7ea;
  width: 100%;
  float: left;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #ffffff;
  background: transparent linear-gradient(265deg, #090e18 0%, #464c58 100%) 0%
    0% no-repeat padding-box;
  padding: 5px;
  .w-50 {
    width: 50%;
    float: left;
  }
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -4px;
}
.v-list {
  padding: 0px !important;
}
.border {
  border: 1px dashed #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: center;
}
.mid-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
</style>
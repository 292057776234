<template>
  <div class="container">
    <div>
      <!-- <v-card-title> -->
      <v-row justify="space-between mt-5">
        <v-col lg="2" md="2" v-if="regOrganization">
          <v-select
            outlined
            dense
            :items="regOrganization"
            v-model="selectedOrg"
            item-text="name"
            item-value="id"
            label="Select Organization"
            :clearable="false"
            @change="onChange"
          ></v-select>
        </v-col>
        <v-col lg="2" md="2">
          <v-select
            outlined
            dense
            :items="durationType"
            v-model="filter.duration"
            item-text="name"
            item-value="value"
            label="Select Duration"
            :clearable="false"
            @change="changeduration()"
          ></v-select>
        </v-col>
      </v-row>
      <!-- </v-card-title> -->
      <v-row class="">
        <v-col lg="2" md="2">
          <v-select
            outlined
            dense
            :items="categories"
            v-model="filter.category"
            item-text="categoryName"
            item-value="categoryId"
            label="Select Category"
            :clearable="false"
            @change="selectProcesses(filter.category)"
          ></v-select>
        </v-col>
        <v-col lg="2" md="2">
          <v-select
            outlined
            dense
            :items="processes"
            v-model="filter.processId"
            item-text="name"
            item-value="processId"
            label="Select Process"
            :clearable="false"
          ></v-select>
        </v-col>
        <v-col lg="5" md="5">
          <div class="d-flex">
            <div
              role="group"
              style="border: 1px solid #aeaeb0; border-radius: 4px"
            >
              <v-btn
                @click="filter.workingDaysType = true"
                type="button"
                text
                :class="filter.workingDaysType === true ? 'btnactive' : ''"
              >
                Working Days
              </v-btn>
              <v-btn
                @click="filter.workingDaysType = false"
                type="button"
                text
                :class="filter.workingDaysType === false ? 'btnactive ' : ''"
              >
                All Days
              </v-btn>
            </div>
            <div
              class="inline-flex rounded-md shadow-sm ml-6"
              role="group"
              style="border: 1px solid #aeaeb0; border-radius: 4px"
            >
              <v-btn
                @click="dateOb.isFuture = false"
                type="button"
                text
                :class="dateOb.isFuture === false ? 'btnactive ' : ''"
              >
                Past
              </v-btn>
              <v-btn
                @click="dateOb.isFuture = true"
                type="button"
                text
                :class="dateOb.isFuture === true ? 'btnactive' : ''"
              >
                Future
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="3" class="ml-auto">
          <div class="d-flex items-center col-span-2 justify-end">
            <v-btn @click="submit" depressed color="primary" class="mr-2">
              Submit
            </v-btn>
            <v-btn @click="clearFilters" outlined color="gray"> Clear </v-btn>
          </div></v-col
        >
      </v-row>
    </div>
    <div v-if="loading" class="d-flex text-center justify-center emptyCart">
      <img
        height="200"
        width="200"
        src="@/assets/quad-cube-shifter.gif"
        alt="no img"
      />
    </div>
    <div
      v-if="!loading"
      class="d-flex mt-12"
      style="border: 1px solid #e4e7ea !important"
    >
      <v-col
        lg="3"
        md="3"
        sm="6"
        cols="12"
        no-gutters
        class="cardbg h-full justify-center align-center"
        align-self="center"
      >
        <div outlined color="#419b8f14" class="justify-center align-center">
          <v-row class="no-gutters" align="center" justify="space-between ">
            <!-- <div class="imgcl">
              <v-img :src="require('@/assets/dashboard.png')"> </v-img>
            </div> -->
            <div class="pa-2 py-4">
              <div class="text-truncate text-capitalize dashtitle">
                Savings%
              </div>
              <div class="dashnum mt-5">{{ roi }}%</div>
            </div>
            <v-progress-circular
              :rotate="360"
              :size="150"
              :width="10"
              :value="roi"
              color="teal"
            >
              {{ roi }}%
            </v-progress-circular>
          </v-row>
        </div>
      </v-col>
      <v-col lg="9" md="9" sm="6" cols="12">
        <v-row no-gutters>
          <v-col
            lg="3"
            md="3"
            sm="6"
            style="
              border-style: hidden dashed hidden hidden;
              border-color: #aeaeb0;
            "
          >
            <v-list-item class="grow">
              <v-list-item-avatar>
                <v-icon> mdi-account-multiple-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle>
                  Predicted Process Run Count
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">{{
                  isFutureAfter == true ? Math.round(noOfRunsAll) : "--"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            lg="3"
            md="3"
            sm="6"
            style="
              border-style: hidden dashed hidden hidden;
              border-color: #aeaeb0;
            "
          >
            <v-list-item class="grow">
              <v-list-item-content class="text-center">
                <v-list-item-subtitle> Avg Staff Cost </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  ${{ Number(avgcost)
                  }}<span class="text-gray-500 font-normal text-sm"
                    >/hr</span
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            lg="3"
            md="3"
            sm="6"
            style="
              border-style: hidden dashed hidden hidden;
              border-color: #aeaeb0;
            "
          >
            <v-list-item class="grow">
              <v-list-item-content class="text-center">
                <v-list-item-subtitle
                  >Estimated Staff Time Before Automation
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ avgstafftime
                  }}<span class="text-gray-500 font-normal text-sm"
                    >/process</span
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col lg="3" md="3" sm="6">
            <v-list-item class="grow">
              <v-list-item-content class="text-center">
                <v-list-item-subtitle>
                  Estimated Digital Agent Time
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ estAutoTime
                  }}<span class="text-gray-500 font-normal text-sm"
                    >/process</span
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-15">
          <v-col
            lg="3"
            md="3"
            sm="6"
            style="
              border-style: hidden dashed hidden hidden;
              border-color: #aeaeb0;
            "
          >
            <v-list-item class="grow">
              <v-list-item-avatar>
                <v-icon> mdi-robot-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle>
                  Actual Process Run Count
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{
                    isFutureAfter == false ? noOfAutomationRuns : "--"
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            lg="3"
            md="3"
            sm="6"
            style="
              border-style: hidden dashed hidden hidden;
              border-color: #aeaeb0;
            "
          >
            <v-list-item class="grow">
              <v-list-item-content class="text-center">
                <v-list-item-subtitle>
                  Avg Digital Agent Cost
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  ${{ avgcostda
                  }}<span class="text-gray-500 font-normal text-sm"
                    >/hr</span
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            lg="3"
            md="3"
            sm="6"
            style="
              border-style: hidden dashed hidden hidden;
              border-color: #aeaeb0;
            "
          >
            <v-list-item class="grow">
              <v-list-item-content class="text-center">
                <v-list-item-subtitle>
                  Estimated Staff Time After Automation
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ avgstafftimeAfter
                  }}<span class="text-gray-500 font-normal text-sm"
                    >/process</span
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col lg="3" md="3" sm="6">
            <v-list-item class="grow">
              <v-list-item-content class="text-center">
                <v-list-item-subtitle>
                  Actual Digital Agent Time
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ avgdatime
                  }}<span class="text-gray-500 font-normal text-sm"
                    >/process</span
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <hr
      v-if="!loading"
      class="my-12"
      style="border-top: 1px solid #e4e7ea !important"
    />
    <v-row v-if="!loading">
      <v-col lg="6" md="6" sm="12">
        <div class="font-weight-black text-[32px] mb-4">
          Time Savings in Hours
        </div>
        <hr class="mb-4" style="border-top: 1px solid #e4e7ea !important" />
        <v-row>
          <v-col
            lg="8"
            md="8"
            sm="12"
            align-self="center"
            justify="space-around"
          >
            <v-row align="center" no-gutters>
              <v-col
                cols="6"
                style="
                  border-bottom: 1px solid #e4e7ea !important;
                  border-right: 1px solid #e4e7ea !important;
                "
              >
                <v-card class="pa-2" tile outlined>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-black text-h6">
                        {{ formatedstaffhr }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Staff Hour Before Automation
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                style="border-bottom: 1px solid #e4e7ea !important"
              >
                <v-card class="pa-2" tile outlined>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black text-h6 red--text text--darken-3"
                      >
                        {{ formatedautohr }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Digital Agent Time
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                style="border-right: 1px solid #e4e7ea !important"
              >
                <v-card class="pa-2" tile outlined>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black text-h6 indigo--text text--darken-3"
                      >
                        {{ totalManualHrAfrAuto }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Staff Hour After Automation
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  class="pa-2"
                  tile
                  outlined
                  style="background-color: rgb(204 251 241 / 0.5)"
                >
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black text-h6 primary--text"
                      >
                        {{ totalhrs }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Your Savings
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            lg="4"
            md="4"
            sm="12"
            align="center"
            align-self="center"
            justify="space-around"
          >
            <Pie
              :chartData="chartDataseriesHr"
              :chartOptions="chartOptionschartOptionsHr"
          /></v-col>
        </v-row>
        <hr class="mt-4" style="border-top: 1px solid #e4e7ea !important" />
        <div class="d-flex flex-row-reverse pt-4">
          <div class="d-flex align-center justify-center">
            <span
              class="d-flex justify-center w-2 h-2 me-1 bg align-center"
              style="height: 10px; width: 10px"
            ></span
            ><span class="text-xs">
              Staff :
              <span class="font-extrabold"> {{ formatedstaffhr }}</span></span
            >
          </div>
          <div class="d-flex mr-8 align-center justify-center">
            <span
              class="d-flex w-2 h-2 me-1 bg1"
              style="height: 10px; width: 10px"
            ></span
            ><span class="text-xs"
              >Digital Agents :
              <span class="font-extrabold"> {{ formatedautohr }}</span></span
            >
          </div>
        </div>
      </v-col>
      <v-col lg="6" md="6" sm="12">
        <div class="font-weight-black text-[32px] mb-4">
          Time savings in Dollars
        </div>
        <hr class="mb-4" style="border-top: 1px solid #e4e7ea !important" />
        <v-row class="">
          <v-col
            lg="8"
            md="8"
            sm="12"
            align-self="center"
            justify="space-around"
          >
            <v-row no-gutters>
              <v-col
                cols="6"
                style="
                  border-bottom: 1px solid #e4e7ea !important;
                  border-right: 1px solid #e4e7ea !important;
                "
              >
                <v-card class="pa-2" tile outlined>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-black text-h6">
                        ${{ totalManualCost }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Staff Cost Before Automation
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                style="border-bottom: 1px solid #e4e7ea !important"
              >
                <v-card class="pa-2" tile outlined>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black text-h6 red--text text--darken-3"
                      >
                        ${{ totalAutomationCost }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Digital Agent Cost
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                style="border-right: 1px solid #e4e7ea !important"
              >
                <v-card class="pa-2" tile outlined>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black text-h6 indigo--text text--darken-3"
                      >
                        ${{ totalManualCostAfrAuto }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Staff Cost After Automation
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  class="pa-2"
                  tile
                  outlined
                  style="background-color: rgb(204 251 241 / 0.5)"
                >
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black text-h6 primary--text"
                      >
                        ${{
                          Number(totalManualCost) &lt; Number(totalAutomationCost)
                            ? 0
                            : (
                                Number(totalManualCost) -
                                (Number(totalAutomationCost) +
                                  Number(totalManualCostAfrAuto))
                              ).toFixed(2)
                        }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Your Savings
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            lg="4"
            md="4"
            sm="12"
            align="center"
            align-self="center"
            justify="space-around"
          >
            <Pie
              :chartData="chartDataseries"
              :chartOptions="chartOptionschartOptions"
          /></v-col>
        </v-row>
        <hr class="mt-4" style="border-top: 1px solid #e4e7ea !important" />
        <div class="d-flex flex-row-reverse pt-4">
          <div class="d-flex align-center justify-center">
            <span
              class="d-flex justify-center w-2 h-2 me-1 bg align-center"
              style="height: 10px; width: 10px"
            ></span
            ><span class="text-xs">
              Staff :
              <span class="font-extrabold"> ${{ totalManualCost }}</span></span
            >
          </div>
          <div class="d-flex mr-8 align-center justify-center">
            <span
              class="d-flex w-2 h-2 me-1 bg1"
              style="height: 10px; width: 10px"
            ></span
            ><span class="text-xs"
              >Digital Agents :
              <span class="font-extrabold">
                ${{ totalAutomationCost }}</span
              ></span
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <hr
      v-if="!loading"
      class="my-12"
      style="border-top: 1px solid #e4e7ea !important"
    />
    <div v-if="!loading" class="">
      <div class="font-weight-black text-[32px] mb-4">
        Time Savings by Process
      </div>
      <hr class="mb-4" style="border-top: 1px solid #e4e7ea !important" />
      <BarChart
        :chartData="seriesprhrnew"
        :chartOptions="chartOptionsprhrnew"
        :height="500"
      />
    </div>
    <hr
      v-if="!loading"
      class="my-12"
      style="border-top: 1px solid #e4e7ea !important"
    />
    <div v-if="!loading" class="">
      <div class="font-weight-black text-[32px] mb-4">
        Time Savings in Dollars by Process
      </div>
      <hr class="mb-4" style="border-top: 1px solid #e4e7ea !important" />
      <BarChart
        :chartData="seriesprnew"
        :chartOptions="chartOptionsprnew"
        :height="500"
      />
    </div>
    <hr
      v-if="!loading"
      class="my-12"
      style="border-top: 1px solid #e4e7ea !important"
    />
    <v-row v-if="!loading" class="mb-12">
      <v-col md="6" lg="6" sm="12">
        <div class="font-weight-black text-[32px] mb-4">
          Time Savings by Department
        </div>
        <hr class="mb-4" style="border-top: 1px solid #e4e7ea !important" />
        <BarChart
          :chartData="seriescathrnew"
          :chartOptions="chartOptionscathrnew"
          :height="500"
        />
      </v-col>
      <v-col md="6" lg="6" sm="12">
        <div class="font-weight-black text-[32px] mb-4">
          Cost Savings by Department
        </div>
        <hr class="mb-4" style="border-top: 1px solid #e4e7ea !important" />
        <BarChart
          :chartData="seriescatcostnew"
          :chartOptions="chartOptionscatcostnew"
          :height="500"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="actionModal" scrollable persistent max-width="500px">
      <v-card>
        <v-card-title>Select Date Range</v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 400px">
          <v-radio-group v-model="dateOb.ispast" @change="setDateval()">
            <!-- <template v-slot:label>
              <label class="v-label v-label--active"
                >Date type<span class="red--text"
                  ><strong>* </strong></span
                ></label
              >
            </template> -->
            <v-radio label="For Past Dates" :value="true"></v-radio>
            <v-radio label="For Future Dates" :value="false"></v-radio>
          </v-radio-group>

          <v-col cols="12" sm="12" md="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateOb.daterange"
                  label="Date Range"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  @click:append="on.click"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="dateOb.ispast == 'true' || dateOb.ispast == true"
                v-model="dateOb.dates"
                no-title
                scrollable
                range
                :max="dateOb.maxDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize grey lighten-2"
                  @click="menu = false"
                  >Cancel
                </v-btn>
                <v-btn
                  dark
                  @click="$refs.menu.save(dateOb.dates), dateRangeText()"
                  >OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
              <v-date-picker
                v-else
                v-model="dateOb.dates"
                no-title
                scrollable
                range
                :min="dateOb.minDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize grey lighten-2"
                  @click="menu = false"
                  >Cancel
                </v-btn>
                <v-btn
                  dark
                  @click="$refs.menu.save(dateOb.dates), dateRangeText()"
                  >OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="text-capitalize grey lighten-2"
            @click="actionModal = false"
          >
            Close
          </v-btn>
          <v-btn
            color="text-capitalize primary lighten-2"
            @click="changeDate()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { ref } from "vue";

import analytics from "@/services/analytics.service.js";
import orgService from "@/services/organization.service.js";
import BarChart from "../components/Bar.vue";
// import DoughnutChart from "../components/Doughnut.vue";
import Pie from "../components/Pie.vue";
export default {
  components: {
    BarChart,
    Pie,
    // DatePicker,
    // PageLoader,
    // YearPicker,
    // Toaster
  },
  // setup() {
  //   const showSuccess = ref(false);
  //   const showError = ref(false);
  //   const message = ref("");
  //   const errors = ref({});
  //   const yearMenu = ref(false);
  //   const currentYear = ref(new Date().getFullYear());
  //   const daterangeval = ref("");
  //   let d = new Date().getDate();
  //   let m = new Date().getMonth();
  //   let y = new Date().getFullYear();
  //   let date = d >= 10 ? d : "0" + d;
  //   let month = m + 1 >= 10 ? m + 1 : "0" + (m + 1);
  //   const defaultDate = ref("");
  //   defaultDate.value = (y + "-" + month + "-" + date).toString();
  //   const isFutureAfter = ref(false);
  //   const dateOb = ref({
  //     daterange: defaultDate.value + " to " + defaultDate.value,
  //     ispast: true,
  //     isFuture: false,
  //     dateMenu: false,
  //     fromdate: defaultDate.value,
  //     todate: defaultDate.value,
  //     dates: [],
  //   });

  //   const actionModal = ref(false);
  //   // const showTooltip = (targetElId, triggerType, event) => {
  //   //   const targetEl = document.getElementById(targetElId);
  //   //   const tooltip = new Tooltip(targetEl, event.target, { triggerType });
  //   // };
  //   return {
  //     errors,
  //     yearMenu,
  //     currentYear,
  //     dateOb,
  //     daterangeval,
  //     defaultDate,
  //     actionModal,
  //     isFutureAfter,
  //     showSuccess,
  //     showError,
  //     message,
  //   };
  // },
  mounted() {
    // initDropdowns()
    // initAccordions()
    // const processStore = useProcessStore()
    // if (processStore.isToken) {
    //   if (processStore.isOrg) {
    //     this.getAnalytics({ OrgId: processStore.orgId })
    //     this.getProcessSummary(
    //       {
    //         OrgId: processStore.orgId,
    //         FilterType: 'ThisYear',
    //         OnlyWorkingDays: true,
    //         IsFuture: this.dateOb.isFuture
    //       },
    //       true
    //     )
    //   }
    // }
  },
  computed: {
    chartOptionsHr() {
      return {
        chart: {
          height: 250,
          type: "pie",
        },
        colors: ["#303F9F", "#0E9F6E"],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
          formatter: function (val, opts) {
            let ms = opts.w.globals.series[opts.seriesIndex];
            var seconds = parseInt((ms / 1000) % 60),
              minutes = parseInt((ms / (1000 * 60)) % 60),
              hours = parseInt((ms / (1000 * 60 * 60)) % 24),
              days = parseInt(ms / (1000 * 60 * 60 * 24));
            var hoursDays = parseInt(days * 24);
            hours += hoursDays;
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            return val + "-" + hours + ":" + minutes + ":" + seconds;
          },
        },
        tooltip: {
          y: {
            formatter: function (ms) {
              var seconds = parseInt((ms / 1000) % 60),
                minutes = parseInt((ms / (1000 * 60)) % 60),
                hours = parseInt((ms / (1000 * 60 * 60)) % 24),
                days = parseInt(ms / (1000 * 60 * 60 * 24));
              var hoursDays = parseInt(days * 24);
              hours += hoursDays;
              hours = hours < 10 ? "0" + hours : hours;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;
              return hours + ":" + minutes + ":" + seconds;
            },
          },
        },
        labels: ["Staff ", "Digital Agent"],
      };
    },

    msToTime(ms) {
      let seconds = (ms / 1000).toFixed(1);
      let minutes = (ms / (1000 * 60)).toFixed(1);
      let hours = (ms / (1000 * 60 * 60)).toFixed(1);
      let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
      if (seconds < 60) return seconds + " Sec";
      else if (minutes < 60) return minutes + " Min";
      else if (hours < 24) return hours + " Hrs";
      else return days + " Days";
    },
  },
  data() {
    return {
      menu: false,
      message: "",
      errors: {},
      showSuccess: false,
      showError: false,
      yearMenu: false,
      daterangeval: "",
      actionModal: false,
      isFutureAfter: "",
      dateOb: {
        daterange:
          new Date().toISOString().slice(0, 10) +
          " - " +
          new Date().toISOString().slice(0, 10),
        ispast: true,
        isFuture: false,
        dateMenu: false,
        fromdate: new Date().toISOString().slice(0, 10),
        todate: new Date().toISOString().slice(0, 10),
        dates: [
          new Date().toISOString().slice(0, 10),
          new Date().toISOString().slice(0, 10),
        ],
        minDate: new Date().toISOString().slice(0, 10),
        maxDate: new Date().toISOString().slice(0, 10),
      },
      //   loading: false,
      currentYear: new Date().getFullYear(),
      //   loading: false,
      avgstafftimeAfter: 0,
      regOrganization: [],
      selectedOrg: "",
      estAutoTime: 0,
      avgstafftime: 0,
      avgdatime: 0,
      noOfProcess: 0,
      avgcost: 0,
      avgcostda: 0,
      noOfRuns: 0,
      noOfRunsAll: 0,
      noOfAutomationRuns: 0,
      filter: {
        duration: "ThisYear",
        durationType: "This Year",
        category: 0,
        categoryName: "All Categories",
        processId: 0,
        workingDaysType: true,
        oldduration: "",
        oldworking: "",
        processName: "All Workflows",
      },
      showCategory: false,
      showProcess: false,
      showDuration: false,
      roiType: "This Year",
      roi: 0,
      durationType: [
        { name: "Today", value: "Today" },
        { name: "Yesterday", value: "Yesterday" },
        { name: "Tomorrow", value: "Tomorrow" },
        { name: "This Week", value: "ThisWeek" },
        { name: "Last Week", value: "LastWeek" },
        { name: "Next Week", value: "NextWeek" },
        { name: "This Month", value: "ThisMonth" },
        { name: "Last Month", value: "LastMonth" },
        { name: "Next Month", value: "NextMonth" },
        { name: "This Year", value: "ThisYear" },
        { name: "Last Year", value: "LastYear" },
        { name: "Next Year", value: "NextYear" },
        { name: "Custom Range", value: "Custom" },
      ],
      categories: [],
      processes: [],
      formatedstaffhr: 0,
      formatedautohr: 0,
      totalhrs: 0,
      impcost: 0,
      selectedYear: new Date().getFullYear(),
      workingDays: [],
      dialogProcess: false,
      editedProcess: {},
      totalAutomationCost: 0,
      totalManualCost: 0,
      totalManualCostAfrAuto: 0,
      totalManualHrAfrAuto: 0,
      totalAutomationHr: 0,
      totalManualHr: 0,
      series: [],
      loading: false,

      seriescatcost: [
        {
          name: "Savings",
          data: [],
        },
      ],
      chartOptionscatcost: {
        chart: {
          type: "bar",
          height: 350,
        },

        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return "$ " + val.toFixed(2);
          },
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val.toFixed(2);
            },
          },
        },
        legend: {
          show: false,
        },
        // legend: {
        //   formatter: function (val, opts) {
        //     return val + ' - $' + opts.w.globals.series[opts.seriesIndex]?.toFixed(2)
        //   }
        // }
      },
      seriescathr: [
        {
          name: "Savings",
          data: [],
        },
      ],
      chartOptionscathr: {
        chart: {
          type: "bar",
          height: 350,
        },

        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
            distributed: true,
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (ms) {
            var seconds = parseInt((ms / 1000) % 60),
              minutes = parseInt((ms / (1000 * 60)) % 60),
              hours = parseInt((ms / (1000 * 60 * 60)) % 24),
              days = parseInt(ms / (1000 * 60 * 60 * 24));
            var hoursDays = parseInt(days * 24);
            hours += hoursDays;
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            return hours + ":" + minutes + ":" + seconds;
          },
          offsetX: 40,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          labels: {
            formatter: function (ms) {
              var seconds = parseInt((ms / 1000) % 60),
                minutes = parseInt((ms / (1000 * 60)) % 60),
                hours = parseInt((ms / (1000 * 60 * 60)) % 24),
                days = parseInt(ms / (1000 * 60 * 60 * 24));
              var hoursDays = parseInt(days * 24);
              hours += hoursDays;
              hours = hours < 10 ? "0" + hours : hours;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;
              return hours + ":" + minutes + ":" + seconds;
            },
          },
        },
        yaxis: {},
        tooltip: {
          y: {
            formatter: function (ms) {
              var seconds = parseInt((ms / 1000) % 60),
                minutes = parseInt((ms / (1000 * 60)) % 60),
                hours = parseInt((ms / (1000 * 60 * 60)) % 24),
                days = parseInt(ms / (1000 * 60 * 60 * 24));
              var hoursDays = parseInt(days * 24);
              hours += hoursDays;
              hours = hours < 10 ? "0" + hours : hours;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;
              return hours + ":" + minutes + ":" + seconds;
            },
          },
        },
        legend: {
          show: false,
        },

        // legend: {
        //   formatter: function (val, opts) {
        //     return val + ' - $' + opts.w.globals.series[opts.seriesIndex]?.toFixed(2)
        //   }
        // }
      },
      seriesHr: [],
      chartOptions: {
        chart: {
          height: 200,
          type: "pie",
        },
        colors: ["#303F9F", "#0E9F6E"],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
        },
        legend: {
          show: false,
          formatter: function (val, opts) {
            return (
              val + " - $" + opts.w.globals.series[opts.seriesIndex]?.toFixed(2)
            );
          },
        },
        tooltip: {
          y: {
            formatter: function (ms) {
              return "$" + ms;
            },
          },
        },
        labels: ["Staff ", "Digital Agent"],
      },
      seriespr: [
        {
          name: "Staff Cost Before Automation",
          data: [],
        },
        {
          name: "Digital Agent",
          data: [],
        },
        {
          name: "Staff Cost After Automation",
          data: [],
        },
      ],
      chartOptionspr: {
        chart: {
          type: "bar",
          height: 500,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
            endingShape: "rounded",
          },
        },
        colors: ["#303F9F", "#0E9F6E", "#F97316"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          labels: {
            hideOverlappingLabels: false,
            trim: true,
            // formatter: function (ms) {
            //   if (ms.length > 20) {
            //     return ms.substring(0, 20)
            //   } else return ms
            // }
          },
        },
        yaxis: {
          title: {
            text: "Cost in $ ",
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val.toFixed(2);
            },
          },
        },
      },
      seriesprhr: [
        {
          name: "Staff hour before Automation",
          data: [],
        },
        {
          name: "Digital Agent",
          data: [],
        },
        {
          name: "Staff hour after Automation",
          data: [],
        },
      ],
      chartOptionsprhr: {
        chart: {
          type: "bar",
          height: 500,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
            endingShape: "rounded",
          },
        },
        colors: ["#303F9F", "#0E9F6E", "#F97316"],
        dataLabels: {
          enabled: false,
        },

        // stroke: {
        //   curve: 'smooth'
        // },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          labels: {
            hideOverlappingLabels: false,
            trim: true,
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            formatter: function (ms) {
              var seconds = parseInt((ms / 1000) % 60),
                minutes = parseInt((ms / (1000 * 60)) % 60),
                hours = parseInt((ms / (1000 * 60 * 60)) % 24),
                days = parseInt(ms / (1000 * 60 * 60 * 24));
              var hoursDays = parseInt(days * 24);
              hours += hoursDays;
              hours = hours < 10 ? "0" + hours : hours;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;
              return hours + ":" + minutes + ":" + seconds;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (ms) {
              var seconds = parseInt((ms / 1000) % 60),
                minutes = parseInt((ms / (1000 * 60)) % 60),
                hours = parseInt((ms / (1000 * 60 * 60)) % 24),
                days = parseInt(ms / (1000 * 60 * 60 * 24));
              var hoursDays = parseInt(days * 24);
              hours += hoursDays;
              hours = hours < 10 ? "0" + hours : hours;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;
              return hours + ":" + minutes + ":" + seconds;
            },
          },
        },
      },

      seriesRoi: [],
      chartOptionsRoi: {
        chart: {
          height: 250,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              background: "#ffffff",
            },
            dataLabels: {
              value: {
                show: true,
                offsetY: 0,
                fontSize: "16px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
            hollow: {
              margin: 10,
              background: "#ffffff",
            },
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            shade: "light",

            shadeIntensity: 0.5,
            opacityFrom: 1,
            opacityTo: 1,

            colorStops: [
              {
                offset: 0,
                color: "#009688",
                opacity: 1,
              },

              {
                offset: 70,
                color: "#283593",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          lineCap: "round",
        },
        // stroke: {
        //   dashArray: 4
        // },
        track: {
          background: "#ffffff",
        },

        labels: [""],
      },

      chartDataseriesHr: {
        labels: ["Staff ", "Digital Agent"],
        datasets: [
          {
            backgroundColor: ["#303F9F", "#0E9F6E"],
            data: [],
          },
        ],
      },
      chartOptionschartOptionsHr: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                // console.log(data);
                // console.log(tooltipItems);
                let duration = tooltipItems.parsed;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return (
                  tooltipItems.label +
                  " - " +
                  hours +
                  ":" +
                  minutes +
                  ":" +
                  seconds
                );
              },
            },
          },
        },
      },
      chartDataseries: {
        labels: ["Staff ", "Digital Agent"],
        datasets: [
          {
            backgroundColor: ["#303F9F", "#0E9F6E"],
            data: [],
          },
        ],
      },
      chartOptionschartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                return (
                  tooltipItems.label + " - $" + tooltipItems.parsed?.toFixed(2)
                );
              },
            },
          },
        },
      },
      seriesprhrnew: {
        labels: [],

        datasets: [
          {
            label: "Staff hour before Automation",
            backgroundColor: "#303F9F",
            borderColor: "#303F9F",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
          {
            label: "Digital Agent",
            backgroundColor: "#0E9F6E",
            borderColor: "#0E9F6E",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
          {
            label: "Staff hour after Automation",
            backgroundColor: "#F97316",
            borderColor: "#F97316",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
        ],
      },
      chartOptionsprhrnew: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: {
            grid: {
              display: false,
            },
          },
          yAxes: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (v) {
                let duration = v;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return hours + ":" + minutes + ":" + seconds;
              },
              // stepSize: 30 * 60,
            },
            display: true,
            title: {
              display: true,
              text: "Hours",
              font: {
                size: 20,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
        },

        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                let duration = tooltipItems.parsed.y;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return hours + ":" + minutes + ":" + seconds;
              },
            },
          },
        },
      },
      seriesprnew: {
        labels: [],

        datasets: [
          {
            label: "Staff Cost before Automation",
            backgroundColor: "#303F9F",
            borderColor: "#303F9F",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
          {
            label: "Digital Agent",
            backgroundColor: "#0E9F6E",
            borderColor: "#0E9F6E",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
          {
            label: "Staff Cost after Automation",
            backgroundColor: "#F97316",
            borderColor: "#F97316",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
        ],
      },
      chartOptionsprnew: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: {
            grid: {
              display: false,
            },
          },
          yAxes: {
            grid: {
              display: false,
            },
            ticks: {
              // callback: function (v) {
              //   return "$" + v;
              // },
              // stepSize: 30 * 60,
            },
            display: true,
            title: {
              display: true,
              text: "Cost in $",
              font: {
                size: 20,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
        },

        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                // let duration = tooltipItems.parsed.y;
                return "$" + tooltipItems.parsed.y;
              },
            },
          },
        },
      },

      seriescathrnew: {
        labels: [],
        datasets: [
          {
            label: "Savings",
            backgroundColor: "#3F51B5",
            borderColor: "#3F51B5",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
        ],
      },
      chartOptionscathrnew: {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: {
            grid: {
              display: false,
            },
          },
          xAxes: {
            grid: {
              display: false,
            },
            // type: "time",
            ticks: {
              callback: function (v) {
                let duration = v;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return hours + ":" + minutes + ":" + seconds;
              },
              // stepSize: 30 * 60,
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                let duration = tooltipItems.parsed.x;
                var seconds = parseInt((duration / 1000) % 60),
                  minutes = parseInt((duration / (1000 * 60)) % 60),
                  hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                  days = parseInt(duration / (1000 * 60 * 60 * 24));

                var hoursDays = parseInt(days * 24);
                hours += hoursDays;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return hours + ":" + minutes + ":" + seconds;
              },
            },
          },
        },
      },
      seriescatcostnew: {
        labels: [],
        datasets: [
          {
            label: "Savings",
            backgroundColor: "#3F51B5",
            borderColor: "#3F51B5",
            borderWidth: 1,
            maxBarThickness: 50,
            data: [],
          },
        ],
      },
      chartOptionscatcostnew: {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: {
            grid: {
              display: false,
            },
          },
          xAxes: {
            grid: {
              display: false,
            },
            // type: "time",
            // ticks: {
            //   callback: function (v) {
            //     let duration = v;
            //     var seconds = parseInt((duration / 1000) % 60),
            //       minutes = parseInt((duration / (1000 * 60)) % 60),
            //       hours = parseInt((duration / (1000 * 60 * 60)) % 24),
            //       days = parseInt(duration / (1000 * 60 * 60 * 24));

            //     var hoursDays = parseInt(days * 24);
            //     hours += hoursDays;
            //     hours = hours < 10 ? "0" + hours : hours;
            //     minutes = minutes < 10 ? "0" + minutes : minutes;
            //     seconds = seconds < 10 ? "0" + seconds : seconds;
            //     return hours + ":" + minutes + ":" + seconds;
            //   },
            //   // stepSize: 30 * 60,
            // },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                // console.log("tooltipItems", tooltipItems);
                // let duration = tooltipItems.parsed.x;
                return "$ " + tooltipItems.parsed.x.toFixed(2);
              },
            },
          },
        },
      },
    };
  },

  created() {
    this.workingDays = this.calculateWorkingDays(new Date().getFullYear());
    this.getOrganization();
  },

  methods: {
    dateRangeText() {
      this.dateOb.dates = this.dateOb.dates.sort();
      this.dateOb.daterange = this.dateOb.dates.sort().join(" - ");
      if (this.dateOb.dates.length > 0) {
        if (this.dateOb.dates.length == 2) {
          this.dateOb.fromdate = this.dateOb.dates[0];
          this.dateOb.todate = this.dateOb.dates[1];
        } else {
          this.dateOb.fromdate = this.dateOb.dates[0];
          this.dateOb.todate = this.dateOb.dates[0];
        }

        // this.getLeadHistory("", this.fromdate, this.todate);
      } else {
        this.dateOb.fromdate = new Date().toISOString().slice(0, 10);
        this.dateOb.todate = new Date().toISOString().slice(0, 10);
      }
    },
    changeduration() {
      if (this.filter.duration == "Custom") {
        this.actionModal = true;
      } else {
        this.actionModal = false;
      }
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
      this.selectedOrg = this.regOrganization[0].id;
      this.onChange();
    },
    setDateval() {
      this.dateOb.fromdate = new Date().toISOString().slice(0, 10);
      this.dateOb.todate = new Date().toISOString().slice(0, 10);

      this.dateOb.dates = [];
      this.dateOb.dates = [
        new Date().toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ];
      this.dateOb.daterange = this.dateOb.dates.sort().join(" - ");
      //  if (this.dateOb.ispast == "true" || this.dateOb.ispast == true) {
      //   this.dateOb.maxDate = new Date().toISOString().slice(0, 10);
      //   this.dateOb.minDate = null;
      // } else {
      //   this.dateOb.minDate = new Date().toISOString().slice(0, 10);
      //   this.dateOb.maxDate = null;
      // }
    },
    async onChange() {
      if (this.selectedOrg > 0) {
        // this.loading = true;
        // let result = await userService.getAll({
        //   skip: 0,
        //   take: 10,
        //   status: "Active",
        //   organizationId: this.selectedOrg,
        // });
        // this.loading = false;
        // this.userList = result;
        this.yearMenu = false;
        this.totalManualCost = 0;
        this.totalManualCostAfrAuto = 0;
        this.totalManualHrAfrAuto = 0;
        this.totalAutomationCost = 0;
        this.formatedautohr = 0;
        this.totalAutomationHr = 0;
        this.totalManualHr = 0;
        this.formatedautohr = 0;
        this.formatedstaffhr = 0;
        this.costMonthly = {};
        this.series.splice(0);
        this.seriesHr.splice(0);
        this.chartDataseriesHr.datasets[0].data = [];
        this.chartDataseries.datasets[0].data = [];
        this.seriespr[0].data.splice(0);
        this.seriespr[1].data.splice(0);
        this.seriespr[2].data.splice(0);
        this.seriesprhr[0].data.splice(0);
        this.seriesprhr[1].data.splice(0);
        this.seriesprhr[2].data.splice(0);
        this.seriesprhrnew.datasets[0].data = [];
        this.seriesprhrnew.datasets[1].data = [];
        this.seriesprhrnew.datasets[2].data = [];
        this.seriesprnew.datasets[0].data = [];
        this.seriesprnew.datasets[1].data = [];
        this.seriesprnew.datasets[2].data = [];
        this.chartOptionspr.xaxis.categories.splice(0);
        this.chartOptionsprhr.xaxis.categories.splice(0);
        this.seriescathrnew.datasets[0].data = [];
        this.seriescathrnew.labels = [];
        this.seriescatcostnew.datasets[0].data = [];
        this.seriescatcostnew.labels = [];
        this.seriesprhrnew.labels = [];
        this.seriesprnew.labels = [];
        this.seriescatcost[0].data.splice(0);
        this.chartOptionscatcost.xaxis.categories.splice(0);
        this.seriescathr[0].data.splice(0);
        // this.seriesprnew.datasets[0].data=[];
        this.seriesprnew.labels = [];
        this.chartOptionscathr.xaxis.categories.splice(0);
        this.filter.category = 0;
        this.noOfRuns = 0;
        this.noOfRunsAll = 0;
        this.noOfAutomationRuns = 0;

        this.filter.duration = "ThisYear";
        this.filter.durationType = "This Year";
        this.filter.category = 0;
        this.filter.categoryName = "All Categories";
        this.filter.processId = 0;
        this.filter.workingDaysType = true;
        this.filter.oldduration = "";
        this.filter.oldworking = "";
        this.filter.processName = "All Workflows";

        this.dateOb.isFuture = false;
        this.isFutureAfter = false;
        this.roiType = "This Year";
        this.roi = 0;
        this.getAnalytics({ OrgId: this.selectedOrg });
        this.getProcessSummary(
          {
            OrgId: this.selectedOrg,
            FilterType: "ThisYear",
            OnlyWorkingDays: true,
            IsFuture: this.dateOb.isFuture,
          },
          true
        );
      }
    },

    // display() {
    //   const doc = new jsPDF()

    //   // let pageWidth = doc.internal.pageSize.getWidth()
    //   // doc.text(this.daterange, pageWidth / 2, 20, 'center')
    //   const charts = document.querySelectorAll('#chartsContainer')
    //   charts.forEach((chart, index) => {
    //     // Convert each chart to an image and add it to the PDF
    //     html2canvas(chart).then((canvas) => {
    //       const imgData = canvas.toDataURL('image/png')
    //       if (index == 0) {
    //         doc.addImage(imgData, 'PNG', 20, 30, 170, 250)
    //       } else {
    //         doc.addImage(imgData, 'PNG', 20, 20, 170, 250)
    //       }

    //       if (index < charts.length - 1) {
    //         doc.addPage() // Add a new page for each chart
    //       } else {
    //         // Save the PDF when all charts are added
    //         doc.save('charts.pdf')
    //       }
    //     })
    //   })
    // },
    calculateWorkingDays(year) {
      const workingDays = [];
      const currentDate = new Date(year, 0, 1);

      while (currentDate.getFullYear() === year) {
        const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          workingDays.push(currentDate.toDateString());
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return workingDays.length;
    },
    getRoi() {
      if (this.totalManualCost > 0 && this.noOfAutomationRuns != 0) {
        let val =
          Number(this.totalAutomationCost) +
          Number(this.totalManualCostAfrAuto);

        let netSavings = this.totalManualCost - val;

        this.roi = ((netSavings / this.totalManualCost) * 100).toFixed(1);
      } else this.roi = 0;
      this.seriesRoi.splice(0);
      this.seriesRoi.push(Number(this.roi));
    },

    selectProcesses(id) {
      let process;
      if (id != 0) {
        const arr = this.costMonthly.summary.filter((t) => t.categoryId === id);
        process = arr;
      } else {
        process = this.costMonthly.summary;
      }
      var arr = Object.assign([], process);
      arr.unshift({ processId: 0, name: "All Processes" });
      this.processes = arr;
    },
    clearFilters() {
      this.yearMenu = false;
      this.totalManualCost = 0;
      this.totalManualCostAfrAuto = 0;
      this.totalManualHrAfrAuto = 0;
      this.totalAutomationCost = 0;
      this.formatedautohr = 0;
      this.totalAutomationHr = 0;
      this.totalManualHr = 0;
      this.formatedautohr = 0;
      this.formatedstaffhr = 0;
      this.costMonthly = {};
      this.series.splice(0);
      this.seriesHr.splice(0);
      this.chartDataseriesHr.datasets[0].data = [];
      this.chartDataseries.datasets[0].data = [];
      this.seriespr[0].data.splice(0);
      this.seriespr[1].data.splice(0);
      this.seriespr[2].data.splice(0);
      this.seriesprhr[0].data.splice(0);
      this.seriesprhr[1].data.splice(0);
      this.seriesprhr[2].data.splice(0);
      this.seriesprhrnew.datasets[0].data = [];
      this.seriesprhrnew.datasets[1].data = [];
      this.seriesprhrnew.datasets[2].data = [];
      this.seriesprnew.datasets[0].data = [];
      this.seriesprnew.datasets[1].data = [];
      this.seriesprnew.datasets[2].data = [];
      this.chartOptionspr.xaxis.categories.splice(0);
      this.chartOptionsprhr.xaxis.categories.splice(0);
      this.seriesprhrnew.labels = [];
      this.seriesprnew.labels = [];
      this.seriescatcost[0].data.splice(0);
      this.chartOptionscatcost.xaxis.categories.splice(0);
      this.seriescathr[0].data.splice(0);
      this.seriescathrnew.datasets[0].data = [];
      this.seriescathrnew.labels = [];
      this.seriescatcostnew.datasets[0].data = [];
      this.seriescatcostnew.labels = [];
      this.seriesprnew.labels = [];
      this.chartOptionscathr.xaxis.categories.splice(0);
      this.filter.category = 0;
      this.noOfRuns = 0;
      this.noOfRunsAll = 0;
      this.noOfAutomationRuns = 0;

      this.filter.duration = "ThisYear";
      this.filter.durationType = "This Year";
      this.filter.category = 0;
      this.filter.categoryName = "All Categories";
      this.filter.processId = 0;
      this.filter.workingDaysType = true;
      this.filter.oldduration = "";
      this.filter.oldworking = "";
      this.filter.processName = "All Workflows";

      this.dateOb.isFuture = false;
      this.isFutureAfter = false;
      this.roiType = "This Year";
      this.roi = 0;
      this.getProcessSummary(
        {
          OrgId: this.selectedOrg,
          FilterType: this.filter.duration,
          OnlyWorkingDays: this.filter.workingDaysType,
          IsFuture: this.dateOb.isFuture,
        },
        true
      );
    },
    submit() {
      if (this.filter.duration == "Custom") {
        this.roiType = this.dateOb.daterange;
        if (this.dateOb.ispast == "true" || this.dateOb.ispast == true) {
          this.dateOb.isFuture = false;
        } else {
          this.dateOb.isFuture = true;
        }
      } else {
        this.roiType = this.filter.durationType;
      }

      if (
        this.filter.duration == "LastWeek" ||
        this.filter.duration == "Yesterday" ||
        this.filter.duration == "LastMonth" ||
        this.filter.duration == "LastYear"
      ) {
        this.dateOb.isFuture = false;
      } else if (
        this.filter.duration == "NextMonth" ||
        this.filter.duration == "NextWeek" ||
        this.filter.duration == "NextYear" ||
        this.filter.duration == "Tomorrow"
      ) {
        this.dateOb.isFuture = true;
      }
      this.isFutureAfter = this.dateOb.isFuture;
      this.showCategory = false;
      this.showProcess = false;
      this.showDuration = false;
      //   const processStore = useProcessStore();
      this.yearMenu = false;
      this.totalManualCost = 0;
      this.totalManualCostAfrAuto = 0;
      this.totalManualHrAfrAuto = 0;
      this.totalAutomationCost = 0;
      this.formatedautohr = 0;
      this.totalAutomationHr = 0;
      this.totalManualHr = 0;
      this.formatedautohr = 0;
      this.formatedstaffhr = 0;
      this.costMonthly = {};
      this.series.splice(0);
      this.seriesHr.splice(0);
      this.chartDataseriesHr.datasets[0].data = [];
      this.chartDataseries.datasets[0].data = [];
      this.seriespr[0].data.splice(0);
      this.seriespr[1].data.splice(0);
      this.seriespr[2].data.splice(0);
      this.seriesprhr[0].data.splice(0);
      this.seriesprhr[1].data.splice(0);
      this.seriesprhr[2].data.splice(0);
      this.seriesprhrnew.datasets[0].data = [];
      this.seriesprhrnew.datasets[1].data = [];
      this.seriesprhrnew.datasets[2].data = [];
      this.seriesprnew.datasets[0].data = [];
      this.seriesprnew.datasets[1].data = [];
      this.seriesprnew.datasets[2].data = [];
      this.seriescathrnew.datasets[0].data = [];
      this.seriescathrnew.labels = [];
      this.seriescatcostnew.datasets[0].data = [];
      this.seriescatcostnew.labels = [];
      this.chartOptionspr.xaxis.categories.splice(0);
      this.chartOptionsprhr.xaxis.categories.splice(0);
      this.seriesprhrnew.labels = [];
      // this.seriesprnew.labels.splice(0);
      this.seriescatcost[0].data.splice(0);
      this.chartOptionscatcost.xaxis.categories.splice(0);
      this.seriescathr[0].data.splice(0);
      this.seriesprnew.datasets[0].data = [];
      this.seriesprnew.labels = [];
      this.chartOptionscathr.xaxis.categories.splice(0);
      if (
        this.filter.oldduration != this.filter.duration ||
        this.filter.oldworking != this.filter.workingDaysType
      ) {
        if (this.filter.duration == "Custom") {
          this.getProcessSummary(
            {
              OrgId: this.selectedOrg,
              FilterType: this.filter.duration,
              OnlyWorkingDays: this.filter.workingDaysType,
              StartDate: this.dateOb.fromdate + "T00:00:00",
              EndDate: this.dateOb.todate + "T23:59:59",
              IsFuture: this.dateOb.isFuture,
            },
            true
          );
        } else {
          this.getProcessSummary(
            {
              OrgId: this.selectedOrg,
              FilterType: this.filter.duration,
              OnlyWorkingDays: this.filter.workingDaysType,
              IsFuture: this.dateOb.isFuture,
            },
            true
          );
        }
      } else {
        if (this.filter.duration == "Custom") {
          this.getProcessSummary(
            {
              OrgId: this.selectedOrg,
              FilterType: this.filter.duration,
              OnlyWorkingDays: this.filter.workingDaysType,
              StartDate: this.dateOb.fromdate + "T00:00:00",
              EndDate: this.dateOb.todate + "T23:59:59",
              IsFuture: this.dateOb.isFuture,
            },
            true
          );
        } else {
          this.getProcessSummary(
            {
              OrgId: this.selectedOrg,
              FilterType: this.filter.duration,
              OnlyWorkingDays: this.filter.workingDaysType,
              IsFuture: this.dateOb.isFuture,
            },
            false
          );
        }
      }
    },
    clearYear() {
      this.currentYear = new Date().getFullYear();
      //   const processStore = useProcessStore();
      this.yearMenu = false;
    },

    showDetails(item) {
      this.selectedProcesses = item.processId;
      this.editedProcess = Object.assign({}, item);
      this.dialogProcess = true;
    },
    closeDialog() {
      this.dialogProcess = false;
    },
    async getAnalytics(input) {
      this.loading = true;
      //   const analyticsStore = useAnalyticsStore()
      let result = await analytics.getSummary(input);

      this.process = result.data;
    },
    async getProcessSummary(input, flag) {
      this.loading = true;

      if (flag) {
        this.costMonthly = [];

        // const analyticsStore = useAnalyticsStore();
        let result = await analytics.getProcessSummary(input);

        this.costMonthly = result.data;
        if (result.data.status == 500) {
          this.showError = true;
          this.message =
            "Number of working days should be greater than 0, Please select working date range";
          setTimeout(() => {
            this.message = "";
            this.showSuccess = false;
            this.showError = false;
          }, 5000);
        }
      }

      this.processes = [];
      //   this.processes = this.costMonthly.summary;

      var arr1 = Object.assign([], this.costMonthly.summary);
      arr1.unshift({ processId: 0, name: "All Processes" });
      this.processes = arr1;
      const uniqueCategories = [
        ...new Set(this.costMonthly.summary?.map((item) => item.categoryId)),
      ];

      let uniqueValuesArray = uniqueCategories.map((categoryId) => {
        const foundObject = this.costMonthly.summary.find(
          (item) => item.categoryId === categoryId
        );
        return { categoryId, categoryName: foundObject.categoryName };
      });
      var arr = Object.assign([], uniqueValuesArray);
      arr.unshift({ categoryId: 0, categoryName: "All Categories" });
      this.categories = arr;

      this.loading = false;
      this.totalManualCost = 0;
      this.totalManualCostAfrAuto = 0;
      this.totalManualHrAfrAuto = 0;
      this.totalAutomationCost = 0;
      this.formatedautohr = 0;
      this.totalAutomationHr = 0;
      this.formatedautohr = 0;
      this.formatedstaffhr = 0;
      this.series.splice(0);
      this.seriesHr.splice(0);
      this.chartDataseriesHr.datasets[0].data = [];
      this.chartDataseries.datasets[0].data = [];
      let totalManualCost = 0;
      //   let totalManualCostAfrAuto = 0;
      let totalManualHrAfrAuto = 0;
      let totalManualHrAfrAutoaa = 0;
      let totalManualHrAfrAutoaahr = 0;
      let totalAutomationCost = 0;
      let totalManualHr = 0;
      let totalAutomationHr = 0;
      let dur = "";
      let durcat = "";
      if (
        this.filter.duration == "Today" ||
        this.filter.duration == "Yesterday" ||
        this.filter.duration == "Tomorrow"
      ) {
        dur = "totDayAmt";
        durcat = "totManualDayCost";
      } else if (
        this.filter.duration == "LastWeek" ||
        this.filter.duration == "NextWeek" ||
        this.filter.duration == "ThisWeek"
      ) {
        dur = "totWeekAmt";
        durcat = "totManualWeekCost";
      } else if (
        this.filter.duration == "LastMonth" ||
        this.filter.duration == "NextMonth" ||
        this.filter.duration == "ThisMonth"
      ) {
        dur = "totMonthAmt";
        durcat = "totManualMonthCost";
      } else if (
        this.filter.duration == "ThisYear" ||
        this.filter.duration == "NextYear" ||
        this.filter.duration == "LastYear"
      ) {
        dur = "totYearlyAmt";
        durcat = "totManualYearCost";
      } else if (this.filter.duration == "Custom") {
        dur = "customAmt";
        durcat = "totManualDayCost";
      }
      let summary = [];
      let processsummary = [];
      if (this.filter.category != 0) {
        if (this.filter.processId != 0) {
          const arr = this.costMonthly.summary.filter(
            (t) => t.processId === this.filter.processId
          );
          summary = arr;
          const arr1 = this.process[0].processCostList.filter(
            (t) => t.processId === this.filter.processId
          );
          processsummary = arr1;
          this.noOfProcess = 1;
        } else {
          const arr = this.costMonthly.summary.filter(
            (t) => t.categoryId === this.filter.category
          );
          summary = arr;
          const arr1 = this.process[0].processCostList.filter(
            (t) => t.categoryId === this.filter.category
          );
          processsummary = arr1;
          this.noOfProcess = arr1.length;
        }
      } else {
        if (this.filter.processId != 0) {
          const arr = this.costMonthly.summary.filter(
            (t) => t.processId === this.filter.processId
          );
          summary = arr;
          const arr1 = this.process[0].processCostList.filter(
            (t) => t.processId === this.filter.processId
          );
          processsummary = arr1;
          this.noOfProcess = 1;
        } else {
          summary = this.costMonthly.summary;
          processsummary = this.process[0].processCostList;
          this.noOfProcess = summary?.length;
        }
      }

      let noOfAutomationRuns = 0;

      this.costMonthly.categoryWiseData?.forEach((item) => {
        if (item.totAutomationCost > 0) {
          let hours1 = (item.totManualTimeDuration / (1000 * 60 * 60)) % 24;

          let days1 = parseInt(
            item.totManualTimeDuration / (1000 * 60 * 60 * 24)
          );

          let hoursDays1 = parseInt(days1 * 24);

          hours1 += hoursDays1;

          let amt = item[durcat] / hours1;
          let hours = (item.totUserSpentTimeDuration / (1000 * 60 * 60)) % 24;

          let days = parseInt(
            item.totUserSpentTimeDuration / (1000 * 60 * 60 * 24)
          );

          let hoursDays = parseInt(days * 24);

          hours += hoursDays;

          let val = hours * amt;
          this.seriescatcost[0].data.push(
            item[durcat] - (item.totAutomationCost + val)
          );
          this.seriescatcostnew.datasets[0].data.push(
            item[durcat] - (item.totAutomationCost + val)
          );
        } else {
          this.seriescatcostnew.datasets[0].data.push(0);
        }
        if (item.totManualTimeDuration > 0) {
          this.seriescathr[0].data.push(
            item.totManualTimeDuration - item.totUserSpentTimeDuration
          );
          this.seriescathrnew.datasets[0].data.push(
            item.totManualTimeDuration - item.totUserSpentTimeDuration
          );
        } else {
          this.seriescathr[0].data.push(0);
          this.seriescathrnew.datasets[0].data.push(0);
        }

        this.chartOptionscatcost.xaxis.categories.push(item.categoryName);
        this.chartOptionscathr.xaxis.categories.push(item.categoryName);
        this.seriescathrnew.labels.push(item.categoryName);
        this.seriescatcostnew.labels.push(item.categoryName);
      });

      let x = 0;
      summary?.forEach((item) => {
        x = 0;
        totalManualCost = totalManualCost + item.manualCostOb[dur];
        totalManualHrAfrAuto =
          totalManualHrAfrAuto + Number(item.userSpentTimeInMinutes);
        totalAutomationCost =
          totalAutomationCost + item.automationCostOb.totalautomationCost;
        totalManualHr = totalManualHr + item.manualTimeOb.totDuration;
        totalAutomationHr =
          totalAutomationHr + item.automationTimeOb.totDuration;
        this.seriespr[0].data.push(item.manualCostOb[dur]);
        this.seriespr[1].data.push(item.automationCostOb.totalautomationCost);
        x =
          Number(item.userSpentTimeInMinutes / 60) *
          item.noOfAutomationRuns *
          Number(item.manualConfiguration.avgCost);
        totalManualHrAfrAutoaa =
          totalManualHrAfrAutoaa +
          Number(item.userSpentTimeInMinutes / 60) *
            item.noOfAutomationRuns *
            Number(item.manualConfiguration.avgCost);
        totalManualHrAfrAutoaahr =
          totalManualHrAfrAutoaahr +
          Number(item.userSpentTimeInMinutes) *
            60 *
            1000 *
            item.noOfAutomationRuns;
        this.seriespr[2].data.push(x);
        this.seriesprnew.datasets[0].data.push(item.manualCostOb[dur]);
        this.seriesprnew.datasets[1].data.push(
          item.automationCostOb.totalautomationCost
        );
        this.seriesprnew.datasets[2].data.push(x);

        this.chartOptionspr.xaxis.categories.push(item.name);
        this.seriesprhr[0].data.push(item.manualTimeOb.totDuration);
        this.seriesprhr[1].data.push(item.automationTimeOb.totDuration);
        this.seriesprhr[2].data.push(
          Number(item.userSpentTimeInMinutes) *
            60 *
            1000 *
            item.noOfAutomationRuns
        );
        this.seriesprhrnew.datasets[0].data.push(item.manualTimeOb.totDuration);
        this.seriesprhrnew.datasets[1].data.push(
          item.automationTimeOb.totDuration
        );
        this.seriesprhrnew.datasets[2].data.push(
          Number(item.userSpentTimeInMinutes) *
            60 *
            1000 *
            item.noOfAutomationRuns
        );
        this.chartOptionsprhr.xaxis.categories.push(item.name);
        this.seriesprhrnew.labels.push(item.name);
        this.seriesprnew.labels.push(item.name);
        noOfAutomationRuns = noOfAutomationRuns + item.noOfAutomationRuns;
      });

      if (summary?.length <= 2) {
        this.chartOptionspr.plotOptions.bar.columnWidth = "20%";
        this.chartOptionsprhr.plotOptions.bar.columnWidth = "10%";
        // this.chartOptionsprhrnew.plotOptions.bar.columnWidth = "10%";
      } else {
        this.chartOptionspr.plotOptions.bar.columnWidth = "100%";
        this.chartOptionsprhr.plotOptions.bar.columnWidth = "100%";
        //this.chartOptionsprhrnew.plotOptions.bar.columnWidth = "100%";
      }
      this.noOfAutomationRuns = noOfAutomationRuns;

      this.totalManualCost = totalManualCost.toFixed(2);
      this.totalAutomationCost = totalAutomationCost.toFixed(2);
      this.series.push(totalManualCost);
      this.series.push(Number(totalAutomationCost.toFixed(2)));
      this.totalManualHr = totalManualHr;
      this.totalAutomationHr = totalAutomationHr;
      this.seriesHr.push(this.totalManualHr);
      this.seriesHr.push(totalAutomationHr);
      this.chartDataseriesHr.datasets[0].data.push(this.totalManualHr);
      this.chartDataseriesHr.datasets[0].data.push(totalAutomationHr);
      this.chartDataseries.datasets[0].data.push(totalManualCost);
      this.chartDataseries.datasets[0].data.push(
        Number(totalAutomationCost.toFixed(2))
      );
      let runtype = 0;
      let autoRunType = 0;
      let noOfRuns = 0;
      let noOfRunsAuto = 0;
      //   let processduration = 0;
      let totaldurationcoststaff = 0;
      let totaldurationcostda = 0;
      //   let daduration = 0;
      processsummary.forEach((item) => {
        totaldurationcoststaff =
          totaldurationcoststaff + item.manualAverageCost;
        totaldurationcostda = totaldurationcostda + item.automationAvgCost;
        runtype = 0;
        autoRunType = 0;
        if (item.manualRunCountType == "Day") {
          runtype = item.manualNoRuns * item.manualPeopleCount;
        } else if (item.manualRunCountType == "Week") {
          if (
            this.filter.workingDaysType == true ||
            this.filter.workingDaysType == "true"
          ) {
            runtype = (item.manualNoRuns * item.manualPeopleCount) / 5;
          } else {
            runtype = (item.manualNoRuns * item.manualPeopleCount) / 7;
          }
        } else if (item.manualRunCountType == "Month") {
          if (
            this.filter.workingDaysType == true ||
            this.filter.workingDaysType == "true"
          ) {
            runtype = (item.manualNoRuns * item.manualPeopleCount) / 22;
          } else {
            runtype = (item.manualNoRuns * item.manualPeopleCount) / 30;
          }
        }
        noOfRuns += runtype;

        if (item.automationRunCountType == "Day") {
          autoRunType = item.automationNoRuns;
        } else if (item.automationRunCountType == "Week") {
          if (
            this.filter.workingDaysType == true ||
            this.filter.workingDaysType == "true"
          ) {
            autoRunType = item.automationNoRuns / 5;
          } else {
            autoRunType = item.automationNoRuns / 7;
          }
        } else if (item.automationRunCountType == "Month") {
          if (
            this.filter.workingDaysType == true ||
            this.filter.workingDaysType == "true"
          ) {
            autoRunType = item.automationNoRuns / 22;
          } else {
            autoRunType = item.automationNoRuns / 30;
          }
        }

        noOfRunsAuto += autoRunType;
      });

      if (
        this.filter.duration == "Today" ||
        this.filter.duration == "Yesterday" ||
        this.filter.duration == "Tomorrow"
      ) {
        this.noOfRuns = noOfRuns;
        this.noOfRunsAuto = noOfRunsAuto;
      } else if (
        this.filter.duration == "LastWeek" ||
        this.filter.duration == "NextWeek" ||
        this.filter.duration == "ThisWeek"
      ) {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns = noOfRuns * 5;
          this.noOfRunsAuto = noOfRunsAuto * 5;
        } else {
          this.noOfRuns = noOfRuns * 7;
          this.noOfRunsAuto = noOfRunsAuto * 7;
        }
      } else if (this.filter.duration == "LastMonth") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns *
            this.getWeekdaysInMonth(
              new Date().getMonth(),
              new Date().getFullYear()
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getWeekdaysInMonth(
              new Date().getMonth(),
              new Date().getFullYear()
            );
        } else {
          this.noOfRuns =
            noOfRuns *
            this.getDaysInMonth(
              new Date().getMonth(),
              new Date().getFullYear()
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getDaysInMonth(
              new Date().getMonth(),
              new Date().getFullYear()
            );
        }
      } else if (this.filter.duration == "NextMonth") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns *
            this.getWeekdaysInMonth(
              new Date().getMonth() + 2,
              new Date().getFullYear()
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getWeekdaysInMonth(
              new Date().getMonth() + 2,
              new Date().getFullYear()
            );
        } else {
          this.noOfRuns =
            noOfRuns *
            this.getDaysInMonth(
              new Date().getMonth() + 2,
              new Date().getFullYear()
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getDaysInMonth(
              new Date().getMonth() + 2,
              new Date().getFullYear()
            );
        }
      } else if (this.filter.duration == "ThisMonth") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns *
            this.getWeekdaysInMonth(
              new Date().getMonth() + 1,
              new Date().getFullYear()
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getWeekdaysInMonth(
              new Date().getMonth() + 1,
              new Date().getFullYear()
            );
        } else {
          this.noOfRuns =
            noOfRuns *
            this.getDaysInMonth(
              new Date().getMonth() + 1,
              new Date().getFullYear()
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getDaysInMonth(
              new Date().getMonth() + 1,
              new Date().getFullYear()
            );
        }
      } else if (this.filter.duration == "ThisYear") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns * this.calculateWorkingDays(new Date().getFullYear());

          this.noOfRunsAuto =
            noOfRunsAuto * this.calculateWorkingDays(new Date().getFullYear());
        } else {
          this.noOfRuns = noOfRuns * 366;
          this.noOfRunsAuto = noOfRunsAuto * 366;
        }
      } else if (this.filter.duration == "NextYear") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns * this.calculateWorkingDays(new Date().getFullYear() + 1);
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.calculateWorkingDays(new Date().getFullYear() + 1);
        } else {
          this.noOfRuns = noOfRuns * 365;
          this.noOfRunsAuto = noOfRunsAuto * 365;
        }
      } else if (this.filter.duration == "LastYear") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns * this.calculateWorkingDays(new Date().getFullYear() - 1);
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.calculateWorkingDays(new Date().getFullYear() - 1);
        } else {
          this.noOfRuns = noOfRuns * 365;
          this.noOfRunsAuto = noOfRunsAuto * 365;
        }
      } else if (this.filter.duration == "Custom") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          this.noOfRuns =
            noOfRuns *
            this.getWorkingDays(this.dateOb.fromdate, this.dateOb.todate, true);
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getWorkingDays(this.dateOb.fromdate, this.dateOb.todate, true);
        } else {
          this.noOfRuns =
            noOfRuns *
            this.getWorkingDays(
              this.dateOb.fromdate,
              this.dateOb.todate,
              false
            );
          this.noOfRunsAuto =
            noOfRunsAuto *
            this.getWorkingDays(
              this.dateOb.fromdate,
              this.dateOb.todate,
              false
            );
        }
      }

      if (this.dateOb.isFuture == true) {
        this.noOfRunsAll = this.noOfAutomationRuns;
      } else {
        this.noOfRunsAll = this.noOfAutomationRuns;
      }
      this.getmanual(processsummary, this.noOfRunsAuto);
      //   let totalManualHrAfrAuto1 =
      //     (totalManualHrAfrAuto / 60) * this.noOfRunsAll;

      this.totalManualHrAfrAuto = this.diff(totalManualHrAfrAutoaahr);

      this.formatedautohr = this.diff(totalAutomationHr);

      this.formatedstaffhr = this.diff(totalManualHr);

      let hr = totalManualHrAfrAutoaahr;
      if (totalManualHr < hr) {
        this.totalhrs = this.diff(0);
      } else {
        this.totalhrs = this.diff(totalManualHr - hr);
      }

      if (totalAutomationHr == 0 || this.noOfRunsAll == 0) {
        this.avgdatime = 0;
      } else {
        this.avgdatime = this.diff(totalAutomationHr / this.noOfRunsAll);
      }
      if (totalManualHr == 0 || this.noOfRunsAll == 0) {
        this.avgstafftime = 0;
      } else {
        this.avgstafftime = this.diff(totalManualHr / this.noOfRunsAll);
      }

      let hours = (totalManualHr / (1000 * 60 * 60)) % 24;

      let days = parseInt(totalManualHr / (1000 * 60 * 60 * 24));

      let hoursDays = parseInt(days * 24);

      hours += hoursDays;

      if (hours <= 0) {
        this.avgcost = 0;
      } else {
        this.avgcost = Number((totalManualCost / hours).toFixed(2));
      }

      if (totalAutomationHr <= 0) {
        this.avgcostda = 0;
      } else {
        let hours1 = (totalAutomationHr / (1000 * 60 * 60)) % 24;

        let days1 = parseInt(totalAutomationHr / (1000 * 60 * 60 * 24));

        let hoursDays1 = parseInt(days1 * 24);

        hours1 += hoursDays1;

        this.avgcostda = (totalAutomationCost / hours1).toFixed(2);
      }

      this.totalManualCostAfrAuto = (
        (totalManualHrAfrAutoaahr / (1000 * 60 * 60)) *
        this.avgcost
      ).toFixed(2);

      if (this.noOfRunsAll == 0) {
        this.avgstafftimeAfter = 0;
      } else {
        this.avgstafftimeAfter = this.diff(hr / this.noOfRunsAll);
      }

      this.avgstaffcostAfter = (
        this.totalManualCostAfrAuto /
        (totalManualHrAfrAutoaahr / (1000 * 60 * 60))
      ).toFixed(2);

      this.getRoi();
    },

    getmanual(process, noOfRunsAll) {
      this.formatedautohr1 = 0;
      this.totalAutomationHr1 = 0;
      this.formatedautohr1 = 0;
      this.formatedstaffhr1 = 0;

      let autoduration = 0;
      let autototaldurationcost = 0;
      let autoruntype = 0;

      //   let totalManualHr = 0;
      let totalAutomationHr = 0;

      process.forEach((item) => {
        autoduration = 0;
        autototaldurationcost = 0;
        autoruntype = 0;

        if (item.automationDurationType == "Hours") {
          autoduration = item.automationDuration;
        } else if (item.automationDurationType == "Days") {
          autoduration = item.automationDuration * 8;
        } else if (item.automationDurationType == "Minutes") {
          autoduration = item.automationDuration / 60;
        }
        autototaldurationcost = autoduration;
        if (item.automationRunCountType == "Day") {
          autoruntype = autototaldurationcost * item.automationNoRuns;
        } else if (item.automationRunCountType == "Week") {
          autoruntype = autototaldurationcost * (item.automationNoRuns / 5);
        } else if (item.automationRunCountType == "Month") {
          autoruntype = autototaldurationcost * (item.automationNoRuns / 12);
        }

        totalAutomationHr = totalAutomationHr + autoruntype;
      });
      let days;
      if (
        this.filter.duration == "Today" ||
        this.filter.duration == "Yesterday" ||
        this.filter.duration == "Tomorrow"
      ) {
        days = 1;
      } else if (
        this.filter.duration == "LastWeek" ||
        this.filter.duration == "NextWeek" ||
        this.filter.duration == "ThisWeek"
      ) {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = 5;
        } else {
          days = 7;
        }
      } else if (this.filter.duration == "LastMonth") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.getWeekdaysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
        } else {
          days = this.getDaysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
        }
      } else if (this.filter.duration == "NextMonth") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.getWeekdaysInMonth(
            new Date().getMonth() + 2,
            new Date().getFullYear()
          );
        } else {
          days = this.getDaysInMonth(
            new Date().getMonth() + 2,
            new Date().getFullYear()
          );
        }
      } else if (this.filter.duration == "ThisMonth") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.getWeekdaysInMonth(
            new Date().getMonth() + 1,
            new Date().getFullYear()
          );
        } else {
          days = this.getDaysInMonth(
            new Date().getMonth() + 1,
            new Date().getFullYear()
          );
        }
      } else if (this.filter.duration == "ThisYear") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.calculateWorkingDays(new Date().getFullYear());
        } else {
          days = 366;
        }
      } else if (this.filter.duration == "NextYear") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.calculateWorkingDays(new Date().getFullYear() + 1);
        } else {
          days = 365;
        }
      } else if (this.filter.duration == "LastYear") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.calculateWorkingDays(new Date().getFullYear() - 1);
        } else {
          days = 365;
        }
      } else if (this.filter.duration == "Custom") {
        if (
          this.filter.workingDaysType == true ||
          this.filter.workingDaysType == "true"
        ) {
          days = this.getWorkingDays(
            this.dateOb.fromdate,
            this.dateOb.todate,
            true
          );
        } else {
          days = this.getWorkingDays(
            this.dateOb.fromdate,
            this.dateOb.todate,
            false
          );
        }
      }
      totalAutomationHr = totalAutomationHr * days;

      if (totalAutomationHr == 0 || noOfRunsAll == 0) {
        this.estAutoTime = this.diff(0);
      } else {
        let val = (totalAutomationHr * 60 * 60 * 1000) / noOfRunsAll;

        this.estAutoTime = this.diff(val);
      }
    },
    getWorkingDays(startDate, endDate, isworkingday) {
      let workingDays = 0;
      // Iterate through each day between start and end dates
      for (
        let currentDate = new Date(startDate);
        currentDate <= new Date(endDate);
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        if (isworkingday) {
          // Check if the current day is not a weekend (Saturday or Sunday)
          if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            workingDays++;
          }
        } else {
          workingDays++;
        }
      }
      return workingDays;
    },
    getDaysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    getWeekdaysInMonth(month, year) {
      var days = this.daysInMonth(month, year);

      var weekdays = 0;
      for (var i = 0; i < days; i++) {
        if (this.isWeekday(year, month - 1, i + 1)) weekdays++;
      }
      return weekdays;
    },
    isWeekday(year, month, day) {
      var day1 = new Date(year, month, day).getDay();

      return day1 != 0 && day1 != 6;
    },
    diff(duration) {
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));
      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    // async download() {
    // //   const processStore = useProcessStore();
    //   this.costMonthly = [];
    //   let input = {
    //     OrgId: processStore.orgId,
    //     FilterType: this.filter.duration,
    //     OnlyWorkingDays: this.filter.workingDaysType,
    //   };
    //   const analyticsStore = useAnalyticsStore();
    //   let result = await analyticsStore.getExcelFile(input);

    //   const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
    //   const downloadLink = document.createElement("a");
    //   const fileName = `roi.xlsx`;

    //   downloadLink.href = linkSource;
    //   downloadLink.download = fileName;
    //   downloadLink.click();
    // },
    getDate1(arr) {
      this.errors = {};
      this.dateOb.fromdate = arr[0];
      this.dateOb.todate = arr[1];
      this.dateOb.daterange =
        this.dateOb.fromdate + " to " + this.dateOb.todate;
      this.dateOb.dateMenu = false;
    },

    clearDate1() {
      this.dateOb.fromdate = "";
      this.dateOb.todate = "";
      this.dateOb.daterange = "";
      this.dateOb.dateMenu = false;
    },
    changeDate() {
      if (this.dateOb.ispast == "true" || this.dateOb.ispast == true) {
        if (new Date(this.dateOb.todate).getTime() <= new Date().getTime()) {
          this.errors = {};
          this.actionModal = false;
          this.submit();
        } else {
          this.errors.date =
            "Please select a date range less than or equal to Today";
        }
      } else {
        if (new Date(this.dateOb.fromdate).getTime() > new Date().getTime()) {
          this.errors = {};
          this.actionModal = false;
          this.submit();
        } else {
          this.errors.date = "Please select a date range greater than Today";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/colors.scss";
.grad {
  background: rgb(230, 245, 244);
  background: linear-gradient(
    90deg,
    rgba(230, 245, 244, 1) 0%,
    rgba(230, 245, 244, 1) 100%
  );
}
.btnactive {
  background-color: #def7ec !important;
  color: #009688 !important;
}
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.emptyCart {
  padding: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.border {
  border: 1px solid #f3f3f4 !important;
  border-radius: 4px !important;
}
.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}
.cardbg {
  box-shadow: none !important;

  /* Grey/100 */
  background: rgb(230, 245, 244);
  background: linear-gradient(
    90deg,
    rgba(230, 245, 244, 1) 0%,
    rgba(230, 245, 244, 1) 100%
  );
  border: 1px solid #00897b !important;
  border-radius: 4px !important;
}
.success-title {
  font-size: 52px !important;
  font-weight: bold !important;
}
.card-bg {
  box-sizing: border-box;

  //   background: #e2e5f4;
  border: 0.5px solid #c6ccef;
  border-radius: 4px;
}
.dashtitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  color: #17181d;
}
.bg {
  background: #303f9f 0% 0% no-repeat padding-box;
  opacity: 1;
}
.bg1 {
  background-color: #0e9f6e;
  opacity: 1;
}
.dashnum {
  font-weight: 700;
  font-size: 36px;
  line-height: 28px;
  font-style: bold;
  color: #17181d;
}
.dashboard-dt-title {
  font-size: 18px !important;
  font-weight: bold !important;
}
.bgcl {
  background-color: #009688;
  opacity: 50;
}
</style>
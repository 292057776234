<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-md-3">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-3 col-md-3 pl-0">
                <v-select
                  :items="getAList"
                  item-text="name"
                  item-value="id"
                  v-model="applicationName"
                  label="Select Allication"
                  :clearable="false"
                  @change="onChange"
                ></v-select>
              </div>
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn
                  dark
                  class="mt-lg-3 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Application</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :server-items-length="applicationList.total"
        :items-per-page="10"
        :items="applicationList.records"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
        class="elevation-0"
        :single-expand="true"
        show-expand
        @item-expanded="loadDetails"
      >
        <template #[`item.addedOn`]="{ item }">
          <td>{{ new Date(item.addedOn).toLocaleString() }}</td>
        </template>
        <!-- <template v-slot:[`item.status`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.status"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template> -->
        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-0 py-0" :colspan="headers.length">
            <div class="col-12 offset-lg-0 offset-xs-0 text-right">
              <v-btn
                dark
                class="ma-3"
                @click="
                  dialogTask = true;
                  editedTaskIndex = -1;
                  editedTask.appplicationId = item.id;
                "
              >
                <v-icon dark> mdi-plus </v-icon> Add TaskID</v-btn
              >
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
              <v-text-field
                v-model="searchtaskid"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headersChild"
              :items="item.taskIdList"
              item-key="id"
              :search="searchtaskid"
              class="elevation-1 grey lighten-3"
            >
              <template #[`item.addedOn`]="{ item }">
                <td>{{ new Date(item.addedOn).toLocaleString() }}</td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="editTaskItem(item, appId)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="remove(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                class="mr-3"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!---application update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedItem.name"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => (v && !!v.trim()) || 'Name cannot be blank',
              ]"
            >
              <template #label>
                Name <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="editedItem.applicationCode"
              required
              maxlength="20"
              :rules="[
                (v) => !!v || 'Application Code is required',
                (v) => (v && !!v.trim()) || 'Application Code cannot be blank',
              ]"
            >
              <template #label>
                Application Code
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-textarea
              v-model="editedItem.description"
              required
              maxlength="200"
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Application?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTask" persistent max-width="500px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitleTask }}</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedTask.taskId"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Task ID is required',
                (v) => (v && !!v.trim()) || 'Task ID cannot be blank',
                (v) =>
                  (v &&
                    !task.find(
                      (t) =>
                        editedTask.id != t.id &&
                        t.taskId.toLowerCase().trim() == v.toLowerCase().trim()
                    )) ||
                  'Task ID already exists',
              ]"
            >
              <template #label>
                Task ID <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="editedTask.description"
              required
              maxlength="200"
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
                (v) =>
                  (v || '').length <= 200 ||
                  'Description must be 200 characters or less',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-select
              :items="idCategory"
              item-text="name"
              item-value="value"
              v-model="editedTask.category"
              required
              :rules="[(v) => !!v || 'Category is required']"
              ><template #label>
                Category <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-textarea v-model="editedTask.comments" maxlength="200">
              <template #label> Comment </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeDialogTask"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdateTask"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteTask" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title
            >Are you sure you want to delete this TaskID?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="dialogDeleteTask = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirmTask"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import appTaskService from "@/services/applicationTask.service.js";
export default {
  created() {
    // this.getApplicationList();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Application" : "Edit Application";
    },
    formTitleTask() {
      return this.editedIndex === -1 ? "New TaskID" : "Edit TaskID";
    },
    getAList() {
      var arr = Object.assign([], this.allApp);
      arr.unshift({ id: "All", name: "All" });

      return arr;
    },
  },
  data: () => ({
    searchtaskid: "",
    allApp: [],
    applicationName: "All",
    appId: "",
    task: "",
    applicationList: {},
    loading: false,
    dialogDelete: false,
    dialogDeleteTask: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    dialogTask: false,
    idCategory: [
      { name: "Task", value: "Task" },
      { name: "Exception", value: "Exception" },
    ],
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
        width: "200px",
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Application Code", value: "applicationCode", sortable: false },
      { text: "Added By", value: "addedBy", sortable: false },
      { text: "Added On", value: "addedOn", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
      { text: "", value: "data-table-expand" },
    ],
    headersChild: [
      {
        text: "Task Id",
        align: "start",
        sortable: false,
        value: "taskId",
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
      },
      {
        text: "Category",
        value: "category",
        sortable: false,
        filterable: false,
      },
      {
        text: "Added By",
        value: "addedBy",
        sortable: false,
        filterable: false,
      },
      {
        text: "Added On",
        value: "addedOn",
        sortable: true,
        filterable: false,
      },
      {
        text: "Comments",
        value: "comments",
        sortable: false,
        filterable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "130px",
        filterable: false,
      },
    ],

    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    searchKey: "",
    editedItem: {
      id: 0,
      name: "",
      description: "",
      applicationCode: "",
    },
    isApplicationDelete: true,
    isTaskDelete: false,
    editedTaskIndex: -1,

    editedTask: {
      id: 0,
      taskId: "",
      category: "",
      description: "",
      comments: "",
      appplicationId: "",
    },
    defaultTask: {
      id: 0,
      taskId: "",
      category: "",
      description: "",
      comments: "",
      appplicationId: "",
    },
  }),

  methods: {
    async onChange() {
      this.pageIndex = 0;
      this.loading = true;
      let result = await appTaskService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
        ApplicationName: this.applicationName,
      });
      this.loading = false;
      this.applicationList = result;
    },
    editItem(item) {
      this.editedIndex = this.applicationList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    resetStatus() {
      this.applicationList.records[this.editedIndex].status =
        this.applicationList.records[this.editedIndex].status == true
          ? false
          : true;
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.applicationList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.applicationList.records[this.editedIndex].id;
      this.editedItem.id = id;

      this.delete({ id: id, isApplicationDelete: this.isApplicationDelete });
      this.dialogDelete = false;
    },

    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await appTaskService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
        ApplicationName: this.applicationName,
      });
      this.loading = false;
      this.applicationList = result;
      var valObj = this.applicationList.records.filter(function (elem) {
        if (elem.id == this.appId) return elem;
      });
      if (valObj.length > 0) {
        this.task = valObj[0].taskIdList;
      }
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          this.create({
            name: this.editedItem.name,
            description: this.editedItem.description,
            applicationCode: this.editedItem.applicationCode,
          });
        } else {
          const id = this.applicationList.records[this.editedIndex].id;
          this.editedItem.id = id;
          this.update(
            {
              name: this.editedItem.name,
              description: this.editedItem.description,
              applicationCode: this.editedItem.applicationCode,
            },
            this.editedItem.id
          );
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await appTaskService.create(item);

      if (result.status == 200) {
        this.status = "Application created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await appTaskService.update(item, id);
      if (result.status == 200) {
        this.status = "Application updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await appTaskService.delete(item);
      if (result.status == 200) {
        this.status = "Application deleted successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await appTaskService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
        ApplicationName: this.applicationName,
      });
      this.loading = false;
      this.applicationList = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      let result = await appTaskService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
        ApplicationName: this.applicationName,
      });
      this.loading = false;
      this.applicationList = result;
    },
    closeDialogTask() {
      this.dialogTask = false;
      this.editedTaskIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },

    onUpdateTask() {
      let valid = this.$refs.form1.validate();

      if (valid) {
        if (this.editedTaskIndex == -1) {
          var list = [];

          list.push({
            taskId: this.editedTask.taskId,
            category: this.editedTask.category,
            description: this.editedTask.description,
            comments: this.editedTask.comments,
          });

          // console.log("list", list);
          this.createTask({ taskIdList: list }, this.appId);
        } else {
          const id = this.task[this.editedTaskIndex].id;

          this.editedTask.id = id;
          this.updateTask(
            {
              taskId: this.editedTask.taskId,
              category: this.editedTask.category,
              description: this.editedTask.description,
              comments: this.editedTask.comments,
            },
            id
          );
        }
        this.closeDialogTask();
      }
    },
    editTaskItem(item, id) {
      var valObj = this.applicationList.records.filter(function (elem) {
        if (elem.id == id) return elem;
      });
      if (valObj.length > 0) {
        this.task = valObj[0].taskIdList;
      }
      this.editedTaskIndex = this.task.indexOf(item);
      this.editedTask = Object.assign({}, item);

      this.dialogTask = true;
    },
    loadDetails({ item, value }) {
      if (value) {
        this.task = item.taskIdList;
        this.appId = item.id;
      }
    },
    async createTask(task, id) {
      let result = await appTaskService.createTask(task, id);

      if (result.status == 200) {
        if (result.data.length == 0) {
          this.status = "TaskID added successfully";
          this.editedTaskIndex = -1;
          this.$refs.form1.reset();
          this.$refs.form1.resetValidation();
          this.editedTask = Object.assign({}, this.defaultTask);
          setTimeout(() => {
            this.status = "";
          }, 2000);
          this.updatePagination({ page: this.pageIndex });
          // this.getAllNotes(this.noteid);
        } else {
          this.status = "TaskID already exist in some other Applications";

          this.editedTask = Object.assign({}, this.defaultTask);
          setTimeout(() => {
            this.status = "";
          }, 2000);
        }
      }
    },
    async updateTask(item, id) {
      let result = await appTaskService.updateTask(item, id);
      if (result.status == 200) {
        this.status = "TaskID updated successfully";

        this.editedTask = Object.assign({}, this.defaultTask);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
        // this.getAllNotes(this.noteid);
      }
    },
    async deleteTask(item) {
      let result = await appTaskService.delete(item);
      if (result.status == 200) {
        this.status = "TaskID deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
        // this.getAllTask(this.noteid);
      }
    },

    remove(item) {
      this.removeId = item.id;
      this.dialogDeleteTask = true;
    },
    deleteConfirmTask() {
      this.deleteTask({
        id: this.removeId,
        isApplicationDelete: this.isTaskDelete,
      });
      this.dialogDeleteTask = false;
    },
    async getApplicationList() {
      let result = await appTaskService.getAllApplication();

      this.allApp = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>

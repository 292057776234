var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('v-tabs',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"mb-3",attrs:{"background-color":"transparent","color":"primary","justify-center":""},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}},[_vm._l((_vm.tabs),function(item,index){return _c('v-tab',{key:item,attrs:{"href":'#tab-' + item},on:{"click":function($event){return _vm.tabsval(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),(_vm.more.length)?_c('v-menu',{staticClass:"ml-3",attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"align-self-center mr-4",attrs:{"text":""}},on),[_vm._v(" more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,false,3608274076)},[_c('v-list',{staticClass:"grey lighten-3"},_vm._l((_vm.more),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){return _vm.addItem(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1):_vm._e()],2),(_vm.selectedTab === 0)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-x-transition',[_c('div',[_c('v-form',{ref:"form1",attrs:{"lazy-validation":""},model:{value:(_vm.valid1),callback:function ($$v) {_vm.valid1=$$v},expression:"valid1"}},[_c('v-text-field',{attrs:{"label":"License Key-1","required":"","maxlength":"100","rules":[
                  (v) => !!v || 'License Key for Bots is required',
                  (v) => (v && !!v.trim()) || 'License Key cannot be blank',
                  (v) =>
                    (v &&
                      !_vm.botNames.find(
                        (t) =>
                          t.license.toLowerCase().trim() ==
                          v.toLowerCase().trim()
                      )) ||
                    'License Key already exists',
                ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" License Key - 1 "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,false,300541042),model:{value:(_vm.editedItem.license),callback:function ($$v) {_vm.$set(_vm.editedItem, "license", $$v)},expression:"editedItem.license"}}),_vm._l((_vm.textFields),function(textField,i){return _c('div',{key:i,staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":textField.label,"append-icon":'mdi-delete',"maxlength":"100","rules":[
                    (v) => !!v || 'License Key for Bots is required',
                    (v) => (v && !!v.trim()) || 'License Key cannot be blank',
                    (v) =>
                      (v &&
                        !_vm.botNames.find(
                          (t) =>
                            t.license.toLowerCase().trim() ==
                            v.toLowerCase().trim()
                        )) ||
                      'License Key already exists',
                  ]},on:{"click:append":function($event){return _vm.remove(i)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" License Key - "+_vm._s(i + 2)+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(textField.value),callback:function ($$v) {_vm.$set(textField, "value", $$v)},expression:"textField.value"}})],1)}),_c('v-btn',{staticClass:"grey lighten-2",on:{"click":_vm.add}},[_vm._v("Add")]),_c('v-select',{attrs:{"items":_vm.regOrganization,"item-text":"name","item-value":"id","required":"","rules":[(v) => !!v || 'Client is required']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Client "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,false,2446668315),model:{value:(_vm.editedItem.clientId),callback:function ($$v) {_vm.$set(_vm.editedItem, "clientId", $$v)},expression:"editedItem.clientId"}}),_c('v-radio-group',{attrs:{"rules":[(v) => !!v || 'Type is required']},on:{"change":_vm.serverTypeChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"v-label v-label--active"},[_vm._v("Orchestrator type"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])])]},proxy:true}],null,false,3132539375),model:{value:(_vm.editedItem.serverType),callback:function ($$v) {_vm.$set(_vm.editedItem, "serverType", $$v)},expression:"editedItem.serverType"}},[_c('v-radio',{attrs:{"label":"On - Premises","value":"On - Premises"}}),_c('v-radio',{attrs:{"label":"Cloud","value":"Cloud"}})],1),(_vm.editedItem.serverType == 'Cloud')?_c('v-text-field',{attrs:{"label":"Tenant Name","validate-on-blur":"","required":"","rules":[
                  (v) => !!v || 'Tenant Name is required',
                  (v) => (v && !!v.trim()) || 'Tenant Name cannot be blank',
                ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tenant Name "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,false,4076241089),model:{value:(_vm.editedItem.tenatName),callback:function ($$v) {_vm.$set(_vm.editedItem, "tenatName", $$v)},expression:"editedItem.tenatName"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Server Name","required":"","maxlength":"20"},model:{value:(_vm.editedItem.serverName),callback:function ($$v) {_vm.$set(_vm.editedItem, "serverName", $$v)},expression:"editedItem.serverName"}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"dark":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.uploadLicense}},[_vm._v(" Create License ")])],1)],2)],1)])],1)],1)],1):_vm._e(),(_vm.selectedTab === 1)?_c('div',[_c('ViewLicense')],1):_vm._e(),(_vm.selectedTab === 2)?_c('div',[_c('DeActivatedBot')],1):_vm._e(),(_vm.selectedTab === 3)?_c('div',[_c('AddExistingBot')],1):_vm._e(),(_vm.status)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
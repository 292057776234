<template>
  <div>
    <v-card>
      <!-- <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </v-card-title> -->
      <v-card-title class="tabtitle">
        <!-- Digital Staff -->
        <v-spacer></v-spacer>
        <v-text-field v-model="filter.searchKey" label="Search" single-line hide-details v-on:keyup.enter="search"
          append-icon="mdi-magnify" clear-icon="mdi-close-circle" clearable outlined dense @click:append="search"
          @click:clear="clear" class="col-md-4 col-12"></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="tabtitle2 text-capitalize mx-2" @click="refreshLiveState(), (loader = 'loading4')"
              v-bind="attrs" v-on="on">
              Refresh <v-icon small>mdi-refresh</v-icon>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table :headers="headers" :items="mergeddata" :items-per-page="10"
        :server-items-length="botClientListUsed.total" :loading="loading" @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }">
        <template v-slot:[`item.botImageURL`]="{ item }">
          <img v-if="item.botImageURL" :src="item.botImageURL" width="32px" />
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <td class="px-0">
            {{ new Date(item.createdOn).toDateString() }}
          </td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td v-if="item.name">{{ item.name }}</td>
          <td v-else class="red--text">Select Name for your Agent</td>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <!-- <v-chip v-if="item.state" small :color="getColor(item.state)" dark>
            {{ item.state }}
          </v-chip> -->
          <v-chip v-if="item.state" label small :color="getColor(item.state)" :text-color="getTextColor(item.state)"
            dark>
            {{ item.state }}
          </v-chip>
        </template>

        <template #[`item.elapse`]="{ item }">
          <td v-if="item.state == 'Busy'" :class="getColor1(item)">
            {{ item.elapse }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-switch @change="deleteItem(item)" v-model="item.status" :false-value="'delete'"
                  :true-value="'Inprogress'" inset></v-switch>
              </div>
            </template>
            <span>Deactivate Agent License</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-tooltip bottom v-if="item.name">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="botDetails(item)" v-bind="attrs" v-on="on">
                mdi-robot
              </v-icon>
            </template>
            <span>Agent details</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this Agent?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>

        <v-card-actions class="justify-end">
          <v-btn color="border" class="text-capitalize" @click="
  dialogDelete = false;
resetStatus();
          ">Cancel</v-btn>
          <v-btn dark color="red" class="text-capitalize" depressed @click="deleteConfirm">yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Agent Data --->
    <!-- <v-dialog
      v-model="dialogBotData"
      width="1400"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Agent Data</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLogs()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear indeterminate v-if="loadingbot"></v-progress-linear>
        <v-card-text>
          <div class="container" v-if="botData.data">
            <div class="row my-4">
              <v-card class="col-lg-6 col-sm-12 text-center">
                <div v-if="getImage == '' && loadingbot == false">
                  <v-img
                    :src="require('@/assets/no-data.jpg')"
                    height="200"
                    contains
                  ></v-img>
                  <h4 class="text-center">Sorry, no image found!</h4>
                </div>
                <div v-else>
                  <img :src="getImage" height="200" />
                  <h4 class="text-center">Image Loading...</h4>
                </div>
              </v-card>
              <div class="col-lg-6 col-sm-12">
                <v-list-item>
                  <v-list-item-content v-if="botData.data">
                    <v-list-item-title class="primary--text"
                      >Status</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <v-chip small :color="getColor(botData.data.state)" dark>
                        {{ botData.data.state }}
                      </v-chip></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content v-if="botData.data">
                    <v-list-item-title class="primary--text"
                      >Agent Name</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      selectedBot.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="botData.data">
                  <v-list-item-content v-if="botData.data.state == 'Busy'">
                    <v-list-item-title class="primary--text"
                      >Process Name</v-list-item-title
                    >
                    <v-list-item-subtitle v-if="botData.data.jobs.length > 0">{{
                      getRunningProcessName()
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
            <v-card v-if="botData.data">
              <v-data-table
                v-if="roboLogsData && botData.data.state == 'Busy'"
                dense
                :headers="headersLogs"
                :items="roboLogsData"
                item-key="name"
                :page.sync="page"
                :items-per-page="rows"
                hide-default-footer
                class="elevation-1 ttl"
                @page-count="pagelength = $event"
                :loading="loadingbotlog"
              >
                <template v-slot:top>
                  <v-toolbar flat color="primary ">
                    <v-toolbar-title class="white--text"
                      >Process Log</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="white"
                          @click="refresh()"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.timeStamp`]="{ item }">
                  <td>{{ new Date(item.timeStamp).toLocaleString() }}</td>
                </template></v-data-table
              >
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse justify-center">
          

          <div class="" v-if="roboLogsData.length > 0">
            <v-pagination
              v-model="page"
              :length="pagelength"
              :total-visible="7"
            ></v-pagination>
          </div>
          <div class="" v-if="roboLogsData.length > 0">
            <v-select
              v-model="rows"
              :items="rowsPerTable"
              item-text="name"
              item-value="value"
              menu-props="auto"
              hide-details
              label="Select"
              single-line
            ></v-select>
          </div>

        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-bottom-sheet v-model="dialogBotData" scrollable>
      <!-- <v-sheet class="d-flex flex-column align-end" height="800px">
        <div class="container"> -->
      <v-card outlined>
        <v-toolbar flat class="container pl-0">
          <v-toolbar-title class="toolbarclass">
            My Assistants
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLogs()" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="container pl-0"></v-divider>
        <p class="text-left textsubtitle container pl-0" v-if="botData.data">
          {{ selectedBot.name }}
          <v-chip small label :color="getColor(botData.data.state)" :text-color="getTextColor(botData.data.state)" dark>
            {{ botData.data.state }}
          </v-chip>
        </p>
        <div v-if="botData.data" class="container pl-0">
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3" size="125" tile v-if="getImage == '' && loadingbot == false">
              <v-img :src="require('@/assets/no-data.jpg')"></v-img>
            </v-avatar>
            <v-avatar class="ma-3" size="125" tile v-else>
              <v-img :src="getImage"></v-img>
            </v-avatar>

            <div>
              <v-subheader class="headtitle">Assistant Details</v-subheader>
              <div class="d-flex">
                <v-subheader>
                  <v-list-item-content>
                    <v-list-item-title class="ListItemClass2">Assistant Name</v-list-item-title>
                    <v-list-item-subtitle class="ListItemClass1">{{
                      selectedBot.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-subheader>
                <v-subheader v-if="botData.data.state == 'Busy'">
                  <v-list-item-content>
                    <v-list-item-title class="ListItemClass2">Workflow Name</v-list-item-title>
                    <v-list-item-subtitle class="ListItemClass1" v-if="botData.data.jobs.length > 0">{{
                      getRunningProcessName()
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-subheader>
              </div>
            </div>
          </div>
        </div>
        <v-card-text v-if="roboLogsData && botData.data" class="container pl-0">
          <v-card-title class="tabtitle1">
            Workflow Log
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="tabtitle2 text-capitalize" @click="refresh()" v-bind="attrs" v-on="on">
                  Refresh <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-card-title>
          <v-data-table v-if="roboLogsData && botData.data.state == 'Busy'" dense :headers="headersLogs"
            :items="roboLogsData" item-key="name" :loading="loadingbotlog" :page.sync="page" :items-per-page="rows"
            hide-default-footer class="elevation-1" @page-count="pagelength = $event">
            <template v-slot:[`item.timeStamp`]="{ item }">
              <td>{{ new Date(item.timeStamp).toLocaleString() }}</td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse justify-center">
          <!-- <v-spacer></v-spacer> -->

          <div class="" v-if="roboLogsData.length > 0">
            <v-pagination v-model="page" :length="pagelength" :total-visible="7"></v-pagination>
          </div>
          <div class="" v-if="roboLogsData.length > 0">
            <v-select v-model="rows" :items="rowsPerTable" item-text="name" item-value="value" menu-props="auto"
              hide-details label="Select" single-line></v-select>
          </div>
        </v-card-actions>
      </v-card>
      <!-- </div>
      </v-sheet> -->
    </v-bottom-sheet>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import clientBotService from "@/services/clientBot.service.js";
import EventBus from "../plugins/eventBus";
import uiPathService from "@/services/uiPath.service.js";
import authService from "@/services/auth.service.js";
import botImagesService from "@/services/botImages.service.js";
import leadService from "@/services/lead.service.js";
import data from "../../public/config/preferences.json";
export default {
  created() {
    this.getProcessList();
    EventBus.on("bot_status", () => {
      if (this.botClientListUsed.records) {
        if (this.botClientListUsed.records.length > 0) {
          if (
            this.botClientListUsed.records[0].folderId &&
            this.botClientListUsed.records[0].tenatName
          ) {
            this.getData();
          }
        }
      }
    });

    EventBus.on("select_changes", (x) => {
      this.status = x;
      this.updatePagination({ page: this.pageIndex });
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("process_killed", (x) => {
      this.killstatus = x;
      setTimeout(() => {
        this.getBotData({
          name: this.selectedBot.name,
          folderId: this.selectedBot.folderId,
          tenant: this.selectedBot.tenatName,
        });
      }, 6000);
    });

    EventBus.on("process_status", (x) => {
      if (
        this.killstatus == x &&
        this.botData.data.state.toLowerCase() == "free"
      ) {
        this.status = x;
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }

      this.refresh();
    });
    EventBus.on("machine_status", () => {
      this.mergeddata = this.botClientListUsed.records.map((subject) => {
        let t = this.machineStatus.data.find(
          (element) => element.name === subject.name
        );

        return { ...subject, ...t };
      });
    });
  },
  beforeDestroy() {
    //clearInterval(this.getting);
  },
  destroyed() {
    EventBus.off("machine_status");
    EventBus.off("bot_status");
    EventBus.off("process_status");
    //clearInterval(this.getting);
  },
  computed: {
    headers() {
      let headers = [
        { text: "", sortable: false, value: "botImageURL" },
        { text: "Agent Name", sortable: false, value: "name" },
        { text: "License", value: "license", sortable: false },
        { text: "Server Name", value: "serverName", sortable: false },
        // { text: "Agent Selected", value: "botSelectedOn", sortable: false },
        { text: "Created By", value: "createdBy", sortable: false },
        { text: "Created On", value: "createdOn", sortable: false },
        { text: "Status", value: "state", sortable: false },
        { text: "Elapsed Time", value: "elapse", sortable: false },
      ];
      if (this.userType == "supuser") {
        headers.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px",
        });
      }
      headers.push({
        text: "Details",
        value: "details",
        sortable: false,
        width: "50px",
      });

      return headers;
    },
    getImage() {
      if (this.botData.data) {
        if (!this.bot) {
          return "";
        } else if (this.bot.childrens.length > 0) {
          let typ = this.botData.data.state.toLowerCase();
          if (typ == "free") {
            typ = "happy";
          } else {
            typ = this.botData.data.state.toLowerCase();
          }
          var val = this.bot.childrens.find((t) => t.type === typ);

          if (val) {
            return val.imageURL;
          } else return "";
        } else {
          return "";
        }
      }
      return "";
    },
  },
  data: () => ({
    refreshTime: data.refreshTime,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    userType: authService.authData.userType,
    getting: "",
    filter: {
      searchKey: "",
      sortBy: "",
    },
    mergeddata: [],
    avgtime: "",
    status: "",
    editedIndex: -1,
    editedIndexb: -1,
    dialogBotData: false,
    dialogKill: false,
    jobId: "",
    selectedBot: {},
    editedItem: {
      id: 0,
      status: 1,
    },
    bot: {},
    botstate: "",
    allProcesses: [],
    editedItemProcess: {},
    loadingbotlog: false,
    dialogDelete: false,
    botClientListUsed: {},
    roboLogsData: [],
    machineStatus: {},
    machineloading: false,
    botClientList: {},
    regOrganization: [],
    botData: [],
    loading: false,
    loadingbot: false,

    headersLogs: [
      { text: "Time", sortable: false, value: "timeStamp" },
      { text: "Level", value: "level", sortable: false },
      { text: "Message", value: "message", sortable: false, width: "40%" },
    ],
    pageIndex: 0,
    rowsPerTable: [
      { name: "10", value: 10 },
      { name: "25", value: 25 },
      { name: "50", value: 50 },
      { name: "100", value: 100 },
      { name: "All", value: -1 },
    ],
    rows: 10,
    pagelength: 0,
    page: 1,
  }),
  methods: {
    refreshLiveState() {
      this.loading4 = true;
      if (this.botClientListUsed.records) {
        if (this.botClientListUsed.records.length > 0) {
          if (
            this.botClientListUsed.records[0].folderId &&
            this.botClientListUsed.records[0].tenatName
          ) {
            this.getData();
          } else {
            this.loading4 = false;
          }
        } else {
          this.loading4 = false;
        }
      } else {
        this.loading4 = false;
      }
    },
    getclr(item) {
      if (item.state == "Busy") {
        if (item.jobs.length > 0) {
          let runningJobs = item.jobs.find((c) => c.state === "Running");
          if (runningJobs) {
            if (this.avgtime) {
              let date1 = new Date();
              let date2 = "";
              date2 = new Date(runningJobs.startTime);

              const duration = date2 - date1;
              let sec = duration / 1000;
              if (sec >= this.avgtime) return "green--text";
              else return "orange--text";
            }
          }
        }
      } else return "";
    },
    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);

      this.botClientListUsed = result;

      if (this.botClientListUsed.records) {
        if (this.botClientListUsed.records.length > 0) {
          if (
            this.botClientListUsed.records[0].folderId &&
            this.botClientListUsed.records[0].tenatName
          ) {
            this.mergeddata = this.botClientListUsed.records;
            this.getData();
          } else {
            this.loading = false;
            this.mergeddata = this.botClientListUsed.records;
          }
        } else {
          this.loading = false;
          this.mergeddata = this.botClientListUsed.records;
        }
      } else {
        this.loading = false;
      }
    },
    updatePagination(pagination) {
      //clearInterval(this.getting);

      this.pageIndex = pagination.page || 0;
      this.getClientBot({
        skip: (pagination.page - 1) * 10,
        take: 10,
        status: "Inprogress",
        searchKey: this.filter.searchKey,
      });
    },
    getRunningProcessName() {
      if (this.botData.data.jobs.length > 0) {
        let runningJobs = this.botData.data.jobs.find(
          (c) => c.state === "Running"
        );
        if (runningJobs) {
          if (this.allProcesses) {
            if (this.allProcesses.length > 0) {
              let processName = this.allProcesses.find(
                (c) => c.releaseProcessName === runningJobs.releaseName
              );
              if (processName) {
                return processName.name;
              } else {
                return runningJobs.releaseName;
              }
            } else {
              return runningJobs.releaseName;
            }
          } else {
            return runningJobs.releaseName;
          }
        }
      }
      return "";
    },
    async getBotData(data) {
      this.loadingbot = true;
      let result = await uiPathService.getBotData(data);

      this.botData = result;
      this.loadingbot = false;
      let botstatus = "Killed Successfully";
      if (this.killstatus == botstatus && this.botData.data) {
        if (this.botData.data.state.toLowerCase() == "free") {
          this.status = botstatus;
          setTimeout(() => {
            this.status = "";
            this.killstatus = "";
          }, 2000);
        }
      }

      this.refresh();
    },
    async getRobotLogs(data) {
      this.loadingbotlog = true;
      let result = await uiPathService.getRobotLogs(data);
      this.loadingbotlog = false;
      this.roboLogsData = result.value.data;
      this.pagelength = Math.ceil(result.value.data.length / this.rows);
    },
    async getProcessData(data, startTime) {
      let result = await uiPathService.getProcessData(data);

      let date1 = new Date();
      let date2 = "";
      this.avgtime = result.data.averageTime;
      date2 = new Date(startTime).toISOString().slice(0, 10);

      this.diff(date2, date1);
    },
    diff(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },

    diff1(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      let sec = duration / 1000;
      if (sec >= this.avgtime) return "green--text";
      else return "orange--text";
    },
    clear() {
      //clearInterval(this.getting);

      this.filter.searchKey = "";
      let data = {
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
        status: "Inprogress",
      };
      this.getClientBot(data);
    },
    search() {
      //clearInterval(this.getting);

      let data = {
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
        status: "Inprogress",
      };
      this.getClientBot(data);
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.mergeddata.indexOf(item);
      this.dialogDelete = true;
    },
    resetStatus() {
      this.mergeddata[this.editedIndex].status =
        this.mergeddata[this.editedIndex].status == "Inprogress"
          ? "delete"
          : "Inprogress";
    },
    deleteConfirm() {
      const id = this.mergeddata[this.editedIndex].id;
      this.editedItem.id = id;
      this.deleteLicense({
        clientBotId: this.editedItem.id,
        status: "Delete",
      });

      this.dialogDelete = false;
    },
    async deleteLicense(item) {
      let result = await clientBotService.selectBotStatus(item);

      if (result.status == 200) {
        this.status = "Agent deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        let data = {
          Skip: 0,
          Take: 10,
          status: "Inprogress",
          searchKey: this.filter.searchKey,
        };
        this.getClientBot(data);
      }
    },
    botDetails(item) {
      this.botData = [];
      if (item.folderId) {
        this.getBotData({
          name: item.name,
          folderId: item.folderId,
          tenant: item.tenatName,
        });
        this.dialogBotData = true;
        this.selectedBot = item;
        this.getById(item.botImageId);
      } else {
        this.status = "Please enter folder ID";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    async getById(input) {
      let result = await botImagesService.getById(input);
      this.bot = result[0];
    },
    getElapsedTime(item) {
      if (item.state == "Busy") {
        if (item.jobs.length > 0) {
          let runningJobs = item.jobs.find((c) => c.state === "Running");
          if (runningJobs) {
            if (!this.avgtime) {
              this.getProcessData(
                {
                  name: runningJobs.releaseName,
                  folderId: this.botClientListUsed.records[0].folderId,
                  tenant: this.botClientListUsed.records[0].tenatName,
                },
                runningJobs.startTime
              );
            } else {
              let date1 = new Date();
              let date2 = "";
              date2 = new Date(runningJobs.startTime);

              this.diff(date2, date1);
            }
          }
        }
      }
    },

    getData() {
      // this.getting = setInterval(() => {
      let data = {
        folderId: this.botClientListUsed.records[0].folderId,
        tenant: this.botClientListUsed.records[0].tenatName,
      };
      this.getMachineStatus(data);
      if (this.botData && this.dialogBotData == true) {
        this.getBotData({
          name: this.selectedBot.name,
          folderId: this.selectedBot.folderId,
          tenant: this.selectedBot.tenatName,
        });
      }
      // }, this.refreshTime);
    },
    async getMachineStatus(data) {
      let result = await uiPathService.getMachineStatus(data);

      this.machineStatus = result;

      this.mergeddata = this.mergeddata.map((subject) => {
        let t = this.machineStatus.data.find(
          (element) => element.name === subject.name
        );

        return { ...subject, ...t };
      });
      this.loading = false;
      let j = 0;
      for (j = 0; j < this.mergeddata.length; j++) {
        if (this.mergeddata[j].state == "Busy") {
          if (this.mergeddata[j].jobs.length > 0) {
            let runningJobs = this.mergeddata[j].jobs.find(
              (c) => c.state === "Running"
            );

            if (runningJobs) {
              this.getProcessLiveData(
                {
                  name: runningJobs.releaseName,
                  folderId: this.botClientListUsed.records[0].folderId,
                  tenant: this.botClientListUsed.records[0].tenatName,
                },
                this.mergeddata[j]
              );
            }
          }
        }
      }
    },
    async getProcessLiveData(data, item) {
      let result = await uiPathService.getProcessData(data);
      this.editedIndexb = this.mergeddata.indexOf(item);
      this.editedItemProcess = Object.assign({}, item);
      if (result.data.jobs.length > 0) {
        let runningJobs = result.data.jobs.find((c) => c.state === "Running");
        if (runningJobs) {
          let date1 = new Date();
          let date2 = "";
          date2 = new Date(runningJobs.startTime);

          const duration = date1 - date2;
          var seconds = parseInt((duration / 1000) % 60),
            minutes = parseInt((duration / (1000 * 60)) % 60),
            hours = parseInt((duration / (1000 * 60 * 60)) % 24),
            days = parseInt(duration / (1000 * 60 * 60 * 24));

          var hoursDays = parseInt(days * 24);
          hours += hoursDays;
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.editedItemProcess.elapse = hours + ":" + minutes + ":" + seconds;
          this.editedItemProcess.seconds = duration / 1000;
          this.editedItemProcess.live_status = "Running";
          this.editedItemProcess.runningJobs = runningJobs;
        } else {
          this.editedItemProcess.live_status = "Free";
        }
      } else {
        this.editedItemProcess.live_status = "Free";
      }
      this.editedItemProcess.avgTime = result.data.averageTime;
      Object.assign(this.mergeddata[this.editedIndexb], this.editedItemProcess);
      var bar = Array.from(this.mergeddata);
      this.mergeddata = [];
      this.mergeddata = bar;
    },
    refresh() {
      if (this.botData.data.state == "Busy") {
        if (this.botData.data.jobs.length > 0) {
          let runningJobs = this.botData.data.jobs.find(
            (c) => c.state === "Running"
          );
          if (runningJobs) {
            this.getRobotLogs({
              processName: runningJobs.releaseName,
              folderId: this.selectedBot.folderId,
              tenant: this.selectedBot.tenatName,
            });
          }
        }
      }
    },
    getColor1(item) {
      if (item.avgTime >= item.elapse) return "green--text";
      else return "red--text";
    },
    // getColor(status) {
    //   if (status == "Busy") return "green";
    //   else if (status == "Free") return "orange";
    //   else return "orange";
    // },

    getColor(status) {
      if (status == "Busy") return "#FDEDE8";
      else if (status == "Free") return "#E6F2F1";
      else return "#E6F2F1";
    },
    getTextColor(status) {
      if (status == "Busy") return "#E64A19";
      else if (status == "Free") return "#00796B";
      else return "#00796B";
    },
    closeLogs() {
      this.botData = [];
      this.dialogBotData = false;
    },
    async getProcessList() {
      let result = await leadService.getAllProcess();

      this.allProcesses = result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";

.box-list {
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}

.active {
  border-color: #1a8ccf;
  border-width: 5px;
}

/deep/.ttl.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}

/deep/.ttl.v-data-table .v-data-table-header tr th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}

.tabtitle1 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.25px !important;
  /* Primary/Primary - Indigo */
  color: $primary !important;
}

.tabtitle2 {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* identical to box height */
  /* Primary/Teal */
  color: $primary !important;
}

.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}

.ListItemClass1 {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* identical to box height */
  text-transform: capitalize !important;
  /* Secondary/500 */
  color: $secondary !important;
  /* Grey/600 */
}

.marketplace {
  bottom: 10;
}

.ListItemClass2 {
  font-weight: 500;
  font-size: 10px !important;
  line-height: 12px !important;
  /* identical to box height */
  text-transform: uppercase;
  /* Grey/600 */
  color: $header !important;
}

.headtitle {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.1px;
  /* TextField/Text */
  color: $secondary !important;
}

::v-deep .v-toolbar__content {
  padding: 4px 0px !important;
}
</style>
<template>
  <div class="container" >

    <v-card>
     
      <v-card-title>
       
        <div class="row">
          <div class="col-12">
            <div class="row">
             <div class="col-lg-4 col-sm-6 col-md-4 col-12">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
       :items="logEmailActivity.records"
        :server-items-length="logEmailActivity.total"
        @update:options="updatePagination"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.emailIds`]="{ item }">
          <li v-for="(item1, i) in emaillist(item.emailIds)" :key="i">
            {{ item1 }}
          </li>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td v-if="item.status == 'Success'" class="green--text">
            {{ item.status }}
          </td>
          <td v-else class="red--text">{{ item.status }}</td>
        </template>
      
      </v-data-table>
    </v-card>
  </div>
</template>
<script>

import emailService from "@/services/email.service.js";

export default {
  created() {
  

  },
  computed: {

   
  },
  data: () => ({
     logEmailActivity:{},
   
    pageIndex: 0,
    filter: {
      searchKey: "",
    },
    loading:false,
    headers: [
      {
        text: "Email Id",
        align: "start",
        sortable: false,
        value: "emailIds",
        width: "25%",
      },
      {
        text: "Created On",
        sortable: false,
        value: "createdOn"
     
      },
      { text: "Subject",sortable: false, value: "subject" 
     },

    
      {
        text: "Status",
        value: "status",
        sortable: false,
     
      },
      {
        text: "Reason",
        value: "error",
        width: "20%",
        sortable: false,
     
      },
    ],
  }),
  methods: {
   
    emaillist(email) {
      var nameArr = email.split(",");
      return nameArr;
    },
     async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading=true;
    let result =   await emailService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
       this.loading=false;
      this.logEmailActivity = result;
    },
    async search() {
       this.loading=true;
      let result =   await emailService.getAll({
        skip: 0,
        take: 10,
        
        searchKey: this.filter.searchKey
      });
       this.loading=false;
      this.logEmailActivity = result;
    },
    async clear() {
       this.loading=true;
      this.filter.searchKey = "";
      let result =   await emailService.getAll({
        skip: 0,
        take: 10,
        
        searchKey: this.filter.searchKey,
      });
       this.loading=false;
      this.logEmailActivity = result;
    },
  },
};
</script>

<template>
  <div class="container mt-5">
    <v-row>
      <v-col cols="12" md="4">
        <div
          class="sideTabs"
          :class="isShow == 1 ? 'custom-black' : 'custom-white'"
          @click="showtab(1)"
        >
          <div class="pa-4">
            <p class="accsetting mb-2">Account Settings</p>
            <p class="desc mb-0">Personal Information and others</p>
          </div>
        </div>
      </v-col>
      <v-divider
        class="d-lg-block d-md-block d-xl-block d-sm-none d-xs-none"
        vertical
      ></v-divider>

      <v-col cols="12" md="8">
        <v-divider
          class="mb-4 d-lg-none d-md-none d-xl-none d-sm-block d-xs-block"
        ></v-divider>
        <div class="d-flex flex-column mt-0">
          <div>
            <p class="personal pa-4">Personal Informations</p>
          </div>
          <v-divider></v-divider>
          <div v-if="isShow == 1">
            <div v-if="isreset" class="pa-4">
              <reset-password></reset-password>
            </div>
            <div v-else>
              <v-list>
                <v-list-item class="pa-4 pb-2">
                  <!-- <v-list-item-avatar size="50">
                    <v-icon class="grey lighten-2" dark> mdi-account </v-icon>
                  </v-list-item-avatar> -->

                  <v-list-item-content>
                    <p class="subtitle">Full Name</p>
                    <p class="maintitle">
                      {{ user.firstName }} {{ user.lastName }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pa-4 pb-2">
                  <!-- <v-list-item-avatar size="50">
                    <v-icon class="grey lighten-2" dark> mdi-email </v-icon>
                  </v-list-item-avatar> -->
                  <v-list-item-content>
                    <p class="subtitle">Email Id</p>

                    <p class="maintitle">{{ user.emailId }}</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item class="pa-4 pb-2">
                  <!-- <v-list-item-avatar size="50">
                    <v-icon class="grey lighten-2" dark> mdi-phone </v-icon>
                  </v-list-item-avatar> -->
                  <v-list-item-content>
                    <p class="subtitle">Contact</p>

                    <p class="maintitle">{{ user.mobileNo }}</p>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item class="pa-4 pb-2">
                  <v-list-item-content>
                    <p class="subtitle">Organisation</p>

                    <p class="maintitle">{{ user.name }}</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
            </div>
            <v-btn
              v-if="!isreset && userType == 'supuser'"
              class="ma-4 text-left text-capitalize mt-8"
              large
              depressed
              outlined
              color="primary"
              @click="UpdateLogo()"
            >
              Update Logo
            </v-btn>
            <v-btn
              v-if="isreset"
              class="ma-4 mt-8 text-capitalize"
              large
              color="primary"
              depressed
              outlined
              @click="resetpass(0)"
            >
              Profile
              <!-- <v-avatar right size="18" class="ml-2 text-right">
                <img src="../assets/arrowblack.svg" />
              </v-avatar> -->
            </v-btn>
            <v-btn
              v-else
              color="primary"
              class="ma-4 mt-8 text-left text-capitalize"
              large
              depressed
              outlined
              @click="resetpass(1)"
            >
              Change Password
            </v-btn>
            <div class="pa-4">
              <v-card dark color="black " class="mt-4 mb-2">
                <v-row>
                  <v-col cols="12" md="8" sm="8" xs="6">
                    <div class="ma-2 ml-4">
                      <h4>Delete my marketplace account</h4>
                      <small
                        >If you delete your account, you will lose data and
                        order history</small
                      >
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                    xs="6"
                    class="d-flex justify-end align-center text-right"
                  >
                    <v-btn
                      dark
                      color="grey darken-3"
                      class="mr-4 text-capitalize"
                      @click="deleteUser"
                      depressed
                    >
                      Delete
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete your Account?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="border"
            class="text-capitalize"
            depressed
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialoglogo" persistent max-width="600px">
      <v-card height="auto">
        <v-card-title class="justify-left">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-left pl-0">
              Upload Logo
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialoglogo" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
        </v-card-title>
        <v-card-subtitle>
          <small class="mr-2">Upload 40X40 square Logo</small>
        </v-card-subtitle>
        <v-divider></v-divider>
        <!-- <v-card-title>
          <span class="text-h6">Upload Logo</span>
        </v-card-title> -->

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-file-input
              v-model="editedItem.image"
              accept="image/*"
              required
              label="Image"
              ref="fileupload"
              :rules="[(v) => !!v || 'Image is required']"
            >
              <template #label>
                Company Logo <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="border"
              class="text-capitalize"
              depressed
              @click="closeDialoglogo"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              class="text-capitalize"
              depressed
              @click="onUpdatelogo"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script >
import ResetPassword from "../components/ResetPassword.vue";
import EventBus from "../plugins/eventBus";
import orgService from "@/services/organization.service.js";
import userService from "@/services/user.service.js";
import authService from "@/services/auth.service.js";
import CryptoJS from "crypto-js";
export default {
  components: {
    ResetPassword,
  },
  created() {
    this.getUser();
  },
  data: () => ({
    status: "",
    isShow: 1,
    isreset: false,
    dialoglogo: false,
    dialogDelete: false,
    valid: false,

    editedItem: {
      id: null,
      image: null,
    },
    user: {},
    isLoggedIn: authService.isLoggedIn ? true : undefined,
    userType: authService.authData.userType,
    organizationId: authService.authData.organizationId,

    logo: authService.authData ? authService.authData.logoUrl : undefined,
  }),

  computed: {
    isMorePage() {
      return (this.pageIndex + 1) * this.pageSize < this.orderGrouplist.count;
    },
  },
  methods: {
    async getUser() {
      let result = await userService.getUser();

      this.user = result;
    },
    resetPassword() {
      this.$router.push("/reset-password");
    },
    deleteUser() {
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.toggleStatus({ userId: this.user.id, status: "Blocked" });
      this.dialogDelete = false;
    },
    async toggleStatus(input) {
      let result = await userService.changeStatus(input);
      if (result.status == 200) {
        this.status = "User Deactivated successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        authService.logout();
      }
    },
    UpdateLogo() {
      this.editedItem.id = this.organizationId;
      this.dialoglogo = true;
    },
    closeDialoglogo() {
      this.dialoglogo = false;
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$refs.fileupload.reset();
      }
    },
    onUpdatelogo() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let formData = new FormData();

        formData.append("LogoFile", this.editedItem.image);

        this.updateLogo(formData, this.editedItem.id);

        this.closeDialoglogo();
      }
    },
    async updateLogo(item, id) {
      let result = await orgService.updateLogo(item, id);
      if (result.status == 200) {
        this.status = "Logo updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        let secret = process.env.VUE_APP_SECRETCODE;
        const secretData = localStorage.getItem("ppAuth");
        const decryptData = CryptoJS.AES.decrypt(secretData, secret).toString(
          CryptoJS.enc.Utf8
        );
        // let  result = JSON.parse(decryptData)
        const userData = JSON.parse(decryptData);
        userData.logoUrl = result.data;
        const data = CryptoJS.AES.encrypt(JSON.stringify(userData), secret);
        localStorage.setItem("ppAuth", data);
        EventBus.emit("logo_changes", "Updated Successfully");
      }
    },
    showtab(id) {
      if (id == 1) {
        this.isShow = 1;
        this.isreset = false;
      } else if (id == 2) {
        this.isShow = 2;
        this.isreset = false;
      } else if (id == 3) {
        this.isShow = 3;
        this.isreset = false;
      }
    },
    resetpass(id) {
      if (id == 1) {
        this.isreset = true;
      } else {
        this.isreset = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
.margins {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom-black {
  background-color: #f2f9f9 !important;
  color: $secondary !important;
  border-radius: 4px;
  cursor: pointer;
}
.custom-white {
  background-color: white !important;
  color: black !important;
  border-radius: 10px;
  cursor: pointer;
}
.box {
  margin-top: 10%;
}
.mt-30 {
  margin-top: 60px;
}
.title {
  padding-top: 30px;
  text-align: left;
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 10px !important;
  margin-bottom: 10px;
  opacity: 1;
}
.header {
  border-bottom: 1px solid #e4e7ea;
  width: 100%;
  float: left;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 5px;
  .w-50 {
    width: 50%;
    float: left;
  }
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -2px;
}

.v-list-item .v-list-item__title {
  line-height: 2;
}
/deep/.v-sheet.v-card,
.v-btn {
  border-radius: 8px;
}
.sideTabs {
  border-radius: 4px;
}
/deep/ .v-list-item__content {
  padding: 0px;
}
/deep/ td {
  padding: 10px;
}
.accsetting {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: $secondary;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #45464a;
}
.personal {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #009688;
}
.subtitle {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding-bottom: 8px;
  color: #5f6463 !important;
}
.maintitle {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
/deep/.v-toolbar__content {
  padding: 0px;
}
</style>

import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/ClientBot`, {
      params: input,
    });
    return result.data;

  },

  async getLicenseNames() {
    let result = await mainAxios.get(`/ClientBot/used/licenses`);
    return result.data;
  },
  async getClientBotNames() {
    let result = await mainAxios.get(`/ClientBot/used/names`);
    return result.data;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/ClientBot`, input, { params: { clientBotId: id } });
    return result;
  },
  async delete(input) {

    let result = await mainAxios.delete(`/ClientBot?clientBotId=${input.clientBotId}`);
    return result;
  },
  async createLicense(input) {
    let result = await mainAxios.post(`/ClientBot/licenses`, input);

    return result;
  },

  async addExistingBot(input, id) {
    let result = await mainAxios.post(`/ClientBot?organizationId=${id}`, input);

    return result;
  },
  async create(input) {
    let result = await mainAxios.post(`/ClientBot/order`, input);
    return result;
  },

  async selectBotImage(input) {
    let result = await mainAxios.put(`/ClientBot/picture`, null, { params: input });
    return result;
  },
  async selectBot(input) {
    let result = await mainAxios.put(`/ClientBot/name`, null, { params: input });
    return result;
  },
  async selectBotStatus(input) {
    let result = await mainAxios.put(`/ClientBot/status`, null, { params: input });
    return result;
  },
}
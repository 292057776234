import { mainAxios } from '../axios-instance';
export default {



  async getBotData(input) {
    let result = await mainAxios.get(`/UIpath/robot-status`, {
      params: input,
    });
    return result.data;

  },

  async getProcessData(input) {
    let result = await mainAxios.get(`/UIpath/process-status`, {
      params: input,
    });
    return result.data;

  },
  async getMachineStatus(input) {
    let result = await mainAxios.get(`/UIpath/machine-status`, {
      params: input,
    });
    return result.data;
  },

  async getRobotLogs(input) {
    let result = await mainAxios.get(`/UIpath/logs`, {
      params: input,
    });
    return result.data;
  },
  async getProcessQueue(input) {
    let result = await mainAxios.get(`/UIpath/queues`, {
      params: input,
    });
    return result.data;
  },
  async killBotProcess(input) {
    let result = await mainAxios.post(`/UIpath/process-kill`, null, { params: input });

    return result;
  },


  async getProcessHistory(input) {
    let result = await mainAxios.get(`/UIpath/uipath-logs`, {
      params: input,
    });
    return result.data;
  },
  async getProcessHistoryElastic(input) {
    let result = await mainAxios.get(`/UIpath/uipath-logs/process-history`, {
      params: input,
    });
    return result.data;
  },
  async getProcessHistoryElasticDashbaord(input) {
    let result = await mainAxios.get(`/UIpath/uipath-logs/process-history/dashboard`, {
      params: input,
    });
    return result.data;
  },
  async getProcessHistoryDetails(input) {
    let result = await mainAxios.get(`/UIpath/uipath-logs/process-history/dashboard/details`, {
      params: input,
    });
    return result.data;
  },
  async getTaskHistory(input) {
    let result = await mainAxios.get(`/UIpath/uipath-logs/task-history`, {
      params: input,
    });
    return result.data;
  },
  async getAlignAPIToken(input) {
    let result = await mainAxios.get(`/UIpath/uipath/client/token`, { params: input });
    return result;
  },
  async register(input) {
    let result = await mainAxios.post(`/UIpath/uipath/register`, input);

    return result;
  },

}
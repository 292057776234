<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-10 col-md-10 col-sm-8 col-12">
        <h4>Child Bots</h4>
      </div>
      <div class="col-lg-2 col-md-10 col-sm-4 col-12">
        <v-btn dark class="mt-lg-2 mt-md-2 mt-sm-2" @click="onAdd">
          Add New Emotion
        </v-btn>
      </div>
    </div>

    <div class="row mt-5 mb-2" v-if="bot">
      <div
        class="col-12 col-md-3 border text-center"
        v-for="item in bot.childrens"
        :key="item.id"
      >
        <v-img :src="item.imageURL" contain>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-3 float-right"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-3 float-right"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-img>

        <h5 class="text-center">{{ item.type }}</h5>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-select
              :items="gridData"
              item-text="name"
              item-value="value"
              v-model="editedItem.type"
              required
              :rules="[(v) => !!v || 'Type is required']"
              ><template #label>
                Type <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>

            <v-file-input
              v-if="!editedItem.imageURL"
              v-model="editedItem.image"
              accept="image/*"
              required
              label="Image"
              ref="fileupload"
              :rules="[
                (v) => !!v || 'Image is required',
                (v) =>
                  (v &&
                    !adminBotNames.find(
                      (t) =>
                        t.imageName.toLowerCase().trim() ==
                        v.name.toLowerCase().trim()
                    )) ||
                  'Image already exists',
              ]"
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="editedItem.imageURL"
              v-model="editedItem.image"
              accept="image/*"
              label="Image"
              ref="fileupload"
              v-on:change="botChange"
              :rules="
                editedItem.imageURL && !botrule
                  ? []
                  : [
                      (v) => !!v || 'Image is required',
                      (v) =>
                        (v &&
                          !adminBotNames.find(
                            (t) =>
                              editedItem.id != t.value &&
                              t.imageName.toLowerCase().trim() ==
                                v.name.toLowerCase().trim()
                          )) ||
                        'Image already exists',
                    ]
              "
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdatechild"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete confirmation -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text>
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Agent Image?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>

<script>
import EventBus from "../plugins/eventBus";
import botImagesService from "@/services/botImages.service.js";
export default {
  props: {
    botId: Number,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    gridData() {
      if (!this.bot.childrens) return this.typeList;
      return this.typeList.map((t) => {
        return {
          name: t.name,
          value: t.value,
          disabled: this.bot.childrens.find((v) =>
            v.type == t.value ? true : false
          ),
        };
      });
    },
  },
  destroyed() {
    EventBus.off("bot_changes");
  },
  created() {
    this.getById(this.botId);
    this.getBotImageNames();
    EventBus.on("bot_changes", () => {
      this.getBotImageNames();
    });
  },

  data: () => ({
    bot: {},
    botrule: false,
    dialog: false,
    showCrs: true,
    dialogCarousel: false,
    dialogDelete: false,
    deleteAction: "",
    status: "",
    valid: false,
    valid1: false,
    deleteId: 0,
    type: "",
    editedIndex: -1,
    editedItem: {
      id: null,
      imageURL: "",
      type: "",
      image: null,
    },
    typeList: [
      { name: "Crying", value: "cry" },
      { name: "Happy", value: "happy" },
      { name: "Busy", value: "busy" },
      { name: "Sad", value: "sad" },
    ],
  }),
  methods: {
    editItem(item) {
      this.editedIndex = this.bot.childrens.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$refs.fileupload.reset();
      }
    },

    onAdd() {
      this.dialog = true;
      this.editedIndex = -1;
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.deleteId = item.id;
    },
    deleteConfirm() {
      this.deleteChildBot({ botImageId: this.deleteId });
      this.dialogDelete = false;
    },
    async deleteChildBot(item) {
      let result = await botImagesService.delete(item);
      if (result.status == 200) {
        this.status = "Emotion deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getBotImageNames();
        this.getById(this.botId);
        this.botrule = false;
      }
    },
    botChange(e) {
      if (e) {
        this.botrule = true;
      } else {
        this.botrule = false;
        this.editedItem.image = undefined;
      }
    },
    onUpdatechild() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let formData = new FormData();

        if (this.editedIndex == -1) {
          formData.append("ImageFile", this.editedItem.image);

          formData.append("Type", this.editedItem.type);
          formData.append("ParentId", this.bot.id);
          formData.append("Name", this.bot.name);
          formData.append("Description", this.bot.description);
          this.addBotEdit(formData);
        } else {
          if (this.editedItem.image !== undefined) {
            formData.append("ImageFile", this.editedItem.image);
          } else {
            formData.append("ImageURL", this.editedItem.imageURL);
          }
          formData.append("Name", this.bot.name);
          formData.append("Description", this.bot.description);
          formData.append("Type", this.editedItem.type);
          this.update(formData, this.editedItem.id);
        }

        this.closeDialog();
      }
    },
    async addBotEdit(item) {
      let result = await botImagesService.create(item);

      if (result.status == 200) {
        this.status = "Emotion created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getById(this.botId);
        EventBus.emit("bot_changes", "Updated Successfully");
      }
    },
    async update(item, id) {
      let result = await botImagesService.update(item, id);

      if (result.status == 200) {
        this.status = "Emotion updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.botrule = false;
        EventBus.emit("bot_changes", "Updated Successfully");
        this.getById(this.botId);
      }
    },
    async getById(input) {
      let result = await botImagesService.getById(input);
      this.bot = result[0];
    },
    async getBotImageNames() {
      let result = await botImagesService.getAdminBotImageNames();

      this.adminBotNames = result;
    },
  },
};
</script>
<style lang="scss" scoped>
h5 {
  padding: 0;
  font-size: 18px;
  margin-top: 30px;
}
h4 {
  font-size: 26px;
  color: #000;
  margin-top: 30px;
}

.border {
  border: 1px dashed #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>

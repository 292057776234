var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-btn',{staticClass:"grey lighten-2",on:{"click":_vm.chooseImage}},[_vm._v(" Select Agent ")]),(_vm.selectedImage)?_c('v-img',{staticClass:"text-center",attrs:{"width":"100px","src":_vm.selectedImage}}):_vm._e(),_c('v-select',{attrs:{"items":_vm.regOrganization,"item-text":"name","item-value":"id","label":"Organization","clearable":true,"rules":[(v) => !!v || 'Organization is required']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Organization "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.bot.orgId),callback:function ($$v) {_vm.$set(_vm.bot, "orgId", $$v)},expression:"bot.orgId"}}),_c('v-text-field',{attrs:{"label":"Name","validate-on-blur":"","required":"","rules":[
          (v) => !!v || 'Name is required',
          (v) => (v && !!v.trim()) || 'Name cannot be blank',
          (v) =>
            (v &&
              !_vm.clientBotNames.find(
                (t) => t.name.toLowerCase() == v.toLowerCase()
              )) ||
            'Name already exists',
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.bot.name),callback:function ($$v) {_vm.$set(_vm.bot, "name", $$v)},expression:"bot.name"}}),_c('v-radio-group',{attrs:{"rules":[(v) => !!v || 'Type is required']},on:{"change":_vm.serverTypeChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"v-label v-label--active"},[_vm._v("Orchestrator type"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])])]},proxy:true}]),model:{value:(_vm.bot.serverType),callback:function ($$v) {_vm.$set(_vm.bot, "serverType", $$v)},expression:"bot.serverType"}},[_c('v-radio',{attrs:{"label":"On - Premises","value":"On - Premises"}}),_c('v-radio',{attrs:{"label":"Cloud","value":"Cloud"}})],1),(_vm.bot.serverType == 'Cloud')?_c('v-text-field',{attrs:{"label":"Tenant Name","validate-on-blur":"","required":"","rules":[
          (v) => !!v || 'Tenant Name is required',
          (v) => (v && !!v.trim()) || 'Tenant Name cannot be blank',
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tenant Name "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,false,4076241089),model:{value:(_vm.bot.tenatName),callback:function ($$v) {_vm.$set(_vm.bot, "tenatName", $$v)},expression:"bot.tenatName"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"License","validate-on-blur":"","required":"","rules":[
          (v) => !!v || 'License is required',
          (v) => (v && !!v.trim()) || 'License cannot be blank',
          (v) =>
            (v &&
              !_vm.botNames.find(
                (t) =>
                  t.license.toLowerCase().trim() == v.toLowerCase().trim()
              )) ||
            'License Key already exists',
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" License "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.bot.license),callback:function ($$v) {_vm.$set(_vm.bot, "license", $$v)},expression:"bot.license"}}),_c('v-text-field',{attrs:{"label":"Server Name","maxlength":"20"},model:{value:(_vm.bot.serverName),callback:function ($$v) {_vm.$set(_vm.bot, "serverName", $$v)},expression:"bot.serverName"}})],1),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"red--text text-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm.statusbot)+" ")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3 grey lighten-2",attrs:{"depressed":""},on:{"click":_vm.closeDialog}},[_vm._v(" Clear ")]),_c('v-btn',{attrs:{"dark":"","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.addLicense}},[_vm._v(" Submit ")])],1)],1),(_vm.dialogImage)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogImage),callback:function ($$v) {_vm.dialogImage=$$v},expression:"dialogImage"}},[_c('v-card',{attrs:{"height":"auto"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogImage = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Image Gallery")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialogImage = false}}},[_vm._v(" Done ")])],1)],1),_c('v-card-text',[_c('v-row',_vm._l((_vm.adminBotNames),function(item){return _c('v-col',{key:item.value,attrs:{"lg":"2","md":"2"},on:{"click":function($event){_vm.bot.botPictureId = item.value;
              _vm.selectedImage = item.url;
              _vm.statusbot = '';}}},[_c('div',{staticClass:"box-list",class:_vm.getActiveClass(item.value)},[_c('v-img',{attrs:{"src":item.url}})],1)])}),1)],1)],1)],1):_vm._e(),(_vm.status)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { mainAxios } from '../axios-instance';
export default {

  async getAllTickets(input) {
    let result = await mainAxios.get(`/Ticket`, {
      params: input,
    });
    return result.data;

  },
  async getTicketById(id) {
    let result = await mainAxios.get(`/Ticket/${id}`);

    return result;

  },
  async create(input) {
    let result = await mainAxios.post(`/Ticket`, input);
    return result;
  },
  async update(input, ticketId) {
    let result = await mainAxios.put(`/Ticket`, input, { params: { ticketId: ticketId } });
    return result;
  },
  async updateFile(input, ticketId) {
    const config = { headers: { 'Content-Type': "multipart/form-data" } };
    let result = await mainAxios.post(`/Ticket/${ticketId}/file`, input, config);

    return result;
  },
  async changeStatus(input) {

    let result = await mainAxios.put(`/Ticket/status?ticketId=${input.ticketId}&status=${input.status}`);

    return result;
  },
  async delete(input) {
    let result = await mainAxios.put(`/Ticket/status`, null, {
      params: { ticketId: input.ticketId, status: input.status },
    });
    return result;
  },

  async deleteFile(id) {
    let result = await mainAxios.delete(`/Ticket/file?fileId=${id}`
    );
    return result;
  },
  async deleteComment(id) {
    let result = await mainAxios.delete(`/Ticket/comment/${id}`
    );
    return result;
  },


  async createComment(input, ticketId) {
    let result = await mainAxios.post(`/Ticket/${ticketId}/comment`, input);
    return result;
  },
  async updateComment(input, ticketId) {
    let result = await mainAxios.put(`/Ticket/comment?commentId=${ticketId}`, input);
    return result;
  }

}
import { customAxios } from '../axios-instance';


export default {
    async getAllProducts() {
        let result = await customAxios.get(`/Client/products`);
        return result.data;

    },
    async getAllClient() {
        let result = await customAxios.get(`/Client`);
        return result.data;

    },
    async disableOrEnable(input) {
        let result = await customAxios.put(`/Client/status`, null, {
            params: { id: input.id, status: input.status },
        });
        return result;

    },

    async apiConsumption(input) {
        let result = await customAxios.get(`/Insight`, { params: input });

        return result;
    },

    async createClientProduct(input) {
        // let result = await customAxios.post(`/Client/client-product`, null, { params: input });
        let result = await customAxios.post(`/Client/client-product`, input);
        return result;
    },

    async editClientProduct(input) {
        // let result = await customAxios.post(`/Client/client-product`, null, { params: input });
        let result = await customAxios.put(`/Client/client-product`, input);
        return result;
    },
    async deleteClientProduct(input) {
        let result = await customAxios.delete(`/Client/client-product?id=${input.id}`);
        return result;
    },
}
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{},[_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"cartitem"},[_vm._v("Do you have an idea for a process?")]),_c('p',{staticClass:"allinfo"},[_vm._v("Please complete this below form")])])]),_c('v-divider'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"title-login"},[_vm._v("Name of the Process "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-text-field',{attrs:{"solo":"","dense":"","rules":_vm.nameRules,"label":"Name of the Process","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name of the Process")]},proxy:true}]),model:{value:(_vm.processName),callback:function ($$v) {_vm.processName=$$v},expression:"processName"}})],1),_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"title-login"},[_vm._v("Description "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-textarea',{attrs:{"rules":_vm.descrule,"label":"Description","required":"","solo":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description")]},proxy:true}]),model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("Your Role "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-text-field',{attrs:{"solo":"","dense":"","label":"Your Role","rules":[
                (v) => !!v || 'Role is required',
                (v) => (v && !!v.trim()) || 'Role cannot be blank',
              ],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Your Role")]},proxy:true}]),model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("Your Department "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-text-field',{attrs:{"solo":"","dense":"","label":"Your Department","rules":[
                (v) => !!v || 'Department is required',
                (v) => (v && !!v.trim()) || 'Department cannot be blank',
              ],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Your Department")]},proxy:true}]),model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("How long does it take to do it manually? "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('v-text-field',{attrs:{"rules":[
                    (v) => !!v || 'Duration is required',
                    (v) => (v && !!v.trim()) || 'Duration cannot be blank',
                  ],"label":"Duration","required":"","solo":"","dense":""},on:{"keypress":function($event){return _vm.onlyNumber($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Duration")]},proxy:true}]),model:{value:(_vm.manualDuration),callback:function ($$v) {_vm.manualDuration=$$v},expression:"manualDuration"}})],1),_c('div',{staticClass:"col-6"},[_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.items,"rules":[(v) => !!v || 'Type is required'],"label":"Type","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Type")]},proxy:true}]),model:{value:(_vm.manualDurationType),callback:function ($$v) {_vm.manualDurationType=$$v},expression:"manualDurationType"}})],1)])]),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("How many times a day/week/month you do this process? "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('v-text-field',{attrs:{"solo":"","dense":"","rules":[
                    (v) => !!v || 'Duration is required',
                    (v) => (v && !!v.trim()) || 'Duration cannot be blank',
                  ],"label":"\n  Duration","required":""},on:{"keypress":function($event){return _vm.onlyNumber($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Duration")]},proxy:true}]),model:{value:(_vm.runCount),callback:function ($$v) {_vm.runCount=$$v},expression:"runCount"}})],1),_c('div',{staticClass:"col-6"},[_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.itemstimes,"rules":[(v) => !!v || 'Type is required'],"label":"Type","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])]),_vm._v(" Type")]},proxy:true}]),model:{value:(_vm.runCountType),callback:function ($$v) {_vm.runCountType=$$v},expression:"runCountType"}})],1)])]),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("What is the average salary per month of a person doing this job manually? "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-text-field',{attrs:{"label":"Salary","dense":"","solo":"","prefix":"$","rules":[
                (v) => !!v || 'Salary is required',
                (v) => (v && !!v.trim()) || 'Salary cannot be blank',
              ],"required":""},on:{"keypress":function($event){return _vm.onlyNumber($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Salary")]},proxy:true}]),model:{value:(_vm.manualAverageCost),callback:function ($$v) {_vm.manualAverageCost=$$v},expression:"manualAverageCost"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("How many people are performing this process manually? "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-text-field',{attrs:{"solo":"","dense":"","rules":[
                (v) => !!v || 'Count is required',
                (v) => (v && !!v.trim()) || 'Count cannot be blank',
              ],"label":"\n  Count ","required":""},on:{"keypress":function($event){return _vm.onlyNumber($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Count ")]},proxy:true}]),model:{value:(_vm.peopleCount),callback:function ($$v) {_vm.peopleCount=$$v},expression:"peopleCount"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("Is the risk HIGH/Medium/Low if this job is not performed? "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-select',{attrs:{"dense":"","solo":"","items":_vm.itemsrisk,"rules":[(v) => !!v || 'Type is required'],"label":"Risk Type","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Risk Type")]},proxy:true}]),model:{value:(_vm.riskType),callback:function ($$v) {_vm.riskType=$$v},expression:"riskType"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"title-login"},[_vm._v("Do you have budget approval to automate this process? "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]),_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.itemsbudget,"rules":[(v) => !!v || 'Budget approval is required'],"required":""},model:{value:(_vm.bugetApproval),callback:function ($$v) {_vm.bugetApproval=$$v},expression:"bugetApproval"}})],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"border"},on:{"click":_vm.reset}},[_vm._v(" Clear ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary"},on:{"click":_vm.validate}},[_vm._v(" Submit ")])],1)],1)],1),(_vm.status)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container mb-12">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
      row
      wrap
      class="mb-3 tabborder"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        :href="'#tab-' + item.name"
        :title="item.title"
        @click="tabsval(index)"
      >
        {{ item.name }}
      </v-tab>
      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="(item, index) in more"
            :key="index"
            @click="addItem(item)"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>

    <!-- <v-tabs-items v-model="currentItem" height="auto">
      <v-tab-item
        key="Submitted Requests"
        value="tab-Submitted Requests"
        height="auto"
      > -->
    <div v-if="selectedTab === 0">
      <RequestNewProcess></RequestNewProcess>
    </div>
    <!-- </v-tab-item> -->
    <!-- 
      <v-tab-item
        key="Create New Request"
        value="tab-Create New Request"
        height="auto"
      > -->
    <div v-if="selectedTab === 1">
      <RequestedNewProcessList></RequestedNewProcessList>
    </div>
    <!-- </v-tab-item>
    </v-tabs-items> -->
  </div>
</template>

<script>
import RequestNewProcess from "../components/RequestNewProcess.vue";
import RequestedNewProcessList from "../components/RequestedNewProcessList.vue";
export default {
  components: {
    RequestNewProcess,
    RequestedNewProcessList,
  },
  computed: {},
  data: () => ({
    tab: null,
    tabItems: [],
    tabs: [],
    more: [],
    currentItem: "tab-Create New Request",
    selectedTab: 0,
  }),
  created() {
    this.tabItems = [
      {
        name: "Create New Request",
        title: "Process Information and other Details",
      },
      {
        name: "Submitted Requests",
        title: "All information related to new Workflows",
      },
    ];
  },
  methods: {
    tabsval(tab) {
      this.selectedTab = tab;
    },
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 1);
      this.more = temp.splice(0);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
/deep/.v-tab {
  padding: 0px 8px;
}
/deep/.v-menu__content {
  min-width: 99px;
  top: 120px;
  left: 190px;
}
</style>

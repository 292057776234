<template>
  <div>
    <!-- <div class="register-box">
      <div class="card my-4 mx-4">
        <div class="card-body p-4 p-sm-5">
          <div class="col-12 mt-5">
            <h3 class="mt-2 mb-5">
              You have an idea for a process? Please complete this form and we
              collect:
            </h3>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="processName"
                :rules="nameRules"
                label="Name of the Process"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> Name
                  of the Process</template
                ></v-text-field
              >

              <v-textarea
                v-model="description"
                :rules="descrule"
                label="Description"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span>
                  Description</template
                ></v-textarea
              >
              <v-text-field
                v-model="role"
                label="Your Role"
                :rules="[
                  (v) => !!v || 'Role is required',
                  (v) => (v && !!v.trim()) || 'Role cannot be blank',
                ]"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> Your
                  Role</template
                ></v-text-field
              >
              <v-text-field
                v-model="department"
                label="Your Department"
                :rules="[
                  (v) => !!v || 'Department is required',
                  (v) => (v && !!v.trim()) || 'Department cannot be blank',
                ]"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> Your
                  Department</template
                ></v-text-field
              >

              <v-text-field
                v-model="manualDuration"
                :rules="[
                  (v) => !!v || 'Duration is required',
                  (v) => (v && !!v.trim()) || 'Duration cannot be blank',
                ]"
                label="
    How long does it take to do it manually?"
                @keypress="onlyNumber($event)"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> How
                  long does it take to do it manually?</template
                ></v-text-field
              >
              <v-select
                v-model="manualDurationType"
                :items="items"
                :rules="[(v) => !!v || 'Type is required']"
                label="Manual Duration Type"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span>
                  Manual Duration Type</template
                ></v-select
              >

              <v-text-field
                v-model="runCount"
                :rules="[
                  (v) => !!v || 'Duration is required',
                  (v) => (v && !!v.trim()) || 'Duration cannot be blank',
                ]"
                label="
    How many times a day/week/month you do this process? "
                @keypress="onlyNumber($event)"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> How
                  many times a day/week/month you do this process?</template
                ></v-text-field
              >
              <v-select
                v-model="runCountType"
                :items="itemstimes"
                :rules="[(v) => !!v || 'Type is required']"
                label="Duration Type"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span>
                  Duration Type</template
                ></v-select
              >
              <v-text-field
                label="What is the average salary per month of a person doing this job manually? "
                v-model="manualAverageCost"
                prefix="$"
                :rules="[
                  (v) => !!v || 'Salary is required',
                  (v) => (v && !!v.trim()) || 'Salary cannot be blank',
                ]"
                required
                @keypress="onlyNumber($event)"
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> What
                  is the average salary per month of a person doing this job
                  manually?</template
                ></v-text-field
              >
              <v-text-field
                v-model="peopleCount"
                :rules="[
                  (v) => !!v || 'Count is required',
                  (v) => (v && !!v.trim()) || 'Count cannot be blank',
                ]"
                label="
    How many people are performing this process manually? "
                @keypress="onlyNumber($event)"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> How
                  many people are performing this process manually?
                </template></v-text-field
              >
              <v-select
                v-model="riskType"
                :items="itemsrisk"
                :rules="[(v) => !!v || 'Type is required']"
                label="Is the risk HIGH/Medium/Low if this job is not performed? "
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> Is
                  the risk HIGH/Medium/Low if this job is not
                  performed?</template
                ></v-select
              >
              <v-select
                v-model="bugetApproval"
                :items="itemsbudget"
                :rules="[(v) => !!v || 'Budget approval is required']"
                label="Do you have budget approval to automate this process?"
                required
                ><template #label>
                  <span class="red--text"><strong>&nbsp;* </strong></span> Do
                  you have budget approval to automate this process?</template
                ></v-select
              >

              <v-btn
                color="text-capitalize grey lighten-2"
                class="mr-4"
                @click="reset"
              >
                Clear
              </v-btn>
              <v-btn dark class="mr-4" @click="validate"> Submit </v-btn>
            </v-form>
          </div>
        </div>
      </div>
    </div> -->
    <v-card outlined>
      <v-card-title class="">
        <!-- <v-toolbar flat> -->
        <div class="mt-2">
          <p class="cartitem">Do you have an idea for a process?</p>
          <p class="allinfo">Please complete this below form</p>
        </div>
        <!-- </v-toolbar> -->

        <!-- <span class="text-h6 text-center">{{ formTitle }}</span> -->
      </v-card-title>
      <v-divider></v-divider>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <div class="row">
            <div class="col-lg-12">
              <label class="title-login"
                >Name of the Process
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-text-field
                solo
                dense
                v-model="processName"
                :rules="nameRules"
                label="Name of the Process"
                required
                ><template #label> Name of the Process</template></v-text-field
              >
            </div>
            <div class="col-lg-12">
              <label class="title-login"
                >Description
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-textarea
                v-model="description"
                :rules="descrule"
                label="Description"
                required
                solo
                dense
                ><template #label> Description</template></v-textarea
              >
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >Your Role
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-text-field
                v-model="role"
                solo
                dense
                label="Your Role"
                :rules="[
                  (v) => !!v || 'Role is required',
                  (v) => (v && !!v.trim()) || 'Role cannot be blank',
                ]"
                required
                ><template #label> Your Role</template></v-text-field
              >
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >Your Department
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-text-field
                v-model="department"
                solo
                dense
                label="Your Department"
                :rules="[
                  (v) => !!v || 'Department is required',
                  (v) => (v && !!v.trim()) || 'Department cannot be blank',
                ]"
                required
                ><template #label> Your Department</template></v-text-field
              >
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >How long does it take to do it manually?
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="manualDuration"
                    :rules="[
                      (v) => !!v || 'Duration is required',
                      (v) => (v && !!v.trim()) || 'Duration cannot be blank',
                    ]"
                    label="Duration"
                    @keypress="onlyNumber($event)"
                    required
                    solo
                    dense
                    ><template #label> Duration</template></v-text-field
                  >
                </div>
                <div class="col-6">
                  <v-select
                    v-model="manualDurationType"
                    solo
                    dense
                    :items="items"
                    :rules="[(v) => !!v || 'Type is required']"
                    label="Type"
                    required
                    ><template #label> Type</template></v-select
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >How many times a day/week/month you do this process?
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    solo
                    dense
                    v-model="runCount"
                    :rules="[
                      (v) => !!v || 'Duration is required',
                      (v) => (v && !!v.trim()) || 'Duration cannot be blank',
                    ]"
                    label="
    Duration"
                    @keypress="onlyNumber($event)"
                    required
                    ><template #label> Duration</template></v-text-field
                  >
                </div>
                <div class="col-6">
                  <v-select
                    solo
                    dense
                    v-model="runCountType"
                    :items="itemstimes"
                    :rules="[(v) => !!v || 'Type is required']"
                    label="Type"
                    required
                    ><template #label>
                      <span class="red--text"><strong>&nbsp;* </strong></span>
                      Type</template
                    ></v-select
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >What is the average salary per month of a person doing this job
                manually?
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-text-field
                label="Salary"
                dense
                solo
                v-model="manualAverageCost"
                prefix="$"
                :rules="[
                  (v) => !!v || 'Salary is required',
                  (v) => (v && !!v.trim()) || 'Salary cannot be blank',
                ]"
                required
                @keypress="onlyNumber($event)"
                ><template #label> Salary</template></v-text-field
              >
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >How many people are performing this process manually?
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-text-field
                v-model="peopleCount"
                solo
                dense
                :rules="[
                  (v) => !!v || 'Count is required',
                  (v) => (v && !!v.trim()) || 'Count cannot be blank',
                ]"
                label="
    Count "
                @keypress="onlyNumber($event)"
                required
                ><template #label> Count </template></v-text-field
              >
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >Is the risk HIGH/Medium/Low if this job is not performed?
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-select
                v-model="riskType"
                dense
                solo
                :items="itemsrisk"
                :rules="[(v) => !!v || 'Type is required']"
                label="Risk Type"
                required
                ><template #label> Risk Type</template></v-select
              >
            </div>
            <div class="col-lg-6">
              <label class="title-login"
                >Do you have budget approval to automate this process?
                <span class="red--text"><strong>&nbsp;* </strong></span></label
              >
              <v-select
                v-model="bugetApproval"
                solo
                dense
                :items="itemsbudget"
                :rules="[(v) => !!v || 'Budget approval is required']"
                required
              ></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="border" class="text-capitalize" @click="reset">
            Clear
          </v-btn>
          <v-btn color="primary" class="text-capitalize" @click="validate">
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>

<script>
import processRequestService from "@/services/processRequest.service.js";
import EventBus from "../plugins/eventBus";
export default {
  data: () => ({
    valid: false,
    nameRules: [
      (v) => !!v || "Process Name is required",
      (v) => (v && !!v.trim()) || "Process Name cannot be blank",
      (v) =>
        (v || "").length <= 30 || "Process Name must be 30 characters or less",
    ],
    descrule: [
      (v) => !!v || "Description is required",

      (v) => (v && !!v.trim()) || "Description cannot be blank",
    ],
    select: null,
    role: "",
    status: "",
    processName: "",
    description: "",
    manualDuration: "",
    manualDurationType: "",
    runCount: "",
    runCountType: "",
    manualAverageCost: "",
    peopleCount: "",
    riskType: "",
    bugetApproval: "",
    department: "",
    manulatime: "",
    items: ["Minutes", "Hours", "Days"],
    itemstimes: ["Day", "Week", "Month"],
    itemsrisk: ["High", "Medium", "Low"],
    itemsbudget: ["Yes", "No"],

    checkbox: false,
  }),
  created() {},
  methods: {
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let data = {
          processName: this.processName,
          description: this.description,
          manualDuration: this.manualDuration,
          manualDurationType: this.manualDurationType,
          noRuns: this.runCount,
          runCountType: this.runCountType,
          manualAverageCost: this.manualAverageCost,
          peopleCount: this.peopleCount,
          riskType: this.riskType,
          bugetApproval: this.bugetApproval,
          department: this.department,
          role: this.role,
        };
        this.newProcessRequest(data);
        this.reset();
        this.resetValidation();
      }
    },
    async newProcessRequest(input) {
      let result = await processRequestService.createRequest(input);
      if (result.status == 200) {
        this.status = "Request for new Process created successfully";
        EventBus.emit("request_changes", input.status);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.register-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 60px #0000000f;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
.cartitem {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
.cartdesc {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #5f6463 !important;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.dateval {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  color: #5f6463;
}
.ordertext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.ordertextb {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/500 */

  color: #17181d;
}
.seperator h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator h5::before,
.seperator h5::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #e3e4e8;
}

.seperator h5 .btnmargin {
  padding: 0 2em;
}
.allinfo {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #45464a;
}
/deep/.v-toolbar__content {
  padding: 0px;
}
/deep/.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
  border: 1px solid;
  border-color: #e8e8e8;
  border-radius: 4px;
}
.title-login {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Secondary/400 */

  color: #45464a;
}
</style>
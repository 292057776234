<template>
  <div class="container-fluid">
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6">
                <v-select
                  :items="categoryList.records"
                  v-model="filter.categoryId"
                  item-text="name"
                  item-value="id"
                  label="Category"
                  :clearable="true"
                  @change="modifyOthers"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <!---Data Table -->
      <v-data-table
        :headers="headers"
        :server-items-length="productsList.total"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :items="productsList.records"
        :loading="loading"
        @update:options="updatePagination"
        class="elevation-1"
      >
        <template v-slot:[`item.complexity`]="{ item }">
          {{ newStatus(item.complexity) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.status"
            :false-value="2"
            :true-value="1"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="features(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dialpad
              </v-icon>
            </template>
            <span>Features</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.imageURL`]="{ item }">
          <img :src="item.imageURL" width="100px" />
        </template>

        <template v-slot:[`item.description`]="{ item }">
          <td class="text-justify">
            {{ truncate(item.description, 400) }}
          </td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td class="font-weight-bold">
            {{ item.name }}
          </td>
        </template>
      </v-data-table>
    </v-card>
    <!---Add or Update the process -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedItem.name"
              required
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => (v && !!v.trim()) || 'Name cannot be blank',
              ]"
            >
              <template #label>
                Name <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-select
              :items="gridData"
              item-text="name"
              item-value="id"
              v-model="editedItem.categoryId"
              required
              :rules="[(v) => !!v || 'Category is required']"
              ><template #label>
                Category <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-select
              :items="platform"
              item-text="name"
              item-value="value"
              v-model="editedItem.platform"
              required
              :rules="[(v) => !!v || 'Platform is required']"
              ><template #label>
                Platform <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-select
              :items="complexity"
              item-text="name"
              item-value="value"
              v-model="editedItem.complexity"
              required
              :rules="[(v) => !!v || 'Complexity is required']"
              ><template #label>
                Complexity <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-file-input
              v-if="!editedItem.imageURL"
              v-model="editedItem.image"
              accept="image/*"
              required
              label="Image"
              :rules="[(v) => !!v || 'Image is required']"
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="editedItem.imageURL"
              v-model="editedItem.image"
              accept="image/*"
              label="Image"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to activate this Workflow?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->
    <v-dialog
      v-if="dialogFeatures"
      v-model="dialogFeatures"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogFeatures = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Process Features</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialogFeatures = false"> Done </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <ProductFeatures :productId="editedIndex"></ProductFeatures>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script>
import processService from "@/services/process.service.js";
import catService from "@/services/category.service.js";
import ProductFeatures from "../components/ProductFeatures.vue";

export default {
  components: {
    ProductFeatures,
  },
  computed: {
    gridData() {
      if (!this.categoryList.records) return [];
      return this.categoryList.records.map((t) => {
        return {
          name: t.status == false ? t.name + "(Deactivated)" : t.name,
          id: t.id,
          disabled: t.status == false ? true : false,
        };
      });
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  data: () => ({
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "imageURL",
      },
      { text: "Name", value: "name", sortable: false, width: "20%" },
      {
        text: "Category",
        value: "categoryName",
        sortable: false,
      },
      {
        text: "Platform",
        value: "platform",
        sortable: false,
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Complexity", value: "complexity", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    activationStatus: 1,
    filter: {
      searchKey: "",
      categoryId: "",
    },
    productsList: {},
    categoryList: {},
    loading: false,
    platform: [
      { name: "UiPath", value: "UiPath" },
      { name: "openbots", value: "openbots" },
    ],
    complexity: [
      { name: "High", value: "2" },
      { name: "Medium", value: "1" },
      { name: "Low", value: "0" },
    ],
    editedItem: {
      id: 0,
      name: "",
      description: "",
      imageURL: "",
      image: null,
      categoryId: null,
      platform: "",
      status: 1,
      complexity: "",
    },
    defaultItem: {
      id: 0,
      name: "",
      description: "",
      imageURL: "",
      image: null,
      categoryId: null,
      platform: "",
      complexity: "",
    },
  }),
  created() {
    this.getAllCategory({ skip: 0, take: 25000 });
  },

  methods: {
    truncate(source, size) {
      if (source == null) {
        return source;
      } else {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
    },
    editItem(item) {
      this.editedIndex = this.productsList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    closeDialog() {
      this.dialogDelete = false;
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    deleteItem(item) {
      this.activationStatus = item.status;
      this.editedIndex = this.productsList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.productsList.records[this.editedIndex].id;

      this.delete({ processId: id, status: "Active" });

      this.dialogDelete = false;
    },
    resetStatus() {
      this.productsList.records[this.editedIndex].status =
        this.productsList.records[this.editedIndex].status == "Active"
          ? "DeActive"
          : "Active";
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: (pagination.page - 1) * 10,
        take: 10,
        status: "Requested",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    features(item) {
      this.editedIndex = item.id;
      this.dialogFeatures = true;
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let formData = new FormData();
        formData.append("Name", this.editedItem.name);
        formData.append("CategoryId", this.editedItem.categoryId);
        formData.append("Description", this.editedItem.description);
        formData.append("ImageURL", this.editedItem.imageURL);
        formData.append("ImageFile", this.editedItem.image);
        formData.append("Complexity", this.editedItem.complexity);
        formData.append("Platform", this.editedItem.platform);

        if (this.editedIndex == -1) {
          this.create(formData);
        } else {
          const id = this.productsList.records[this.editedIndex].id;
          this.update(formData, id);
        }
        this.closeDialog();
      }
    },
    async create(item) {
      let result = await processService.create(item);

      if (result.status == 200) {
        this.status = "Process created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await processService.update(item, id);
      if (result.status == 200) {
        this.status = "Process updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await processService.delete(item);
      if (result.status == 200) {
        if (item.status == true) {
          this.status = "Process activated successfully";
        } else {
          this.status = "Process deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    async search() {
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: 0,
        take: 10,
        status: "Requested",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: 0,
        take: 10,
        status: "Requested",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    async modifyOthers() {
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: 0,
        take: 10,
        status: "Requested",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    async getAllCategory(input) {
      let result = await catService.getAll(input);

      this.categoryList = result;
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "0") {
        newstatus = "Low";
      } else if (id == "1") {
        newstatus = "Medium";
      } else if (id == "2") {
        newstatus = "High";
      }
      return newstatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.custom-bg {
  color: gray;
}
/deep/.style-1 {
  background: #e4e7ea !important;
  opacity: 0.5;
}
</style>

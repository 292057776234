import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/User`, {
      params: input,
    });
    return result.data;

  },
  async getUser() {
    let result = await mainAxios.get(`/User/detail`);
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/User`, input);

    return result;
  },
  async update(input) {

    let result = await mainAxios.put(`/User`, null, {
      params: { organizationId: input.organizationId, name: input.name }
    });
    return result;
  },

  async changeStatus(input) {
    let result = await mainAxios.put(`/User/status?userId=${input.userId}&status=${input.status}`);
    return result;
  },

  async resetPasswordProfile(input) {
    let result = await mainAxios.put(`/User/reset`, null, {
      params: input,
    });
    return result;

  },
  async revoke(input) {

    let result = await mainAxios.put(`/User/role`, null, { params: input });
    return result;

  },
  async changeOrg(input) {
    let result = await mainAxios.put(`/User/organization`, null, {
      params: input
    });
    return result;
  },

  async contactUs(input) {
    let result = await mainAxios.post(`/UserQueries/query`, input);

    return result;
  },
  async getAllQuery(input) {
    let result = await mainAxios.get(`/UserQueries/query`, {
      params: input,
    });

    return result.data;
  },
  async replyQuery(input, id) {

    let result = await mainAxios.put(`/UserQueries/reply?id=${id}`, input);
    return result;

  },
}
<template>
  <div class="container">
    <p class="header0">
      on a <label class="header1">mission</label> to
      <label class="header2">transform businesses.</label>
    </p>
    <p class="normaltexttitle text-justify pb-8">
      What makes us different from rest!
    </p>
    <div class="text-center mt-4 pb-8">
      <v-img :src="require('@/assets/aboutus.svg')" contains></v-img>
    </div>
    <p class="header00 pb-6">
      workflows to <label class="header11">start safe</label> and
      <label class="header11">finish smart</label>
    </p>

    <p class="normaltext text-justify pb-6">
      alignxcel marketplace enables and empowers you to accelerate your business
      process transformation with a unique approach that is <b>safe</b> because
      it won't disrupt your current operations and works with your existing
      systems and is <b>smart</b> because it allows you to optimize your
      transformation journey by providing the right tools for increasing staff
      adoptions and the ability to scale transformation with automation across
      the organization.
    </p>
    <p class="normaltext text-justify pb-6">
      alignxcel marketplace accelerates your process automation initiatives by
      offering ready to use workflows with easy integrations, so that complex
      business processes can start delivering value to your staff and customers
      in weeks.
    </p>
    <p class="normaltext text-justify pb-8">
      All the workflows published on alignxcel marketplace are actual workflows
      implemented at several of Digital Align clients and are most are running
      in production for years to prove the stability and reliability of our
      solution to transform any business.
    </p>
    <p class="links text-justify pb-16">
      Learn more about how
      <a class="text-decoration-underline">we're changing the world</a
      ><v-icon color="primary" right> mdi-arrow-right-thin </v-icon>
    </p>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";
.header0 {
  font-weight: 300;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: #17181d;
}
.header00 {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;

  /* Secondary/500 */

  color: #17181d;
}
.header1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: #17181d;
}
.header11 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;

  /* Secondary/500 */

  color: #17181d;
}
.header2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: $primary;
}
.header3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.normaltext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  /* Secondary/500 */

  color: #17181d;
}
.links {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #009688;
}
.normaltexttitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  /* Secondary/400 */

  color: #45464a;
}
</style>
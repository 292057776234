import { mainAxios } from '../axios-instance';
export default {


  getSummary(input) {
    return mainAxios.get(`/Services/cost-estimation/summary`, {
        params: input,
    });

},

getProcessSummary(input) {

    return mainAxios.get(`/Services/roi/summary`, {
        params: input,
    });

},
getExcelFile(input) {

    return mainAxios.get(`/Services/roi/export`, {
        params: input,
    });

},


}
<template>
  <div>
    <div class="row mt-1">
      <div class="col-sm-9 col-md-8 col-lg-6 ml-3 log-box">
        <div class="">
          <div class="">
            <h2 class="mt-5 mb-2 mainLogin">Reset password</h2>
            <label class="sec-login">Do you want to change your password</label>

            <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
              <div class="">
                <label class="title-login"
                  >Old Password<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  ></label
                >

                <v-text-field
                  class="mt-1"
                  :type="show1 ? 'text' : 'password'"
                  v-model="oldPassword"
                  :rules="passwordRules"
                  required
                  validate-on-blur
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  @keypress.native="noSpace($event)"
                  solo
                  v-on:keyup.enter="validate"
                  ><template #label>
                    Old Password
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
                <label class="title-login"
                  >New Password<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  ></label
                >
                <v-text-field
                  class="mt-1"
                  :type="showN ? 'text' : 'password'"
                  v-model="password"
                  :rules="passwordRules2"
                  required
                  maxlength="20"
                  solo
                  validate-on-blur
                  :append-icon="showN ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showN = !showN"
                  @keypress.native="noSpace($event)"
                  v-on:keyup.enter="validate"
                  ><template #label>
                    New Password
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >

                <label class="title-login"
                  >Confirm Password<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  ></label
                >
                <v-text-field
                  class="mt-1"
                  :type="showC ? 'text' : 'password'"
                  v-model="confirm_password"
                  maxlength="20"
                  solo
                  :rules="[
                    (v) => !!v.trim() || 'Password is required',
                    (v) =>
                      (v.trim() && v.length <= 20) ||
                      'Password must be maximum 20 characters',
                    passwordConfirmationRule,
                    (v) =>
                      (v && v.length >= 6) ||
                      'Password must be minimum 6 characters',
                  ]"
                  required
                  validate-on-blur
                  :append-icon="showC ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showC = !showC"
                  @keypress.native="noSpace($event)"
                  v-on:keyup.enter="validate"
                  ><template #label>
                    Confirm Password
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
              </div>
              <div class="row">
                <div class="col-12">
                  <v-btn
                    dark
                    x-large
                    color="buttonColor"
                    class="btn-block"
                    @click="validate"
                  >
                    Change Password
                  </v-btn>
                </div>
              </div>
              <div v-if="authStatus" class="text-center mt-3 error-text">
                Invalid Password
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import userService from "@/services/user.service.js";
export default {
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },
  created() {
    this.authStatus = "";
    this.authStatusMsg = "";
  },
  data: () => ({
    authStatus: "",
    authStatusMsg: "",
    status: "",
    show1: false,
    showN: false,
    showC: false,
    valid: false,
    oldPassword: "",
    password: "",
    confirm_password: "",
    emailRules: [
      (v) => !!v.trim() || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v.trim() || "Password is required"],
    passwordRules1: [
      (v) => !!v.trim() || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be minimum 6 characters",
      (v) =>
        (v.trim() && v.length <= 20) ||
        "Password must be maximum 20 characters",
    ],
    passwordRules2: [
      (v) => !!v.trim() || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be minimum 6 characters",
      (v) =>
        (v.trim() && v.length <= 20) ||
        "Password must be maximum 20 characters",
    ],
  }),
  methods: {
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.resetPasswordProfile({
          oldPassword: this.oldPassword,
          newPassword: this.password,
        });
      }
    },
    async resetPasswordProfile(item) {
      let result = await userService.resetPasswordProfile(item);

      if (result.status == 200) {
        this.status = "Password Reset Successful ";
        this.oldPassword = "";
        this.password = "";
        this.confirm_password = "";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      } else {
        this.authStatus = "error";
        this.authStatusMsg = result.data;
      }
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
.login-box {
  margin: 10% 30%;
}
.log-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
.error-text {
  color: red;
}
.v-avatar {
  height: 24px !important;
  width: 24px !important;
}

.v-btn {
  background: $buttonColor;
  height: 50px;
  text-transform: none;
  border-radius: 4px !important;
}
</style>
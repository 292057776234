<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="search"
                  label="Search"
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <!-- <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="getOList"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  @change="onChange"
                ></v-select>
              </div> -->
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn dark class="mt-lg-3 mt-0" @click="dialog = true">
                  <v-icon dark> mdi-plus </v-icon> Add License Type</v-btn
                >
              </div>
            </div>
          </div>
        </div></v-card-title
      >
      <v-data-table
        :headers="headers"
        :items="licenseTypeOb.records"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ new Date(item.createdOn).toLocaleString() }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="(licenseTypeId = item.id), (dialogDelete = true)"
                size="15"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-card-title> Add New License Type </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="licenseType"
              label="License type"
              required
              :rules="[
                (v) => !!v || 'License type is required',
                (v) =>
                  (v && !licenseTypeOb.records.find((t) => t.name.trim() == v.trim())) ||
                  'Duplicate License type',
              ]"
              validate-on-blur
            >
              <template #label>
                License type
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea label="Description" v-model="description"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize grey lighten-2" depressed @click="closeDialog">
            Close
          </v-btn>
          <v-btn
            color="black"
            class="text-capitalize white--text"
            :loading="loading1"
            :disabled="loading1"
            @click="createNewLicenseType"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this License Type?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="text-capitalize grey lighten-2" @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            color="black"
            class="text-capitalize white--text"
            :loading="loading2"
            :disabled="loading2"
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import licenseService from "@/services/licenseExpiry.service.js";
export default {
  async mounted() {
    this.getAllLicenseType();
  },
  data: () => ({
    dialogDelete: false,
    search: "",
    licenseType: "",
    description: "",
    valid: false,
    dialog: false,
    licenseTypeOb: {},
    licenseTypeId: 0,
    loading: false,
    loading1: false,
    loading2: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Created On",
        value: "createdOn",
      },
      {
        text: "Created By",
        value: "createdBy",
      },
      {
        text: "Action",
        value: "action",
      },
    ],
  }),
  methods: {
    async getAllLicenseType() {
      this.loading = true;
      let result = await licenseService.getAllLicenseTypes();
      this.licenseTypeOb = result.data;
      this.loading = false;
    },
    async createNewLicenseType() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.loading1 = true;
        var input = {
          name: this.licenseType,
          description: this.description,
        };

        let result = await licenseService.createNewLicenseType(input);
        this.licenseTypeOb = result;
        if (result?.status == 200) {
          this.dialog = false;
          this.getAllLicenseType();
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        }

        this.loading1 = false;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    async deleteConfirm() {
      this.loading2 = true;
      let result = await licenseService.deleteLicenseType(this.licenseTypeId);
      this.licenseTypeOb = result;
      if (result?.status == 200) {
        this.licenseTypeId = 0;
        this.dialogDelete = false;
        this.getAllLicenseType();
      }
      this.loading2 = false;
    },
  },
};
</script>

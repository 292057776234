<template>
  <div>
 

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-4">
            <v-text-field
              v-model="filter.searchKey"
              label="Search"
              single-line
              hide-details
              v-on:keyup.enter="search"
              append-icon="mdi-magnify"
              clear-icon="mdi-close-circle"
              clearable
              @click:append="search"
              @click:clear="clear"
            ></v-text-field>
          </div>
<div class="col-3">
               <v-select
           :items="allOrganization"
              item-text="organization"
              item-value="id"
          v-model="selectedOrg"
          label="Select Organization"
          :clearable="true"
          @change="onChange"
        ></v-select>
              </div>
      
        </div>
      </div>
    </div>
  <v-data-table
            :headers="headersParent"
            :items="orderGrouplist.data"            
            :expanded.sync="expanded"
            :single-expand="singleExpand"
            item-key="groupId"
            class="elevation-1"
            show-expand
            :server-items-length="orderGrouplist.count"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [10],
        }"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :loading="loading"
        @update:options="updatePagination"
          >
          

        

            <template #[`item.organization.name`]="{ item }">
              <span class="text-subtitle-2 blue--text text--darken-3">{{item.organization.name}}</span>
            </template>
             <template #[`item.createdOn`]="{ item }">
              <td >{{ new Date(item.createdOn).toDateString() }}</td>
            </template>
             <template #[`item.order`]="{ item }">
              <td >{{item.order.length}}</td>
            </template>
             
            <template v-slot:expanded-item="{ headers, item }">
              <td class="px-0 py-2" :colspan="headers.length">
                <v-data-table
                  :headers="headersChild"
                  :items="item.order"
                  item-key="orderId"
                  elevation="0"
                  hide-default-footer
                >
                <template v-slot:[`item.product.imageURL`]="{ item }">
          <img :src="item.product.imageURL" width="100px" />
        </template>
                  <template #[`item.createdOn`]="{ item }">
              <td >{{ new Date(item.createdOn).toDateString() }}</td>
            </template>

 <template v-slot:[`item.isSynched`]="props">
     
       <v-edit-dialog
          large
          @save="save(props.item,orderid)"
           @open="orderid = props.item.isSynched"
          @cancel="cancel"
        >
           <td v-if="props.item.isSynched==0">New</td>
           <td v-else-if="props.item.isSynched==1">Implemented</td>
           <td v-else-if="props.item.isSynched==2">In Progress</td>
           <td v-else-if="props.item.isSynched==-1">Cancelled</td>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Status
            </div>
           
              <v-select
           :items="statuslist"
              item-text="name"
              item-value="value"
          v-model="orderid"
          label="Select Status"
          :clearable="false"
         
        ></v-select>
          </template>
        </v-edit-dialog>
        
      </template>

                </v-data-table>
              </td>
            </template>
          </v-data-table>
   


 
 


    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapAuthGetter, mapActions: mapAuthActions } =
  createNamespacedHelpers("auth");
import EventBus from "../plugins/eventBus";
const { mapGetters: mapGettersOrder, mapActions: mapActionsOrder } =
  createNamespacedHelpers("order");
export default {
     props: {
    statusNo: Number,
  },
  created() {
    this.getOrganization();
    this.orderid=this.statusNo


   
    EventBus.on("status_changes", (x) => {
      this.updatePagination({ page: this.pageIndex });
      this.status = x;
     
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
  },
  computed: {
   ...mapGettersOrder(["orderGrouplist", "loading"]),
    ...mapAuthGetter(["allOrganization"]),
 
  },
  data: () => ({
      orderid:'',
     selectedOrg: "",
     statusName:"",
   singleExpand: true,
    valid: false,
    status: "",
    statuslist: [
      { name: "New", value:0},
      { name: "In Progress", value: 2 },
      { name: "cancelled", value: -1},
      { name: "Implemented", value: 1},
    ],
    filter: {
      searchKey: "",
      sortBy: "",
    },
    expanded: [],
    headersParent: [  
      {
        text: 'Organization',
        align: 'left',
        value: 'organization.name',
        
      },    
      {
        text: 'Group ID',
        align: 'left',
        value: 'groupId'
      },
      {
        text: 'Ordered On',
        align: 'left',
        value: 'createdOn'
      },
       {
        text: 'No of Orders',
        align: 'left',
        value: 'order'
      },
      
      { text: '', value: 'data-table-expand' }],    
    headersChild: [
       {
        text: "Image",
        align: "start",
        sortable: false,
        value: "product.imageURL",
      },
      { text: "Name", value: "product.name", width: "200px", sortable: false },
      {
        text: "Created On",
        value: "createdOn",
        width: "200px",
        sortable: false,
      },
      { text: "Description", value: "product.description", sortable: false },
      { text: "Status", value: "isSynched", sortable: false },
    ],

    pageIndex: 0,
  }),
  
  methods: {
   ...mapActionsOrder(["getAll","updateStatus"]),
    ...mapAuthActions(["getOrganization"]),
 onChange() {
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      let data = {
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
        status:this.statusNo
      };
      this.getAll(data);
    },
  
save (item,id) {
     
        
        this.updateStatus({ isSynched: id, id: item.orderId });
      },
      cancel () {
       
      },
    search() {
      let data = {
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      };
      this.getAll(data);
    },
    clear() {
      this.filter.searchKey = "";
      let data = {
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      };
      this.getAll(data);
    },
    getstatus(status)
    {
if(status.isSynched==0)
{
  return "New"
}else if(status.isSynched==1){
  return "Implemented"
}else if(status.isSynched==-1){
  return "Cancelled"
}else if(status.isSynched==2){
  return "In Progress"
}
    }

  },
};
</script>
<style lang="scss" scoped>
.box-list {
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}
.active {
  border-color: #1a8ccf;
  border-width: 5px;
}
</style>
import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/Comment`, {
      params: input,
    });
    let list = [];
    if (result.data) {
      if (result.data.length != 0) {
        result.data.sort((a, b) => {
          return parseInt(b.id) - parseInt(a.id);
        });
      }
      let total = 0;
      let star1 = 0;
      let star2 = 0;
      let star3 = 0;
      let star4 = 0;
      let star5 = 0;
      for (let i = 0; i < result.data.length; i++) {
        total = total + parseFloat(result.data[i].rating);
        if (Math.floor(result.data[i].rating) == 1) {
          star1 = star1 + 1;
        } else if (Math.floor(result.data[i].rating) == 2) {
          star2 = star2 + 1;
        } else if (Math.floor(result.data[i].rating) == 3) {
          star3 = star3 + 1;
        } else if (Math.floor(result.data[i].rating) == 4) {
          star4 = star4 + 1;
        } else if (Math.floor(result.data[i].rating) == 5) {
          star5 = star5 + 1;
        }
      }
      let obj = {};
      obj.total = total;

      obj.avgtotal = Number((total / result.data.length || 0).toFixed(2));
      obj.star1 = Math.round((star1 / result.data.length || 0) * 100);
      obj.star2 = Math.round((star2 / result.data.length || 0) * 100);
      obj.star3 = Math.round((star3 / result.data.length || 0) * 100);
      obj.star4 = Math.round((star4 / result.data.length || 0) * 100);
      obj.star5 = Math.round((star5 / result.data.length || 0) * 100);
      list.push({
        review: result.data,
        summary: obj
      })

      return list[0]
    }

  },

  async create(input, id) {
    let result = await mainAxios.post(`/Comment`, input, { params: { processId: id } });

    return result;
  },

  async update(input, id) {

    let result = await mainAxios.put(`/Comment`, input, { params: { commentId: id } });
    return result;
  },
  async delete(input) {
    let result = await mainAxios.delete(`/Comment?commentId=${input.commentId}`);

    return result;
  },

}
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import "bootstrap";
import "./style.scss";
import { VueMaskDirective } from "v-mask";
import VueClipboard from 'vue-clipboard2'
import YearPicker from './components/YearPicker.vue';
import swapna from "swapna-socialcause/dist/swapna-socialcause.common"
import "swapna-socialcause/dist/swapna-socialcause.css"
import VueGtag from "vue-gtag"
Vue.use(swapna)
Vue.directive("mask", VueMaskDirective);


Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard);

Vue.component('v-year-field', YearPicker)
Vue.use(
  VueGtag,
  {
    config: { id: "G-R87WB0L5H0" },
  },
  router
)

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-x-transition',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-8 col-lg-6 mx-auto log-box"},[_c('div',{staticClass:"card border-0 shadow rounded-3 my-5"},[_c('div',{staticClass:"card-body p-4 p-sm-5"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.userType == 'admin')?_c('label',{attrs:{"for":"organizationLBL"}},[_vm._v("Client "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]):_vm._e(),(_vm.userType == 'admin')?_c('v-select',{staticClass:"company-size-dropdown",attrs:{"items":_vm.regOrganization,"item-text":"name","item-value":"id","label":"Client","required":"","rules":[(v) => !!v || 'Client is required']},on:{"change":function($event){return _vm.orgChange($event)}},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}):_vm._e(),(_vm.email != '' && _vm.userType != 'admin')?_c('v-text-field',{attrs:{"label":"User Email Id","required":"","rules":[
                      (v) => !!v || 'User Email Id is required',
                      (v) =>
                        /.+@.+\..+/.test(v) || 'User Email Id must be valid',
                      (v) =>
                        (v && !!v.trim()) || 'User Email Id cannot be blank',
                      (v) =>
                        (v &&
                          v.substring(v.indexOf('@') + 1) ==
                            _vm.email.substring(_vm.email.indexOf('@') + 1)) ||
                        'Domain must match',
                      (v) =>
                        (v &&
                          !_vm.userEmailIds.find((t) => t.trim() == v.trim())) ||
                        'Invitation already sent',
                    ],"validate-on-blur":""},nativeOn:{"keypress":function($event){return _vm.noSpace($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" User Email Id "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,false,683482770),model:{value:(_vm.editedItem.emailId),callback:function ($$v) {_vm.$set(_vm.editedItem, "emailId", $$v)},expression:"editedItem.emailId"}}):_c('v-text-field',{attrs:{"label":"User Email Id","required":"","rules":[
                      (v) => !!v || 'User Email Id is required',
                      (v) =>
                        /.+@.+\..+/.test(v) || 'User Email Id must be valid',
                      (v) =>
                        (v && !!v.trim()) || 'User Email Id cannot be blank',

                      (v) =>
                        (v &&
                          !_vm.userEmailIds.find((t) => t.trim() == v.trim())) ||
                        'Invitation already sent',
                    ],"validate-on-blur":""},nativeOn:{"keypress":function($event){return _vm.noSpace($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" User Email Id "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.editedItem.emailId),callback:function ($$v) {_vm.$set(_vm.editedItem, "emailId", $$v)},expression:"editedItem.emailId"}}),_c('div',{staticClass:"text-right mt-2"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.uploadUser}},[_vm._v(" Invite User ")])],1)],1)],1)])])])])],1)],1),(_vm.status)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn
                  dark
                  class="mt-lg-3 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Role</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :server-items-length="roleList.total"
        :items-per-page="10"
        :items="roleList.records"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
        class="elevation-1"
      >
        <!-- <template v-slot:[`item.isDeleted`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.isDeleted"
            :false-value="true"
            :true-value="false"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="25" small @click="deleteItem(item)" v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete Role</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.modifiedOn`]="{ item }">
          <td v-if="item.modifiedOn">{{ new Date(item.modifiedOn).toDateString() }}</td>
        </template>

        <template v-slot:[`item.addedOn`]="{ item }">
          <td v-if="item.addedOn">{{ new Date(item.addedOn).toDateString() }}</td>
        </template>
      </v-data-table>
    </v-card>

    <!---Role update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedItem.roleName"
              required
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => (v && !!v.trim()) || 'Name cannot be blank',
              ]"
            >
              <template #label>
                Name <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn dark color="black" class="text-capitalize" depressed @click="onUpdate">
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Role?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="text-capitalize grey lighten-2" @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import roleService from "@/services/role.service.js";
export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  data: () => ({
    roleList: {},
    loading: false,
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Role Name",
        align: "start",
        sortable: false,
        value: "roleName",
        width: "200px",
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Added On", value: "addedOn", sortable: false },
      { text: "Added By", value: "addedBy", sortable: false },
      { text: "Modified On", value: "modifiedOn", sortable: false },
      { text: "Modified By", value: "modifiedBy", sortable: false },
      // { text: "Status", value: "isDeleted", width: "200px", sortable: false },

      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    searchKey: "",
    editedItem: {
      id: 0,
      roleName: "",
      description: "",
    },
  }),
  created() {},

  methods: {
    editItem(item) {
      this.editedIndex = this.roleList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    resetStatus() {
      this.roleList.records[this.editedIndex].isDeleted =
        this.roleList.records[this.editedIndex].isDeleted == true ? false : true;
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.roleList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.roleList.records[this.editedIndex].id;
      this.editedItem.id = id;

      this.delete({ roleId: id });
      this.dialogDelete = false;
    },

    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await roleService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.roleList = result;
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          this.create({
            roleName: this.editedItem.roleName,
            description: this.editedItem.description,
          });
        } else {
          const id = this.roleList.records[this.editedIndex].id;
          this.editedItem.id = id;
          this.update({
            roleId: this.editedItem.id,
            roleName: this.editedItem.roleName,
            description: this.editedItem.description,
          });
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await roleService.create(item);

      if (result.status == 200) {
        this.status = "Role created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item) {
      let result = await roleService.update(item);
      if (result.status == 200) {
        this.status = "Role updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await roleService.delete(item);
      if (result.status == 200) {
        if (item.status == true) {
          this.status = "Role activated successfully";
        } else {
          this.status = "Role deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await roleService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.roleList = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      let result = await roleService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.roleList = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>

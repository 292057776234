import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/Assets`, {
      params: input,
    });
    return result.data;

  },

  async create(input) {
    let result = await mainAxios.post(`/Assets`, input);

    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/Assets/${id}`, input);
    return result;
  },

  async delete(id) {
    let result = await mainAxios.delete(`/Assets/${id}`);
    return result;
  },


}
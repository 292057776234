<template>
  <div class="container">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
       class="mb-3"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        
        :href="'#tab-' + item.name"
        :title="item.title"
      >
        {{ item.name }}
      </v-tab>

      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center mr-4" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="(item, index) in more"
            :key="index"
            @click="addItem(item)"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-tabs-items v-model="currentItem" height="auto">
      <v-tab-item
        key="New"
        value="tab-New"
        height="auto"
      >
        <admin-leads-by-status-new></admin-leads-by-status-new>
      </v-tab-item>
        <v-tab-item
        key="In Progress"
        value="tab-In Progress"
        height="auto"
      >
        <admin-leads-by-status-in-progress></admin-leads-by-status-in-progress>
      </v-tab-item>
      <v-tab-item
        key="Confirmed"
        value="tab-Confirmed"
        height="auto"
      >
        <admin-leads-by-status-confirmed></admin-leads-by-status-confirmed>
      </v-tab-item>
     <v-tab-item
        key="UAT"
        value="tab-UAT"
        height="auto"
      >
        <admin-leads-by-status-uat></admin-leads-by-status-uat>
      </v-tab-item>

      <v-tab-item
        key="Implemented"
        value="tab-Implemented"
        height="auto"
      >
        <admin-leads-by-status-implemented></admin-leads-by-status-implemented>
      </v-tab-item>

      <v-tab-item
        key="Cancelled"
        value="tab-Cancelled"
        height="auto"
      >
        <admin-leads-by-status-cancelled ></admin-leads-by-status-cancelled>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AdminLeadsByStatusNew from "../components/AdminLeadsByStatusNew.vue";
import AdminLeadsByStatusInProgress from "../components/AdminLeadsByStatusInProgress.vue";
import AdminLeadsByStatusConfirmed from "../components/AdminLeadsByStatusConfirmed.vue";
import AdminLeadsByStatusImplemented from "../components/AdminLeadsByStatusImplemented.vue";
import AdminLeadsByStatusCancelled from "../components/AdminLeadsByStatusCancelled.vue";
import AdminLeadsByStatusUat from "../components/AdminLeadsByStatusUat.vue";

export default {
  components: {
    AdminLeadsByStatusNew,
    AdminLeadsByStatusInProgress,
    AdminLeadsByStatusConfirmed,
    AdminLeadsByStatusImplemented,
    AdminLeadsByStatusCancelled,
    AdminLeadsByStatusUat
  },
  computed: {
  },
  data: () => ({
    tab: null,
    index: 0,
    stuatusno: "Pending",
    currentItem: "tab-New",
    tabItems: [{
      name: "New",
        title: "Processes Purchased by customers needs an Action plan"},
        
        {
      name: "In Progress",
        title: "Processes that are marked as in Progress by DA Admin"},
        {
      name: "Confirmed",
        title: "Processes that are Confirmed by Clients"},
         {
      name: "UAT",
        title: "Processes that are Under User Acceptance Testing"},
        {
      name: "Implemented",
        title: "Processes that are Implemented for the clients"},
        {
      name: "Cancelled",
        title: "Processes that are marked as cancelled by DA Admin"}],
    tabs: [],
    more: [],
  }),
  created() {},
  methods: {
   
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 1);
      this.more = temp.splice(0);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
/deep/.v-date-picker-table {
    height: 100% !important;
}
</style>



<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col lg="4" md="3" v-if="regOrganization">
            <v-select
              :items="regOrganization"
              v-model="selectedOrg"
              item-text="name"
              item-value="id"
              label="Select Organization"
              :clearable="false"
              @change="onChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="userList.records"
        :items-per-page="10"
        :server-items-length="userList.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
      >
        <template v-slot:[`item.image`]="{ item }">
          <img :src="item.image" width="100px" />
        </template>
        <template v-slot:[`item.userType`]="{ item }">
          <v-checkbox
            :false-value="''"
            :true-value="'supuser'"
            v-model="item.userType"
            @change="onSelect(item)"
            :disabled="item.userType == 'admin'"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>

<script>
import orgService from "@/services/organization.service.js";
import userService from "@/services/user.service.js";
import EventBus from "../plugins/eventBus";
export default {
  computed: {},
  created() {
    this.getOrganization();

    EventBus.on("revoke_changes", () => {
      this.status = "updated successfully";
      setTimeout(() => {
        this.status = "";
      }, 2000);
      this.onChange();
    });
    EventBus.on("org_load", () => {
      this.selectedOrg = this.orgList[0].id;
      this.onChange();
    });
  },
  data: () => ({
    userList: {},
    loading: false,
    regOrganization: [],
    selectedOrg: "",
    disable: true,
    status: "",

    selected: [],
    headers: [
      {
        text: "Is Admin",
        align: "start",
        value: "userType",
      },
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Last Name",
        align: "start",
        value: "lastName",
      },
      {
        text: "Email ID",
        align: "start",
        value: "emailId",
      },
    ],
  }),
  methods: {
    async onChange() {
      if (this.selectedOrg > 0) {
        this.loading = true;
        let result = await userService.getAll({
          skip: 0,
          take: 10,
          status: "Active",
          organizationId: this.selectedOrg,
        });
        this.loading = false;
        this.userList = result;
      }
    },

    onSelect(item) {
      if (item.userType == "") {
        this.revoke({ userId: item.id });
      } else {
        this.revoke({ userId: item.id, userType: item.userType });
      }
    },
    async revoke(item) {
      let result = await userService.revoke(item);

      if (result.status == 200) {
        this.status = "User updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.onChange();
      }
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
      this.selectedOrg = this.regOrganization[0].id;
      this.onChange();
    },

    async updatePagination(pagination) {
      this.pageIndex = pagination.page;

      if (this.selectedOrg > 0) {
        this.loading = true;
        let result = await userService.getAll({
          skip: (pagination.page - 1) * 10,
          take: 10,
          status: "Active",
          organizationId: this.selectedOrg,
        });
        this.loading = false;
        this.userList = result;
      }
    },
  },
};
</script>
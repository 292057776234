<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="getOList"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  @change="onChange"
                  return-object
                ></v-select>
              </div>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="daterange"
                      label="Date Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="clearDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    no-title
                    scrollable
                    range
                    :max="nowDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize grey lighten-2"
                      @click="menu = false"
                      >Cancel
                    </v-btn>
                    <v-btn dark @click="$refs.menu.save(dates), dateRangeText()"
                      >OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="allLeads.records"
        :items-per-page="10"
        :server-items-length="allLeads.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :single-expand="singleExpand"
        show-expand
        @item-expanded="loadDetails"
      >
        <template v-slot:[`item.imageURL`]="{ item }">
          <img :src="item.imageURL" width="50px" />
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.modifiedOn`]="{ item }">
          <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td
                class="cursor-point"
                @click="statusItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  class="cursor-point block font-weight-black text-capitalize pa-0"
                  plain
                >
                  {{ newStatus(item.status) }}
                  <v-icon right> mdi-dots-vertical </v-icon>
                </v-btn>
              </td>
            </template>
            <span>Edit Status</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="contactItem(item.user)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-phone
              </v-icon>
            </template>
            <span>Customer Contact Information</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.historyactions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="historyItem(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-page-previous
              </v-icon>
            </template>
            <span>Sub Processes</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.subprocess`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="SubProcessItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-page-previous
              </v-icon>
            </template>
            <span>Sub Process</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions1`]="{ item }">
          <v-tooltip bottom v-if="item.releaseProcessName">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="processDetails(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-robot
              </v-icon>
            </template>
            <span>Process details</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.releaseProcessName">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="processQueueDetails(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-tray-full
              </v-icon>
            </template>
            <span>Queue Data</span>
          </v-tooltip>
          <v-menu
            bottom
            left
            offset-y
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="unMapRole(item)">
                <v-list-item-title>Delete Role</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-0 py-0" :colspan="headers.length">
            <div class="col-12 offset-lg-0 offset-xs-0 text-right">
              <v-btn
                dark
                class="ma-3"
                @click="
                  dialog = true;
                  editedNoteIndex = -1;
                  editedNote.clientProcessId = item.id;
                "
              >
                <v-icon dark> mdi-plus </v-icon> Add Note</v-btn
              >
            </div>

            <v-data-table
              :headers="headersChild"
              :items="allNotes"
              item-key="id"
              class="elevation-1 grey lighten-3"
            >
              <template #[`item.modifiedOn`]="{ item }">
                <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="remove(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogStatus" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Status</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card-text>
            <v-container>
              <div d-flex d-flex-column>
                <v-select
                  :items="statuslist"
                  item-text="name"
                  item-value="value"
                  v-model="editedStatus.status"
                  label="Select Status"
                  :clearable="false"
                ></v-select>
                <v-text-field
                  class="mt-1"
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.releaseProcessName"
                  :rules="[
                    (v) => !!v || 'Release name is required',
                    (v) => (v && !!v.trim()) || 'Release name cannot be blank',
                  ]"
                  required
                >
                  <template #label>
                    Release name
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                  class="mt-1"
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.serverProcessId"
                  :rules="[
                    (v) => !!v || 'Process Id is required',
                    (v) => (v && !!v.trim()) || 'Process Id cannot be blank',
                  ]"
                  required
                >
                  <template #label>
                    Process Id
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
                <v-autocomplete
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.role"
                  :items="gridDataroles"
                  label="Roles"
                  multiple
                  required
                  :rules="[(v) => !!v || 'Roles is required']"
                  small-chips
                  item-text="roleName"
                  item-value="id"
                >
                  <template #label>
                    Roles <span class="red--text"><strong>* </strong></span>
                  </template></v-autocomplete
                >
                <v-text-field
                  class="mt-1"
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.menuName"
                >
                  <template #label> Menu Name </template>
                </v-text-field>
                <v-text-field
                  class="mt-1"
                  v-if="editedStatus.status == 'Implemented'"
                  v-model="editedStatus.userSpentTimeInMinutes"
                >
                  <template #label>User Spending Time(Min)</template>
                </v-text-field>

                <v-switch
                  v-model="editedStatus.isHideMenu"
                  :false-value="false"
                  :true-value="true"
                  inset
                  label="Hide Menu?"
                  class="offset-4 offset-lg-0 mt-1"
                ></v-switch>
              </div>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize grey lighten-2" @click="close">
            Cancel
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogContact" max-width="500px">
      <v-card height="auto" class="text-center justify-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-justify1">
          <v-card-title class="text-center">Contact Details</v-card-title>
          <div>
            <div class="font-weight-normal">
              <strong>{{ editedItem.name }}</strong>
            </div>
            <div>
              <a :href="'mailto:' + editedItem.emailId">{{
                editedItem.emailId
              }}</a>
            </div>
            <div>{{ editedItem.mobileNo }}</div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeContact"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHistory" max-width="500px">
      <v-card height="auto" class="text-center justify-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-justify1">
          <v-card-title class="text-center">Lead History</v-card-title>
          <v-progress-linear
            indeterminate
            v-if="loadingHistory"
          ></v-progress-linear>
          <div v-if="leadHistory.length > 0">
            <v-list>
              <template v-for="(item, index) in leadHistory">
                <v-list-item :key="item.id">
                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{
                      newStatus(item.status)
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="
                        new Date(
                          new Date(item.createdOn).getTime() -
                            new Date(item.createdOn).getTimezoneOffset() * 60000
                        ).toLocaleString()
                      "
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="index < leadHistory.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
          <div v-else-if="loadingHistory == false">No record found</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeHistory"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-textarea
              v-model="editedNote.message"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Note is required',
                (v) => (v && !!v.trim()) || 'Note cannot be blank',
                (v) =>
                  (v || '').length <= 100 ||
                  'Note must be 100 characters or less',
              ]"
            >
              <template #label>
                Note <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title
            >Are you sure you want to delete this note?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogBotData"
      width="1400"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Process Data</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLogs()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          v-if="loadingprocess"
        ></v-progress-linear>
        <v-card-text>
          <div class="container" v-if="processData.data">
            <v-card class="my-4">
              <v-simple-table class="ttl">
                <template v-slot:default>
                  <thead>
                    <tr class="mb-1">
                      <th class="text-left">Process Name</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="mt-1">
                      <td v-if="runningProcess">
                        {{ runningProcess.releaseName }} - {{ process.name }}
                      </td>
                      <td v-else>{{ releaseName }} - {{ process.name }}</td>
                      <td>
                        <v-chip v-if="runningProcess" small color="green" dark>
                          {{ runningProcess.state }}
                        </v-chip>
                        <v-chip v-else small color="orange" dark>
                          Stopped
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <v-card v-if="processData.data">
              <v-data-table
                dense
                :headers="headersLogs"
                :items="roboLogsData"
                item-key="name"
                :loading="loadingbotlog"
                class="elevation-1 ttl"
              >
                <template v-slot:top>
                  <v-toolbar flat color="primary ">
                    <v-toolbar-title class="white--text"
                      >Process Log</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="white"
                          @click="refresh()"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.timeStamp`]="{ item }">
                  <td>{{ new Date(item.timeStamp).toLocaleString() }}</td>
                </template></v-data-table
              >
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="processData.data" class="mr-1">
            <template v-if="processData.data.length > 0">
              <v-btn
                v-if="processData.data[0].state == 'Running'"
                dark
                color="black"
                class="text-capitalize"
                depressed
                @click="killProcess()"
                >Kill Process</v-btn
              >
            </template>
          </div>
          <v-btn class="text-capitalize grey lighten-2" @click="closeLogs()">
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay" :absolute="absolute">
          <v-progress-circular indeterminate size="110"
            >Terminating...</v-progress-circular
          >
        </v-overlay>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBotQueue" scrollable max-width="500px">
      <v-card>
        <v-card-title>Process Queue</v-card-title>
        <v-divider></v-divider>
        <v-progress-linear
          indeterminate
          v-if="loadingprocess"
        ></v-progress-linear>
        <v-card-text style="height: 400px">
          <v-data-iterator
            v-if="processQueue.data"
            :items="processQueue.data"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.name" cols="12">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content
                        >Application Exceptions No:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.applicationExceptionsNo }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Business Exceptions No:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.businessExceptionsNo }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Items In Progress:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.itemsInProgress }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Items To Process:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.itemsToProcess }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Processing Mean Time:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.processingMeanTime }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Successful Transactions No:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.successfulTransactionsNo }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Successful Transactions Processing
                        Time:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.successfulTransactionsProcessingTime }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content
                        >Total Number of Transactions:</v-list-item-content
                      >
                      <v-list-item-action class="align-end">
                        {{ item.totalNumberOfTransactions }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="text-capitalize grey lighten-2"
            @click="dialogBotQueue = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogKill" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center" v-if="processData.data">
          <v-card-title
            >Are you sure you want to kill this Process?</v-card-title
          >
          <div>
            <div>Process Name</div>
            <div class="font-weight-normal" v-if="processData.data.length > 0">
              <strong>{{ this.processData.data[0].releaseName }}</strong>
            </div>
            <div>Start Time</div>
            <div class="font-weight-normal" v-if="processData.data.length > 0">
              <strong>{{
                new Date(this.processData.data[0].startTime).toLocaleString()
              }}</strong>
            </div>
            <div>Status</div>
            <div class="font-weight-normal" v-if="processData.data.length > 0">
              <strong>{{ this.processData.data[0].state }}</strong>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogKill = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="killConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProcessHistory"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title> Process History</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogHistory">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-3 col-sm-3 col-md-3">
                  <v-select
                    :items="getPList"
                    item-text="name"
                    item-value="id"
                    v-model="selectedProcesses"
                    label="Select Process"
                    :clearable="false"
                    @change="onChangeProcess"
                    return-object
                  ></v-select>
                </div>
                <v-col cols="12" sm="3" md="3">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="daterange1"
                        label="Date Range"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates1"
                      no-title
                      scrollable
                      range
                      :max="nowDate1"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        class="text-capitalize grey lighten-2"
                        @click="menu1 = false"
                        >Cancel
                      </v-btn>
                      <v-btn
                        dark
                        @click="$refs.menu1.save(dates1), dateRangeText1()"
                        >OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <div class="col-lg-3 col-sm-3 col-md-3">
                  <v-btn label color="primary" class="label" @click="getData()"
                    >Load Process History</v-btn
                  >
                </div>
                <div class="col-lg-3 col-sm-3 col-md-3 text-right">
                  <download-excel
                    :data="leadProcessHistory"
                    worksheet="My Worksheet"
                    :name="organizationName + '_' + getTime()"
                    :fields="export_fields"
                    :footer="footer"
                  >
                    <v-btn outlined color="teal" @click="download()"
                      >Download Excel

                      <v-icon> mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </download-excel>
                </div>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-card-text
          class="text-justify1"
          color="white"
          style="min-height: 500px"
        >
          <v-data-table
            :headers="headersProcess"
            :items="leadProcessHistory"
            item-key="name"
            class="elevation-1"
            :loading="loadingProcessHistory"
            :sort-by="['processStartDate']"
            :sort-desc="[true]"
          >
            <template #[`item.processStartDate`]="{ item }">
              <td>{{ new Date(item.processStartDate).toDateString() }}</td>
            </template>
            <template #[`item.processEndDate`]="{ item }">
              <td>{{ new Date(item.processEndDate).toDateString() }}</td>
            </template>
            <template #[`item.difference`]="{ item }">
              <td>
                {{ diff(item.processStartDate, item.processEndDate, item) }}
              </td>
            </template>
            <template v-slot:[`item.exception`]="{ item }">
              <p class="text-justify" v-if="item.exception">
                {{ truncateText(item.exception) }}
              </p>
            </template>
            <template slot="body.append">
              <tr class="green--text">
                <th>Total time spent</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{{ totaltime() }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteClient" scrollable max-width="500px">
      <v-card>
        <v-card-title>Unmap Role</v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 400px">
          <v-list class="vlistcolor" dense>
            <v-list-item
              class="list-box"
              v-for="item in editedStatus.roles"
              :key="item.roleId"
            >
              <v-list-item-content>
                <v-list-item-title class="cartitem"
                  >{{ item.roleName }} <br class="d-md-none" />
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="removeRole(item)">
                  <v-icon size="20" color="red lighten-1"
                    >mdi-minus-circle-outline</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="text-capitalize grey lighten-2"
            @click="dialogDeleteClient = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->

    <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this Role?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDeleteConfirm = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirmClient"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="dialogSubProcess" scrollable>
      <!-- <v-sheet class="d-flex flex-column align-end" height="800px">
        <div class="container"> -->
      <v-card outlined>
        <v-toolbar flat class="container pl-0">
          <v-toolbar-title class="toolbarclass">
            Sub Processes
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeSubProcess()" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="container pl-0"></v-divider>

        <v-card-text class="container pl-0">
          <!-- <v-card-title class="tabtitle1">
           
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="tabtitle2 text-capitalize"
                  @click="addSubProcess()"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Sub Process <v-icon small>mdi-add</v-icon>
                </v-btn>
              </template>
              <span>Add Sub Process</span>
            </v-tooltip>
          </v-card-title> -->
          <v-card-title>
            <div class="row">
              <div class="col-12 text-end">
                <!-- <div class="row"> -->
                <!-- <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div> -->
                <!-- <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0"> -->
                <v-btn
                  dark
                  class="mt-lg-3 mt-0"
                  @click="
                    dialogSub = true;
                    editedIndexSub = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Sub Process</v-btn
                >
                <!-- </div> -->
                <!-- </div> -->
              </div>
            </div>
          </v-card-title>
          <v-data-table
            dense
            :headers="headersSubProcess"
            :items="subProcess"
            item-key="name"
            :loading="loadingSubProcess"
            :page.sync="page"
            :items-per-page="rows"
            hide-default-footer
            class="elevation-1"
            @page-count="pagelength = $event"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="50"
                    small
                    class="mr-3"
                    @click="editItemSub(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="25"
                    small
                    @click="deleteItemSub(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete Sub Process</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse justify-center">
          <!-- <v-spacer></v-spacer> -->

          <div class="" v-if="subProcess.length > 0">
            <v-pagination
              v-model="page"
              :length="pagelength"
              :total-visible="7"
            ></v-pagination>
          </div>
          <div class="" v-if="subProcess.length > 0">
            <v-select
              v-model="rows"
              :items="rowsPerTable"
              item-text="name"
              item-value="value"
              menu-props="auto"
              hide-details
              label="Select"
              single-line
            ></v-select>
          </div>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <v-dialog v-model="dialogSub" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle1 }}</span>
        </v-card-title>
        <v-form ref="formsub" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              class="mt-1"
              v-model="editedItemSub.processName"
              :rules="[
                (v) => !!v || 'Process Name is required',
                (v) => (v && !!v.trim()) || 'Process Name cannot be blank',
              ]"
              required
            >
              <template #label>
                Process Name
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-textarea
              v-model="editedItemSub.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-text-field
              class="mt-1"
              v-model="editedItemSub.releaseProcessName"
              :rules="[
                (v) => !!v || 'Release name is required',
                (v) => (v && !!v.trim()) || 'Release name cannot be blank',
              ]"
              required
            >
              <template #label>
                Release name
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              class="mt-1"
              v-model="editedItemSub.serverProcessId"
              :rules="[
                (v) => !!v || 'Process Id is required',
                (v) => (v && !!v.trim()) || 'Process Id cannot be blank',
              ]"
              required
            >
              <template #label>
                Process Id
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-switch
              v-model="editedItemSub.isHideMenu"
              :false-value="false"
              :true-value="true"
              inset
              label="Hide Menu?"
              class="offset-4 offset-lg-0 mt-1"
            ></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdateSub"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Role?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteSub" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Sub process?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDeleteSub = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirmSub"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import orgService from "@/services/organization.service.js";
import clientBotService from "@/services/clientBot.service.js";
import leadService from "@/services/lead.service.js";
import uiPathService from "@/services/uiPath.service.js";
import downloadExcel from "vue-json-excel";
import EventBus from "../plugins/eventBus";
import roleService from "@/services/role.service.js";
export default {
  components: {
    downloadExcel,
  },
  created() {
    this.getOrganization();
    this.getAllRole();
    this.editedStatus.status = this.statusNo;

    EventBus.on("status", (x) => {
      if (x == "Implemented") {
        this.updatePagination({ page: this.pageIndex });
      }
    });

    EventBus.on("process_killed", (x) => {
      this.killstatus = x;
      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;
        this.getProcessData({
          name: this.process.releaseProcessName,
          folderId: this.selectedBot.folderId,
          tenant: this.selectedBot.tenatName,
          organizationId: this.selectedOrg,
        });
      }, 6000);
    });
  },

  computed: {
    getPList() {
      var arr = Object.assign([], this.allProcesses);
      arr.unshift({ id: "", name: "All" });

      return arr;
    },
    formTitle() {
      return this.editedNoteIndex === -1 ? "New Note" : "Edit Note";
    },
    formTitle1() {
      return this.editedIndexSub === -1
        ? "New Sub Process"
        : "Edit Sub Process";
    },
    getOList() {
      var arr = Object.assign([], this.allOrganization);
      arr.unshift({ id: "", name: "All" });

      return arr;
    },
    gridDataroles() {
      if (!(this.roleList && this.editedStatus.roles)) return [];

      let res = [];
      res = this.roleList.filter((el) => {
        return !this.editedStatus.roles.find((element) => {
          return element.roleId === el.id;
        });
      });
      return res;
    },
    headers() {
      let headers = [
        {
          text: "Lead ID",
          sortable: false,
          value: "id",
        },
        {
          text: "Organization",
          align: "start",
          sortable: false,
          value: "organization",
        },
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "imageURL",
        },
        { text: "Name", value: "name", width: "200px", sortable: false },

        { text: "Contact", value: "actions", sortable: false },
        { text: "History", value: "historyactions", sortable: false },
        { text: "Ordered on", value: "createdOn", sortable: false },
        { text: "Modified on", value: "modifiedOn", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Sub Process", value: "subprocess", sortable: false },
      ];
      if (this.allLeads.records) {
        if (this.statusNo == "Implemented" && this.selectedOrg.id > 0) {
          headers.push({
            text: "Actions",
            value: "actions1",
            sortable: false,
            width: "150px",
          });
        }
      }
      headers.push({
        text: "",
        value: "data-table-expand",
        sortable: false,
      });

      return headers;
    },
  },
  watch: {
    dialogContact(val) {
      val || this.closeContact();
    },
    dialogHistory(val) {
      val || this.closeHistory();
    },
    dialogStatus(val) {
      val || this.close();
    },
  },
  destroyed() {
    EventBus.off("status");
  },
  data: () => ({
    rowsPerTable: [
      { name: "10", value: 10 },
      { name: "25", value: 25 },
      { name: "50", value: 50 },
      { name: "100", value: 100 },
      { name: "All", value: -1 },
    ],
    editedSubProcessIndex: -1,
    rows: 10,
    pagelength: 0,
    page: 1,
    dialogSub: false,
    dialogSubProcess: false,
    subProcess: [],
    subprocessMain: {},
    dialogDeleteConfirm: false,
    dialogDeleteClient: false,
    dialogClient: false,
    organizationName: "",
    releaseName: "",
    runningProcess: {},
    botClientList: {},
    processData: {},
    processQueue: {},
    loading: false,
    allLeads: {},
    pageIndex: 0,
    selectedBot: {},
    loadingbotlog: false,

    loadingprocess: false,
    dialogBotLog: false,

    statusNo: "Implemented",
    allOrganization: [],

    loadingHistory: false,
    leadHistory: [],
    leadProcessHistory: [],
    selectedProcesses: "",
    dialogProcessHistory: false,
    daterange: "",
    nowDate: new Date().toISOString().slice(0, 10),
    dates: [],
    fromdate: "",
    todate: "",
    menu: false,

    daterange1:
      new Date().toISOString().slice(0, 10) +
      " - " +
      new Date().toISOString().slice(0, 10),
    nowDate1: new Date().toISOString().slice(0, 10),
    dates1: [
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10),
    ],
    fromdate1: new Date().toISOString().slice(0, 10),
    todate1: new Date().toISOString().slice(0, 10),

    menu1: false,
    singleExpand: true,
    dialogContact: false,
    dialogHistory: false,
    dialog: false,
    dialogStatus: false,
    dialogDelete: false,
    dialogDeleteSub: false,
    orderid: "",
    selectedOrg: "",
    valid: false,
    valid1: false,
    status: "",
    noteid: 0,
    items: "",
    roleList: [],
    statuslist: [
      { name: "New", value: "Pending" },

      { name: "In Progress", value: "InProgress" },
      { name: "Confirmed", value: "Confirmed" },
      { name: "UAT", value: "UAT" },
      { name: "Implemented", value: "Implemented" },
      { name: "Cancelled", value: "Cancelled" },
    ],
    filter: {
      searchKey: "",
      sortBy: "",
    },
    expanded: [],
    loadingSubProcess: false,
    headersChild: [
      {
        text: "Notes",
        align: "start",
        sortable: false,
        value: "message",
      },
      {
        text: "Created On",
        value: "modifiedOn",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    headersSubProcess: [
      {
        text: "Process Name",
        align: "start",
        sortable: false,
        value: "processName",
      },
      {
        text: "Description",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Server Process ID",
        align: "start",
        sortable: false,
        value: "serverProcessId",
      },
      {
        text: "Process Release Name",
        align: "start",
        sortable: false,
        value: "releaseProcessName",
      },
      {
        text: "Created On",
        value: "createdOn",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    headersProcess: [
      { text: "Process Name", value: "processName" },
      { text: "Transaction Number", value: "transactionNumber" },
      { text: "Requestor", value: "requestor" },
      { text: "Process Status", value: "status" },
      { text: "Exception Type", value: "exceptionType" },
      { text: "Message", value: "exception" },
      { text: "Additional Details", value: "additionalData" },
      { text: "Process Start Date", value: "processStartDate" },
      { text: "Process End Date", value: "processEndDate" },
      { text: "Total Time Taken", value: "difference" },
      { text: "Agent Name", value: "botName" },
    ],
    export_fields: {
      "Process Name": "processName",
      "Transaction Number": "transactionNumber",
      Requestor: "requestor",
      Status: "status",
      "Exception Type": "exceptionType",
      Exception: "exception",
      "Process Start Date": {
        field: "processStartDate",
        callback: (value) => {
          return `${new Date(value).toDateString()}`;
        },
      },
      "Process End Date": {
        field: "processEndDate",
        callback: (value) => {
          return `${new Date(value).toDateString()}`;
        },
      },
      "Agent Name": "botName",
      "Agent User Name": "botUserName",
      "Process Unique ID": "processUniqueID",
      "Total Time Taken": "difference",
      "Additional Data": "additionalData",
    },
    process: {},
    roboLogsData: [],
    dialogKill: false,
    loadingProcessHistory: false,
    overlay: false,
    absolute: true,
    killstatus: "",
    dialogBotQueue: false,
    headersLogs: [
      { text: "Time", sortable: false, value: "timeStamp" },
      { text: "Level", value: "level", sortable: false },
      { text: "Message", value: "message", sortable: false, width: "40%" },
    ],
    dialogBotData: false,
    editedIndex: -1,
    editedNoteIndex: -1,
    editedStatusIndex: -1,
    editedItem: {
      name: "",
      mobileNo: "",
      emailId: "",
    },
    editedNote: {
      id: 0,
      message: "",
      clientProcessId: 0,
    },
    removeId: -1,
    allNotes: [],
    defaultItem: {
      name: "",
      mobileNo: "",
      emailId: "",
    },
    editedItemSub: {
      id: "",
      releaseProcessName: "",
      serverProcessId: "",

      isHideMenu: true,
      processName: "",
      description: "",
    },
    defaultItemSub: {
      id: "",
      releaseProcessName: "",
      serverProcessId: "",
      processName: "",
      description: "",
      isHideMenu: true,
    },
    editedStatus: {
      id: "",
      status: "",
      releaseProcessName: "",
      serverProcessId: "",
      role: [],
      roles: [],
      menuName: "",
      isHideMenu: true,
      userSpentTimeInMinutes: "",
    },
    defaultStatus: {
      id: "",
      status: "",
      releaseProcessName: "",
      serverProcessId: "",
      role: [],
      roles: [],
      menuName: "",
      isHideMenu: true,
      userSpentTimeInMinutes: "",
    },
    allProcesses: [],
  }),

  methods: {
    // mapRole(item) {
    //   // this.editedIndexClient = this.holidayList.records.indexOf(item);
    //   // console.log("this.editedIndexClient", this.editedIndexClient);
    //   // this.editedIndexRole = item.id;
    //   // this.client = item;
    //   this.editedIndex = this.productsList.records.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogClient = true;
    // },
    unMapRole(item) {
      // this.editedIndexClient = this.productsList.records.indexOf(item);
      // this.editedIndexRole = item.id;
      // this.client = item;
      this.editedStatusIndex = this.allLeads.records.indexOf(item);
      this.editedStatus = Object.assign({}, item);
      this.dialogDeleteClient = true;
    },
    // closeClientDialog() {
    //   this.dialogClient = false;
    //   this.editedIndex = -1;
    //   this.$refs.form2.reset();
    //   this.$refs.form2.resetValidation();
    // },
    // onAddClient() {
    //   let valid = this.$refs.form2.validate();

    //   if (valid) {

    //       this.mapRoleids(
    //         {
    //           roleIdList: this.editedItem.role,
    //         },
    //         this.editedItem.id
    //       );
    //     // }
    //     this.closeClientDialog();
    //   }
    // },
    // async mapRoleids(item, id) {
    //   let result = await roleService.mapRole(item, id);
    //   if (result.status == 200) {
    //     this.status = "Role Mapping successfull";

    //     setTimeout(() => {
    //       this.status = "";
    //     }, 2000);
    //     this.updatePagination({ page: this.pageIndex });
    //   }
    // },
    removeRole(item) {
      this.removeRoleId = item.id;
      this.dialogDeleteConfirm = true;
    },
    deleteConfirmClient() {
      // var list = [];

      //     list.push({
      //       clientId: this.removeId,
      //       isDelete:true,
      //     });

      this.ConfirmClient(this.removeRoleId);
      this.dialogDeleteConfirm = false;
    },
    async ConfirmClient(mappingId) {
      let result = await roleService.unmapRoleClient(mappingId);
      if (result.status == 200) {
        this.status = "Role removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    async getAllRole() {
      let result = await roleService.getAllRole();

      this.roleList = result.records;
    },

    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }
        let data = {
          Skip: 0,
          Take: 10,
          SearchKey: this.filter.searchKey,
          Status: this.statusNo,
          OrganizationId: this.selectedOrg.id,
          FromDate: this.fromdate,
          ToDate: this.todate,
        };
        this.getAllLeads(data);
      } else {
        this.fromdate = "";
        this.todate = "";
      }
    },

    dateRangeText1() {
      this.dates1 = this.dates1.sort();
      this.daterange1 = this.dates1.sort().join(" - ");
      if (this.dates1.length > 0) {
        if (this.dates1.length == 2) {
          this.fromdate1 = this.dates1[0];
          this.todate1 = this.dates1[1];
        } else {
          this.fromdate1 = this.dates1[0];
          this.todate1 = this.dates1[0];
        }
      } else {
        this.fromdate1 = new Date().toISOString().slice(0, 10);
        this.todate1 = new Date().toISOString().slice(0, 10);
      }
    },
    getData() {
      this.getLeadHistory(
        this.editedProcess.serverProcessId,
        this.fromdate1,
        this.todate1
      );
    },
    async getProcessList(data) {
      let result = await leadService.getAllProcess(data);

      this.allProcesses = result;
    },
    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);
      this.loading = false;

      this.botClientList = result;
    },
    getTime() {
      var today = new Date();
      var y = today.getFullYear();
      var m = today.getMonth() + 1;
      var d = today.getDate();
      var h = today.getHours();
      var mi = today.getMinutes();
      var s = today.getSeconds();
      return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
    },
    onChange() {
      if (this.selectedOrg == null) {
        this.selectedOrg = "";
      } else {
        this.organizationName = this.selectedOrg.name;
        let data = {
          skip: 0,
          take: 1,
          organizationId: this.selectedOrg.id,
        };
        this.getClientBot(data);
        this.getProcessList({ orgnaizationId: this.selectedOrg.id });
      }

      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg.id,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg.id,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },

    save() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.updateStatus({
          status: this.editedStatus.status,
          clientProcessId: this.editedStatus.id,
        });
        if (this.editedStatus.status == "Implemented") {
          //           var arr = Array.from(this.editedStatus.roles);

          //       let i=0;
          //       let j=0;
          //       for(i=0;i<this.editedStatus.role.length;i++){
          //         for(j=0;j<this.editedStatus.roles.length;j++){
          //         if(this.editedStatus.role[i]==this.editedStatus.roles[j].roleId)
          //         {
          // arr.splice
          //         }
          //         var list=[];
          // list.push(item.roles[i].roleId)
          //       }
          //     }
          // var arr = Array.from(this.mergeddata);
          // console.log("result",this.editedStatus)
          // var result = this.editedStatus.roles.filter(function(o1){
          //     // filter out (!) items in result2
          //     return !this.editedStatus.role.some(function(o2){
          //         return o1.roleId === o2;          // assumes unique id
          //     });
          // });
          // console.log("result",result)
          this.updateReleaseName(
            {
              releaseProcessName: this.editedStatus.releaseProcessName,
              serverProcessId: this.editedStatus.serverProcessId,
              roleIdList: this.editedStatus.role,
              isHideMenu: this.editedStatus.isHideMenu,
              menuName: this.editedStatus.menuName,
              userSpentTimeInMinutes: this.editedStatus.userSpentTimeInMinutes,
            },
            this.editedStatus.id
          );
        }

        this.close();
      }
    },

    async updateStatus(input) {
      let result = await leadService.changeStatus(input);
      if (result.status == 200) {
        this.status = "Status updated successfully";
        EventBus.emit("status", input.status);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    async updateReleaseName(input, id) {
      let result = await leadService.updateReleaseName(input, id);
      if (result.status == 200) {
        this.status = "Release name added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        // this.updateRoles(
        //     {
        //       roleIdList: roleIdList,
        //       clientId: organizationId,
        //     },
        //     processId
        //   );
      }
    },
    async updateRoles(input, id) {
      let result = await roleService.updateRoles(input, id);
      if (result.status == 200) {
        this.status = "Roles added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    cancel() {},
    search() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg.id,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      this.filter.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg.id,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    clearDate() {
      this.dates = [];
      this.fromdate = "";
      this.todate = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: this.statusNo,
        OrganizationId: this.selectedOrg.id,
        FromDate: this.fromdate,
        ToDate: this.todate,
      };
      this.getAllLeads(data);
    },
    contactItem(item) {
      this.editedIndex = this.allLeads.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogContact = true;
    },
    closeContact() {
      this.dialogContact = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async historyItem(item) {
      this.loadingHistory = true;
      let result = await leadService.getLeadHistory({ clientProcessId: item });
      this.loadingHistory = false;
      this.leadHistory = result;
      this.dialogHistory = true;
    },

    SubProcessItem(item) {
      this.loadingSubProcess = true;
      this.editedSubProcessIndex = this.allLeads.records.indexOf(item);
      this.subProcess = item.subProcesses;
      this.subprocessMain = item;
      this.loadingSubProcess = false;
      this.dialogSubProcess = true;
    },
    closeSubProcess() {
      this.editedSubProcessIndex = -1;
      this.dialogSubProcess = false;
    },
    closeHistory() {
      this.dialogHistory = false;
    },
    closeDialogHistory() {
      this.dialogProcessHistory = false;
    },
    processHistoryItem(item) {
      this.selectedProcesses = item.id;
      this.editedProcess = Object.assign({}, item);

      this.getLeadHistory(item.serverProcessId, this.fromdate1, this.todate1);
      this.dialogProcessHistory = true;
    },
    async getLeadHistory(item, fromdate, todate) {
      this.leadProcessHistory = [];
      this.loadingProcessHistory = true;
      let result = await uiPathService.getProcessHistory({
        StartDate: fromdate,
        EndDate: todate,
        ProcessId: item,
      });
      this.loadingProcessHistory = false;
      this.leadProcessHistory = result.data;
    },

    loadDetails({ item, value }) {
      if (value) {
        this.noteid = item.id;
        this.getAllNotes(item.id);
      }
    },
    async getAllNotes(id) {
      this.allNotes = [];
      let result = await leadService.getAllNotes({ clientProcessId: id });

      this.allNotes = result;
    },

    editItem(item) {
      this.editedNoteIndex = this.allNotes.indexOf(item);
      this.editedNote = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedNoteIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    onUpdate() {
      let valid = this.$refs.form.validate();

      if (valid) {
        if (this.editedNoteIndex == -1) {
          this.createNote(
            this.editedNote.message,
            this.editedNote.clientProcessId
          );
        } else {
          const id = this.allNotes[this.editedNoteIndex].id;

          this.editedNote.id = id;
          this.updateNote(this.editedNote);
        }
        this.closeDialog();
      }
    },

    async createNote(note, id) {
      let result = await leadService.create(note, id);

      if (result.status == 200) {
        this.status = "Note added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },
    async updateNote(item) {
      let result = await leadService.update(item);
      if (result.status == 200) {
        this.status = "Note updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },
    async deleteNote(item) {
      let result = await leadService.delete(item);
      if (result.status == 200) {
        this.status = "Note deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },

    remove(item) {
      this.removeId = item.id;
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.deleteNote({ id: this.removeId });
      this.dialogDelete = false;
    },
    statusItem(item) {
      this.editedStatusIndex = this.allLeads.records.indexOf(item);

      this.editedStatus = Object.assign({}, item);
      var list = [];
      let i = 0;
      for (i = 0; i < item.roles.length; i++) {
        list.push(item.roles[i].roleId);
      }
      this.editedStatus.role = list;
      this.dialogStatus = true;
    },
    close() {
      this.dialogStatus = false;

      this.editedStatusIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "Pending") {
        newstatus = "New";
      } else if (id == "Implemented") {
        newstatus = "Implemented";
      } else if (id == "Confirmed") {
        newstatus = "Confirmed";
      } else if (id == "InProgress") {
        newstatus = "In Progress";
      } else if (id == "UAT") {
        newstatus = "UAT";
      } else if (id == "Cancelled") {
        newstatus = "Cancelled";
      }
      return newstatus;
    },
    processDetails(item) {
      this.process = Object.assign({}, item);
      if (this.botClientList.records) {
        if (this.botClientList.records.length > 0) {
          if (
            this.botClientList.records[0].folderId &&
            this.botClientList.records[0].tenatName
          ) {
            this.getProcessData({
              name: item.releaseProcessName,
              folderId: this.botClientList.records[0].folderId,
              tenant: this.botClientList.records[0].tenatName,
              orgId: this.selectedOrg.id,
            });
            this.dialogBotData = true;
            this.selectedBot = this.botClientList.records[0];
          } else {
            this.status = "Please contact admin for process details";
            setTimeout(() => {
              this.status = "";
            }, 2000);
          }
        } else {
          this.status =
            "Agent not assigned! Please contact admin for process details ";
          setTimeout(() => {
            this.status = "";
          }, 2000);
        }
      }
    },

    processQueueDetails(item) {
      if (this.botClientList.records) {
        if (this.botClientList.records.length > 0) {
          if (
            this.botClientList.records[0].folderId &&
            this.botClientList.records[0].tenatName
          ) {
            this.dialogBotQueue = true;
            this.selectedBot = this.botClientList.records[0];
            this.getProcessQueue({
              releaseName: item.releaseProcessName,
              folderId: this.botClientList.records[0].folderId,
              tenant: this.botClientList.records[0].tenatName,
              orgId: this.selectedOrg.id,
            });
          } else {
            this.status = "Please contact admin for process queues ";
            setTimeout(() => {
              this.status = "";
            }, 2000);
          }
        } else {
          this.status =
            "Agent not assigned! Please contact admin for process queues ";
          setTimeout(() => {
            this.status = "";
          }, 2000);
        }
      }
    },
    refresh() {
      if (this.processData.data) {
        if (this.processData.data.jobs.length > 0) {
          this.getRobotLogs({
            processName: this.releaseName,
            folderId: this.selectedBot.folderId,
            tenant: this.selectedBot.tenatName,
            orgId: this.selectedOrg.id,
          });
        }
      }
    },
    onChangeProcess(e) {
      this.editedProcess = Object.assign({}, e);
    },

    async getRobotLogs(data) {
      this.loadingbotlog = true;
      this.roboLogsData = [];
      let result = await uiPathService.getRobotLogs(data);
      this.loadingbotlog = false;
      this.roboLogsData = result.value.data;
    },
    async getProcessData(data) {
      this.processData = [];
      this.roboLogsData = [];
      this.loadingprocess = true;
      let result = await uiPathService.getProcessData(data);
      this.loadingprocess = false;
      this.processData = result;
      this.getRunningProcessData(data.name);
      this.refresh();
    },
    getRunningProcessData(name) {
      if (this.processData.data.jobs.length > 0) {
        let runningJobs = this.processData.data.jobs.find(
          (c) => c.state === "Running"
        );
        if (runningJobs) {
          this.runningProcess = runningJobs;
        } else {
          this.runningProcess = null;
        }
      } else {
        this.runningProcess = null;
      }
      this.releaseName = name;
    },
    async getProcessQueue(data) {
      this.processQueue = [];
      this.loadingprocess = true;
      let result = await uiPathService.getProcessQueue(data);
      this.loadingprocess = false;
      this.processQueue = result;
    },

    footer() {
      return "Total Time Spent : " + this.totaltime();
    },
    totaltime() {
      let dd = 0;
      this.leadHistory.forEach((item) => {
        dd = dd + this.diffduration(item.processStartDate, item.processEndDate);
      });
      return this.ddTime(dd);
    },
    ddTime(duration) {
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    diffduration(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      let dif = toDate - fromDate;
      return dif;
    },
    killProcess() {
      if (this.processData.data.length > 0) {
        if (this.processData.data[0].state == "Running") {
          this.jobId = this.processData.data[0].id;
        } else {
          this.jobId = "";
        }
      }
      if (this.jobId) {
        this.dialogKill = true;
      } else {
        this.status = "Process Terminating...";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    killConfirm() {
      this.killBotProcess({
        jobId: this.jobId,
        folderId: this.selectedBot.folderId,
        tenant: this.selectedBot.tenatName,
        orgId: this.selectedOrg.id,
      });
      this.dialogKill = false;
    },
    async killBotProcess(data) {
      let result = await uiPathService.killBotProcess(data);
      console.log(result);
      this.killstatus = "Killed Successfully";
      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;

        this.getProcessData({
          name: this.process.releaseProcessName,
          folderId: this.selectedBot.folderId,
          tenant: this.selectedBot.tenatName,
          orgId: this.selectedOrg.id,
        });
      }, 6000);
    },
    closeLogs() {
      this.dialogBotData = false;
    },
    closeQueue() {
      this.dialogBotQueue = false;
    },

    async getOrganization() {
      let result = await orgService.getAllOrg();
      this.allOrganization = result;
    },
    async getAllLeads(input) {
      this.loading = true;
      let result = await leadService.getAllLeads(input);
      this.loading = false;
      this.allLeads = result;
      if (this.allLeads.records.length > 0 && this.editedStatusIndex != -1) {
        this.editedStatus = this.allLeads.records[this.editedStatusIndex];
      }
      if (
        this.allLeads.records.length > 0 &&
        this.editedSubProcessIndex != -1
      ) {
        this.subProcess =
          this.allLeads.records[this.editedSubProcessIndex].subProcesses;
        // this.subProcess = item.subProcesses;
        this.subprocessMain = this.allLeads.records[this.editedSubProcessIndex];
      }
    },
    diff(from, to, item) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      item.duration = duration;
      item.difference = hours + ":" + minutes + ":" + seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    download() {
      if (this.leadHistory.length == 0) {
        this.status = "No Process History ";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },

    onUpdateSub() {
      console.log(" this.subprocessMain.id", this.subprocessMain.id);
      let valid = this.$refs.formsub.validate();
      if (valid) {
        if (this.editedIndexSub == -1) {
          console.log(" this.subprocessMain.id", this.subprocessMain.id);
          this.createSub(
            {
              processName: this.editedItemSub.processName,
              description: this.editedItemSub.description,
              releaseProcessName: this.editedItemSub.releaseProcessName,
              serverProcessId: this.editedItemSub.serverProcessId,
              isHideMenu: this.editedItemSub.isHideMenu,
              imageURL: this.subprocessMain.imageURL,
            },
            this.subprocessMain.id
          );
        } else {
          const id = this.subProcess[this.editedIndexSub].id;
          this.editedItemSub.id = id;
          this.updateSub(
            {
              processName: this.editedItemSub.processName,
              description: this.editedItemSub.description,
              releaseProcessName: this.editedItemSub.releaseProcessName,
              serverProcessId: this.editedItemSub.serverProcessId,
              isHideMenu: this.editedItemSub.isHideMenu,
              imageURL: this.subprocessMain.imageURL,
            },
            id
          );
        }
        this.closeDialogSub();
      }
    },

    async createSub(item, id) {
      console.log("id", id);
      let result = await leadService.createSub(item, id);
      console.log("result", result);
      if (result.status == 200) {
        this.status = "Sub Process added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async updateSub(item, id) {
      let result = await leadService.updateSub(item, id);
      if (result.status == 200) {
        this.status = "Sub Process updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async deleteSub(id) {
      let result = await leadService.deleteSub(id);
      if (result.status == 200) {
        this.status = "Sub Process Deleted successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    editItemSub(item) {
      this.editedIndexSub = this.subProcess.indexOf(item);
      this.editedItemSub = Object.assign({}, item);
      this.dialogSub = true;
    },
    closeDialogSub() {
      this.dialogSub = false;
      this.editedIndexSub = -1;
      this.$refs.formsub.reset();
      this.$refs.formsub.resetValidation();
      this.editedItemSub.isHideMenu = true;
    },
    resetStatus() {
      this.subProcess[this.editedIndexSub].isHideMenu =
        this.subProcess[this.editedIndexSub].isHideMenu == true ? false : true;
    },
    deleteItemSub(item) {
      this.editedItemSub = Object.assign({}, item);
      this.editedIndexSub = this.subProcess.indexOf(item);
      this.dialogDeleteSub = true;
    },
    deleteConfirmSub() {
      const id = this.subProcess[this.editedIndexSub].id;
      this.editedItemSub.id = id;

      this.deleteSub(id);
      this.dialogDeleteSub = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.v-card__title {
  display: block !important;
}
.cursor-point {
  cursor: pointer;
}
/deep/.v-date-picker-table {
  height: 100% !important;
}
/deep/.ttl.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}
/deep/.ttl.v-data-table .v-data-table-header tr th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}
</style>
<template>
  <div class="container">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
      class="mb-3 align-self-center"
      row
      wrap
    >
      <v-tab v-for="item in tabs" :href="'#tab-' + item" :key="item">
        {{ item }}
      </v-tab>

      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="(item, index) in more"
            :key="index"
            @click="addItem(item)"
          >
            {{ item }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-tabs-items v-model="currentItem" height="auto">
      <v-tab-item
        key="Add New Agent Image"
        height="auto"
        value="tab-Add New Agent Image"
      >
        <v-row>
          <v-col cols="12" md="4" class="text-center">
            <image-input v-model="avatar">
              <div slot="activator">
                <v-avatar
                  size="150px"
                  v-ripple
                  v-if="!avatar"
                  class="grey lighten-3 mb-3"
                >
                  <span>Click to add Agent</span>
                </v-avatar>
                <v-avatar size="150px" v-ripple v-else class="mb-3">
                  <img :src="avatar.imageURL" alt="avatar" />
                </v-avatar>
              </div>
            </image-input>
          </v-col>
          <v-col cols="12" md="8">
            <v-slide-x-transition>
              <div>
                <v-form ref="form1" v-model="valid1" lazy-validation>
                  <v-text-field
                    v-model="name"
                    label="Name"
                    required
                    :rules="[
                      (v) => !!v || 'Name is required',
                      (v) => (v && !!v.trim()) || 'Name cannot be blank',
                      (v) =>
                        (v &&
                          !adminBotNames.find(
                            (t) =>
                              t.name.toLowerCase().trim() ==
                              v.toLowerCase().trim()
                          )) ||
                        'Name already exists',
                    ]"
                  >
                    <template #label>
                      Name <span class="red--text"><strong>* </strong></span>
                    </template>
                  </v-text-field>
                  <v-textarea
                    v-model="description"
                    required
                    :rules="[
                      (v) => !!v || 'Description is required',
                      (v) => (v && !!v.trim()) || 'Description cannot be blank',
                    ]"
                  >
                    <template #label>
                      Description
                      <span class="red--text"><strong>* </strong></span>
                    </template>
                  </v-textarea>
                </v-form>
              </div>
            </v-slide-x-transition>
          </v-col>
        </v-row>

        <div class="text-right">
          <v-btn
            :loading="loading"
            :disabled="loading"
            dark
            @click="uploadBots"
            v-if="avatar != null && saved == false"
          >
            Upload
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </div>
      </v-tab-item>

      <v-tab-item key="View/Edit Agent Image" value="tab-View/Edit Agent Image">
        <BotView></BotView>
      </v-tab-item>

      <v-tab-item
        key="Client owned Agent Images"
        value="tab-Client owned Agent Images"
      >
        <ClientBotView></ClientBotView>
      </v-tab-item>
    </v-tabs-items>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusbot" type="error">
      {{ statusbot }}
    </v-alert>
  </div>
</template>
<script>
import EventBus from "../plugins/eventBus";
import botImagesService from "@/services/botImages.service.js";
import ImageInput from "../components/ImageInput.vue";
import BotView from "../components/BotView.vue";
import ClientBotView from "../components/ClientBotView.vue";
export default {
  components: {
    ImageInput,
    BotView,
    ClientBotView,
  },
  created() {
    this.getBotImageNames();
    EventBus.on("bot_changes", () => {
      this.getBotImageNames();
    });
  },
  destroyed() {
    EventBus.off("bot_changes");
  },
  data: () => ({
    tabs: [],
    more: [],
    currentItem: "tab-Add New Agent Image",
    tab: null,
    loading: false,
    tabItems: [
      "Add New Agent Image",
      "View/Edit Agent Image",
      "Client owned Agent Images",
    ],
    dialog: false,
    statusbot: "",
    avatar: null,
    saving: false,
    saved: false,
    editedIndex: -1,
    attachment: null,
    type: "",
    status: "",
    imageUrl: null,
    name: "",
    description: "",
    valid: false,
    valid1: false,

    editedItem: {},
    typeList: [
      { name: "Crying", value: "cry" },
      { name: "Happy", value: "happy" },
      { name: "Busy", value: "busy" },
      { name: "Sad", value: "sad" },
    ],

    adminBotNames: [],
  }),
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
        if (this.saved == false) {
          if (this.avatar) {
            if (
              !this.adminBotNames.find(
                (t) =>
                  t.imageName.toLowerCase().trim() ==
                  this.avatar.imageFile.name.toLowerCase().trim()
              )
            ) {
              this.saved = false;
            } else {
              this.statusbot = "This Image is already present";
              setTimeout(() => {
                this.statusbot = "";
              }, 2000);
              this.avatar = null;
              this.saved = true;
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Agent" : "Edit Agent";
    },
  },
  methods: {
    async getBotImageNames() {
      let result = await botImagesService.getAdminBotImageNames();

      this.adminBotNames = result;
    },
    uploadImage() {
      this.saving = true;
      this.savedAvatar();
    },
    savedAvatar() {
      this.saving = false;
      this.dialog = true;
      this.editedIndex = -1;
    },

    uploadBots() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.loading = true;
        let formData = new FormData();
        formData.append("Name", this.name);
        formData.append("Description", this.description);
        formData.append("Type", "main");
        formData.append("ImageFile", this.avatar.imageFile);

        this.addBot(formData);
      }
    },

    async addBot(item) {
      let result = await botImagesService.create(item);

      if (result.status == 200) {
        this.status = "Agent created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.clear();
        EventBus.emit("bot_changes", "Updated Successfully");
      }
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    createImage(file) {
      const file1 = file;
      if (file1) {
        this.imageUrl = URL.createObjectURL(file1);
        URL.revokeObjectURL(file1);
      } else {
        this.imageUrl = null;
      }
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    clear() {
      this.editedIndex = -1;
      this.avatar = null;
      this.saved = true;
      this.loading = false;
      if (this.$refs.form1 != undefined) {
        this.$refs.form1.reset();
        this.$refs.form1.resetValidation();
      }
    },
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 2);
      this.more = temp.splice(0);
    },
  },
};
</script>
<style scoped lang="scss">
.border {
  border: 1px dashed #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
</style>
<template>
  <v-footer padless class="secondary">
    <v-card
      flat
      tile
      width="100%"
      class="secondary white--text text-center container my-5"
    >
      <v-row>
        <v-col md="6" cols="12" no-gutters class="px-md-0">
          <v-col md="7" cols="12" class="text-left" no-gutters>
            <img
              class="cursor-point"
              :src="require('@/assets/footerlogo.svg')"
              :height="!isMobile ? 42 : 30"
              contain
            />
            <p class="footeralign mt-4">
              Helps to move Credit Union or Community Bank towards strategic
              transformation with automation
            </p>
          </v-col>
        </v-col>
        <v-col md="6" cols="12" class="text-left px-md-0" no-gutters>
          <v-expansion-panels accordion v-model="panel" multiple dark>
            <v-expansion-panel class="secondary col-md-6 col-12 elevation-0">
              <h3 class="d-none d-md-block py-2 footername">
                {{ item[0].name }}
              </h3>
              <v-expansion-panel-header class="d-md-none footername">{{
                item[0].name
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <li v-for="(tag, k) in item[0].tags" :key="k" class="footerval">
                  <router-link :to="tag.url">
                    <a class="footercontent">{{ tag.urlname }} </a>
                  </router-link>
                </li>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              class="secondary col-md-6 col-12 elevation-0 text-md-right"
            >
              <h3 class="d-none d-md-block py-2 footername">
                {{ item[1].name }}
              </h3>
              <v-expansion-panel-header class="d-md-none footername">{{
                item[1].name
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <li v-for="(tag, k) in item[1].tags" :key="k" class="footerval">
                  <router-link :to="tag.url">
                    <a class="footercontent">{{ tag.urlname }} </a>
                  </router-link>
                </li>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-divider class="mt-16" style="background: rgba(255, 255, 255, 0.1)"></v-divider>
      <div class="text-left pl-md-0 mt-5 d-flex align-center">
        <v-btn
          dark
          text
          :ripple="false"
          class="footersub text-capitalize px-md-0 min-button"
          href="https://www.digitalalign.com/"
          target="_blank"
          >Digital Align</v-btn
        >
        <v-divider dark class="mx-md-4 mx-lg-4 my-2" vertical></v-divider>
        <v-btn
          :ripple="false"
          dark
          text
          class="footersub text-capitalize px-md-0 min-button"
          >Alignxcel
        </v-btn>
        <v-spacer></v-spacer>
        <span class="footersub  px-md-0 min-button mr-3">
          <small>Do you want to report abuse? click here to report.</small></span
        >
        <sw-report-abuse
          class="text-start"
          :email="swEmail"
          :accessKey="swAccessKey"
        ></sw-report-abuse>
      </div>
      <div class="d-md-flex align-center">
        <v-card-text class="copyright text-left pl-md-0">
          © 2022 Alignxcel. Trademark and brand are the property of Digital Align Inc
        </v-card-text>
        <v-card-text class="ml-auto text-right pr-md-0">
          <a
            target="_blank"
            v-bind:href="social.url"
            v-for="(social, j) in socials"
            :key="j"
          >
            <v-btn class="ml-4 white--text" icon>
              <v-icon size="15px" color="greyText">{{ social.icon }}</v-icon>
            </v-btn>
          </a>
        </v-card-text>
      </div>
    </v-card>
  </v-footer>
</template>
<script>
import authService from "@/services/auth.service.js";
import swapnaOrgConfig from "@/services/swapna.org.config";
export default {
  computed: {},
  data: () => ({
    isMobile: false,
    swEmail: swapnaOrgConfig.email,
    swAccessKey: swapnaOrgConfig.accessKey,
    isLoggedIn: authService.isLoggedIn ? true : undefined,
    logo: authService.authData ? authService.authData.logoUrl : undefined,

    panel: [],
    socials: [
      // { name: "facebook", icon: "mdi-facebook", url: "" },
      {
        name: "twitter",
        icon: "mdi-twitter",
        url: "https://twitter.com/digitalalign",
      },
      {
        name: "linkedin",
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/company/digital-align-inc",
      },
      // { name: "instagram", icon: "mdi-instagram", url: "" },
    ],
    item: [
      {
        name: "Align MarketPlace",
        tags: [
          { urlname: "Contact Us", url: "/contact-us" },
          { urlname: "About Us", url: "/about-us" },
        ],
      },

      {
        name: "Help",
        tags: [
          { urlname: "Help Center", url: "/help-center" },
          { urlname: "Privacy Policy", url: "/privacy-policy" },
          { urlname: "Terms and Conditions", url: "/terms-conditions" },
        ],
      },
    ],
  }),

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 600;
      if (window.innerWidth >= 960) {
        this.panel = [...Array(4).keys()].map((k, i) => i);
      } else {
        this.panel = [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
@media (min-width: 960px) {
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel:not(:first-child)::after {
    border-top: none;
  }
  /deep/.v-expansion-panel-content__wrap {
    padding: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
  }
  .footerval {
    margin-top: 16px;
  }
}
.footername {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* identical to box height */

  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.2) !important;
}
.footercontent {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #ffffff;
}
.resize {
  text-align: center;
}
.resize {
  margin-top: 3rem;
  font-size: 1.25rem;
}
/*RESIZESCREEN ANIMATION*/
.right {
  animation: rightanime 1s linear infinite;
}

.left {
  animation: leftanime 1s linear infinite;
}
@keyframes rightanime {
  50% {
    transform: translateX(10px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(10px);
    opacity: 0;
  }
}
@keyframes leftanime {
  50% {
    transform: translateX(-10px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}
.footeralign {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $header;
}
.footersub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $white;
}
.copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $header;
}
.min-button::before {
  display: none;
}
/deep/.logoSec .v-image {
  height: auto !important;
  width: 24px !important;
}

/deep/ .popup-title {
  font-family: monospace !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: -0.05em !important;
  color: #000000 !important;
}
/deep/ #getHelp-title {
  /* font-family: "Roboto Mono"; */
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: -0.05em !important;

  /* Palette/Transparent Black/100 */

  color: #000000 !important;
}

/deep/ #helpline-title {
  font-family: "Roboto Mono" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: -0.05em !important;

  /* Palette/Transparent Black/100 */

  color: #000000 !important;
}
</style>

<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text>
        <v-btn @click="chooseImage" class="grey lighten-2"> Select Agent </v-btn>

        <v-img
          v-if="selectedImage"
          class="text-center"
          width="100px"
          :src="selectedImage"
        ></v-img>
        <v-select
          v-model="bot.orgId"
          :items="regOrganization"
          item-text="name"
          item-value="id"
          label="Organization"
          :clearable="true"
          :rules="[(v) => !!v || 'Organization is required']"
          ><template #label>
            Organization <span class="red--text"><strong>* </strong></span>
          </template>
        </v-select>
        <v-text-field
          v-model="bot.name"
          label="Name"
          validate-on-blur
          required
          :rules="[
            (v) => !!v || 'Name is required',
            (v) => (v && !!v.trim()) || 'Name cannot be blank',
            (v) =>
              (v &&
                !clientBotNames.find(
                  (t) => t.name.toLowerCase() == v.toLowerCase()
                )) ||
              'Name already exists',
          ]"
        >
          <template #label>
            Name <span class="red--text"><strong>* </strong></span>
          </template>
        </v-text-field>
        <v-radio-group
          v-model="bot.serverType"
          @change="serverTypeChange"
          :rules="[(v) => !!v || 'Type is required']"
        >
          <template v-slot:label>
            <label class="v-label v-label--active"
              >Orchestrator type<span class="red--text"
                ><strong>* </strong></span
              ></label
            >
          </template>
          <v-radio label="On - Premises" value="On - Premises"></v-radio>
          <v-radio label="Cloud" value="Cloud"></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="bot.serverType == 'Cloud'"
          v-model="bot.tenatName"
          label="Tenant Name"
          validate-on-blur
          required
          :rules="[
            (v) => !!v || 'Tenant Name is required',
            (v) => (v && !!v.trim()) || 'Tenant Name cannot be blank',
          ]"
        >
          <template #label>
            Tenant Name <span class="red--text"><strong>* </strong></span>
          </template>
        </v-text-field>
        <v-text-field
          v-model="bot.license"
          label="License"
          validate-on-blur
          required
          :rules="[
            (v) => !!v || 'License is required',
            (v) => (v && !!v.trim()) || 'License cannot be blank',
            (v) =>
              (v &&
                !botNames.find(
                  (t) =>
                    t.license.toLowerCase().trim() == v.toLowerCase().trim()
                )) ||
              'License Key already exists',
          ]"
        >
          <template #label>
            License <span class="red--text"><strong>* </strong></span>
          </template>
        </v-text-field>

        <v-text-field
          v-model="bot.serverName"
          label="Server Name"
          maxlength="20"
        >
        </v-text-field>
      </v-card-text>
      <div class="text-center">
        <span class="red--text text-center"
          ><strong> {{ statusbot }} </strong></span
        >
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-3 grey lighten-2" depressed @click="closeDialog">
          Clear
        </v-btn>
        <v-btn
          dark
          depressed
          @click="addLicense"
          :loading="loading"
          :disabled="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>

    <v-dialog
      v-if="dialogImage"
      v-model="dialogImage"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogImage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Image Gallery</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialogImage = false"> Done </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              v-for="item in adminBotNames"
              :key="item.value"
              lg="2"
              md="2"
              @click="
                bot.botPictureId = item.value;
                selectedImage = item.url;
                statusbot = '';
              "
            >
              <div class="box-list" :class="getActiveClass(item.value)">
                <v-img :src="item.url"></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import EventBus from "../plugins/eventBus";

import botImagesService from "@/services/botImages.service.js";
import orgService from "@/services/organization.service.js";

import clientBotService from "@/services/clientBot.service.js";
export default {
  created() {
    this.getOrganization();
    this.getLicenseNames();
    this.getClientBotNames();

    EventBus.on("exist_bot_changes", (x) => {
      this.status = x;
      this.closeDialog();
      this.loading = false;
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });

    EventBus.on("license_changes", (x) => {
      this.status = x;
      this.deleteId = 0;
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("license_add_delete", () => {
      this.getLicenseNames();
    });
  },
  computed: {},
  data: () => ({
    adminBotNames: [],
    regOrganization: [],
    botNames: [],
    clientBotNames: [],
    loading: false,
    dialogImage: false,
    valid: false,
    status: "",
    statusbot: "",
    selectedBot: {},
    selectedImage: "",
    bot: {
      botPictureId: 0,
      license: "",
      serverName: "",
      serverType: "",
      tenatName: "",
      folderId: 0,
      name: "",
    },
    valid1: false,
    editlicense: {
      id: 0,
      license: "",
      status: 1,
      serverName: "",
      serverType: "",
      tenatName: "",
      deleteId: 0,
    },
  }),
  methods: {
    closeDialog() {
      this.bot.botPictureId = 0;
      this.selectedImage = "";
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    addLicense() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.bot.botPictureId == 0) {
          this.statusbot = "Please select Agent image";
        } else {
          this.loading = true;
          this.statusbot = "";
          this.addExistingBot(
            {
              botImageId: parseInt(this.bot.botPictureId),
              name: this.bot.name,
              license: this.bot.license,
              serverName: this.bot.serverName,
              serverType: this.bot.serverType,
              tenatName: this.bot.tenatName,
              folderId: 0,
            },
            this.bot.orgId
          );
        }
      }
    },
    async addExistingBot(item, orgId) {
      let result = await clientBotService.addExistingBot(item, orgId);

      if (result.status == 200) {
        EventBus.emit("license_add_delete", "Added successfully");
        EventBus.emit("exist_bot_changes", "Agent Added successfully");
        this.status = "Agent added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.closeDialog();

        this.getClientBotNames();
        this.loading = false;
      }
    },
    chooseImage() {
      this.getBotImageNames();
    },
    getActiveClass(id) {
      return id == this.bot.botPictureId ? "active" : "";
    },

    serverTypeChange() {
      if (this.bot.serverType != "Cloud") {
        this.bot.tenatName = "";
      }
      if (this.editlicense.serverType != "Cloud") {
        this.editlicense.tenatName = "";
      }
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    async getLicenseNames() {
      let result = await clientBotService.getLicenseNames();

      this.botNames = result;
    },
    async getBotImageNames() {
      let result = await botImagesService.getBotImageNames();

      this.adminBotNames = result;
      this.dialogImage = true;
    },
    async getClientBotNames() {
      let result = await clientBotService.getClientBotNames();

      this.clientBotNames = result;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-list {
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}
.active {
  border-color: #1a8ccf;
  border-width: 5px;
}
</style>
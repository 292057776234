<template>
  <div class="container-fluid" style="overflow: hidden">
    <div id="cardBackground">
      <div class="row mt-5">
        <div class="col-lg-4 col-1"></div>
        <div class="col-lg-4 col-10 mt-16 mb-16 log-box">
          <div class="card my-3 mx-5 mt-7">
            <div class="card-body p-4 p-sm-5">
              <!-- <img src="../assets/Pattern.png" class="patternImg" /> -->

              <h2 class="mt-5 mb-2 mainLogin">Forgot password</h2>
              <label class="sec-login">No worries we got your back</label>
              <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
                <label class="title-login"
                  >Email Id<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  ></label
                ><v-text-field
                  class="mt-1"
                  solo
                  v-model="emailId"
                  :rules="emailRules"
                  required
                  :readonly="checkisForgot"
                  validate-on-blur
                  v-on:keyup.enter="validate"
                >
                  <template #label> Email Id </template>
                </v-text-field>
                <label for="" class="title-login" v-if="isForgot"
                  >Password<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  >
                </label>
                <v-text-field
                  class="mt-1"
                  solo
                  v-if="isForgot"
                  :type="show1 ? 'text' : 'password'"
                  v-model="password"
                  :rules="passwordRules"
                  required
                  maxlength="20"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  @keypress.native="noSpace($event)"
                  v-on:keyup.enter="validate"
                  validate-on-blur
                  ><template #label> New Password </template></v-text-field
                >

                <label for="" class="title-login" v-if="isForgot"
                  >Confirm Password<span class="red--text"
                    ><strong>&nbsp;* </strong></span
                  >
                </label>
                <v-text-field
                  class="mt-1"
                  solo
                  v-if="isForgot"
                  :type="showC ? 'text' : 'password'"
                  v-model="confirm_password"
                  maxlength="20"
                  :rules="[
                    (v) => !!v.trim() || 'Confirm Password is required',
                    (v) =>
                      (v.trim() && v.length <= 20) ||
                      'Confirm Password must be maximum 20 characters',
                    passwordConfirmationRule,
                    (v) =>
                      (v.trim() && v.length >= 6) ||
                      'Confirm Password must be minimum 6 characters',
                  ]"
                  required
                  :append-icon="showC ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showC = !showC"
                  @keypress.native="noSpace($event)"
                  v-on:keyup.enter="validate"
                  validate-on-blur
                  ><template #label> Confirm Password </template></v-text-field
                >
                <label for="" class="title-login" v-if="isForgot"
                  >Code<span class="red--text"><strong>&nbsp;* </strong></span>
                </label>
                <v-text-field
                  class="mt-1"
                  solo
                  v-if="isForgot"
                  v-model="code"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                  :rules="[(v) => !!v || 'Code is required']"
                  required
                  :hint="'Verification Code sent to Registered Email Id'"
                  persistent-hint
                  validate-on-blur
                  v-on:keyup.enter="validate"
                >
                  <template #label> Code </template>
                </v-text-field>
                <v-btn
                  dark
                  x-large
                  color="buttonColor"
                  class="btn-block"
                  @click="validate"
                >
                  Reset Password
                </v-btn>

                <div
                  v-if="authStatus == 'error'"
                  class="text-center mt-3 error-text"
                >
                  {{ authStatusMsg }}
                </div>

                <div class="mt-8 mb-4 text-center signUp">
                  Sign in from here!<router-link to="/login" class="SignUpLink">
                    Sign In</router-link
                  >
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";
import router from "../router";
export default {
  computed: {
    checkisForgot: function () {
      let disable = false;
      if (this.isForgot == true) {
        disable = true;
      } else {
        disable = false;
      }
      return disable;
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },

  created() {
    this.authStatus = "";
    this.authStatusMsg = "";
    this.isForgot = false;
  },
  data: () => ({
    isForgot: false,
    authStatus: "",
    authStatusMsg: "",
    show1: false,
    show2: false,
    showC: false,
    valid: false,
    emailId: "",
    password: "",
    code: "",
    status: "",
    confirm_password: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be minimum 6 characters",
    ],
  }),
  methods: {
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.isForgot == false) {
          this.forgotPassword({ emailId: this.emailId });
        } else {
          this.resetPassword({
            emailId: this.emailId,
            password: this.password,
            code: this.code,
          });
        }
      }
    },
    async forgotPassword(item) {
      let result = await authService.forgotPassword(item);

      if (result.status == 200) {
        this.isForgot = true;
        this.status = "Reset code is sent to " + item.emailId;
        this.authStatus = "";
        this.authStatusMsg = "";

        setTimeout(() => {
          this.status = "";
        }, 2000);
      } else {
        this.authStatus = "error";
        this.authStatusMsg = result.data;
      }
    },
    async resetPassword(item) {
      let result = await authService.resetPassword(item);

      if (result.status == 200) {
        this.status = "Password Reset Successful ";

        this.authStatus = "";
        this.authStatusMsg = "";
        this.isForgot = false;
        router.push("/login");

        setTimeout(() => {
          this.status = "";
        }, 2000);
      } else {
        this.authStatus = "error";
        this.authStatusMsg = result.data;
      }
    },

    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
.login-box {
  margin: 10% 30%;
}
.error-text {
  color: red;
}
/deep/.v-messages__message {
  font-size: 14px !important;
}
.log-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
small {
  font-size: 16px;
}
.v-btn {
  background: $buttonColor;
  height: 50px;
  text-transform: none;
  border-radius: 4px !important;
}
.error-text {
  color: red;
}
.formLabel {
  font-weight: bolder;
}
.v-avatar {
  height: 24px !important;
  width: 24px !important;
  justify-content: right;
}
.signInRouterLink {
  color: #20d2b6;
  font-weight: bolder;
}
// #cardBackground {
//   background: transparent linear-gradient(259deg, #090e18 0%, #464c58 100%) 0%
//     0% no-repeat padding-box;
//   background-size: 100% 60%;
//   background-position: bottom;
// }
@media (min-width: 239px) and (max-width: 320px) {
  // .cardBackground {
  //   width: 320px;
  // }
}
</style>

import processService from "@/services/process.service.js";
import orderService from "@/services/order.service.js";
import { mainAxios } from '../axios-instance';
export default {



  async getAllRequest(input) {
    let result = await mainAxios.get(`/ProcessRequest`, {
      params: input,
    });
    return result.data;

  },
  async createRequest(input) {
    let result = await mainAxios.post(`/ProcessRequest`, input);
    return result;
  },
  async updateRequest(input, id) {
    let result = await mainAxios.put(`/ProcessRequest`, input, { params: { requestId: id } });
    return result;
  },


  async getAllNotes(input) {
    let result = await mainAxios.get(`/ProcessRequest/notes`, { params: input });

    return result.data;
  },
  async create(note, id) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let result = await mainAxios.post(`/ProcessRequest/notes?clientProcessId=${id}`, note, config
    );
    return result;
  },
  async update(input) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let result = await mainAxios.put(`/ProcessRequest/notes?noteId=${input.id}`, input.message, config);
    return result;
  },
  async delete(input) {
    let result = await mainAxios.delete(`/ProcessRequest/notes?noteId=${input.id}`);
    return result;
  },
  async changeStatus(input) {

    let result = await mainAxios.put(`/ProcessRequest/status?requestId=${input.clientProcessId}&status=${input.status}`);
    if (input.status == "Approved") {
      this.addProcess(input.inputs);
    }
    return result;
  },
  async addProcess(input) {
    let formData = new FormData();
    formData.append("Name", input.processName);
    formData.append("CategoryId", 1);
    formData.append("Description", input.description);

    formData.append("Platform", "UiPath");
    let result = await processService.create(formData);

    if (result.status == 200) {
      let arr = [];
      arr.push(result.data)
      processService.delete({ processId: result.data, status: "Requested" })
      let result1 = await orderService.adminCreateOrder(input.organizationId, arr);

      return result1;
    }
  },
}
<template>
  <div class="container">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
      row
      wrap
      class="mb-3"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        :href="'#tab-' + item.name"
        :title="item.title"
        @click="tabsval(index)"
      >
        {{ item.name }}
      </v-tab>
      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="(item, index) in more"
            :key="index"
            @click="addItem(item)"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>

    <!-- <div v-if="selectedTab === 1">
      <ApiConsumption v-if="isToken"></ApiConsumption>
    </div> -->
    <div v-if="selectedTab === 0">
      <AdminLicenseMaster></AdminLicenseMaster>
    </div>
    <div v-if="selectedTab === 1">
      <ClientList v-if="isToken"></ClientList>
    </div>
    <div v-if="selectedTab === 2">
      <LicenseType></LicenseType>
    </div>
  </div>
</template>

<script>
import ClientList from "../components/ClientList.vue";
import LicenseType from "../components/AddLicenseType.vue";

import authService from "@/services/auth.service.js";
import uiPathService from "@/services/uiPath.service.js";
import storedtoken from "@/services//alignApiToken.js";
// import ApiConsumption from "../components/ApiConsumption.vue";
import AdminLicenseMaster from "../views/AdminLicenseMaster.vue";
export default {
  components: {
    ClientList,
    AdminLicenseMaster,LicenseType
    // ApiConsumption,
  },
  data: () => ({
    selectedTab: 0,
    isToken: false,
    organizationId: authService.authData.organizationId,
    tab: null,
    tabItems: [],
    tabs: [],
    more: [],
    currentItem: "tab-Add License",
  }),
  created() {
    this.getToken();
    this.tabItems = [
      {
        name: "Add License",
        title: "Add License to the Clients",
      },
      {
        name: "Register New Client",
        title: "List of Clients currently using Align API",
      },
      {
        name: "Add New License Type",
        title: "List of License type",
      },

      // {
      //   name: "API Consumption",
      //   title: "List of Clients currently using Align API",
      // },
    ];
  },
  methods: {
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 1);
      this.more = temp.splice(0);
    },
    async getToken() {
      let data = {
        organizationId: this.organizationId,
      };
      let result = await uiPathService.getAlignAPIToken(data);

      storedtoken.token = result.data;
      this.isToken = true;
    },
    tabsval(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
/deep/.v-tab {
  padding: 0px 8px;
}
/deep/.v-menu__content {
  min-width: 99px;
  top: 120px;
  left: 190px;
}
</style>


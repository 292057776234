
import { mainAxios } from '../axios-instance';
export default {

  async getAllLicense(input) {
    let result = await mainAxios.get(`/License`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/License`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/License`, input, { params: { id: id } });
    return result;
  },
  async getAllLicenseTypes() {
    let result = await mainAxios.get(`/License/license-type`);
    return result;
  },
  async createNewLicenseType(input) {
    let result = await mainAxios.post(`/License/license-type`, input);
    return result;
  },
  async deleteLicenseType(id) {
    let result = await mainAxios.delete(`/License/license-type/${id}`);
    return result;
  },
}
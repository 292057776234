<template>
  <div class="container">
    <p class="header0">
      Frequently Asked
      <label class="header2">Questions</label>
    </p>
    <p class="normaltexttitle text-justify pb-8">
      We are answering most frequent questions. No worries if you don't find
      exact one.
    </p>
    <p class="normaltextb text-justify pb-6">General Information</p>
    <v-card outlined>
      <v-card-title class="tabtitle">
        <!-- <v-spacer v-if="userType !== 'admin'"></v-spacer> -->
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          dense
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
        <v-spacer></v-spacer>
        <!-- <div > -->
        <v-select
          :items="categoryList"
          v-on:change="categoryChange"
          item-text="name"
          item-value="value"
          v-model="filter.category"
          outlined
          dense
          class="selection col-lg-4 col-sm-12 col-md-6"
        >
        </v-select>
        <!-- </div> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in faqList.records" :key="i">
          <v-expansion-panel-header class="faqq">{{
            item.question
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="faqa">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-if="faqList.records">
        <div v-if="faqList.records.length == 0" class="pa-4 text-center">
          No search results found
        </div>
      </div>
    </v-card>
    <v-row class="mt-7">
      <v-col md="8" lg="8" sm="12">
        <div class="d-flex justify-start">
          <p class="normaltextprimary text-justify pb-6">
            We are glad having you here looking for the answer.
            <label class="normaltextblack"
              >As our team hardly working on the products, feel free to ask any
              questions. We believe only your feedback might move us
              forward.</label
            >
          </p>
        </div>
      </v-col>
      <v-col md="4" lg="4" sm="12">
        <div class="d-flex justify-end">
          <v-btn dark color="buttonColor" class="text-none" to="/contact-us">
            <v-icon left> mdi-lifebuoy</v-icon>
            Contact Us
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import faqService from "@/services/faq.service.js";
// import catService from "@/services/category.service.js";
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    // getcategotyList() {
    //   var arr = Object.assign([], this.categoryList.records);
    //   arr.unshift({ id: "", name: "All Categories" });
    //   return arr;
    // },
    // isMorePage() {
    //   return (this.pageIndex + 1) * this.pageSize < this.faqList.total;
    // },
  },
  created() {
    this.getAllFAQ({
      // skip: 0,
      // take: this.pageSize,
      applicableTo: "MP",
      category: this.filter.category,
      question: this.searchKey,
      isActive: true,
      // isPage: this.pageIndex > 0 ? true : false,
    });
    // this.getAllCategory({ skip: 0, take: 25000 });
  },
  data: () => ({
    categoryList: [
      { name: "All Categories", value: "" },
      { name: "Process", value: "Process" },
      { name: "Agent", value: "Bot" },
      { name: "Logs", value: "Logs" },
      { name: "Marketplace", value: "Marketplace" },
      { name: "Alignxcel", value: "Alignxcel" },
    ],
    filter: {
      searchKey: "",
      category: "",
    },
    searchKey: "",
    loading: false,
    faqList: [],
    // categoryList: {},
    pageIndex: 0,
    pageSize: 10,
    faq: [
      {
        question: "What is AlignXcel?",
        answer:
          "AlignXcel is a safe and smart accelerator for business transformation, a product developed by Digital Align Inc. to help credit unions and community banks become efficient and safely adopt automation.",
      },
      {
        question: "What is AlignXcel Marketplace?",
        answer:
          "AlignXcel marketplace is a commerce website hosted by Digital Align Inc. All business workflows that are automated by Digital Align team for their customers are published on this website, allowing members to review, rate, and comment on each or simply place an order to automate similar workflow at their institution.",
      },
      {
        question: "Who can be the members of AlignXcel Marketplace?",
        answer:
          "All Credit Union and Community Bank  employees can become AlignXcel Marketplace members. Once you register using your business email address, our team will approve your membership.",
      },
      {
        question: "Is the AlignXcel Marketplace membership free?",
        answer:
          "Yes, all Credit Union and Community Bank  employees get free access to AlignXcel Marketplace. If you are a Digital Align customer you can to manage your digital staff, select an avatar for your digital staff and live monitor bot activities from the marketplace.",
      },
      {
        question: "How to order a workflow on AlignXcel Marketplace?",
        answer:
          "As a member of AlignXcel Marketplace, you will simply select the workflow and 'Add to Cart' and proceed to checkout. Once the order is placed, Digital Align team will receive the lead and contact you immediately to further discuss implementing the selected process within your environment.",
      },
      {
        question:
          "Can I download the workflow from AlignXcel Marketplace and run it on my own?",
        answer:
          "Not at this time, you are simply placing the order based on the workflow name and description and rest is managed by Digital Align team.",
      },
      {
        question:
          "Will I know which customer of Digital Align implemented what workflows within their organization?",
        answer:
          "No, due to our strict information privacy and data sharing guidelines we will not be disclosing the workflow names that are implemented by our customers. You can always directly reach out to our customers and ask specific questions",
      },
      {
        question:
          "Can we add multiple users under one organization on AlignXcel Marketplace?",
        answer:
          "Yes, you will have a super admin user who can add other users within the organization. ",
      },
      {
        question: "What features are accessible to super users?",
        answer:
          "Super users are allowed to browse through different workflows , rate, review and order the workflows and can even make a request for a new workflow by completing a simple request form. In addition to this, the super users can select Digital Staff avatar, monitor the workflows in real-time, pause a workflow and even restart a workflow from the marketplace.",
      },
      {
        question:
          "What is an AlignXcel Application and how is it different from AlignXcel Marketplace?",
        answer:
          "AlignXcel Application is a safe and smart accelerator application that is installed within the client's environment and is customized to meet client's business requirements, while AlignXcel Marketplace is a cloud hosted workflow web store. Both applications are connected and share non-sensitive information.",
      },
      {
        question: "How do I get access to AlignXcel Application?",
        answer:
          "Its easy, since AlignXcel Application is a customized application that is only accessible for Digital Align customers, you just have to order a workflow on AlignXcel Marketplace and the Digital Align team will deploy the AlignXcel Applications within your network.",
      },
      {
        question: "What is Digital Assistant avatar?",
        answer:
          "Digital Assistant avatar is a unique and one-of-a-kind Agent avatar that is created by Digital Align artist with multiple traits and features. No two avatars are same, and each client will be asked to click and reveal a random avatar to each of the bot licenses they purchase from Digital Align Inc. These avatars are an NFT's and they can be eventually traded on selected NFT marketplaces. Client's will permanently own these avatars once they are assigned to them.",
      },
      {
        question: "Can I submit a ticket on AlignXcel Marketplace?",
        answer:
          "Yes, you can use our 'Submit a Ticket' feature and submit a support ticket on AlignXcel Marketplace.",
      },
    ],
  }),
  methods: {
    categoryChange() {
      this.pageIndex = 0;
      this.search();
    },
    // onLoadMore() {
    //   this.pageIndex = this.pageIndex + 1;
    //   this.search(this.pageIndex);
    // },
    search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.getAllFAQ({
        category: this.filter.category,
        question: this.searchKey,
        isActive: true,
        applicableTo: "MP",
      });
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async getAllFAQ(input) {
      this.loading = true;
      let result = await faqService.getAllFAQ(input);
      this.loading = false;
      this.faqList = result;
    },
    // async getAllCategory(input) {
    //   let result = await catService.getAll(input);
    //   this.categoryList = result;
    // },
    clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.getAllFAQ({
        category: this.filter.category,
        question: this.searchKey,
        isActive: true,
        applicableTo: "MP",
      });
    },
  },
};
</script>



<style lang="scss" scoped>
@import "./src/colors.scss";
.header0 {
  font-weight: 300;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: #17181d;
}
.header00 {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;

  /* Secondary/500 */

  color: #17181d;
}
.header1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: #17181d;
}
.header11 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;

  /* Secondary/500 */

  color: #17181d;
}
.header2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;

  /* Secondary/500 */

  color: $primary;
}
.header3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  /* Secondary/500 */

  color: #17181d;
}
.normaltext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  /* Secondary/500 */

  color: #17181d;
}
.links {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #009688;
}
.normaltexttitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  /* Secondary/400 */

  color: #45464a;
}
.normaltextb {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 145% */

  letter-spacing: 0.263298px;

  color: #3f51b5;
}
.normaltextblack {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Secondary/500 */

  color: #17181d;
}
.normaltextprimary {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Primary/Teal */

  color: #009688;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.faqq {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  /* identical to box height */

  /* Secondary/500 */

  color: #17181d !important;
}
.faqa {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  /* or 129% */

  /* Secondary/400 */

  color: #45464a !important;
}
.selection {
  margin-bottom: -30px !important;
}
</style>

import { mainAxios } from '../axios-instance';
import router from "../router";
import EventBus from "../plugins/eventBus";
import CryptoJS from 'crypto-js';

export default {

  get authData() {
    var result = null;
    let secret = process.env.VUE_APP_SECRETCODE;
    try {
      const secretData = localStorage.getItem("ppAuth");
      const decryptData = CryptoJS.AES.decrypt(
        secretData,
        secret
      ).toString(CryptoJS.enc.Utf8);
      result = JSON.parse(decryptData)
    } catch (ex) {
      result = null
    }
    return result;
  },

  get isLoggedIn() {
    if (this.authData && this.authData.token) {
      return true;
    }
    return false;
  },

  async login(input) {
    let secret = process.env.VUE_APP_SECRETCODE;
    let result = await mainAxios.post(`/Auth/login`, input)
    if (result.status == 200) {
      const data = CryptoJS.AES.encrypt(JSON.stringify(result.data), secret);
      // console.log("data", data)
      // store into localStorage
      // localStorage.setItem("secretData", data);
      window.localStorage.setItem('ppToken', result.data.token);
      window.localStorage.setItem('ppAuth', data)
      return { status: true, msg: '' };
    }
    return { status: false, msg: result.data };
  },
  async register(input) {
    let result = await mainAxios.post(`/Auth/register`, input);
    return result;
  },
  async reSendRegsiter(input) {
    let result = await mainAxios.post(`/Auth/resend`, null, { params: input });

    return result;
  },

  async forgotPassword(input) {
    let result = await mainAxios.post(`/Auth/forgot`, null, {
      params: input,
    });

    return result;
  },
  async resetPassword(input) {
    let result = await mainAxios.post(`/Auth/reset`, null, {
      params: input,
    });

    return result;
  },

  // logout() {

  //   localStorage.clear();
  //   EventBus.emit("login_changes", "Logged out Successfully");
  //   router.push("/login");
  // },

  async logout() {
    if (this.authData && this.authData.token) {
      let id = this.authData.loginActivityId;
      if (id != undefined) {
        let result = await mainAxios.put(`/LoginActivity?id=${id}`);
        if (result.status == 200) {
          localStorage.clear();
          EventBus.emit("login_changes", "Logged out Successfully");
          router.push("/login");
        }
      } else {
        localStorage.clear();
        EventBus.emit("login_changes", "Logged out Successfully");
        router.push("/login");
      }

    }

  },
  async verify(input) {

    let result = await mainAxios.post(`/Auth/verify`, null, { params: input });
    return result;
  },



}
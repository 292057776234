<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn
                  dark
                  class="mt-lg-3 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Service</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :server-items-length="serviceList.total"
        :items-per-page="10"
        :items="serviceList.records"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.date`]="{ item }">
          <td>{{ new Date(item.date).toDateString() }}</td>
        </template>
        <template v-slot:[`item.term`]="{ item }">
          <td>
            {{
              parseInt(item.term) > 1
                ? item.term + " Months"
                : item.term + "Month"
            }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete Service</span>
          </v-tooltip>
          <v-menu
            bottom
            left
            offset-y
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="features(item)">
                <v-list-item-title>Map Client</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteClient(item)">
                <v-list-item-title>Delete Client</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!---Service update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedItem.serviceName"
              required
              :rules="[
                (v) => !!v || 'Service is required',
                (v) => (v && !!v.trim()) || 'Service cannot be blank',
              ]"
            >
              <template #label>
                Service <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-text-field
              v-model="editedItem.term"
              label="Term"
              @keypress="onlyNumber($event), noSpace($event)"
              required
              :suffix="parseInt(editedItem.term) > 1 ? 'months' : 'month'"
              :rules="[
                (v) => !!v || 'Term is required',
                (v) => (v && !!v.toString().trim()) || 'Term cannot be blank',
              ]"
            >
              <template #label>
                Term <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="editedItem.cost"
              label="Cost"
              @keypress="onlyNumber($event), noSpace($event)"
              required
              :rules="[
                (v) => !!v || 'Cost is required',
                (v) => (v && !!v.toString().trim()) || 'Cost cannot be blank',
              ]"
            >
              <template #label>
                Cost <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to Delete this Service?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteClient" scrollable max-width="500px">
      <v-card>
        <v-card-title>Unmap Client</v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 400px">
          <v-list class="vlistcolor" dense>
            <v-list-item
              class="list-box"
              v-for="item in client.orgList"
              :key="item.id"
            >
              <v-list-item-content>
                <v-list-item-title class="cartitem"
                  >{{ item.name }} <br class="d-md-none" />
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="remove(item, client.id)">
                  <v-icon size="20" color="red lighten-1"
                    >mdi-minus-circle-outline</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="text-capitalize grey lighten-2"
            @click="dialogDeleteClient = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->
    <v-dialog
      v-if="dialogClient"
      v-model="dialogClient"
      persistent
      max-width="800px"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <!-- <v-btn icon dark @click="dialogFeatures = false">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
          <v-toolbar-title>Add Clients</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialogFeatures = false"> Close </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-card-text>
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-card-text>
              <v-select
                class="company-size-dropdown"
                :items="gridDataClient"
                item-text="name"
                item-value="id"
                v-model="editedClient.clientId"
                label="Client"
                multiple
                required
                :rules="[(v) => !!v || 'Client is required']"
              >
                <template #label>
                  Client <span class="red--text"><strong>* </strong></span>
                </template>
              </v-select>
              <!-- <v-switch
                v-model="editedClient.isApplicableToAllClients"
                :false-value="false"
                :true-value="true"
                inset
                label="Is Applicable To All Clients"
                class="offset-4 offset-lg-0"
              ></v-switch> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize grey lighten-2"
                depressed
                @click="closeClientDialog"
              >
                Close
              </v-btn>
              <v-btn
                dark
                color="black"
                class="text-capitalize"
                depressed
                @click="onAddClient"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this Client?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDeleteConfirm = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirmClient"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import serviceService from "@/services/service.service.js";

import orgService from "@/services/organization.service.js";
export default {
  created() {
    // this.getAllCategory({ skip: 0, take: 25000 });
    this.getOrganization();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    gridDataClient() {
      if (!(this.regOrganization && this.client)) return [];

      let res = [];
      res = this.regOrganization.filter((el) => {
        return !this.client.orgList.find((element) => {
          return element.name === el.name;
        });
      });
      return res;
    },
  },
  data: () => ({
    editedIndexClient: 0,
    dialogDeleteConfirm: false,
    regOrganization: [],
    editedClient: {
      id: 0,

      clientId: 0,
      // isApplicableToAllClients: true,
    },
    client: {},

    fromdate: "",
    todate: "",

    serviceList: {},
    loading: false,
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    dialogDeleteClient: false,
    dialogClient: false,
    headers: [
      { text: "Service Name", value: "serviceName", sortable: false },

      {
        text: "Description",
        value: "description",
        width: "30%",
        sortable: false,
      },
      { text: "Service Code", value: "serviceCode", sortable: false },
      { text: "Term", value: "term", sortable: false },
      { text: "Cost", value: "cost", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
      year: null,
    },
    searchKey: "",
    editedItem: {
      id: 0,
      serviceName: "",
      description: "",
      cost: "",
      term: "",
    },
  }),

  methods: {
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    clearDate() {
      this.dates = [];
      this.fromdate = "";
      this.todate = "";
      this.getServices({
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
      });
    },
    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }

        this.getServices({
          Skip: 0,
          Take: 10,
          SearchKey: this.filter.searchKey,
        });
      } else {
        this.fromdate = "";
        this.todate = "";
      }
    },
    add(item) {
      this.countryname = item.country;
      // console.log("item", item);
      this.editedIndexcountry = this.serviceList.records.indexOf(item);
      // console.log("this.editedIndexClient", this.editedIndexcountry);
      this.editedIndex = item.id;
      this.countryitem = item;

      this.editedcountry = [];
      for (var i = 0; i < item.mappings.length; i++) {
        this.editedcountry.push(item.mappings[i].state);
      }
      // console.log("this.editedcountry", this.editedcountry);
      this.dialogFeatures = true;
    },
    // closeCountryDialog() {
    //   this.dialogFeatures = false;
    //   this.editedCountryIndex = -1;
    //   this.$refs.form1.reset();
    //   this.$refs.form1.resetValidation();
    // },
    onAddState() {
      let valid = this.$refs.form1.validate();

      if (valid) {
        if (this.editedCountryIndex == -1) {
          // console.log("editedcountry", this.editedcountry);
          var list = [];
          for (var i = 0; i < this.editedcountry.length; i++) {
            list.push({
              state: this.editedcountry[i],
            });
          }
          // console.log("list", list);
          this.createCountryState(
            {
              mappings: list,
            },
            this.countryitem.id
          );
        }
        this.closeCountryDialog();
      }
    },
    closeCountryDialog() {
      this.dialogFeatures = false;
      this.editedCountryIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    async createCountryState(item, id) {
      let result = await serviceService.createCountryState(item, id);
      if (result.status == 200) {
        this.status = "State Mapping successfull";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    dateText() {
      if (this.editedItem.date) {
        this.editedItem.date = new Date(this.editedItem.date).toISOString();
      } else {
        this.editedItem.date = "";
      }
    },
    editItem(item) {
      this.editedIndex = this.serviceList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.date) {
        this.editedItem.date = item.date.slice(0, 10);

        this.editedItem.date = item.date;
      } else {
        this.editedItem.date = "";
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    resetStatus() {
      // this.serviceList.records[this.editedIndex].isDeleted =
      //   this.serviceList.records[this.editedIndex].isDeleted == true
      //     ? false
      //     : true;
      this.isPermanentDelete = false;
    },
    deleteItem(item) {
      this.activationStatus = item.isDeleted;
      this.editedIndex = this.serviceList.records.indexOf(item);
      this.dialogDelete = true;
    },
    // deleteItem(item) {
    //   this.editedItem = Object.assign({}, item);
    //   this.editedIndex = this.serviceList.records.indexOf(item);
    //   this.dialogDelete = true;
    // },
    deleteConfirm() {
      const id = this.serviceList.records[this.editedIndex].id;
      this.editedItem.id = id;

      this.delete({ uniqueId: id });
      // this.resetStatus();
      this.dialogDelete = false;
    },

    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await serviceService.getAll({
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.searchKey,
      });
      this.loading = false;
      this.serviceList = result;

      if (this.serviceList.records.length > 0) {
        this.client = this.serviceList.records[this.editedIndexClient];
      }
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          this.create({
            serviceName: this.editedItem.serviceName,
            description: this.editedItem.description,
            cost: this.editedItem.cost,
            term: this.editedItem.term,
          });
        } else {
          const id = this.serviceList.records[this.editedIndex].id;
          this.editedItem.id = id;
          this.update(
            {
              serviceName: this.editedItem.serviceName,
              description: this.editedItem.description,
              cost: this.editedItem.cost,
              term: this.editedItem.term,
            },
            this.editedItem.id
          );
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await serviceService.create(item);

      if (result.status == 200) {
        this.status = "Service created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await serviceService.update(item, id);
      if (result.status == 200) {
        this.status = "Service updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await serviceService.delete(item);
      if (result.status == 200) {
        this.status = "Service deactivated successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await serviceService.getAll({
        Skip: 0,
        Take: 10,
        SearchKey: this.searchKey,
      });
      this.loading = false;
      this.serviceList = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      let result = await serviceService.getAll({
        Skip: 0,
        Take: 10,
        SearchKey: this.searchKey,
      });
      this.loading = false;
      this.serviceList = result;
    },
    async getServices(input) {
      this.loading = true;
      let result = await serviceService.getAll(input);

      this.serviceList = result;
      this.loading = false;
    },
    itemChange() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.searchKey,
      };
      this.getServices(data);
    },

    features(item) {
      this.editedIndexClient = this.serviceList.records.indexOf(item);
      // console.log("this.editedIndexClient", this.editedIndexClient);
      this.editedIndex = item.id;
      this.client = item;
      this.dialogClient = true;
    },
    deleteClient(item) {
      this.editedIndexClient = this.serviceList.records.indexOf(item);
      this.editedIndex = item.id;
      this.client = item;
      this.dialogDeleteClient = true;
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    closeClientDialog() {
      this.dialogClient = false;
      this.editedClientIndex = -1;
      this.$refs.form2.reset();
      this.$refs.form2.resetValidation();
    },
    onAddClient() {
      let valid = this.$refs.form2.validate();

      if (valid) {
        // if (this.editedClientIndex == -1) {
        var list = [];
        for (var i = 0; i < this.editedClient.clientId.length; i++) {
          list.push({
            clientId: this.editedClient.clientId[i],
            axcelServiceId: this.client.id,
          });
        }
        // console.log("list", list);
        // console.log("ids", this.editedClient.clientId);

        this.createClient({
          mappings: list,
        });
        // }
        this.closeClientDialog();
      }
    },
    async createClient(item) {
      let result = await serviceService.createClient(item);
      if (result.status == 200) {
        this.status = "Client Mapping successfull";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    remove(item) {
      this.removeId = item.id;
      this.dialogDeleteConfirm = true;
    },
    deleteConfirmClient() {
      // var list = [];

      //     list.push({
      //       clientId: this.removeId,
      //       isDelete:true,
      //     });

      this.ConfirmClient(this.removeId);
      this.dialogDeleteConfirm = false;
    },
    async ConfirmClient(mappingId) {
      let result = await serviceService.deleteConfirmClient(mappingId);
      if (result.status == 200) {
        this.status = "Client removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>

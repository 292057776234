
import { mainAxios } from '../axios-instance';
export default {

  async getAllRelease(input) {
    let result = await mainAxios.get(`/AlignXcelRelease`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/AlignXcelRelease`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/AlignXcelRelease/${id}`, input);
    return result;
  },

  async delete(input) {
    let result = await mainAxios.delete(`/AlignXcelRelease/${input.id}`);
    return result;
  },

  async getAllNotes(input) {
    let result = await mainAxios.get(`/AlignXcelRelease/file/${input.releaseId}`);

    return result.data;
  },


  async deleteFile(id) {
    let result = await mainAxios.delete(`/AlignXcelRelease/file/${id}`
    );
    return result;
  },
  async updateFile(input, id) {
    const config = { headers: { 'Content-Type': "multipart/form-data" } };
    let result = await mainAxios.put(`/AlignXcelRelease/file/${id}`, input, config);

    return result;
  },
}
<template>
  <div id="app">
    <div class="container-fluid">
      <v-app>
        <Header></Header>
        <div class="body">
          <v-row v-if="$route.meta.breadCrumb" class="crumbs mb-5">
            <div class="container align-self-center mt-6 mb-4">
              <p class="routename mb-2">{{ $route.meta.breadCrumb }}</p>
              <v-breadcrumbs :items="items" class="pa-0">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                    class="crumbval"
                    :href="item.href"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </div>
          </v-row>

          <router-view />
        </div>
        <FooterLarge class="footer" v-if="!$route.meta.hideFooter"></FooterLarge>
        <FooterSmall class="footer" v-if="$route.meta.hideFooter"></FooterSmall>
      </v-app>
    </div>
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import FooterLarge from "./components/FooterLarge.vue";
import FooterSmall from "./components/FooterSmall.vue";
export default {
  components: {
    Header,
    FooterLarge,
    FooterSmall,
  },
  computed: {
    items() {
      let item = [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.$route.meta.breadCrumb,
          disabled: true,
          href: this.$route.path,
        },
      ];
      return item;
    },
    // breadCrumbs() {
    //   let pathArray = this.$route.path.split("/");
    //   console.log("pathArray", pathArray);
    //   pathArray.shift();
    //   const breadCrumbs = [];
    //   // needed to handle the intermediary entries for nested vue routes
    //   let breadcrumb = "";
    //   let lastIndexFound = 0;
    //   for (let i = 0; i < pathArray.length; ++i) {
    //     breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
    //     if (
    //       this.$route.matched[i] &&
    //       Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
    //       Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
    //     ) {
    //       breadCrumbs.push({
    //         href:
    //           i !== 0 && pathArray[i - (i - lastIndexFound)]
    //             ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
    //             : breadcrumb,
    //         disabled: i + 1 === pathArray.length,
    //         text: this.$route.matched[i].meta.breadCrumb || pathArray[i],
    //       });
    //       lastIndexFound = i;
    //       breadcrumb = "";
    //     }
    //   }
    //   return breadCrumbs;
    // },
  },
  data: () => ({}),
};
</script>

<style lang="scss">
@import "./src/colors.scss";
.body {
  margin-top: 64px;
  font-family: "Roboto Flex" !important;
  // background: #f5f6fb;
}
html {
  min-height: 100%;
  // background: #f5f6fb;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // background: #f5f6fb;
}
.footer {
  margin-top: auto;
}
button {
  border-radius: 8px !important;
}
/deep/.v-btn:before {
  border-radius: 4px !important;
}
.v-btn {
  border-radius: 4px !important;
}
.v-btn--round {
  border-radius: 50% !important;
}
.v-application a {
  border-radius: 8px !important;
}
// td {
//   padding-top: 10px !important;
//   padding-bottom: 10px !important;
// }
.v-card {
  border-radius: 8px !important;
}
.v-btn--is-elevated {
  box-shadow: none !important;
}
.v-btn {
  box-shadow: none !important;
}
.crumbs {
  // height: 104px;
  background: #f5f6fb;
}
.routename {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #17181d;
}
.crumbval {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  /* Secondary/400 */

  color: #45464a !important;
}
</style>

<template>
  <div>
    <v-card>
      <!-- <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div
                class="col-lg-4 col-sm-6 col-md-4"
                v-if="userType == 'admin'"
              >
                <v-select
                  :items="regOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  :clearable="true"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title> -->
      <v-card-title class="tabtitle">
        <!-- <v-spacer v-if="userType !== 'admin'"></v-spacer> -->
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          dense
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
        <v-spacer v-if="userType == 'admin'"></v-spacer>
        <div class="col-lg-4 col-sm-12 col-md-6" v-if="userType == 'admin'">
          <v-select
            :items="regOrganization"
            item-text="name"
            item-value="id"
            v-model="selectedOrg"
            label="Select Organization"
            :clearable="true"
            @change="onChange"
          ></v-select>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers1"
        :loading="loading"
        :items-per-page="10"
        :items="pendingUser.records"
        :server-items-length="pendingUser.total"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card class="text-center pa-4">
              <!-- <img
                v-if="logo"
                :src="logo"
                height="80"
                width="80"
                class="ma-4"
              /> -->
              <img
                class="ma-4"
                :src="require('@/assets/logoDA.svg')"
                height="80"
                width="80"
              />
              <v-card-title class="justify-center alert"
                >Are you sure you want to Approve this User?</v-card-title
              >
              <div class="text-center mt-3 mb-4">
                <p class="capitalize font-weight-black mb-1">
                  {{ userItem.firstName }} {{ userItem.lastName }}
                </p>
                <p class="font-weight-bold modalEmailId">
                  {{ userItem.emailId }}
                </p>
                <small
                  class="font-weight-black"
                  v-if="userItem.organization != null"
                >
                  Organization</small
                >
                <p
                  class="capitalize font-weight-bold"
                  v-if="userItem.organization != null"
                >
                  {{ userItem.organization }}
                </p>
                <small class="font-weight-black">Mobile No </small>
                <p class="font-weight-bold">{{ userItem.mobileNo }}</p>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="border" class="text-capitalize" @click="close"
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  color="primary"
                  class="text-capitalize"
                  depressed
                  @click="acceptItemConfirm"
                  >Approve
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="text-center pa-4">
              <img
                class="ma-4"
                :src="require('@/assets/logoDA.svg')"
                height="80"
                width="80"
              />
              <v-card-title class="justify-center alert"
                >Are you sure you want to Reject this User?</v-card-title
              >
              <div class="text-center mt-3 mb-4">
                <p class="capitalize font-weight-black mb-1">
                  {{ userItem.firstName }} {{ userItem.lastName }}
                </p>
                <p class="font-weight-bold modelEmailId">
                  {{ userItem.emailId }}
                </p>
                <small
                  class="font-weight-black"
                  v-if="userItem.organization != null"
                >
                  Organization</small
                >
                <p
                  class="capitalize font-weight-bold"
                  v-if="userItem.organization != null"
                >
                  {{ userItem.organization }}
                </p>
                <small class="font-weight-black">Mobile No </small>
                <p class="font-weight-bold">{{ userItem.mobileNo }}</p>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="border"
                  class="text-capitalize"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  color="red"
                  class="text-capitalize"
                  depressed
                  @click="deleteItemConfirm"
                  >Reject
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.registeredOrg`]="{ item }">
          <td v-if="item.registeredOrg != null">{{ item.registeredOrg }}</td>
          <td
            v-else-if="item.organization != null && item.registeredOrg == null"
          >
            {{ item.organization }}
          </td>
        </template>

        <template v-slot:[`item.organization`]="props">
          <v-edit-dialog
            v-if="userType == 'admin'"
            large
            @save="save(props.item, orgid)"
            @open="orgid = getorg(props.item)"
            @cancel="cancel"
          >
            <div v-if="props.item.organization != ''">
              {{ props.item.organization }}
            </div>
            <div v-else class="red--text">Select Organization</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">Update Organization</div>
              <v-select
                :items="regOrganization"
                item-text="name"
                item-value="id"
                v-model="orgid"
                label="Select Organization"
                :clearable="false"
                v-on:change="orgChange($event)"
                :rules="[
                  (v) => (!!v && v != -1) || 'Organization is required',
                  (v) =>
                    email
                      ? (v &&
                          props.item.emailId.substring(
                            props.item.emailId.indexOf('@') + 1
                          ) == email.substring(email.indexOf('@') + 1)) ||
                        'Domain must match'
                      : (!!v && v != -1) || 'Organization is required',
                ]"
              ></v-select>
            </template>
          </v-edit-dialog>
          <div v-else-if="props.item.organization != ''">
            {{ props.item.organization }}
          </div>
          <div v-else class="red--text">Organization not set</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small color="#20D2B6" class="mr-2" @click="acceptItem(item)">
            mdi-account-check
          </v-icon>
          <v-icon color="grey" small @click="deleteItem(item)">
            mdi-account-remove
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusw" type="error">
      {{ statusw }}
    </v-alert>
  </div>
</template>

<script>
import userService from "@/services/user.service.js";
import orgService from "@/services/organization.service.js";
import authService from "@/services/auth.service.js";
import EventBus from "../plugins/eventBus";

export default {
  computed: {
    headers1() {
      return [
        {
          text: "First Name",
          align: "start",

          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        {
          text: "Organization",
          value: "organization",
          sortable: false,
        },
        {
          text: "Registered Organization",
          value: "registeredOrg",
        },

        { text: "Email Id", value: "emailId" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  data: () => ({
    pendingUser: {},
    loading: false,
    regOrganization: [],
    isLoggedIn: authService.isLoggedIn ? true : undefined,
    userType: authService.authData.userType,
    organizationName: authService.authData.organization,

    logo: authService.authData ? authService.authData.logoUrl : undefined,
    valid: false,

    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusw: "",
    filter: {
      searchKey: "",
    },
    dialog: false,
    dialogDelete: false,
    sortBy: "id",
    sortDesc: true,

    userItem: {
      id: 0,
      firstName: "",
      lastName: "",
      organization: "",
      emailId: "",
      mobileNo: "",
    },
    defaultItem: {
      id: 0,
      firstName: "",
      lastName: "",
      organization: "",
      emailId: "",
      mobileNo: "",
    },
    selectedOrg: "",
    orgid: 0,
    email: "",
  }),
  created() {
    if (this.userType == "admin") {
      this.getOrganization();
    }
    EventBus.on("org_id_changes", (x) => {
      this.status = x;
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("org_changes", (x) => {
      this.status = x;
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("user_changes", (x) => {
      this.status = x;
      this.getPendingUser();
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    async onChange() {
      this.loading = true;
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Pending",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.pendingUser = result;
    },

    save(item, id) {
      this.orgIdEdit({ organizationId: id, userId: item.id });
    },
    async orgIdEdit(input) {
      let result = await userService.changeOrg(input);
      if (result.status == 200) {
        this.status = "Organization Changed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    cancel() {},
    getorg(org) {
      if (org.organization != null) {
        return org.organizationId;
      } else {
        return 0;
      }
    },
    acceptItem(item) {
      if (item.organization != "") {
        this.userItem = Object.assign({}, item);
        this.dialog = true;
      } else {
        this.statusw = "Please select organization";
        setTimeout(() => {
          this.statusw = "";
        }, 2000);
      }
    },

    deleteItem(item) {
      this.userItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.approve({ userId: this.userItem.id, status: "Rejected" });
      this.closeDelete();
    },
    acceptItemConfirm() {
      this.approve({
        userId: this.userItem.id,
        status: "Active",
      });

      this.close();
    },
    async approve(input) {
      let result = await userService.changeStatus(input);
      if (result.status == 200) {
        if (input.status == "Active") {
          this.status = "User Accepted successfully";
          EventBus.emit("user_accepted", "User Accepted successfully");
        } else if (input.status == "Rejected") {
          EventBus.emit("user_rejected", "User Rejected successfully");
          this.status = "User Rejected successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    close() {
      this.dialog = false;
      this.userItem = Object.assign({}, this.defaultItem);
    },
    orgFilter(value) {
      if (!this.selectedOrg) {
        return true;
      }
      return value.name === this.selectedOrg;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.userItem = Object.assign({}, this.defaultItem);
    },
    orgChange(item) {
      if (this.orgid == 0) {
        this.email = "";
      } else {
        if (item > 0) {
          var filterObj = this.regOrganization.filter(function (e) {
            return e.id == item;
          })[0];
          if (filterObj.adminEmailId) {
            this.email = filterObj.adminEmailId.emailId;
          } else {
            this.email = "";
          }
        }
      }
    },

    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await userService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        status: "Pending",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.pendingUser = result;
    },
    async search() {
      this.loading = true;
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Pending",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.pendingUser = result;
    },
    async clear() {
      this.loading = true;
      this.filter.searchKey = "";
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Pending",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.pendingUser = result;
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/colors.scss";
.v-icon {
  font-size: 20px !important;
}
.modalEmailId {
  color: #20d2b6;
}
.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}
</style>

<template>
  <div class="mb-12">
    <div class="bg">
      <div class="container">
        <div class="paddings col-12 d-flex flex-lg-row flex-column sec-header align-center">
          <div class="row d-flex flex-lg-row col-md-3 col-12 ">
          <v-select
            color="#000000"
            background-color="secheader"
            class="selection col-md-6 col-12 rounded-lg "
            :items="getcategotyList"
            v-on:change="categoryChange"
            item-text="name"
            item-value="id"
            v-model="filter.categoryId"
          >
          </v-select>
          <v-select
            color="#000000"
            background-color="secheader"
            class="selection col-md-6 col-12 rounded-lg pl-2"
            :items="getRoleList"
            v-on:change="roleChange"
            item-text="roleName"
            item-value="id"
            v-model="filter.roleId"
          >
          </v-select>
        </div>
          <div
            class="
              ml-md-2
              mt-3 mt-md-1
              col-md-9 col-12
              paddings
              align-self-center
              mb-0
            "
          >
            <div class="d-flex justify-space-between">
              <div
                class="
                  d-flex
                  sort sort-list
                  mt-2
                  ProductListCategory
                  align-self-center
                "
              >
                <ul>
                  <li
                    :class="
                      sort == 'all' && filter.sortBy == '' ? 'active' : ''
                    "
                    title="Catalogue of Workflows"
                  >
                    <span @click="sortBy('all')"> All</span>
                  </li>
                  <li
                    :class="sort == 'new' ? 'active' : ''"
                    title="Catalogue of Newly Added Workflows"
                  >
                    <span @click="sortBy('new')"> Newly Added</span>
                  </li>
                  <li
                    :class="sort == 'Reviews' ? 'active' : ''"
                    title="Workflows with Highest Reviews"
                  >
                    <span @click="sortBy('Reviews')">Highest Reviews</span>
                  </li>
                  <li
                    :class="sort == 'Rating' ? 'active' : ''"
                    title="Most rated Workflows"
                  >
                    <span @click="sortBy('Rating')">Highest Rated</span>
                  </li>
                  <li
                    :class="sort == 'Complexity' ? 'active' : ''"
                    title="Workflows Based on Complexity"
                  >
                    <span @click="sortBy('Complexity')">Complexity</span>
                  </li>
                </ul>
             
              </div>
              <!-- <v-spacer></v-spacer> -->
              <div class="align-self-center">
                <!-- <v-divider class="mx-4" vertical></v-divider> -->
                <div class="">
                  <v-text-field
                    append-icon="mdi-magnify"
                    @click:append="
                      pageIndex = 0;
                      search();
                    "
                    v-model="filter.searchKey"
                    label="Search by Name or Description"
                    dense
                    rounded
                    single-line
                    hide-details
                    v-on:keyup.enter="
                      pageIndex = 0;
                      search();
                    "
                    clear-icon="mdi-close-circle"
                    clearable
                    @click:clear="clear"
                  >
                    <template #label class="searchtext">
                      Search by Name or Description
                    </template></v-text-field
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row list mb-5">
        <!---product listing -->
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <div
          class="col-12 text-center no-result"
          v-if="
            productsList.records && productsList.records.length == 0 && !loading
          "
        >
          <!-- <img width="300px" :src="require('@/assets/no-data.jpg')" />
          <h3>Sorry, no results found!</h3> -->
          <img
            :src="require('@/assets/emptycart.svg')"
            height="180"
            width="180"
          />
          <h2 class="mt-5 carttext">Sorry, no results found!</h2>
        </div>
        <div
          v-if="
            productsList.records && productsList.records.length > 0 && !loading
          "
          class="col-12 d-flex mt-3"
        >
          <h5>
            1 to {{ productsList.records.length }} of
            {{ productsList.total }} Workflows
          </h5>
        </div>

        <div
          class="col-md-3"
          v-for="image in productsList.records"
          :key="image.id"
        >
          <!-- <v-hover v-slot:default="{ hover }">
            <v-card class="cardrt" @click="goToList(image)">
              <v-avatar tile class="ml-4 mt-4" size="46">
                <v-img :src="image.imageURL" :alt="image.name"> </v-img>
              </v-avatar>

              <v-card-title>
                <h6 class="fill-width processtitle">{{ image.name }}</h6>
              </v-card-title>

              <v-card-subtitle class="pl-3">
                <div class="d-flex justify-space-between">
                  <v-rating
                    :value="parse(image.rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="15"
                    background-color="#ccc"
                  >
                  </v-rating>
                  <small class="grey--text text-sm my-1"
                    >{{ image.rating }} ({{ image.reviews }} reviews)</small
                  >
                </div>
              </v-card-subtitle>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="
                    d-flex
                    flex-column
                    transition-fast-in-fast-out
                    v-card--reveal
                    white--text
                  "
                  style="height: 100%"
                >
                  <h4 class="fill-width" v-if="image.complexity">
                    Complexity : {{ newStatus(image.complexity) }}
                  </h4>
                  <h4 class="fill-width" v-if="image.noImplemented > 0">
                    {{ imp(image.noImplemented) }}
                  </h4>
                </div>
              </v-expand-transition>
            </v-card>
          </v-hover> -->
          <v-hover v-slot:default="{ hover }">
            <v-card class="cardrt">
              <v-avatar tile class="ml-4 mt-4" size="46">
                <v-img :src="image.imageURL" :alt="image.name"> </v-img>
              </v-avatar>

              <v-card-text class="px-4 pb-2">
                <h6 class="fill-width processtitle mt-2 mb-2">
                  {{ image.name }}
                </h6>
                <div class="d-flex justify-space-between">
                  <v-chip
                    label
                    class="fill-width text-capitalize btnclr textclr mb-2"
                  >
                    {{ newStatus(image.complexity) }}
                  </v-chip>
                  <!-- <v-spacer></v-spacer> -->
                  <v-chip
                    label
                    class="fill-width text-capitalize btnclrl textclrl mb-2"
                    v-if="image.noImplemented > 0"
                  >
                    {{ imp(image.noImplemented) }}
                  </v-chip>
                  <v-chip label class="fill-width btnclrl textclrl mb-2" v-else>
                    Be the First to Order
                  </v-chip>
                </div>
                <div class="d-flex justify-space-between">
                  <v-rating
                    :value="parse(image.rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="15"
                    background-color="#ccc"
                  >
                  </v-rating>
                  <small class="grey--text text-sm my-1"
                    >{{ parse(image.rating) }} ({{
                      image.reviews
                    }}
                    reviews)</small
                  >
                </div>
              </v-card-text>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute z-index="0" class="overlay">
                  <v-btn
                    small
                    rounded
                    color="#ffffff"
                    class="text-capitalize black--text btn-rnd"
                    @click="goToList(image)"
                    >open<v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </div>
      </div>
      <!-- <div class="col-12 text-center">
        <v-btn
          v-if="isMorePage"
          color="primary"
          @click="onLoadMore"
          depressed
          dark
        >
          Load More</v-btn
        >
      </div> -->
      <div class="container text-center" v-if="isMorePage">
        <div class="seperator">
          <h5>
            <v-btn
              class="black--text text-left text-capitalize"
              large
              depressed
              outlined
              @click="onLoadMore"
            >
              Load More
            </v-btn>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import processService from "@/services/process.service.js";
import catService from "@/services/category.service.js";
import roleService from "@/services/role.service.js";
export default {
  computed: {
    getcategotyList() {
      var arr = Object.assign([], this.categoryList.records);
      arr.unshift({ id: "", name: "All Categories" });
      return arr;
    },
    getRoleList() {
      var arr = Object.assign([], this.roleList);
      arr.unshift({ id: "", roleName: "All Roles" });
      return arr;
    },
    isMorePage() {
      return (this.pageIndex + 1) * this.pageSize < this.productsList.total;
    },
  },
  created() {
    this.getAll({
      skip: 0,
      take: this.pageSize,
      status: "Active",
      isPage: this.pageIndex > 0 ? true : false,
    });
    this.getAllCategory({ skip: 0, take: 25000 });
    this.getAllRole();
    this.message = this.$route.params.message;

    // console.log("log", this.message);
  },
  data: () => ({
    message: "",
    loading: false,
    productsList: [],
    categoryList: {},
    roleList: [],
    pageIndex: 0,
    pageSize: 10,
    filter: {
      searchKey: "",
      sortBy: "",
      categoryId: "",
      roleId: "",
    },
    sort: "all",
    searchKey: "",
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    imp(item) {
      if (item == 1) {
        return "Implemented : " + item + " customer";
      } else {
        return "Implemented : " + item + " customers";
      }
    },
    async getAllRole() {
      let result = await roleService.getAllRole();

      this.roleList = result.records;
    },

    moveToDetails(item, id) {
      this.$router.push({
        name: `ProductDetails`, //use name for router push
        params: { data: item, id: id },
      });
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    search(skip = 0) {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.getAll({
        skip: skip * this.pageSize,
        take: this.pageSize,
        categoryId: this.filter.categoryId,
        roleId: this.filter.roleId,
        searchKey: this.searchKey,
        sortBy: this.filter.sortBy,
        isPage: this.pageIndex > 0 ? true : false,
        status: "Active",
      });
    },
    clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.getAll({
        skip: 0,
        take: this.pageSize,
        categoryId: this.filter.categoryId,
        roleId: this.filter.roleId,
        searchKey: this.searchKey,
        sortBy: this.filter.sortBy,
        isPage: this.pageIndex > 0 ? true : false,
        status: "Active",
      });
    },
    categoryChange() {
      this.pageIndex = 0;
      this.search();
    },
    roleChange() {
      this.pageIndex = 0;
      this.search();
    },
    sortBy(by) {
      this.pageIndex = 0;
      this.sort = by;
      if (by == "all" || by == "") {
        this.filter.sortBy = "";
      } else if (by == "new") {
        this.filter.sortBy = "";
      } else {
        this.filter.sortBy = by;
      }

      this.search();
    },
    parse(item) {
      return Number(item.toFixed(2));
    },
    onLoadMore() {
      this.pageIndex = this.pageIndex + 1;
      this.search(this.pageIndex);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    async getAll(input) {
      this.loading = true;
      let result = await processService.getAll(input);
      this.loading = false;
      this.productsList = result[0];
    },
    async getAllCategory(input) {
      let result = await catService.getAll(input);
      this.categoryList = result;
      if (this.message != undefined) {
        let cat = result.records.find((c) => c.name === this.message);
        if (cat) {
          this.filter.categoryId = cat.id;
          this.pageIndex = 0;
          this.search();
        } else {
          this.filter.categoryId = "";
        }
      }
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "0") {
        newstatus = "Low";
      } else if (id == "1") {
        newstatus = "Medium";
      } else if (id == "2") {
        newstatus = "High";
      }
      return newstatus;
    },
    goToList(image) {
      this.$router.push(`processes/${image.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/colors.scss";
.v-card:hover {
  cursor: pointer;
}
.sort {
  margin: 0 !important;
  font-size: 14px;
  // font-weight: bold;
  .text {
    margin: 0 !important;
  }
}
.sort-list {
  margin: 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Primary/Teal */

  color: #45464a;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li span {
    display: block;
    color: #000;
    text-align: center;
    margin: 0px 16px;
    text-decoration: none;
    cursor: pointer;
  }
  li.active span {
    color: #20d2b6;
    border-bottom: 1px solid #20d2b6;
  }
}
.filter-search {
  margin-top: -25px;
  font-size: 12px;

  .v-select__selection {
    font-size: 13px;
  }
}
/deep/.v-menu__content {
  box-shadow: none !important;
  border: gray !important;
  border-radius: 8px !important;
  border: 1px solid rgb(231, 221, 221) !important;
}
.bg {
  background: #fbfcfe 0% 0% no-repeat padding-box;
  opacity: 1;
}
.bg1 {
  background-color: #ffffff;
  border-radius: 10px;
}
.paddings {
  padding: 0px !important;
  padding-bottom: 0px !important;
}

.selection {
  margin-bottom: -30px !important;
  padding: 0px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/400 */

  color: #45464a;
}

/deep/.v-list-item__title {
  align-self: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Secondary/400 */

  color: #45464a;
}

/deep/.v-text-field .v-input__control {
  color: transparent;
}
.no-result {
  margin-top: 10%;
}

@media (min-width: 280px) and (max-width: 400px) {
  .ProductListCategory .col-12,
  .col-md-7 {
    padding: 0px;
  }
}
@media (min-width: 280px) and (max-width: 424px) {
  li {
    float: inherit !important;
  }
}
.textsize {
  font-size: 14px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.cardrt {
  border: gray;

  border: 1px solid rgb(231, 221, 221) !important;
}
.cardrt:hover {
  background: #ffffff;
  box-shadow: none !important;
  border: 2px solid $primary !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 0px;
}
.fill-width {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.v-card--reveal {
  background-color: #020d17;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  border-radius: 8px;
}

.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}
.processtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #222222;
}
.sec-header {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #45464a;
}
.searchtext {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  /* Grey/700 */

  color: #7f8083 !important;
}
/deep/.theme--light.v-label {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  /* Grey/700 */

  color: #7f8083 !important;
}
/deep/.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 0px;
}
/deep/.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding: 0 0px;
}
/deep/.theme--light.v-text-field:not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}
/deep/.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}
.btnclr {
  height: 24px !important;
  justify-content: center;
  align-items: center;
  padding: 4px 6px !important;
  gap: 8px !important;
  background: $secondary !important;
  border-radius: 4px !important;
}
.textclr {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: $white !important;
}
.btnclrl {
  height: 24px !important;
  justify-content: center;
  align-items: center;
  padding: 4px 6px !important;
  gap: 8px !important;
  background: #e8e8e8 !important;
  border-radius: 4px !important;
}
.textclrl {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: $secondary !important;
}
.overlay {
  // opacity: 0.8 !important;
  background-color: rgba(23, 24, 29, 0.8);
  border-color: rgba(23, 24, 29, 0.8);
  background: rgba(23, 24, 29, 0.8) !important;
  backdrop-filter: blur(4px);
}
.btn-rnd {
  border-radius: 25px !important;
}
.seperator h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator h5::before,
.seperator h5::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #e3e4e8;
}

.seperator h5 .btnmargin {
  padding: 0 2em;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
</style>

<template>
  <div class="container" v-if="product">
    <div class="row">
      <div class="col-lg-6 col-sm-12 text-center">
        <v-carousel v-if="showCrs" :show-arrows="false">
          <v-carousel-item
            v-for="(item, i) in getImages"
            :key="i"
            :src="item.fileUrl"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-icon
              small
              v-if="i != 0"
              class="editcarsl float-right mr-3"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              v-if="i != 0"
              class="editcarsl float-right mr-3"
              @click="editCarousel(item)"
            >
              mdi-pencil
            </v-icon>
          </v-carousel-item>
        </v-carousel>
        <v-icon small class="addcarsl" @click="addCarousel()">
          mdi-plus
        </v-icon>
      </div>
      <div class="col-lg-6 col-sm-12 pl-3">
        <h3 class="mb-4">{{ product.name }}</h3>
        <p class="text-justify">{{ product.description }}</p>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-10 col-sm-6 col-md-10 col-12">
        <h4>Features</h4>
      </div>
      <div class="col-lg-2 col-sm-6 col-md-2 col-12">
        <v-btn dark class="mt-lg-2 mt-md-2" @click="onAdd"> New Feature </v-btn>
      </div>
    </div>

    <!---Loop all the features -->
    <div class="row mt-5">
      <div
        v-for="item in getFeatures"
        class="border"
        :key="item.id"
        :class="item.className == 'null' ? 'col-12' : item.className"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-3 float-right"
              @click="deleteItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              class="mr-3 float-right"
              @click="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <div class="float-right mr-5">Order: {{ item.order }}</div>
        <v-img :src="item.fileUrl" v-if="item.type == 'image'" contain />
        <iframe
          v-if="item.type == 'video'"
          class="full"
          x-ref="player"
          :src="item.fileUrl"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
        <p v-if="item.type == 'text'">{{ item.description }}</p>
        <h5 v-if="item.type == 'header'">{{ item.description }}</h5>
      </div>
    </div>

    <!---Add or update product features -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-select
              :items="typeList"
              item-text="name"
              item-value="value"
              v-model="editedItem.type"
              required
              :rules="[(v) => !!v || 'Type is required']"
              ><template #label>
                Type <span class="red--text"><strong>* </strong></span>
              </template></v-select
            >
            <v-card>
              <div class="row my-4">
                <v-overflow-btn
                  v-model="selectedwidth"
                  dense
                  :items="dropdown_font"
                  label="Select width"
                  hide-details
                  class="col-md-6 col-12"
                ></v-overflow-btn>

                <div class="col-md-6 col-12">
                  <v-btn-toggle
                    v-model="toggle_multiple"
                    color="primary"
                    dense
                    group
                    multiple
                  >
                    <v-btn :value="1" text small>
                      <v-icon>mdi-format-bold</v-icon>
                    </v-btn>

                    <v-btn :value="2" text small>
                      <v-icon>mdi-format-italic</v-icon>
                    </v-btn>

                    <v-btn :value="3" text small>
                      <v-icon>mdi-format-underline</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </v-card>
            <v-text-field v-model="editedItem.className" label="Class Name">
            </v-text-field>
            <v-text-field
              v-model="editedItem.order"
              label="Item Order"
              required
              :rules="[(v) => !!v || 'Order is required']"
            >
              <template #label>
                Order <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea
              v-if="editedItem.type == 'text'"
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-textarea
              v-if="editedItem.type == 'header'"
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Header is required',
                (v) => (v && !!v.trim()) || 'Header cannot be blank',
              ]"
            >
              <template #label>
                Header <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-file-input
              v-if="!editedItem.fileUrl && editedItem.type == 'video'"
              v-model="editedItem.attachment"
              accept="video/*"
              required
              label="Video"
              :rules="[(v) => !!v || 'Video is required']"
            >
              <template #label>
                Video file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="editedItem.fileUrl && editedItem.type == 'video'"
              v-model="editedItem.attachment"
              accept="video/*"
              label="Video"
            >
              <template #label>
                Video file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="!editedItem.fileUrl && editedItem.type == 'image'"
              v-model="editedItem.attachment"
              accept="image/*"
              required
              label="Image"
              :rules="[(v) => !!v || 'Image is required']"
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="editedItem.fileUrl && editedItem.type == 'image'"
              v-model="editedItem.attachment"
              accept="image/*"
              label="Image"
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text>
          <v-card-title
            >Are you sure you want to delete this item?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCarousel"
      transition="dialog-bottom-transition"
      max-width="500px"
    >
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-file-input
              v-model="editedItem.attachment"
              accept="image/*"
              required
              :rules="[(v) => !!v || 'Image is required']"
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>

<script>
import processService from "@/services/process.service.js";
import EventBus from "../plugins/eventBus";
export default {
  props: {
    productId: Number,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    getImages() {
      const arr = this.features.filter((t) => t.type === "crimage");
      let img = {
        type: "crimage",
        fileUrl: this.product.imageURL,
        order: 0,
      };
      arr.unshift(img);
      return arr;
    },
    getFeatures() {
      return this.features.filter((t) => t.type !== "crimage");
    },
  },
  created() {
    this.getProductById(this.productId);
    this.getById(this.productId);
    EventBus.on("feature_changes", (x) => {
      this.showCrs = false;
      this.getById(this.productId);
      this.status = x;
      setTimeout(() => {
        this.showCrs = true;
      }, 100);
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
  },
  destroyed() {
    EventBus.off("feature_changes");
  },
  data: () => ({
    product: {},
    features: [],
    dropdown_font: [
      { text: "col-2" },
      { text: "col-3" },
      { text: "col-4" },
      { text: "col-6" },
      { text: "col-12" },
    ],

    toggle_multiple: [],
    dialog: false,
    showCrs: true,
    dialogCarousel: false,
    dialogDelete: false,
    deleteAction: "",
    status: "",
    valid: false,
    editedIndex: -1,
    selectedwidth: "",
    typeList: [
      { name: "Text", value: "text" },
      { name: "Image", value: "image" },
      { name: "Video", value: "video" },
      { name: "Header", value: "header" },
    ],
    editedItem: {
      id: null,
      fileUrl: "",
      type: "",
      order: 0,
      className: "",
      description: "",
      productId: 0,
      attachment: null,
    },
  }),
  methods: {
    editItem(item) {
      this.editedIndex = this.features.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const myArray = this.editedItem.className.split(" ");
      if (myArray[0].includes("col")) {
        this.selectedwidth = myArray[0];
        let i = 0;
        for (i = 1; i < myArray.length; i++) {
          let index = myArray.findIndex((element) => element === myArray[i]);
          if (index != -1) {
            if (myArray[i] == "font-weight-bold") {
              this.toggle_multiple.push(1);
            } else if (myArray[i] == "font-italic") {
              this.toggle_multiple.push(2);
            } else if (myArray[i] == "text-decoration-underline") {
              this.toggle_multiple.push(3);
            }
          }
        }
      } else {
        myArray.map((subject) => {
          let index = myArray.findIndex((element) => element === subject);
          if (index != -1) {
            if (myArray[index] == "font-weight-bold") {
              this.toggle_multiple.push(1);
            } else if (myArray[index] == "font-italic") {
              this.toggle_multiple.push(2);
            } else if (myArray[index] == "text-decoration-underline") {
              this.toggle_multiple.push(3);
            }
          }
        });
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.dialogCarousel = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    onAdd() {
      this.dialog = true;
      this.editedIndex = -1;
      this.editedItem.order = this.features.length + 1;
      this.editedItem.productId = this.productId;
    },
    addCarousel() {
      this.dialogCarousel = true;
      this.editedIndex = -1;
      this.editedItem.type = "crimage";
      this.editedItem.productId = this.productId;
      this.editedItem.order =
        this.features.filter((t) => t.type === "crimage").length + 1;
    },
    editCarousel(item) {
      this.editedIndex = this.features.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogCarousel = true;
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.editedIndex = this.features.indexOf(item);
    },
    deleteConfirm() {
      let id = this.features[this.editedIndex].id;
      this.deleteFeature(id);
      this.dialogDelete = false;
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        let fonts = "";
        let i = 0;
        for (i = 0; i < this.toggle_multiple.length; i++) {
          if (this.toggle_multiple[i] == 1) {
            fonts = fonts + "font-weight-bold" + " ";
          } else if (this.toggle_multiple[i] == 2) {
            fonts = fonts + "font-italic" + " ";
          } else if (this.toggle_multiple[i] == 3) {
            fonts = fonts + "text-decoration-underline" + " ";
          }
        }
        let value = this.selectedwidth + " " + fonts;
        this.editedItem.className = value;
        let formData = new FormData();
        formData.append("Type", this.editedItem.type);
        formData.append("ClassName", this.editedItem.className);
        formData.append("Order", this.editedItem.order);
        formData.append("Description", this.editedItem.description);
        formData.append("Attachment", this.editedItem.attachment);
        if (this.editedIndex == -1) {
          formData.append("FileUrl", this.editedItem.fileUrl);
          this.addFeature(formData, this.editedItem.productId);
        } else {
          if (
            this.editedItem.type == "image" &&
            this.editedItem.attachment !== undefined
          ) {
            formData.append("FileUrl", "");
          } else {
            formData.append("FileUrl", this.editedItem.fileUrl);
          }
          const id = this.features[this.editedIndex].id;
          formData.append("Id", id);
          this.updateFeature(formData, id);
        }
        this.closeDialog();
      }
    },

    async addFeature(item, id) {
      let result = await processService.addFeature(item, id);

      if (result.status == 200) {
        this.status = "Feature added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getById(this.productId);
      }
    },
    async updateFeature(item, id) {
      let result = await processService.updateFeature(item, id);
      if (result.status == 200) {
        this.status = "Feature updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);

        this.getById(this.productId);
      }
    },
    async deleteFeature(item) {
      let result = await processService.deleteFeature(item);
      if (result.status == 200) {
        this.status = "Feature deleted successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getById(this.productId);
      }
    },

    async getProductById(input) {
      let result = await processService.getProcessById(input);

      this.product = result[0];
    },

    async getById(input) {
      let result = await processService.getFeatureById(input);

      this.features = result;
    },
  },
};
</script>
<style lang="scss" scoped>
h3 {
  padding: 0;
  font-size: 30px;
  color: #000;
  margin-top: 10%;
}
h5 {
  padding: 0;
  font-size: 18px;
  margin-top: 30px;
}
h4 {
  font-size: 26px;
  color: #000;
  margin-top: 30px;
}
.full {
  width: 100%;
  display: block;
  min-height: 50vh;
}
.editcarsl,
.addcarsl {
  margin-right: 30px;
  margin-top: 10px;
  font-size: 18px !important;
  background: #fff;
  padding: 10px;
  border-radius: 20px;
}
.addcarsl {
  background: #1976d2;
}
.addcarsl::before {
  color: #fff;
}
.border {
  border: 1px dashed #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>
<template>
  <div class="container">
    <v-row>
      <v-col lg="3" md="3" sm="6" cols="12">
        <v-card min-height="120px" outlined color="#419b8f14">
          <v-row class="no-gutters">
            <div class="imgcl">
              <v-img :src="require('@/assets/dashboard.png')"> </v-img>
            </div>
            <div class="pa-2 py-4">
              <div class="text-truncate text-capitalize dashtitle">
                Successful Transactions
              </div>
              <div class="dashnum mt-5">{{ totSuccessTransaction }}</div>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" md="3" sm="6" cols="12">
        <v-card min-height="120px" outlined color="#ff00000a">
          <v-row class="no-gutters">
            <div class="imgcl">
              <v-img :src="require('@/assets/dashboard.png')"> </v-img>
            </div>
            <div class="pa-2 py-4">
              <div class="text-truncate text-capitalize dashtitle">
                Failed Transactions
              </div>
              <div class="dashnum mt-5">{{ totFailedTransaction }}</div>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col lg="3" md="3" sm="6" cols="12" class="d-flex align-end">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="daterange"
              label="Date Range"
              append-icon="mdi-calendar"
              readonly
              outlined
              hide-details=""
              dense
              @click:append="on.click"
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" no-title scrollable range :max="nowDate">
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="menu = false"
              >Cancel
            </v-btn>
            <v-btn dark @click="$refs.menu.save(dates), dateRangeText()">OK </v-btn>
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-menu>
        <v-btn class="py-5 px-0" @click="getAll"
          ><v-icon size="20">mdi-reload</v-icon></v-btn
        >
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>
    <v-row v-if="loading">
      <v-col v-for="i in 6" :key="i" lg="4" md="4" cols="12">
        <v-skeleton-loader v-bind="attrs" type="article, actions"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col v-for="item in uipathLog" :key="item.id" lg="4" md="4" cols="12">
        <v-card outlined min-height="400px" @click="openDialog(item)">
          <v-card-title class="dashboard-dt-title d-flex justify-space-between">
            {{ item.name }}
            <div>
              <v-icon
                v-if="item.lastHeartBeatDT != null"
                :color="__getTimeDiff(item.lastHeartBeatDT) > 1 ? '#E64A19' : '#4caf50cc'"
                class="mr-1"
              >
                mdi-heart-pulse
              </v-icon>
              <span v-if="item.lastHeartBeatDT != null"> {{ item.lastHeartBeatDT }}</span>
              <v-icon
                v-if="item.lastHeartBeatDT == null"
                :color="__getTimeDiff(item.lastHeartBeatDT) > 1 ? '#E64A19' : '#4caf50cc'"
                class="mr-0"
                size="16"
              >
                mdi-heart-flash
              </v-icon>
              <small v-if="item.lastHeartBeatDT == null" class="error--text">
                Need Urgent Attention
              </small>

              <v-badge
                v-if="__getFailedTransactionCount(item) > 0"
                :content="__getFailedTransactionCount(item)"
                color="red"
                class="ml-3 mr-5"
              ></v-badge>
            </div>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="item.processHistory != null ? item.processHistory : []"
            :footer-props="{
              'items-per-page-options': [5],
            }"
            class="elevation-0"
            hide-default-footer
            :sort-by="['status']"
            :sort-desc="[false]"
          >
            <template v-slot:[`item.processStartDate`]="{ item }">
              <td>{{ new Date(item.processStartDate).toLocaleDateString() }}</td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td>
                <v-chip
                  label
                  small
                  :color="getColor(item.status)"
                  :text-color="getTextColor(item.status)"
                  dark
                >
                  {{ item.status }}
                </v-chip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <LogPopup
      :dialog="logDialog"
      :selectedItem="selectedItem"
      :loading="selectedItemDtLoading"
      :orgName="selectedItemOrgName"
      @closeDialog="closeDialog"
    ></LogPopup>
  </div>
</template>
<script>
import uiPathService from "@/services/uiPath.service.js";
import LogPopup from "@/components/LogDashBoardPopup.vue";
export default {
  components: { LogPopup },
  mounted() {
    this.getAll();
  },
  data() {
    return {
      loading: false,
      uipathLog: [],
      menu: false,
      daterange:
        new Date(new Date().setDate(new Date().getDate() - 2))
          .toISOString()
          .slice(0, 10) +
        " - " +
        new Date().toISOString().slice(0, 10),
      nowDate: new Date().toISOString().slice(0, 10),
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ],
      fromdate: new Date(new Date().setDate(new Date().getDate() - 2))
        .toISOString()
        .slice(0, 10),
      todate: new Date().toISOString().slice(0, 10),
      headers: [
        { text: "Process Name", value: "processUniqueID" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "processStartDate" },
      ],
      totSuccessTransaction: 0,
      totFailedTransaction: 0,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 1,
      },
      logDialog: false,
      selectedItem: { name: "", processHistory: [] },
      selectedItemOrgName: "",
      selectedItemDtLoading: false,
    };
  },
  methods: {
    getColor(status) {
      if (status == "Failed" || status == "Failure") return "#FDEDE8";
      else if (status == "Successful") return "#E6F2F1";
    },
    getTextColor(status) {
      if (status == "Failed" || status == "Failure") return "#E64A19";
      else if (status == "Successful") return "#00796B";
    },
    __getTimeDiff(lastHeartBeatDT) {
      return parseInt(
        new Date(new Date() - new Date(lastHeartBeatDT))
          .toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "UTC",
          })
          .split(":")[0]
      );
    },
    async openDialog(item) {
      this.selectedItemOrgName = item.name;
      this.selectedItemDtLoading = true;
      this.logDialog = true;
      let result = await uiPathService.getProcessHistoryDetails({
        processStartDate: this.fromdate,
        processEndDate: this.todate,
        orgId: item.id,
      });
      this.selectedItem.processHistory = result;
      this.selectedItemDtLoading = false;
    },
    closeDialog() {
      this.selectedItem = {};
      this.logDialog = false;
    },
    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }
      } else {
        this.fromdate = new Date(new Date().setDate(new Date().getDate() - 2))
          .toISOString()
          .slice(0, 10);
        this.todate = new Date().toISOString().slice(0, 10);
      }
      this.getAll();
    },
    __getFailedTransactionCount(item) {
      let count = 0;
      item.processHistory?.forEach((log) => {
        if (log.status == "Failed" || log.status == "Failure") {
          count += 1;
        }
      });
      return count;
    },
    async getAll() {
      this.loading = true;
      let result = await uiPathService.getProcessHistoryElasticDashbaord({
        processStartDate: this.fromdate,
        processEndDate: this.todate,
        processUniqueID: "",
      });

      this.uipathLog = result;

      this.uipathLog.forEach((element) => {
        if (element.processHistory != null && element.processHistory?.length > 0) {
          element.processHistory?.forEach((log) => {
            if (log.status == "Successful") {
              this.totSuccessTransaction += 1;
            } else {
              this.totFailedTransaction += 1;
            }
          });
        }
      });
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.success-title {
  font-size: 52px !important;
  font-weight: bold !important;
}
.card-bg {
  box-sizing: border-box;

  //   background: #e2e5f4;
  border: 0.5px solid #c6ccef;
  border-radius: 4px;
}
.dashtitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  color: #17181d;
}
.dashnum {
  font-weight: 700;
  font-size: 36px;
  line-height: 28px;
  font-style: italic;
  color: #17181d;
}
.dashboard-dt-title {
  font-size: 18px !important;
  font-weight: bold !important;
}
</style>

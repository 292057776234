
import { mainAxios } from '../axios-instance';
export default {

  async getAll(input) {
    let result = await mainAxios.get(`/Holidays`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Holidays`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/Holidays/${id}`, input);
    return result;
  },
  async createCountryState(input, id) {
    let result = await mainAxios.post(`/Holidays/${id}/mapping`, input);
    return result;
  },
  async delete(input) {
    let result = await mainAxios.delete(`/Holidays/${input.uniqueId}`, null, {
      params: { uniqueId: input.uniqueId, isPermanentDelete: input.isPermanentDelete },
    });
    return result;
  },
  async createClient(input, id) {
    let result = await mainAxios.post(`/Holidays/${id}/client/mapping`, input);
    return result;
  },
  async deleteConfirmClient(mappingId, id) {
    let result = await mainAxios.delete(`/Holidays/${id}/client/mapping?mappingId=${mappingId}&holidayId=${id}`);
    return result;
  },
}
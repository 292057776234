
import { mainAxios } from '../axios-instance';
export default {

  async getAll(input) {
    let result = await mainAxios.get(`/Services`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Services`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/Services/${id}`, input);
    return result;
  },
  async addExpenditure(input) {
    let result = await mainAxios.post(`/Services/expenditure`, input);
    return result;
  },
  async delete(input) {
    let result = await mainAxios.delete(`/Services/${input.uniqueId}`, null, {
      params: { uniqueId: input.uniqueId },
    });
    return result;
  },
  async createClient(input) {
    let result = await mainAxios.post(`/Services/mappings`, input);
    return result;
  },
  async deleteConfirmClient(id) {
    let result = await mainAxios.delete(`/Services/mappings/${id}`);
    return result;
  },
}
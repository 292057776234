<template>
  <div>
    <v-card class="mx-2 pa-2 cardrt" @click="processIssueOrEnhance(task)">
      <div class="d-flex justify-space-between">
        <small class="font-weight-bold text-justify">{{ task.name }}</small>
      </div>
      <div class="d-flex align-center mt-4 justify-space-between">
        <small class="grey--text">{{
          new Date(task.createdOn).toDateString()
        }}</small>
      </div>
      <div class="d-flex text-left mt-4 justify-space-between">
        <v-btn v-if="task.type == 'Issue'" class="pl-0" small text>
          <v-avatar left>
            <v-icon color="red " small>mdi-alert-circle</v-icon>
          </v-avatar>

          {{ task.id }}
        </v-btn>
        <v-btn v-else-if="task.type == 'Enhancement'" class="pl-0" small text>
          <v-avatar left>
            <v-icon color="green " small>mdi-bookmark-plus</v-icon>
          </v-avatar>

          {{ task.id }}
        </v-btn>
        <v-btn v-else class="pl-0" small text>
          <v-avatar left>
            <v-icon color="buttonColor " small>mdi-comment-quote</v-icon>
          </v-avatar>

          {{ task.id }}
        </v-btn>
        <v-chip color="primary lighten-4 black--text" small label
          >{{ task.organization }}
        </v-chip>
      </div>
    </v-card>

    <v-dialog
      v-model="dialog"
      width="1000"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-btn v-if="task.type == 'Issue'" color="red" small plain>
              <v-avatar left>
                <v-icon small>mdi-alert-circle</v-icon>
              </v-avatar>

              {{ task.type }} - {{ task.id }}
            </v-btn>
            <v-btn
              v-else-if="task.type == 'Enhancement'"
              color="green"
              small
              plain
            >
              <v-avatar left>
                <v-icon small>mdi-bookmark-plus</v-icon>
              </v-avatar>

              {{ task.type }} - {{ task.id }}
            </v-btn>
            <v-btn v-else color="buttonColor" small plain>
              <v-avatar left>
                <v-icon small>mdi-bookmark-plus</v-icon>
              </v-avatar>

              {{ task.type }} - {{ task.id }}
            </v-btn></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="row col-md-12 mb-0">
          <div class="col-md-8">
            <!-- <v-card-text> -->

            <v-card-text>{{ tickets.processName }}</v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-title class="font-weight-bold">
              {{ tickets.name }}
            </v-card-title>
            <v-card-text class="pb-0">Description</v-card-text>
            <v-card-subtitle class="font-weight-bold pt-0">
              {{ tickets.description }}</v-card-subtitle
            >

            <div>
              <v-row no-gutters justify="center" align="center">
                <v-col cols="8">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-file-input
                      multiple
                      show-size
                      v-model="selectedFiles"
                      label="Select File"
                      required
                      :rules="[
                        (v) => !!v || 'File is required',
                        (files) =>
                          !files ||
                          !files.some((file) => file.size > fileLimit) ||
                          'File size should be less than ' + limit,
                      ]"
                      @change="selectFiles"
                    ></v-file-input>
                  </v-form>
                </v-col>

                <v-col cols="4" class="pl-2">
                  <v-btn color="primary" dark small @click="upload">
                    Upload
                    <v-icon right dark>mdi-cloud-upload</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-alert
                v-if="message"
                border="left"
                color="teal"
                outlined
                class="multi-line"
              >
                {{ message }}
              </v-alert>

              <v-card v-if="fileInfos.length > 0" class="mx-auto">
                <v-subheader>List of Files</v-subheader>
                <div class="row mt-5 mb-2">
                  <div
                    class="col-12 col-md-3 border text-center"
                    v-for="(file, index) in fileInfos"
                    :key="index"
                  >
                    <v-img
                      height="50"
                      v-if="typeimage(file.name)"
                      :src="file.fileURL"
                      contain
                      :alt="file.name"
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="mr-3 float-right"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip></v-img
                    >
                    <v-img
                      height="50"
                      v-else-if="typedoc(file.name)"
                      :src="require('@/assets/word.png')"
                      contain
                      :alt="file.name"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="mr-3 float-right"
                            @click="remove(file.id)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip></v-img
                    >
                    <v-img
                      height="50"
                      v-else-if="typeexcel(file.name)"
                      :src="require('@/assets/excel.png')"
                      contain
                      :alt="file.name"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="mr-3 float-right"
                            @click="remove(file.id)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip></v-img
                    >
                    <v-img
                      height="50"
                      v-else-if="typepdf(file.name)"
                      :src="require('@/assets/pdf.png')"
                      contain
                      :alt="file.name"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="mr-3 float-right"
                            @click="remove(file.id)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip></v-img
                    >

                    <h5
                      class="text-center"
                      v-if="(fname = strtrunk(file.name))"
                    >
                      <a :href="file.fileURL" target="_blank">{{ fname }}</a>
                    </h5>
                  </div>
                </div>
              </v-card>
              <div class="pt-10">
                <div class="font-medium text-15">Comments</div>
                <Comment
                  @commentUpdate="onClickSubmit"
                  isCreate
                  :comment="{
                    user: '',
                    message: 'Add a comment...',
                  }"
                />
                <Comment
                  @commentUpdate="onClickSubmit"
                  @commentDelete="onClickDelete"
                  :comment="comment"
                  v-for="comment in comments"
                  :key="comment.id"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <v-card-subtitle>
              <span class="grey--text mr-2"> By {{ tickets.createdBy }} </span>
              <v-chip color="primary lighten-4 black--text" small label
                >{{ tickets.organization }}
              </v-chip>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>Status</v-card-text>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card-title
                  class="cursor-point font-weight-bold"
                  @click="statusItem(tickets)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ newStatus(tickets.status) }}
                </v-card-title>
              </template>
              <span>Edit Status</span>
            </v-tooltip>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStatus" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Status</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-select
                  :items="statuslist"
                  item-text="name"
                  item-value="value"
                  v-model="editedStatus.status"
                  label="Select Status"
                  :clearable="false"
                ></v-select>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize grey lighten-2" @click="close">
            Cancel
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img
          class="mt-7"
          :src="require('@/assets/logoDA.svg')"
          height="80"
          width="80"
        />
        <v-card-text>
          <v-card-title class="justify-center pt-0 pb-1"
            >Are you sure you want to delete this File?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import Comment from "../components/Comment.vue";
import config from "../apiurl.js";
import ticketService from "@/services/ticket.service.js";
export default {
  components: {
    Comment,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    fileLimit: config.fileLimit,
    limit: config.limitText,
    dialogDelete: false,
    dialogConfirm: false,
    selectedFiles: undefined,
    deleteid: 0,
    progressInfos: [],
    message: "",
    fileInfos: [],
    comments: [],
    editedIndex: -1,
    valid: false,
    dialog: false,
    tickets: {},
    editedItem: {
      id: 0,
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    defaultItem: {
      id: 0,
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    editedStatus: {
      id: "",
      status: "",
    },
    defaultStatus: {
      id: "",
      status: "",
    },
    status: "",
    valid1: false,
    dialogStatus: false,
    statuslist: [
      { name: "ToDo", value: "New" },
      { name: "In Progress", value: "InProgress" },
      { name: "Testing", value: "UAT" },
      { name: "Done", value: "Implemented" },
    ],
  }),
  computed: {},
  watch: {
    dialogStatus(val) {
      val || this.close();
    },
  },
  methods: {
    typeimage(item) {
      var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    typedoc(item) {
      var _validFileExtensions = [".doc", ".docx", "txt"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    typepdf(item) {
      var _validFileExtensions = [".pdf"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    typeexcel(item) {
      var _validFileExtensions = [".xlsx", ".csv"];
      if (item.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            item
              .substr(item.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    strtrunk(n) {
      var len = 7;
      var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
      var filename = n.replace("." + ext, "");
      if (filename.length <= len) {
        return n;
      }
      filename = filename.substr(0, len) + (n.length > len ? "..." : "");
      return filename + "." + ext;
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "New") {
        newstatus = "ToDo";
      } else if (id == "Implemented") {
        newstatus = "Done";
      } else if (id == "InProgress") {
        newstatus = "In Progress";
      } else if (id == "UAT") {
        newstatus = "Testing";
      }
      return newstatus;
    },
    processIssueOrEnhance(item) {
      this.getById(item.id);
    },
    statusItem(item) {
      this.editedStatus = Object.assign({}, item);
      this.dialogStatus = true;
    },
    close() {
      this.dialogStatus = false;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    async getById(input) {
      let result = await ticketService.getTicketById(input);

      if (result.status == 200) {
        this.tickets = result.data;
        this.fileInfos = result.data.file;
        this.comments = result.data.comments;
        this.comments = result.data.comments.sort((a, b) => {
          return parseInt(b.id) - parseInt(a.id);
        });
        this.dialog = true;
      }
    },
    remove(id) {
      this.deleteid = id;
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.deleteFile(this.deleteid);
      this.dialogDelete = false;
    },
    async deleteFile(id) {
      let result = await ticketService.deleteFile(id);
      if (result.status == 200) {
        this.status = "File removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getById(this.tickets.id);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("tecketStatus", "someValue");
    },
    save() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.updateStatus({
          status: this.editedStatus.status,
          ticketId: this.editedStatus.id,
        });

        this.close();
      }
    },
    async updateStatus(input) {
      let result = await ticketService.changeStatus(input);
      if (result.status == 200) {
        this.status = "Status updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getById(input.ticketId);
      }
    },
    selectFiles(files) {
      this.progressInfos = [];
      this.selectedFiles = files;
    },
    upload() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.uploadFiles();
      }
    },
    async uploadFiles() {
      this.message = "";
      let formData = new FormData();

      for (var i = 0; i < this.selectedFiles.length; i++) {
        let file = this.selectedFiles[i];
        formData.append("files", file);
      }

      let result = await ticketService.updateFile(formData, this.tickets.id);

      if (result.status == 200) {
        this.status = "Files uploaded successfully";
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.getById(this.tickets.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    onClickSubmit(value) {
      if (value.type) {
        let data = {
          message: value.newComment,
        };
        this.submitComments(data, this.tickets.id);
      } else {
        let data = {
          message: value.newComment,
        };
        this.updateComments(data, value.id);
      }
    },
    onClickDelete(value) {
      this.deleteComments(value.id);
    },
    async deleteComments(id) {
      let result = await ticketService.deleteComment(id);
      if (result.status == 200) {
        this.status = "Comment deleted successfully";

        this.getById(this.tickets.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    async submitComments(input, id) {
      let result = await ticketService.createComment(input, id);
      if (result.status == 200) {
        this.status = "Comment added successfully";

        this.getById(this.tickets.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    async updateComments(input, id) {
      let result = await ticketService.updateComment(input, id);
      if (result.status == 200) {
        this.status = "Comment updated successfully";

        this.getById(this.tickets.id);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.multi-line {
  white-space: pre-line;
}

.cardrt {
  border: gray;

  border: 1px solid rgb(231, 221, 221) !important;
}

.v-icon.v-icon {
  justify-content: left !important;
}
</style>

import { render, staticRenderFns } from "./ServicesList.vue?vue&type=template&id=64d46235&scoped=true&"
import script from "./ServicesList.vue?vue&type=script&lang=js&"
export * from "./ServicesList.vue?vue&type=script&lang=js&"
import style0 from "./ServicesList.vue?vue&type=style&index=0&id=64d46235&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d46235",
  null
  
)

export default component.exports
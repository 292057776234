<template>
  <div class="container-fluid" style="overflow: hidden">
    <div id="cardBackground">
      <div class="row">
        <div class="col-lg-3 col-1"></div>
        <div class="col-lg-6 col-10 mt-16 mb-16">
          <div class="card my-3 px-4 register-box">
            <div class="card-body p-4 p-sm-5">
              <!-- <img src="../assets/Pattern.png" class="patternImg ml-3" /> -->
              <div v-if="authStatus != 'success'" class="col-12 mt-5">
                <h2 class="mb-2 mainLogin">Sign Up</h2>
                <small class="sec-login"
                  >Let's onboard you to Marketplace</small
                >
                <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
                  <div class="row">
                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >First Name
                        <span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        v-model="firstName"
                        :rules="[
                          (v) => !!v || 'First Name is required',
                          (v) =>
                            (v && !!v.trim()) || 'First Name cannot be blank',
                        ]"
                        required
                        @keypress="onlyCharater($event)"
                        v-on:keyup.enter="validate"
                        validate-on-blur
                      >
                        <template #label> First Name </template>
                      </v-text-field>
                    </div>
                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >Last Name
                        <span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        v-model="lastName"
                        :rules="[
                          (v) => !!v || 'Last Name is required',
                          (v) =>
                            (v && !!v.trim()) || 'Last Name cannot be blank',
                        ]"
                        required
                        v-on:keyup.enter="validate"
                        @keypress="onlyCharater($event)"
                        validate-on-blur
                      >
                        <template #label> Last Name </template>
                      </v-text-field>
                    </div>
                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >Email Id<span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        v-model="emailId"
                        :rules="[
                          (v) => !!v || 'E-mail is required',
                          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                          (v) =>
                            (v &&
                              !rejecteddomains.find((item) =>
                                v.substring(v.indexOf('@') + 1).includes(item)
                              )) ||
                            'Domain not allowed',
                        ]"
                        required
                        :readonly="emailDes"
                        v-on:keyup.enter="validate"
                        @keypress.native="noSpace($event)"
                        validate-on-blur
                      >
                        <template #label> Email Id </template>
                      </v-text-field>
                    </div>

                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >Country
                        <span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-select
                        :items="countries"
                        item-text="text"
                        solo
                        item-value="value"
                        required
                        v-model="country"
                        label="Country"
                        return-object
                        :rules="[() => !!country || 'Country is required']"
                      ></v-select>
                    </div>
                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >Phone Number<span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        v-model="mobileNo"
                        :prefix="country ? country.countryCode : ''"
                        v-mask="country ? country.mask : emask"
                        :rules="[
                          (v) => !!v || 'Phone Number is required',
                          (v) =>
                            (v && v.length >= 10) || 'Phone Number Invalid',
                        ]"
                        required
                        v-on:keyup.enter="validate"
                        validate-on-blur
                      >
                        <template #label> Phone Number </template></v-text-field
                      >
                    </div>

                    <div class="col-lg-6 py-0" v-if="!orgDes">
                      <label class="title-login"
                        >Organization Name
                        <span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        v-model="input"
                        :rules="[
                          (v) => !!v || 'Organization is required',
                          (v) =>
                            (v && !!v.trim()) || 'Organization cannot be blank',
                        ]"
                        required
                        v-on:keyup.enter="validate"
                        validate-on-blur
                      >
                        <template #label>
                          Organization Name</template
                        ></v-text-field
                      >
                    </div>
                    <div class="col-lg-6 py-0" v-else>
                      <label class="title-login"
                        >Organization Name
                        <span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >

                      <v-select
                        class="company-size-dropdown"
                        :items="regOrganization"
                        solo
                        item-text="name"
                        item-value="id"
                        v-model="organization"
                        label="Organization"
                        :disabled="orgDes"
                        required
                      >
                      </v-select>
                    </div>

                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >Password<span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        v-model="password"
                        :rules="passwordRules"
                        :type="show1 ? 'text' : 'password'"
                        required
                        maxlength="20"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        @keypress.native="noSpace($event)"
                        v-on:keyup.enter="validate"
                        validate-on-blur
                      >
                        <template #label> Password </template></v-text-field
                      >
                    </div>
                    <div class="col-lg-6 py-0">
                      <label class="title-login"
                        >Confirm Password<span class="red--text"
                          ><strong>&nbsp;* </strong></span
                        ></label
                      >
                      <v-text-field
                        solo
                        :type="showC ? 'text' : 'password'"
                        v-model="confirm_password"
                        maxlength="20"
                        :rules="[
                          (v) => !!v.trim() || 'Password is required',
                          (v) =>
                            (v.trim() && v.length <= 20) ||
                            'Password must be maximum 20 characters',
                          passwordConfirmationRule,
                          (v) =>
                            (v.trim() && v.length >= 6) ||
                            'Password must be minimum 6 characters',
                        ]"
                        required
                        :append-icon="showC ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showC = !showC"
                        @keypress.native="noSpace($event)"
                        v-on:keyup.enter="validate"
                        ><template #label>
                          Confirm Password
                        </template></v-text-field
                      >
                    </div>
                  </div>

                  <v-btn
                    dark
                    x-large
                    color="buttonColor"
                    class="btn-block mt-4"
                    @click="validate"
                  >
                    Join Here
                  </v-btn>

                  <div
                    v-if="authStatus == 'error'"
                    class="text-center mt-3 error-text"
                  >
                    {{ authStatusMsg }}
                  </div>
                  <div class="mt-8 mb-4 text-center signUp">
                    Already have an account?<router-link
                      to="/login"
                      class="SignUpLink"
                      >Sign In</router-link
                    >
                  </div>
                </v-form>
              </div>

              <div
                class="text-center col-12 mt-5"
                v-if="authStatus == 'success'"
              >
                <img
                  :src="require('@/assets/success.svg')"
                  height="180"
                  width="180"
                />
                <h2 class="mt-5 carttext">
                  Verification link sent to {{ emailId }}
                </h2>
                <p class="orderdesc mt-2">
                  Please check your email for account verification
                </p>
                <v-btn
                  color="primary"
                  class="white--text mt-5 text-none"
                  large
                  depressed
                  @click="resend"
                >
                  Resend Link
                </v-btn>
                <!-- <img :src="require('@/assets/logoDA.svg')" />
                <h2 class="mt-5">Verification link sent to {{ emailId }}</h2>
                <h4>Please check your email for account verification</h4>
                <v-btn dark class="mt-5" @click="resend"> Resend Link </v-btn> -->
              </div>
            </div>
          </div>
          <div class="text-center textval mt-16" v-if="authStatus != 'success'">
            <div>
              Alignxcel collects and uses personal data in accordance with our
              <router-link to="/privacy-policy">
                <a class="footercontent">Privacy Policy </a>.
              </router-link>
            </div>
            <div>
              By creating an account, you agree to our
              <router-link to="/terms-conditions">
                <a class="footercontent">Terms & Conditions </a>.
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";

import orgService from "@/services/organization.service.js";

export default {
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
    getval() {
      return this.rejecteddomains.find((item) =>
        this.emailId.substring((this.emailId.indexOf("@") + 1).includes(item))
      );
    },
  },
  created() {
    if (this.authStatus == "success") {
      window.scrollTo(0, 0);
    }
    this.authStatus = "";
    this.authStatusMsg = "";
    this.emailDes = false;
    this.orgDes = false;
    if (this.$route.params.id) {
      let val = atob(this.$route.params.id).split("*");
      this.emailId = val[0];
      this.organization = parseInt(val[1]);
      this.emailDes = true;
      this.orgDes = true;
      this.getRegOrganization();
    }
  },
  data: () => ({
    regOrganization: [],
    authStatus: "",
    authStatusMsg: "",
    email: "",
    status: "",
    emailDes: false,
    orgDes: false,
    show1: false,
    showC: false,
    valid: false,
    emailId: "",
    organization: 0,
    firstName: "",
    lastName: "",
    password: "",
    mobileNo: "",
    confirm_password: "",
    input: "",
    orgReq: false,
    rejecteddomains: ["yahoo", "rediff", "gmail"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && !this.getval()) || "Domain not allowed",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be minimum 6 characters",
    ],
    actualRules: [
      (v) => (!!v && v != -1) || "Organization is required",
      (v) =>
        (v &&
          this.emailId.substring(this.emailId.indexOf("@") + 1) ==
            this.email.substring(this.email.indexOf("@") + 1)) ||
        "Domain must match",
    ],
    countries: [
      {
        text: "United States",
        value: "USA",
        countryCode: "+1",
        mask: "(###) ###-####",
      },
      {
        text: "India",
        value: "IN",
        countryCode: "+91",
        mask: "##########",
      },
    ],
    emask: "##########",
    country: null,
  }),
  methods: {
    validate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.organization == 0) {
          this.register({
            firstName: this.firstName,
            lastName: this.lastName,
            mobileNo: this.country.countryCode + this.mobileNo,
            emailId: this.emailId,
            registeredOrg: this.input,
            password: this.password,
            organizationId: null,
            webUrl: window.location.origin + "/#/verify/",
          });
        } else {
          this.register({
            firstName: this.firstName,
            lastName: this.lastName,
            mobileNo: this.country.countryCode + this.mobileNo,
            emailId: this.emailId,
            organizationId: this.organization,
            password: this.password,
            webUrl: window.location.origin + "/#/verify/",
          });
        }
      }
    },
    async register(item) {
      let result = await authService.register(item);

      if (result.status == 200) {
        this.status = "Registered successfully! verify your Email Id";
        this.authStatus = "success";
        this.authStatusMsg = "";

        setTimeout(() => {
          this.status = "";
        }, 2000);
      } else {
        this.authStatus = "error";
        this.authStatusMsg = result.data;
      }
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onlyCharater($event) {
      if (
        $event.charCode === 0 ||
        /^[A-Za-z ]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    async getRegOrganization() {
      let result = await orgService.getAllOrg();
      this.regOrganization = result;
    },
    resend() {
      if (this.emailId) {
        this.reSendRegsiter({
          emailId: this.emailId,
          webURL: window.location.origin + "/#/verify/",
        });
      }
    },
    async reSendRegsiter(item) {
      let result = await authService.reSendRegsiter(item);

      if (result.status == 200) {
        this.status = "Verify link resended successfully! verify your Email Id";

        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
.error-text {
  color: red;
}
.error-text {
  color: red;
}
.register-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 60px #0000000f;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
small {
  font-size: 16px;
}
.v-btn {
  background: $buttonColor;
  height: 50px;
  text-transform: none;
  border-radius: 4px !important;
}
.v-avatar {
  height: 24px !important;
  width: 24px !important;
  justify-content: right;
}
.patternImg {
  margin-top: 10px;
}
.signInRouterLink {
  color: #20d2b6;
  font-weight: bolder;
}
// label {
//   font-weight: bolder;
// }
// #cardBackground {
//   background: transparent linear-gradient(259deg, #090e18 0%, #464c58 100%) 0%
//     0% no-repeat padding-box;
//   background-size: 100% 40%;
//   background-position: bottom center;
// }
@media (min-width: 239px) and (max-width: 320px) {
  // #cardBackground {
  //   width: 320px;
  // }
}

.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.orderdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #45464a;
}
.textval {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #17181d;
}
.footercontent {
  color: $primary;
}
</style>
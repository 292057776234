
import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/ClientProcess/cart`, {
      params: input,
    });
    return result.data;

  },

  async create(input) {
    let result = await mainAxios.post(`/ClientProcess/cart/add`, null, { params: input });

    return result;
  },


  async deleteCart(input) {

    let result = await mainAxios.delete(`/ClientProcess/cart/remove`, {
      data: input
    });
    return result;
  },

}
<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <!-- <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="getOList"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  @change="onChange"
                ></v-select>
              </div> -->
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn dark class="mt-lg-3 mt-0" @click="openDialog">
                  <v-icon dark> mdi-plus </v-icon> Add License</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="mainheaders"
        :server-items-length="licenseList.total"
        :items-per-page="10"
        :items="licenseList.records"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
        class="elevation-1"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
        @item-expanded="loadDetails"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toLocaleDateString() }}</td>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4">
            <v-data-table
              :headers="childheaders"
              :items="item.license"
              item-key="id"
              class="elevation-1 grey lighten-3"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Licenses</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn
                    dark
                    class="ma-3"
                    @click="
                      dialogLicense = true;
                      editedLicenseIndex = -1;
                      editedLicense.clientId = item.id;
                      getproducts(item);
                    "
                  >
                    <v-icon dark> mdi-plus </v-icon> Add Product</v-btn
                  > -->
                </v-toolbar>
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                <td>
                  {{ new Date(item.endDate).toLocaleDateString() }}
                </td>
              </template>
              <template v-slot:[`item.startDate`]="{ item }">
                <td>{{ new Date(item.startDate).toLocaleDateString() }}</td>
              </template>
              <template v-slot:[`item.expiry`]="{ item }">
                <v-chip
                  label
                  small
                  :color="getColorStatus(item.expiry)"
                  :text-color="getTextColor(item.expiry)"
                  dark
                >
                  <span v-if="item.expiry >= 0 && item.expiry <= 7">About to Expire</span>
                  <span v-else-if="item.expiry < 0">Expired</span>
                  <span v-else>In Use</span>
                </v-chip>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <v-switch
                  @change="deleteItem(item, clientId)"
                  v-model="item.isActive"
                  :false-value="false"
                  :true-value="true"
                  inset
                  class="offset-4 offset-lg-0"
                ></v-switch>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="50"
                      small
                      class="mr-3"
                      @click="editItem(item, clientId)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!---Category update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-select
              :items="allOrganization"
              item-text="name"
              item-value="id"
              v-model="editedItem.clientId"
              :disabled="this.editedIndex !== -1 ? true : false"
              required
              :rules="[(v) => !!v || 'Client is required']"
              ><template #label>
                Client
                <span class="red--text"><strong>* </strong></span>
              </template></v-select
            >
            <v-text-field
              v-model="editedItem.licenseKey"
              label="License Key"
              required
              maxlength="100"
              :disabled="this.editedIndex !== -1 ? true : false"
              :rules="[
                (v) => !!v || 'License Key is required',
                (v) => (v && !!v.trim()) || 'License Key cannot be blank',
              ]"
            >
              <template #label>
                License Key
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-select
              :items="licenseTypeOb.records"
              item-text="name"
              item-value="id"
              v-model="editedItem.licenseTypeId"
              required
              :rules="[(v) => !!v || 'License Type is required']"
              ><template #label>
                License Type
                <span class="red--text"><strong>* </strong></span>
              </template></v-select
            >

            <v-menu
              ref="menustart"
              v-model="menustart"
              :close-on-content-click="false"
              :return-value.sync="editedItem.startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.startDate"
                  label="Start Date"
                  append-icon="mdi-calendar"
                  @click:append="on.click"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Start Date Required']"
                  ><template #label>
                    Start Date
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="editedItem.startDate"
                no-title
                scrollable
                :max="editedItem.endDate ? editedItem.endDate : ''"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menustart = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menustart.save(editedItem.startDate), startdateText()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menuend"
              v-model="menuend"
              :close-on-content-click="false"
              :return-value.sync="editedItem.endDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.endDate"
                  label="End Date"
                  append-icon="mdi-calendar"
                  readonly
                  @click:append="on.click"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'End Date Required']"
                  ><template #label>
                    End Date
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="editedItem.endDate"
                no-title
                scrollable
                :min="editedItem.startDate ? editedItem.startDate : ''"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuend = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuend.save(editedItem.endDate), enddateText()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn dark color="black" class="text-capitalize" depressed @click="onUpdate">
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to
            {{ this.editedItem.isActive == true ? "Activate" : "Deactivate" }}
            this License?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import orgService from "@/services/organization.service.js";
import licenseService from "@/services/licenseExpiry.service.js";

export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New License" : "Edit License";
    },
    getOList() {
      var arr = Object.assign([], this.allOrganization);
      arr.unshift({ id: "", name: "All" });
      return arr;
    },
    // gridData() {
    //   if (!(this.allOrganization && this.clientList)) return [];

    //   let res = [];
    //   res = this.allOrganization.filter((el) => {
    //     return !this.clientList.find((element) => {
    //       return element.name === el.name;
    //     });
    //   });
    //   return res;
    // },
    gridDataProduct() {
      if (!this.productList.data) return [];

      let res = [];
      res = this.productList.data.filter((el) => {
        return !this.client.find((element) => {
          return element.code === el.code;
        });
      });
      return res;
    },
  },
  data: () => ({
    allOrganization: [],
    licenseList: {},
    selectedOrg: "",
    menustart: false,
    expanded: [],
    menuend: false,

    nowDate: new Date().toISOString().slice(0, 10),
    loading: false,
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    licenseType: [
      { name: "Ui Path Agent", value: "Ui Path Bot" },
      { name: "UI Path Studio", value: "UI Path Studio" },
    ],
    mainheaders: [
      {
        text: "Client",
        value: "name",
        sortable: false,
      },

      {
        text: "Created On",
        sortable: false,
        value: "createdOn",
      },

      {
        text: "",
        value: "data-table-expand",
        sortable: false,
      },
    ],
    childheaders: [
      {
        text: "License Added By",
        align: "start",
        sortable: false,
        value: "userName",
      },

      {
        text: "License key",
        value: "licenseKey",
        sortable: false,
      },
      {
        text: "License Type",
        value: "licenseType",
        sortable: false,
      },
      {
        text: "Start Date",
        sortable: false,
        value: "startDate",
      },
      {
        text: "End Date",
        value: "endDate",
        sortable: false,
      },
      {
        text: "Alert",
        value: "expiry",
        sortable: false,
      },
      { text: "Status", value: "isActive", width: "100px", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "100px" },
    ],
    singleExpand: true,
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    searchKey: "",
    editedItem: {
      id: 0,
      clientId: 0,
      startDate: "",
      endDate: "",
      licenseType: "",
      licenseKey: "",
      startdate: "",
      enddate: "",
      isActive: true,
    },
    defaultItem: {
      id: 0,
      clientId: 0,
      startDate: "",
      endDate: "",
      licenseType: "",
      licenseTypeId: 0,
      licenseKey: "",
      startdate: "",
      enddate: "",
      isActive: true,
    },
    licenseTypeOb: {},
  }),
  created() {
    this.getOrganization();

    // EventBus.on("request", (x) => {
    //   if (x == "Pending") {
    //     this.updatePagination({ page: this.pageIndex });
    //   }
    // });
  },

  methods: {
    // getTextColor(item) {
    //   if (item >= 0 && item <= 7) return "orange--text";
    //   else if (item < 0) return "red--text";
    //   else return "black--text";
    // },
    //   gridData() {
    //   if (!(this.regOrganization && this.client)) return [];

    //   let res = [];
    //   res = this.regOrganization.filter((el) => {
    //     return !this.client.mapping.find((element) => {
    //       return element.clientId === el.id;
    //     });
    //   });
    //   return res;
    // },
    openDialog() {
      this.dialog = true;
      this.editedIndex = -1;
      this.getAllLicenseType();
    },
    getTextColor(item) {
      if (item >= 0 && item <= 7) return "#FFA500";
      else if (item < 0) return "#E64A19";
      else return "#00796B";
    },
    getColorStatus(item) {
      if (item >= 0 && item <= 7) return "#fff6e6";
      else if (item < 0) return "#FDEDE8";
      else return "#E6F2F1";
    },
    calculate(date) {
      var date1 = new Date();
      var date2 = new Date(date);
      date1.setDate(date2.getDate() - 7);
      var date3 = new Date();
      const diffTime = date2 - date3;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log("diffTime", date2 - date3);
      // date > Date.now()
      // console.log(diffDays + " days");
      if (diffDays > 0) {
        return diffDays;
      } else if (diffDays == 0) {
        return Math.abs(diffDays);
      } else {
        return -1;
      }
    },

    startdateText() {
      if (this.editedItem.startDate) {
        this.editedItem.startdate = new Date(this.editedItem.startDate).toISOString();
      } else {
        this.editedItem.startdate = "";
      }
    },

    enddateText() {
      if (this.editedItem.endDate) {
        this.editedItem.enddate = new Date(this.editedItem.endDate).toISOString();
      } else {
        this.editedItem.enddate = "";
      }
    },
    editItem(item, id) {
      var valObj = this.licenseList.records.filter(function (elem) {
        if (elem.id == id) return elem;
      });
      if (valObj.length > 0) {
        this.license = valObj[0].license;
      }
      this.editedIndex = this.license.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.startDate) {
        this.editedItem.startDate = item.startDate.slice(0, 10);

        this.editedItem.startdate = item.startDate;
      } else {
        this.editedItem.startDate = "";
      }
      if (item.endDate) {
        this.editedItem.endDate = item.endDate.slice(0, 10);
        // .toISOString()
        // .slice(0, 10);
        this.editedItem.enddate = item.endDate;
      } else {
        this.editedItem.endDate = "";
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    resetStatus() {
      // console.log("this.editedIndex1", this.editedIndex);
      this.license[this.editedIndex].isActive =
        this.license[this.editedIndex].isActive == true ? false : true;
    },
    deleteItem(item, id) {
      var valObj = this.licenseList.records.filter(function (elem) {
        if (elem.id == id) return elem;
      });
      if (valObj.length > 0) {
        this.license = valObj[0].license;
      }
      // console.log("item", item);
      this.editedItem = Object.assign({}, item);
      // console.log("this.editedItem", this.editedItem);
      // console.log("this.license", this.license);
      this.editedIndex = this.license.indexOf(item);
      // console.log("this.editedIndex", this.editedIndex);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      // console.log("this.editedIndex2", this.editedIndex);
      const id = this.license[this.editedIndex].id;

      this.editedItem.id = id;

      this.delete({ isActive: this.editedItem.isActive }, id);
      this.dialogDelete = false;
    },
    onChange() {
      if (this.selectedOrg == null) {
        this.selectedOrg = "";
      }
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.selectedOrg,
      };
      this.getAllLicense(data);
    },
    loadDetails({ item, value }) {
      if (value) {
        this.license = item.license;
        this.clientId = item.id;
      }
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      this.loading = true;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.selectedOrg,
      };
      this.getAllLicense(data);
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          // var licenseList = [];
          // licenseList.push({
          //   clientId: this.editedItem.clientId,
          //   licenseType: this.editedItem.licenseType,
          //   licenseKey: this.editedItem.licenseKey,
          //   startDate: this.editedItem.startdate,
          //   endDate: this.editedItem.enddate,
          // });
          // console.log("licenseList", licenseList);
          this.create({
            clientId: this.editedItem.clientId,
            licenseTypeId: this.editedItem.licenseTypeId,
            licenseKey: this.editedItem.licenseKey,
            startDate: this.editedItem.startdate,
            endDate: this.editedItem.enddate,
          });
          // this.create({ licenseList: licenseList });
        } else {
          const id = this.license[this.editedIndex].id;
          this.editedItem.id = id;
          this.update(
            {
              licenseTypeId: this.editedItem.licenseTypeId,
              startDate: this.editedItem.startdate,
              endDate: this.editedItem.enddate,
            },
            this.editedItem.id
          );
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await licenseService.create(item);

      if (result.status == 200) {
        this.status = "License created successfully";
        this.editedItem = Object.assign({}, this.defaultItem);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await licenseService.update(item, id);
      if (result.status == 200) {
        this.status = "License updated successfully";
        this.editedItem = Object.assign({}, this.defaultItem);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item, id) {
      let result = await licenseService.update(item, id);
      if (result.status == 200) {
        if (item.isActive == true) {
          this.status = "License activated successfully";
        } else {
          this.status = "License deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.selectedOrg,
      };
      this.getAllLicense(data);
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      this.loading = true;
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.selectedOrg,
      };
      this.getAllLicense(data);
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.allOrganization = result;
    },
    async getAllLicense(input) {
      this.loading = true;
      let result = await licenseService.getAllLicense(input);
      this.loading = false;
      if (result.records.length > 0) {
        let j = 0;
        for (j = 0; j < result.records.length; j++) {
          // let alert = 0;
          // result.records[j].verificationDetails.forEach((al) => {
          //   alert = alert + al.noOfAlerts;
          // });
          // console.log("resp.data", result.records[j]);
          // // resp.data.records[j].push({ totalAlerts: alert });
          if (result.records[j].license.length > 0) {
            let i = 0;
            for (i = 0; i < result.records[j].license.length; i++) {
              result.records[j].license[i].expiry = this.calculate(
                result.records[j].license[i].endDate
              );
            }
          }
        }
        // console.log("resp.data", result);
      }

      this.licenseList = result;
    },
    async getAllLicenseType() {
      let result = await licenseService.getAllLicenseTypes();
      this.licenseTypeOb = result.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>

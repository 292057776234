<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn
                  dark
                  class="mt-lg-3 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Category</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :server-items-length="categoryList.total"
        :items-per-page="10"
        :items="categoryList.records"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.status"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!---Category update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editedItem.name"
              required
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => (v && !!v.trim()) || 'Name cannot be blank',
              ]"
            >
              <template #label>
                Name <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-select
              :items="typeList"
              item-text="name"
              item-value="value"
              v-model="editedItem.iconType"
              required
              @change="typeChange"
              :rules="[(v) => !!v || 'Icon Type is required']"
              ><template #label>
               Icon Type <span class="red--text"><strong>* </strong></span>
              </template></v-select
            >
            
            <v-text-field
              v-model="editedItem.icon"
              required
              v-if="editedItem.iconType == 'icon'"
              :rules="[
                (v) => !!v || 'Icon is required',
                (v) => (v && !!v.trim()) || 'Icon cannot be blank',
              ]"
            >
              <template #label>
                Icon <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-file-input
              v-if="!editedItem.icon && editedItem.iconType == 'image'"
              v-model="editedItem.imgIcon"
              accept="image/*"
              required
              label="Image"
              :rules="[(v) => !!v || 'Image is required']"
              
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="editedItem.icon && editedItem.iconType == 'image'"
              v-model="editedItem.imgIcon"
              accept="image/*"
              label="Image"
            >
              <template #label>
                Image file 
              </template></v-file-input
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to
            {{ this.editedItem.status == true ? "Activate" : "Deactivate" }}
            this Category?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import catService from "@/services/category.service.js";
export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  data: () => ({
    categoryList: {},
    loading: false,
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    typeList: [
      { name: "Icon", value: "icon" },
      { name: "Image", value: "image" }
    ],
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
        width: "200px",
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Status", value: "status", width: "200px", sortable: false },

      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    searchKey: "",
    editedItem: {
      id: 0,
      name: "",
      description: "",
      iconType:'',
      icon:null,
      imgIcon:null
    },
  }),
  created() {},

  methods: {
    handleFileUpload(){
      const reader = new FileReader()

reader.onload = () => {
  const binaryString = reader.result
  // Use the binaryString as needed
  // console.log(binaryString);
  this.editedItem.iconBinary= binaryString; 
  
  // console.log("binaryString", this.editedItem.iconBinary);
}

if (this.editedItem.icon) {
  reader.readAsBinaryString(this.editedItem.icon)
}
    },
    typeChange(){
      this.editedItem.icon=null;
      this.editedItem.iconBinary==''
    },
    editItem(item) {
      this.editedIndex = this.categoryList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    resetStatus() {
      this.categoryList.records[this.editedIndex].status =
        this.categoryList.records[this.editedIndex].status == true
          ? false
          : true;
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.categoryList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.categoryList.records[this.editedIndex].id;
      this.editedItem.id = id;

      this.delete({ categoryId: id, status: this.editedItem.status });
      this.dialogDelete = false;
    },

    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await catService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.categoryList = result;
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {

        // if (this.editedIndex == -1) {
        //   this.create({
        //     name: this.editedItem.name,
        //     description: this.editedItem.description,
        //     iconType:this.editedItem.iconType,
        //     icon:this.editedItem.iconType=="image"?this.editedItem.iconBinary:this.editedItem.icon,
        //   });
        // } else {
        //   const id = this.categoryList.records[this.editedIndex].id;
        //   this.editedItem.id = id;
        //   this.update({
        //     categoryId: this.editedItem.id,
        //     name: this.editedItem.name,
        //     description: this.editedItem.description,
        //     iconType:this.editedItem.iconType,
        //     icon:this.editedItem.iconType=="image"?this.editedItem.iconBinary:this.editedItem.icon,
        //   });
        // }
        let formData = new FormData();
        formData.append("Name", this.editedItem.name);
        formData.append("Description", this.editedItem.description);
        formData.append("Icon", this.editedItem.icon);
        formData.append("IconType", this.editedItem.iconType);
        formData.append("ImgIcon", this.editedItem.imgIcon);
        if (this.editedIndex == -1) {
          this.create(formData);
        } else {
             const id = this.categoryList.records[this.editedIndex].id;
          this.editedItem.id = id;
          formData.append("CategoryId", id);
          this.update(formData);
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await catService.create(item);

      if (result.status == 200) {
        this.status = "Category created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item) {
      let result = await catService.update(item);
      if (result.status == 200) {
        this.status = "Category updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await catService.delete(item);
      if (result.status == 200) {
        if (item.status == true) {
          this.status = "Category activated successfully";
        } else {
          this.status = "Category deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await catService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.categoryList = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      let result = await catService.getAll({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.categoryList = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="toogle"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :content-class="'custom-dialog-scrollbar'"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="font-weight-bold">
            {{ __orgName }}&nbsp; Process History
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="(toogle = false), (statusSwitch = false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <!-- content -->
        <v-card-title>
          <v-spacer></v-spacer>
          <v-switch
            v-model="statusSwitch"
            inset
            :label="statusSwitch ? 'Successful' : 'Failed'"
          ></v-switch>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            outlined
            hide-details
            class="col-lg-3 col-md-3 col-12 ml-2"
          ></v-text-field>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="
              selectedItem?.processHistory != null
                ? selectedItem?.processHistory?.filter((ele) =>
                    statusSwitch
                      ? ele.status == 'Successful'
                      : ele.status == 'Failure' || ele.status == 'Failed'
                  )
                : []
            "
            :footer-props="{
              'items-per-page-options': [-1],
            }"
            hide-default-footer
            class="elevation-0"
            :sort-by="['status']"
            :sort-desc="[false]"
            :group-by="['processUniqueID']"
            :search="search"
            :loading="loading"
          >
            <template v-slot:[`item.processStartDate`]="{ item }">
              <td>
                {{ new Date(item.processStartDate).toLocaleString("en-US", options) }}
              </td>
            </template>
            <template v-slot:[`item.processEndDate`]="{ item }">
              <td>
                {{ new Date(item.processEndDate).toLocaleString("en-US", options) }}
              </td>
            </template>
            <template v-slot:[`item.lastHeartBeatDT`]="{ item }">
              <td>
                <v-icon
                  :color="
                    __getTimeDiff(item.lastHeartBeatDT) > 1 ? '#E64A19' : '#4caf50cc'
                  "
                  class="mr-1"
                >
                  mdi-heart-pulse
                </v-icon>
                {{ item.lastHeartBeatDT }}
              </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td>
                <v-chip
                  label
                  small
                  :color="getColor(item.status)"
                  :text-color="getTextColor(item.status)"
                  dark
                >
                  {{ item.status }}
                </v-chip>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    selectedItem: Object,
    loading: Boolean,
    orgName: String,
  },
  computed: {
    toogle: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    __selectedItemList() {
      return this.selectedItem?.processHistory?.filter((ele) =>
        this.statusSwitch
          ? ele.status == "Successful"
          : ele.status == "Failure" || ele.status == "Failed"
      );
    },
    __orgName() {
      return this.orgName;
    },
  },
  data() {
    return {
      headers: [
        { text: "Process Name", value: "processUniqueID" },
        { text: "Transaction Number", value: "transactionNumber", width: "10%" },
        { text: "Status", value: "status", width: "5%" },
        { text: "Process Start Date", value: "processStartDate", width: "15%" },
        { text: "Process End Date", value: "processEndDate", width: "15%" },
        { text: "Last Heart Beat", value: "lastHeartBeatDT", width: "15%" },
        { text: "Exception Type", value: "exceptionType" },
        { text: "Exception", value: "exception" },
      ],
      search: "",
      statusSwitch: false,
      processHistoryList: [],
      options: {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "UTC",
      },
    };
  },

  methods: {
    getColor(status) {
      if (status == "Failed" || status == "Failure") return "#FDEDE8";
      else if (status == "Successful") return "#E6F2F1";
    },
    getTextColor(status) {
      if (status == "Failed" || status == "Failure") return "#E64A19";
      else if (status == "Successful") return "#00796B";
    },
    __getTimeDiff(lastHeartBeatDT) {
      return parseInt(
        new Date(new Date() - new Date(lastHeartBeatDT))
          .toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "UTC",
          })
          .split(":")[0]
      );
    },
    // onChange() {
    //   this.__selectedItemList = this.selectedItem?.processHistory?.filter((ele) =>
    //     this.statusSwitch
    //       ? ele.status == "Successful"
    //       : ele.status == "Failure" || ele.status == "Failed"
    //   );
    // },
  },
};
</script>
<style lang="scss" scoped>
.custom-dialog-scrollbar ::webkit-scrollbar {
  width: 0.5em; /* Set a width to make it visible (optional) */
}

.custom-dialog-scrollbar ::webkit-scrollbar-track {
  background: transparent; /* Set a transparent background color */
}

.custom-dialog-scrollbar ::webkit-scrollbar-thumb {
  background: transparent; /* Set a transparent background color for the thumb */
}
</style>

<template>
  <div>
    <v-card>
      <!-- <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-12 col-md-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div
                class="col-lg-4 col-sm-12 col-md-6"
                v-if="userType == 'admin'"
              >
                <v-select
                  :items="regOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  :clearable="true"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title> -->
      <v-card-title class="tabtitle">
        <!-- <v-spacer v-if="userType !== 'admin'"></v-spacer> -->
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          dense
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
        <v-spacer v-if="userType == 'admin'"></v-spacer>
        <div class="col-lg-4 col-sm-12 col-md-6" v-if="userType == 'admin'">
          <v-select
            :items="regOrganization"
            item-text="name"
            item-value="id"
            v-model="selectedOrg"
            label="Select Organization"
            :clearable="true"
            @change="onChange"
          ></v-select>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :server-items-length="userList.total"
        :items-per-page="10"
        :items="userList.records"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        @update:options="updatePagination"
        :loading="loading"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            @change="deleteUser(item)"
            v-model="item.status"
            :false-value="-1"
            :true-value="1"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.organization`]="props">
          <v-edit-dialog
            v-if="userType == 'admin'"
            :return-value.sync="props.item.organizationId"
            large
            @save="save(props.item, orgid)"
            @open="orgid = props.item.organizationId"
            @cancel="cancel"
          >
            <div>{{ props.item.organization }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">Update Organization</div>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-select
                  required
                  :items="regOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="orgid"
                  label="Select Organization"
                  :clearable="false"
                  v-on:change="orgChange($event)"
                  :rules="[
                    (v) => (!!v && v != -1) || 'Organization is required',
                    (v) =>
                      email
                        ? (v &&
                            props.item.emailId.substring(
                              props.item.emailId.indexOf('@') + 1
                            ) == email.substring(email.indexOf('@') + 1)) ||
                          'Domain must match'
                        : (!!v && v != -1) || 'Organization is required',
                  ]"
                ></v-select>
              </v-form>
            </template>
          </v-edit-dialog>
          <div v-else>{{ props.item.organization }}</div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center justify-center pa-4">
        <img
          :src="require('@/assets/logoDA.svg')"
          height="80"
          width="80"
          class="ma-4"
        />
        <v-card-text class="text-justify1">
          <v-card-title
            >Are you sure you want to activate this Account?</v-card-title
          >
        </v-card-text>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="primary"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>

<script>
import EventBus from "../plugins/eventBus";
import userService from "@/services/user.service.js";
import orgService from "@/services/organization.service.js";
import authService from "@/services/auth.service.js";
export default {
  computed: {},
  data: () => ({
    userList: {},
    loading: false,
    regOrganization: [],
    isLoggedIn: authService.isLoggedIn ? true : undefined,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    userType: authService.authData.userType,
    userId: authService.authData.id,
    dialogDelete: false,
    pagination: {},
    headers: [
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Last Name",
        align: "start",
        sortable: false,
        value: "lastName",
      },
      {
        text: "Email Id",
        align: "start",
        sortable: false,
        value: "emailId",
      },
      {
        text: "Mobile No",
        align: "start",
        sortable: false,
        value: "mobileNo",
      },
      { text: "Status", value: "status", width: "200px" },
      {
        text: "Organization",
        value: "organization",
        width: "200px",
        sortable: false,
      },
    ],

    pageIndex: 0,
    status: "",
    filter: {
      searchKey: "",
    },
    searchKey: "",
    valid: false,
    selectedOrg: "",
    orgid: 0,
    email: "",
    deleteId: 0,
    editedIndex: -1,
  }),
  created() {
    if (this.userType == "admin") {
      this.getOrganization();
    }
    EventBus.on("user_deactivated", () => {
      this.refreshuserlist();
    });
    EventBus.on("user_changes", (x) => {
      this.status = x;
      if (x == "User Accepted") {
        this.refreshuserlist();
      }
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("deactivate", (x) => {
      this.status = x;
      this.refreshuserlist();

      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("org_id_changes", (x) => {
      this.status = x;
      this.updatePagination({ page: this.pageIndex });
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
  },

  methods: {
    async onChange() {
      this.loading = true;
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Blocked",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userList = result;
    },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await userService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        status: "Blocked",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userList = result;
    },
    async search() {
      this.loading = true;
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Blocked",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userList = result;
    },
    async clear() {
      this.loading = true;
      this.filter.searchKey = "";
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Blocked",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userList = result;
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    async refreshuserlist() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.selectedOrg = "";
      this.loading = true;
      let result = await userService.getAll({
        skip: 0,
        take: 10,
        status: "Blocked",
        organizationId: this.selectedOrg,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userList = result;
    },
    save(item, id) {
      let valid = this.$refs.form.validate();

      if (valid) {
        this.orgIdEdit({ organizationId: id, userId: item.id });
      }
    },
    async orgIdEdit(input) {
      let result = await userService.changeOrg(input);
      if (result.status == 200) {
        this.status = "Organization Changed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    cancel() {},
    orgChange(item) {
      if (this.orgid == 0) {
        this.email = "";
      } else {
        if (item > 0) {
          var filterObj = this.regOrganization.filter(function (e) {
            return e.id == item;
          })[0];
          if (filterObj.adminEmailId) {
            this.email = filterObj.adminEmailId.emailId;
          } else {
            this.email = "";
          }
        }
      }
    },
    deleteUser(item) {
      this.editedIndex = this.userList.records.indexOf(item);
      this.deleteId = item.id;
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.toggleStatus({ userId: this.deleteId, status: "Active" });
      this.dialogDelete = false;
    },
    async toggleStatus(input) {
      let result = await userService.changeStatus(input);
      if (result.status == 200) {
        this.status = "User Activated successfully";
        EventBus.emit("user_accepted", "User Accepted successfully");
        this.updatePagination({ page: this.pageIndex });
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    resetStatus() {
      this.userList.records[this.editedIndex].status =
        this.userList.records[this.editedIndex].status == "active"
          ? "Blocked"
          : "Active";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/colors.scss";
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}
</style>

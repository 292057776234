import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ProductList from "../views/ProductList.vue";
import ProductDetails from "../views/ProductDetails.vue";
import Profile from "../views/Profile.vue";
import ProductMaster from "../views/ProductMaster.vue";
import FaqMaster from "../views/FaqMaster.vue";
import CategoryMaster from "../views/CategoryMaster.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import UsersList from "../views/UsersList.vue";
import OrganizationServices from "../views/OrganizationServices.vue";
import Leads from "../views/Leads.vue";
import AdminLeads from "../views/AdminLeads.vue";
import AdminNewRequest from "../views/AdminNewRequest.vue";
import NewProcess from "../views/NewProcess.vue";
import RegisterNewClient from "../views/RegisterNewClient.vue";
import Cart from "../views/Cart.vue";
import Order from "../views/Order.vue";
import Verify from "../views/Verify.vue";
import AdminBotImages from "../views/AdminBotImages.vue";
import BotImages from "../views/BotImages.vue";
import AdminLicense from "../views/AdminLicense.vue";
import LogActivity from "../views/LogActivity.vue";
import MySelectedProcesses from "../views/MySelectedProcesses.vue";
import OwnedProcesses from "../views/OwnedProcesses.vue";
import IssueEnhanceProcesses from "../views/IssueEnhanceProcesses.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import AboutUs from "../views/AboutUs.vue";
import ContactUs from "../views/ContactUs.vue";
import AdminUserQuery from "../views/AdminUserQuery.vue";
import HelpCenter from "../views/HelpCenter.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import AdminIssueEnhance from "../views/AdminIssueEnhance.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ApplicationTaskId from "../views/ApplicationTaskId.vue";
import ClientLicense from "../views/ClientLicense.vue";
import HolidayMaster from "../views/HolidayMaster.vue";
import AlignxcelReleaseVersionMaster from "../views/AlignxcelReleaseVersionMaster.vue";
import LogDashboard from "../views/LogDashboard.vue";
import AssetsList from "../views/AssetsList.vue";
import RoleMaster from "../views/RoleMaster.vue";
import authService from "../services/auth.service";
import SavingsPage from "../views/SavingsPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      noAuth: true,
      hideFooter: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      noAuth: true,
      hideFooter: true,
    },
  },
  {
    path: "/register/:id",
    name: "Register",
    component: Register,
    meta: {
      noAuth: true,
      hideFooter: true,
    },
  },
  {
    path: "/verify/:id",
    name: "Verify",
    component: Verify,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/myaccount",
    name: "Profile",
    component: Profile,
    meta: { breadCrumb: 'My Account' }
  },

  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    meta: { breadCrumb: 'Privacy Policy', noAuth: true, }
  },
  {
    path: "/user-queries",
    name: "User Queries",
    component: AdminUserQuery,
    meta: { breadCrumb: 'User Queries' }
  },
  {
    path: "/terms-conditions",
    name: "Terms and Conditions",
    component: TermsAndConditions,
    meta: { breadCrumb: 'Terms and Conditions', noAuth: true, }
  },
  {
    path: "/help-center",
    name: "Help Center",
    component: HelpCenter,
    meta: { breadCrumb: 'Help Center', noAuth: true, }
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs,
    meta: { breadCrumb: 'About Us', noAuth: true, }
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
    meta: { breadCrumb: 'Contact Us', noAuth: true, }
  },
  {
    path: "/masterdata/botsandimages",
    name: "Admin Agent",
    component: AdminBotImages,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/log-activity",
    name: "Log Activity",
    component: LogActivity,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/licenses",
    name: "Admin License",
    component: AdminLicense,
    meta: {
      isAdmin: true,
    },
  },
  // {
  //   path: "/masterdata/license-expiry",
  //   name: "Admin License Master",
  //   component: AdminLicenseMaster,
  //   meta: {
  //     isAdmin: true,
  //   },
  // },

  {
    path: "/masterdata/alignapi-clients",
    name: "AlignAPI Clients",
    component: RegisterNewClient,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/mybots",
    name: "Agent Selection",
    component: BotImages,
    meta: {
      breadCrumb: 'My Digital Staffs'
    }
  }
  ,

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      noAuth: true,
      hideFooter: true,
    },
  },

  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/processes",
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/processes/:id",
    name: "ProductDetails",
    component: ProductDetails,
  },
  {
    path: "/order/:id",
    name: "Order",
    component: Order,
  },

  {
    path: "/masterdata/processes",
    name: "ProductMaster",
    component: ProductMaster,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/holidays",
    name: "HolidayMaster",
    component: HolidayMaster,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/assets",
    name: "AssetsList",
    component: AssetsList,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/roles",
    name: "RoleMaster",
    component: RoleMaster,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/faqs",
    name: "FaqMaster",
    component: FaqMaster,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata",
    redirect: "/masterdata/processes",
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/categories",
    name: "CategoryMaster",
    component: CategoryMaster,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/application-task",
    name: "ApplicationTaskId",
    component: ApplicationTaskId,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/alignxcel-release",
    name: "AlignxcelReleaseVersionMaster",
    component: AlignxcelReleaseVersionMaster,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/masterdata/clientorganization",
    name: "OrganizationServices",
    component: OrganizationServices,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/manageusers",
    name: "UsersList",
    component: UsersList,
    meta: {
      isAdmin: true,
      breadCrumb: 'Manage Users'
    },

  },
  {
    path: "/leads1",
    name: "LeadsGrouped",
    component: Leads,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/leads",
    name: "Leads",
    component: AdminLeads,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/admin-issue-enhance",
    name: "Issue Enhance",
    component: AdminIssueEnhance,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/newprocess",
    name: "NewProcess",
    component: AdminNewRequest,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      breadCrumb: 'Cart'
    }
  },
  {
    path: "/client-license",
    name: "Client License",
    component: ClientLicense,
    meta: {
      breadCrumb: 'License Details'
    }
  },
  {
    path: "/myprocesses/request-new-process",
    name: "Requests",
    component: NewProcess,
    meta: {
      breadCrumb: 'Requests'
    }
  },

  {
    path: "/myprocesses",
    redirect: "/myprocesses/requested-processes",
    meta: {
      breadCrumb: 'My Workflows'
    }
  },
  {
    path: "/myprocesses/requested-processes",
    name: "Requested Processes",
    component: MySelectedProcesses,
    meta: {
      breadCrumb: 'Requested Workflows'
    }
  },
  {
    path: "/myprocesses/owned-processes",
    name: "Owned Processes",
    component: OwnedProcesses,
    meta: {
      breadCrumb: 'Owned Workflows'
    }
  },
  {
    path: "/myprocesses/issue-enhance",
    name: "Issue Enhancement",
    component: IssueEnhanceProcesses,
    meta: {
      breadCrumb: 'Issue and Enhancement'
    }
  },
  { path: "*", component: PageNotFound },
  {
    path: "/log-dashboard",
    name: "LogDashboard",
    component: LogDashboard,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: "/savings-page",
    name: "SavingsPage",
    component: SavingsPage,
    meta: {
      isAdmin: true,
    },
  },
  
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.noAuth)) {
    if (authService.isLoggedIn) {
      if (to.matched.some((record) => record.meta.isAdmin)) {
        if (authService.authData.userType === "admin") {
          next();
          return;
        }
        else if (authService.authData.userType === "supuser" && to.name == "UsersList") {
          next();
          return;
        }
        next("/home");
        return;
      } else {
        next();
        return;
      }
    }
    next("/login");
  }
  next();
});

export default router;


import { mainAxios } from '../axios-instance';
// let list = [];
export default {

  // async getAllFAQ(input) {
  //   let result = await mainAxios.get(`/FAQ`, {
  //     params: {
  //       applicableTo: input.applicableTo, skip: input.skip,
  //       take: input.take,
  //       isActive: true
  //     }
  //   });
  //   if (result.status == 200) {
  //     if (input.isPage) {
  //       let arra = Object.assign([], list[0].records);

  //       list = [];
  //       list.push({
  //         records: arra.concat(result.data.records),
  //         total: result.data.total
  //       })
  //       return list
  //     } else {
  //       list = [];
  //       list.push(result.data)
  //       return list
  //     }
  //   }

  // },
  async getAllFAQ(input) {
    let result = await mainAxios.get(`/FAQ/client`, {
      params: input,
    });
    return result.data;

  },
  async getAllFAQs(input) {
    let result = await mainAxios.get(`/FAQ`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/FAQ`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/FAQ`, input, { params: { id: id } });
    return result;
  },

  async delete(input) {
    let result = await mainAxios.put(`/FAQ/status`, null, {
      params: { FAQId: input.FAQId, status: input.status },
    });
    return result;
  },
  async createClient(input, id) {
    let result = await mainAxios.post(`/FAQ/mapping/${id}`, input);
    return result;
  },

  async deleteConfirmClient(id) {
    let result = await mainAxios.delete(`/FAQ/mapping?id=${id}`);
    return result;
  },
}
<template>
  <div class="container">
    <v-card class="pb-2">
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="allOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  :clearable="true"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <div class="row ma-2">
        <div class="col mx-2 px-2 py-3 grey lighten-3 border rounded">
          <div class="p-2">
            <h3>ToDo</h3>
            <draggable
              :animation="200"
              class="ghost-card"
              :list="arrBackLog"
              group="tasks"
              @change="seeChange($event, 'New')"
            >
              <ticket-card
                @tecketStatus="onClickChild"
                v-for="task in arrBackLog"
                :key="task.id"
                :task="task"
                class="mt-3 mb-2 cursor-move"
              ></ticket-card>
            </draggable>
          </div>
        </div>
        <div class="col mx-2 px-2 py-3 grey lighten-3 border rounded">
          <div class="p-2">
            <h3>In Progress</h3>
            <draggable
              class="ghost-card"
              :list="arrInProgress"
              group="tasks"
              @change="seeChange($event, 'InProgress')"
            >
              <ticket-card
                @tecketStatus="onClickChild"
                v-for="task in arrInProgress"
                :key="task.id"
                :task="task"
                class="mt-3 mb-2 cursor-move"
              ></ticket-card>
            </draggable>
          </div>
        </div>
        <div class="col mx-2 px-2 py-3 grey lighten-3 border rounded">
          <div class="p-2">
            <h3>Under Testing</h3>
            <draggable
              class="ghost-card"
              :list="arrTested"
              group="tasks"
              @change="seeChange($event, 'UAT')"
            >
              <ticket-card
                @tecketStatus="onClickChild"
                v-for="task in arrTested"
                :key="task.id"
                :task="task"
                class="mt-3 mb-2 cursor-move"
              ></ticket-card>
            </draggable>
          </div>
        </div>
        <div class="col mx-2 px-2 py-3 grey lighten-3 border rounded">
          <div class="p-2">
            <h3>Done</h3>
            <draggable
              class="ghost-card"
              :list="arrDone"
              group="tasks"
              @change="seeChange($event, 'Implemented')"
            >
              <ticket-card
                @tecketStatus="onClickChild"
                v-for="task in arrDone"
                :key="task.id"
                :task="task"
                class="mt-3 mb-2 cursor-move"
              ></ticket-card>
            </draggable>
          </div>
        </div>
      </div>

      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="pagelength"
          :total-visible="5"
          @input="next"
        ></v-pagination>
      </div>
    </v-card>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script >
import draggable from "vuedraggable";
import TicketCard from "../components/TicketCard.vue";
import ticketService from "@/services/ticket.service.js";
import orgService from "@/services/organization.service.js";
export default {
  created() {
    this.getOrganization();
    this.loadTickets(this.pageIndex);
  },
  components: {
    TicketCard,
    draggable,
  },
  data: () => ({
    pageIndex: 0,
    pageSize: 10,
    arrBackLog: [],
    arrInProgress: [],
    arrTested: [],
    arrDone: [],

    selectedOrg: "",
    allOrganization: [],
    loading: false,
    allTickets: {},

    statusE: "",
    status: "",
    filter: {
      searchKey: "",
    },

    editedIndex: -1,
    valid: false,
    dialog: false,
    editedItem: {
      id: 0,
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    defaultItem: {
      id: 0,
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    type: [
      { name: "Issue", value: "Issue" },
      { name: "Enhancement", value: "Enhancement" },
      { name: "Feedback", value: "Feedback" },
    ],
    page: 1,
    pagelength: 0,
  }),

  computed: {},
  methods: {
    processIssueOrEnhance(item) {
      this.editedIndex = this.allTickets.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();
      this.allOrganization = result;
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.create(
          {
            processId: this.editedItem.processId,
            name: this.editedItem.name,
            description: this.editedItem.description,
            type: this.editedItem.type,
          },
          this.editedItem.id
        );

        this.closeDialog();
      }
    },

    async create(item, id) {
      let result = await ticketService.update(item, id);

      if (result.status == 200) {
        this.statuss = "Request updated successfully";
        setTimeout(() => {
          this.statuss = "";
        }, 2000);
        this.loadTickets(this.pageIndex);
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    loadTickets(pageIndex) {
      let data = {
        Skip: 10 * pageIndex,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrganizationId: this.selectedOrg,
      };
      this.getAllTickets(data);
    },

    async getAllTickets(input) {
      this.arrBackLog = [];
      this.arrInProgress = [];
      this.arrTested = [];
      this.arrDone = [];
      this.loading = true;
      let result = await ticketService.getAllTickets(input);
      this.loading = false;
      this.allTickets = result;
      this.pagelength = Math.ceil(result.total / 10);
      result.records.map((x) => {
        if (x.status == "New") {
          this.arrBackLog.push(x);
        } else if (x.status == "InProgress") {
          this.arrInProgress.push(x);
        } else if (x.status == "UAT") {
          this.arrTested.push(x);
        } else if (x.status == "Implemented") {
          this.arrDone.push(x);
        }
      });
    },
    search() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrganizationId: this.selectedOrg,
      };
      this.getAllTickets(data);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      this.filter.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
      };
      this.getAllTickets(data);
    },

    onChange() {
      if (this.selectedOrg == null) {
        this.selectedOrg = "";
      }
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrganizationId: this.selectedOrg,
      };
      this.getAllTickets(data);
    },
    next(page) {
      this.pageIndex = page - 1;
      this.loadTickets(this.pageIndex);
    },
    seeChange(event, arr) {
      if (event.added) {
        this.updateStatus({
          ticketId: event.added.element.id,
          status: arr,
        });
      }
    },
    async updateStatus(input) {
      let result = await ticketService.changeStatus(input);
      if (result.status == 200) {
        this.status = "Status updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.loadTickets((this.pageIndex = 0));
      }
    },
    onClickChild() {
      this.loadTickets((this.pageIndex = 0));
    },
  },
};
</script>
<style scoped>
.ghost-card {
  /* opacity: 0.5; */
  min-height: 300px;
  /* background: #F7FAFC;
  border: 1px solid #4299e1; */
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>


import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    let result = await mainAxios.get(`/Invitation`, {
      params: input,
    });
    return result.data;

  },
  async getAllEmails() {
    let result = await mainAxios.get(`/Invitation/used/mails`);
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Invitation`, input);

    return result;
  },


}
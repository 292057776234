<template>
  <div class="container-fluid">
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6">
                <v-select
                  :items="categoryList.records"
                  v-model="filter.categoryId"
                  item-text="name"
                  item-value="id"
                  label="Category"
                  :clearable="true"
                  @change="modifyOthers"
                ></v-select>
              </div>
              <div class="col-lg-2 col-sm-4">
                <v-btn
                  dark
                  class="mt-lg-4 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Process</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :server-items-length="productsList.total"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :items="productsList.records"
        :loading="loading"
        @update:options="updatePagination"
        class="elevation-1"
      >
        <template v-slot:[`item.complexity`]="{ item }">
          {{ newStatus(item.complexity) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.status"
            false-value="DeActive"
            true-value="Active"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="features(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dialpad
              </v-icon>
            </template>
            <span>Features</span>
          </v-tooltip>
          <v-menu
            bottom
            left
            offset-y
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="mapRole(item)">
                <v-list-item-title>Map Role</v-list-item-title>
              </v-list-item>
              <v-list-item @click="unMapRole(item)">
                <v-list-item-title>Delete Role</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.imageURL`]="{ item }">
          <img :src="item.imageURL" width="100px" />
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <td class="text-justify">
            {{ truncate(item.description, 200) }}
          </td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td class="font-weight-bold">
            {{ item.name }}
          </td>
        </template>
      </v-data-table>
    </v-card>
    <!---Add or Update the process -->
    <!-- <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="editedItem.name"
              required
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => (v && !!v.trim()) || 'Name cannot be blank',
              ]"
            >
              <template #label>
                Name <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-select
              :items="gridData"
              item-text="name"
              item-value="id"
              v-model="editedItem.categoryId"
              required
              :rules="[(v) => !!v || 'Category is required']"
              ><template #label>
                Category <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-select
              :items="platform"
              item-text="name"
              item-value="value"
              v-model="editedItem.platform"
              required
              :rules="[(v) => !!v || 'Platform is required']"
              ><template #label>
                Platform <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-select
              :items="complexity"
              item-text="name"
              item-value="value"
              v-model="editedItem.complexity"
              required
              :rules="[(v) => !!v || 'Complexity is required']"
              ><template #label>
                Complexity <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-autocomplete
                v-model="editedItem.role"
                :items="gridDataroles"
                label="Roles"
                multiple
                small-chips
                item-text="roleName"
              item-value="id"
              >
                <template #label>
                  Roles 
                </template></v-autocomplete
              >
            <v-file-input
              v-if="!editedItem.imageURL"
              v-model="editedItem.image"
              accept="image/*"
              required
              label="Image"
              :rules="[(v) => !!v || 'Image is required']"
            >
              <template #label>
                Image file <span class="red--text"><strong>* </strong></span>
              </template></v-file-input
            >
            <v-file-input
              v-if="editedItem.imageURL"
              v-model="editedItem.image"
              accept="image/*"
              label="Image"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="onUpdate"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-stepper v-model="curr" color="green">
        <v-stepper-header class="overflow-x-auto">
          <v-stepper-step
            v-for="(step, n) in steps"
            :key="n"
            :complete="stepComplete(n + 1)"
            :step="n + 1"
            :rules="[(value) => !!step.valid]"
            :color="stepStatus(n + 1)"
          >
            {{ step.name }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-content
          v-for="(step, n) in steps"
          :step="n + 1"
          :key="n"
          class="overflow-y-auto"
        >
          {{ formTitle }}-{{ step.name }}

          <v-card v-if="n == 0" class="overflow-y-auto mb-12" height="400px">
            <v-card-text class="overflow-y-auto">
              <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                <v-text-field
                  v-model="editedItem.name"
                  required
                  :rules="[
                    (v) => !!v || 'Name is required',
                    (v) => (v && !!v.trim()) || 'Name cannot be blank',
                  ]"
                >
                  <template #label>
                    Name <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-text-field>
                <v-select
                  :items="gridData"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.categoryId"
                  required
                  :rules="[(v) => !!v || 'Category is required']"
                  ><template #label>
                    Category <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-select>
                <v-select
                  :items="platform"
                  item-text="name"
                  item-value="value"
                  v-model="editedItem.platform"
                  required
                  :rules="[(v) => !!v || 'Platform is required']"
                  ><template #label>
                    Platform <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-select>
                <v-textarea
                  v-model="editedItem.description"
                  required
                  :rules="[
                    (v) => !!v || 'Description is required',
                    (v) => (v && !!v.trim()) || 'Description cannot be blank',
                  ]"
                >
                  <template #label>
                    Description
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-textarea>
                <v-select
                  :items="complexity"
                  item-text="name"
                  item-value="value"
                  v-model="editedItem.complexity"
                  required
                  :rules="[(v) => !!v || 'Complexity is required']"
                  ><template #label>
                    Complexity
                    <span class="red--text"><strong>* </strong></span>
                  </template>
                </v-select>
                <v-autocomplete
                  v-model="editedItem.role"
                  :items="gridDataroles"
                  label="Roles"
                  multiple
                  small-chips
                  item-text="roleName"
                  item-value="id"
                >
                  <template #label> Roles </template></v-autocomplete
                >
                <v-file-input
                  v-if="!editedItem.imageURL"
                  v-model="editedItem.image"
                  accept="image/*"
                  required
                  label="Image"
                  :rules="[(v) => !!v || 'Image is required']"
                >
                  <template #label>
                    Image file
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-file-input
                >
                <v-file-input
                  v-if="editedItem.imageURL"
                  v-model="editedItem.image"
                  accept="image/*"
                  label="Image"
                ></v-file-input>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card
            v-else-if="n == 1"
            class="overflow-y-auto mb-12"
            height="400px"
          >
            <v-card-text class="overflow-y-auto">
              <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                <div>
                  <label class="title-login"
                    >How long does staff takes to do this process?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="editedItem.manualDuration"
                        :rules="[
                          (v) => !!v || 'Duration is required',
                          (v) =>
                            (v && !!v.toString().trim()) ||
                            'Duration cannot be blank',
                        ]"
                        label="Duration"
                        @keypress="onlyNumber($event)"
                        required
                        ><template #label> Duration</template></v-text-field
                      >
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="editedItem.manualDurationType"
                        :items="items"
                        :rules="[(v) => !!v || 'Type is required']"
                        label="Type"
                        required
                        ><template #label> Type</template></v-select
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <label class="title-login"
                    >How many times a day/week/month you do this process?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="editedItem.manualNoRuns"
                        :rules="[
                          (v) => !!v || 'Duration is required',
                          (v) =>
                            (v && !!v.toString().trim()) ||
                            'Duration cannot be blank',
                        ]"
                        label="
    Duration"
                        @keypress="onlyNumber($event)"
                        required
                        ><template #label> Duration</template></v-text-field
                      >
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="editedItem.manualRunCountType"
                        :items="itemstimes"
                        :rules="[(v) => !!v || 'Type is required']"
                        label="Type"
                        required
                        ><template #label>
                          <span class="red--text"
                            ><strong>&nbsp;* </strong></span
                          >
                          Type</template
                        ></v-select
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <label class="title-login"
                    >What is the average salary per hour of a staff doing this
                    job manually?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <v-text-field
                    label="Salary"
                    v-model="editedItem.manualAverageCost"
                    prefix="$"
                    :rules="[
                      (v) => !!v || 'Salary is required',
                      (v) =>
                        (v && !!v.toString().trim()) ||
                        'Salary cannot be blank',
                    ]"
                    required
                    type="number"
                    ><template #label> Salary</template></v-text-field
                  >
                  <!-- @keypress="onlyNumber($event)" -->
                </div>
                <div>
                  <label class="title-login"
                    >How many people are performing this process manually?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <v-text-field
                    v-model="editedItem.manualPeopleCount"
                    :rules="[
                      (v) => !!v || 'Count is required',
                      (v) =>
                        (v && !!v.toString().trim()) || 'Count cannot be blank',
                    ]"
                    label="
    Count "
                    @keypress="onlyNumber($event)"
                    required
                    ><template #label> Count </template></v-text-field
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card
            v-else-if="n == 2"
            class="overflow-y-auto mb-12"
            height="400px"
          >
            <v-card-text class="overflow-y-auto">
              <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                <div>
                  <label class="title-login"
                    >How long does digital assistant takes to run the process?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="editedItem.automationDuration"
                        :rules="[
                          (v) => !!v || 'Duration is required',
                          (v) =>
                            (v && !!v.toString().trim()) ||
                            'Duration cannot be blank',
                        ]"
                        label="Duration"
                        @keypress="onlyNumber($event)"
                        required
                        ><template #label> Duration</template></v-text-field
                      >
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="editedItem.automationDurationType"
                        :items="items"
                        :rules="[(v) => !!v || 'Type is required']"
                        label="Type"
                        required
                        ><template #label> Type</template></v-select
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <label class="title-login"
                    >How many times a day/week/month you do this process?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="editedItem.automationNoRuns"
                        :rules="[
                          (v) => !!v || 'Duration is required',
                          (v) =>
                            (v && !!v.toString().trim()) ||
                            'Duration cannot be blank',
                        ]"
                        label="
    Duration"
                        @keypress="onlyNumber($event)"
                        required
                        ><template #label> Duration</template></v-text-field
                      >
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="editedItem.automationRunCountType"
                        :items="itemstimes"
                        :rules="[(v) => !!v || 'Type is required']"
                        label="Type"
                        required
                        ><template #label>
                          <span class="red--text"
                            ><strong>&nbsp;* </strong></span
                          >
                          Type</template
                        ></v-select
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <label class="title-login"
                    >What is the average cost per hour of this job automate?
                    <span class="red--text"
                      ><strong>&nbsp;* </strong></span
                    ></label
                  >
                  <v-text-field
                    label="Cost"
                    v-model="editedItem.automationAvgCost"
                    prefix="$"
                    :rules="[
                      (v) => !!v || 'Cost is required',
                      (v) =>
                        (v && !!v.toString().trim()) || 'Cost cannot be blank',
                    ]"
                    required
                    type="number"
                    ><template #label> Cost</template></v-text-field
                  >
                  <!-- @keypress="onlyNumber($event)" -->
                </div>
              </v-form>
            </v-card-text>
          </v-card>

          <v-btn
            v-if="n + 1 < lastStep"
            color="primary"
            @click="validate(n)"
            :disabled="!step.valid"
            >Continue</v-btn
          >
          <v-btn v-else color="success" @click="onUpdate">Submit</v-btn>
          <!-- <v-btn color="success" @click="onUpdate">Submit</v-btn> -->
          <v-btn class="ml-2" v-if="n > 0" text @click="curr = n">Back</v-btn>
          <v-btn
            class="text-capitalize grey lighten-2 ml-2"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to deactivate this Workflow?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteClient" scrollable max-width="500px">
      <v-card>
        <v-card-title>Unmap Role</v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 400px">
          <v-list class="vlistcolor" dense>
            <v-list-item
              class="list-box"
              v-for="item in editedItem.roles"
              :key="item.roleId"
            >
              <v-list-item-content>
                <v-list-item-title class="cartitem"
                  >{{ item.roleName }} <br class="d-md-none" />
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="remove(item, editedItem.id)">
                  <v-icon size="20" color="red lighten-1"
                    >mdi-minus-circle-outline</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="text-capitalize grey lighten-2"
            @click="dialogDeleteClient = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->
    <v-dialog
      v-if="dialogClient"
      v-model="dialogClient"
      persistent
      max-width="800px"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <!-- <v-btn icon dark @click="dialogFeatures = false">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
          <v-toolbar-title>Add Role</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialogFeatures = false"> Close </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-card-text>
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-card-text>
              <v-select
                class="company-size-dropdown"
                :items="gridDataroles"
                item-text="roleName"
                item-value="id"
                v-model="editedItem.role"
                label="Role"
                multiple
                required
                :rules="[(v) => !!v || 'Role is required']"
              >
                <template #label>
                  Role <span class="red--text"><strong>* </strong></span>
                </template>
              </v-select>
              <!-- <v-switch
                v-model="editedClient.isApplicableToAllClients"
                :false-value="false"
                :true-value="true"
                inset
                label="Is Applicable To All Clients"
                class="offset-4 offset-lg-0"
              ></v-switch> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize grey lighten-2"
                depressed
                @click="closeClientDialog"
              >
                Close
              </v-btn>
              <v-btn
                dark
                color="black"
                class="text-capitalize"
                depressed
                @click="onAddClient"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this Role?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogDeleteConfirm = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteConfirmClient"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Process features -->
    <v-dialog
      v-if="dialogFeatures"
      v-model="dialogFeatures"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogFeatures = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Process Features</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialogFeatures = false"> Done </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <ProductFeatures :productId="editedIndex"></ProductFeatures>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script>
import processService from "@/services/process.service.js";
import catService from "@/services/category.service.js";
import roleService from "@/services/role.service.js";
import ProductFeatures from "../components/ProductFeatures.vue";
export default {
  components: {
    ProductFeatures,
  },
  computed: {
    gridData() {
      if (!this.categoryList.records) return [];
      return this.categoryList.records.map((t) => {
        return {
          name: t.status == false ? t.name + "(Deactivated)" : t.name,
          id: t.id,
          disabled: t.status == false ? true : false,
        };
      });
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    gridDataroles() {
      if (!(this.roleList && this.editedItem.roles)) return [];

      let res = [];
      res = this.roleList.filter((el) => {
        return !this.editedItem.roles.find((element) => {
          return element.roleId === el.id;
        });
      });
      return res;
    },
  },
  data: () => ({
    dialogDeleteConfirm: false,
    dialogDeleteClient: false,
    dialogClient: false,
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "imageURL",
      },
      { text: "Name", value: "name", sortable: false, width: "20%" },
      {
        text: "Category",
        value: "categoryName",
        sortable: false,
      },
      {
        text: "Platform",
        value: "platform",
        sortable: false,
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Complexity", value: "complexity", sortable: false },

      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    activationStatus: 1,
    filter: {
      searchKey: "",
      categoryId: "",
    },
    platform: [
      { name: "UiPath", value: "UiPath" },
      { name: "openbots", value: "openbots" },
    ],
    complexity: [
      { name: "High", value: "2" },
      { name: "Medium", value: "1" },
      { name: "Low", value: "0" },
    ],
    editedItem: {
      id: 0,
      name: "",
      description: "",
      imageURL: "",
      image: null,
      categoryId: null,
      platform: "",
      complexity: "",
      role: [],
      roles: [],
      manualDuration: "",
      manualDurationType: "",
      manualNoRuns: "",
      manualRunCountType: "",
      manualAverageCost: "",
      manualPeopleCount: "",
      automationDuration: "",
      automationDurationType: "",
      automationNoRuns: "",
      automationRunCountType: "",
      automationAvgCost: "",
    },

    items: ["Minutes", "Hours", "Days"],
    itemstimes: ["Day", "Week", "Month"],
    defaultItem: {
      id: 0,
      name: "",
      description: "",
      imageURL: "",
      image: null,
      categoryId: null,
      platform: "",
      complexity: "",
      role: [],
      roles: [],
      manualDuration: "",
      manualDurationType: "",
      manualNoRuns: "",
      manualRunCountType: "",
      manualAverageCost: "",
      manualPeopleCount: "",
      automationDuration: "",
      automationDurationType: "",
      automationNoRuns: "",
      automationRunCountType: "",
      automationAvgCost: "",
    },
    productsList: {},
    categoryList: {},
    roleList: [],
    loading: false,
    product: {},
    curr: 1,
    lastStep: 3,
    steps: [
      { name: "Workflow", rules: [(v) => !!v || "Required."], valid: true },
      { name: "Manual", rules: [(v) => !!v || "Required."], valid: true },
      { name: "Automation", rules: [(v) => !!v || "Required."], valid: true },
    ],
    // valid: false,
    stepForm: [],
  }),
  created() {
    this.getAllCategory({ skip: 0, take: 25000 });
    this.getAllRole();
  },

  methods: {
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    stepComplete(step) {
      return this.curr > step;
    },
    stepStatus(step) {
      return this.curr > step ? "green" : "blue";
    },
    validate(n) {
      this.steps[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steps[n].valid = true;
        // continue to next
        this.curr = n + 2;
      }
    },
    done() {
      this.curr = 4;
    },
    mapRole(item) {
      // this.editedIndexClient = this.holidayList.records.indexOf(item);
      // console.log("this.editedIndexClient", this.editedIndexClient);
      // this.editedIndexRole = item.id;
      // this.client = item;
      this.editedIndex = this.productsList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogClient = true;
    },
    unMapRole(item) {
      // this.editedIndexClient = this.productsList.records.indexOf(item);
      // this.editedIndexRole = item.id;
      // this.client = item;
      this.editedIndex = this.productsList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteClient = true;
    },
    closeClientDialog() {
      this.dialogClient = false;
      this.editedIndex = -1;
      this.$refs.form2.reset();
      this.$refs.form2.resetValidation();
    },
    onAddClient() {
      let valid = this.$refs.form2.validate();

      if (valid) {
        this.mapRoleids(
          {
            roleIdList: this.editedItem.role,
          },
          this.editedItem.id
        );
        // }
        this.closeClientDialog();
      }
    },
    async mapRoleids(item, id) {
      let result = await roleService.mapRole(item, id);
      if (result.status == 200) {
        this.status = "Role Mapping successfull";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    remove(item) {
      this.removeId = item.id;
      this.dialogDeleteConfirm = true;
    },
    deleteConfirmClient() {
      // var list = [];

      //     list.push({
      //       clientId: this.removeId,
      //       isDelete:true,
      //     });

      this.ConfirmClient(this.removeId);
      this.dialogDeleteConfirm = false;
    },
    async ConfirmClient(mappingId) {
      let result = await roleService.unmapRole(mappingId);
      if (result.status == 200) {
        this.status = "Role removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    truncate(source, size) {
      if (source == null) {
        return source;
      } else {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
    },
    editItem(item) {
      this.editedIndex = this.productsList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    closeDialog() {
      this.dialogDelete = false;
      this.dialog = false;
      this.editedIndex = -1;
      this.stepForm = [];
      this.curr = 1;
      let i = 0;
      for (i = 0; i < this.lastStep; i++) {
        this.$refs.stepForm[i].reset();
        this.$refs.stepForm[i].resetValidation();
      }

      this.editedItem = Object.assign({}, this.defaultItem);
    },
    deleteItem(item) {
      this.activationStatus = item.status;
      this.editedIndex = this.productsList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.productsList.records[this.editedIndex].id;

      this.delete({ processId: id, status: "DeActive" });

      this.dialogDelete = false;
    },
    resetStatus() {
      this.productsList.records[this.editedIndex].status =
        this.productsList.records[this.editedIndex].status == "Active"
          ? "DeActive"
          : "Active";
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: (pagination.page - 1) * 10,
        take: 10,
        status: "Active",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });

      this.loading = false;
      this.productsList = result;
      if (this.productsList.records.length > 0 && this.editedIndex != -1) {
        this.editedItem = this.productsList.records[this.editedIndex];
      }
    },
    features(item) {
      this.editedIndex = item.id;
      this.product = item;
      this.dialogFeatures = true;
    },
    onUpdate() {
      let valid = this.$refs.stepForm[2].validate();
      if (valid) {
        let formData = new FormData();
        formData.append("Name", this.editedItem.name);
        formData.append("CategoryId", this.editedItem.categoryId);
        formData.append("Description", this.editedItem.description);
        formData.append("ImageURL", this.editedItem.imageURL);
        formData.append("ImageFile", this.editedItem.image);
        formData.append("Complexity", this.editedItem.complexity);
        formData.append("Platform", this.editedItem.platform);
        formData.append("ManualDuration", this.editedItem.manualDuration);
        formData.append(
          "ManualDurationType",
          this.editedItem.manualDurationType
        );
        formData.append("ManualAverageCost", this.editedItem.manualAverageCost);
        formData.append("ManualPeopleCount", this.editedItem.manualPeopleCount);
        formData.append("ManualNoRuns", this.editedItem.manualNoRuns);
        formData.append(
          "ManualRunCountType",
          this.editedItem.manualRunCountType
        );
        formData.append(
          "AutomationDuration",
          this.editedItem.automationDuration
        );
        formData.append(
          "AutomationDurationType",
          this.editedItem.automationDurationType
        );
        formData.append("AutomationAvgCost", this.editedItem.automationAvgCost);
        formData.append("AutomationNoRuns", this.editedItem.automationNoRuns);
        formData.append(
          "AutomationRunCountType",
          this.editedItem.automationRunCountType
        );
        // console.log("this.editedItem.role",this.editedItem.role);
        // formData.append("RoleIdList[]", this.editedItem.role);
        //         this.editedItem.role.forEach((item) => {
        //   formData.append("RoleIdList", item);
        // });
        // for (var i = 0; i < this.editedItem.role.length; i++) {
        //   formData.append('RoleIdList[]', this.editedItem.role[i]);
        // }
        if (this.editedItem.role) {
          this.editedItem.role.forEach((value, index) => {
            formData.append(`RoleIdList[${index}]`, value);
          });
        }
        // else{
        //   formData.append("RoleIdList[]", '');
        // }

        if (this.editedIndex == -1) {
          this.create(formData);
        } else {
          const id = this.productsList.records[this.editedIndex].id;

          this.update(formData, id);
        }
        this.closeDialog();
      }
    },

    async create(item) {
      // console.log("log",item)
      let result = await processService.create(item);

      if (result.status == 200) {
        this.status = "Process created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await processService.update(item, id);
      if (result.status == 200) {
        this.status = "Process updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async delete(item) {
      let result = await processService.delete(item);
      if (result.status == 200) {
        if (item.status == true) {
          this.status = "Process activated successfully";
        } else {
          this.status = "Process deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    async search() {
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: 0,
        take: 10,
        status: "Active",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: 0,
        take: 10,
        status: "Active",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    async modifyOthers() {
      this.loading = true;
      let result = await processService.getAllProcess({
        skip: 0,
        take: 10,
        status: "Active",
        searchKey: this.filter.searchKey,
        categoryId: this.filter.categoryId,
      });
      this.loading = false;
      this.productsList = result;
    },
    async getAllCategory(input) {
      let result = await catService.getAll(input);

      this.categoryList = result;
    },
    async getAllRole() {
      let result = await roleService.getAllRole();

      this.roleList = result.records;
    },

    newStatus(id) {
      let newstatus = "";
      if (id == "0") {
        newstatus = "Low";
      } else if (id == "1") {
        newstatus = "Medium";
      } else if (id == "2") {
        newstatus = "High";
      }
      return newstatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.custom-bg {
  color: gray;
}
/deep/.style-1 {
  background: #e4e7ea !important;
  opacity: 0.5;
}
</style>

<template>
  <div class="container">
    <v-row>
      <v-col cols="12">
        <v-slide-x-transition>
          <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-6 mx-auto log-box">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body p-4 p-sm-5">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <label for="organizationLBL" v-if="userType == 'admin'"
                      >Client
                      <span class="red--text"
                        ><strong>&nbsp;* </strong></span
                      ></label
                    >

                    <v-select
                      v-if="userType == 'admin'"
                      class="company-size-dropdown"
                      :items="regOrganization"
                      item-text="name"
                      item-value="id"
                      v-model="organization"
                      label="Client"
                      v-on:change="orgChange($event)"
                      required
                      :rules="[(v) => !!v || 'Client is required']"
                    >
                    </v-select>
                    <v-text-field
                      v-if="email != '' && userType != 'admin'"
                      v-model="editedItem.emailId"
                      label="User Email Id"
                      required
                      :rules="[
                        (v) => !!v || 'User Email Id is required',
                        (v) =>
                          /.+@.+\..+/.test(v) || 'User Email Id must be valid',
                        (v) =>
                          (v && !!v.trim()) || 'User Email Id cannot be blank',
                        (v) =>
                          (v &&
                            v.substring(v.indexOf('@') + 1) ==
                              email.substring(email.indexOf('@') + 1)) ||
                          'Domain must match',
                        (v) =>
                          (v &&
                            !userEmailIds.find((t) => t.trim() == v.trim())) ||
                          'Invitation already sent',
                      ]"
                      validate-on-blur
                      @keypress.native="noSpace($event)"
                    >
                      <template #label>
                        User Email Id
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-else
                      v-model="editedItem.emailId"
                      label="User Email Id"
                      required
                      :rules="[
                        (v) => !!v || 'User Email Id is required',
                        (v) =>
                          /.+@.+\..+/.test(v) || 'User Email Id must be valid',
                        (v) =>
                          (v && !!v.trim()) || 'User Email Id cannot be blank',

                        (v) =>
                          (v &&
                            !userEmailIds.find((t) => t.trim() == v.trim())) ||
                          'Invitation already sent',
                      ]"
                      validate-on-blur
                      @keypress.native="noSpace($event)"
                    >
                      <template #label>
                        User Email Id
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                    </v-text-field>

                    <div class="text-right mt-2">
                      <v-btn
                        class="text-capitalize"
                        color="primary"
                        @click="uploadUser"
                        :loading="loading"
                        :disabled="loading"
                      >
                        Invite User
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </v-slide-x-transition>
      </v-col>
    </v-row>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import EventBus from "../plugins/eventBus";
import inviteService from "@/services/invitation.service.js";
import orgService from "@/services/organization.service.js";
import authService from "@/services/auth.service.js";

export default {
  created() {
    this.getAllEmailIds();
    if (this.userType == "admin") {
      this.getOrganization();
      this.email = "";
    } else {
      this.email = authService.authData.emailId;
    }

    EventBus.on("invite", (x) => {
      this.clear();
      this.loading = false;
      this.status = x;

      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
  },
  data: () => ({
    userType: authService.authData.userType,
    regOrganization: [],
    userEmailIds: [],
    tab: null,
    loading: false,
    tabItems: ["Invite User", "Invited Users"],
    valid: false,
    status: "",
    textFields: [],
    editedItem: {
      emailId: "",
    },
    organization: 0,
    email: "",
    emailRules: [
      (v) => !!v || "User Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "User Email Id must be valid",
    ],
  }),

  computed: {},
  methods: {
    uploadUser() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        let val = [];
        val.push(this.editedItem.emailId);
        let orgid = 0;
        if (this.organization > 0) {
          orgid = this.organization;
        } else {
          orgid = authService.authData.organizationId;
        }
        let par = this.editedItem.emailId + "*" + orgid;
        let encoded = btoa(par);

        this.inviteUser({
          emailIds: val,
          siteLink: window.location.origin + "/#/register/" + encoded,
        });
      }
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    async getAllEmailIds() {
      let result = await inviteService.getAllEmails();

      this.userEmailIds = result;
    },
    async inviteUser(item) {
      let result = await inviteService.create(item);

      if (result.status == 200) {
        this.status = "Invitation sent successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.clear();
        this.loading = false;
      }
    },
    orgChange(item) {
      if (item > 0) {
        var filterObj = this.regOrganization.filter(function (e) {
          return e.id == item;
        })[0];

        if (filterObj.adminEmailId) {
          this.email = filterObj.adminEmailId.emailId;
        } else {
          this.email = "";
        }
      } else {
        this.email = "";
      }
    },

    add() {
      this.textFields.push({
        label: "User Email Id",
        value: "",
      });
    },

    remove(index) {
      this.textFields.splice(index, 1);
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    clear() {
      this.editedIndex = -1;
      this.textFields.splice(0);
      this.organization = 0;
      this.editedItem.emailId = "";
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.log-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
}
</style>
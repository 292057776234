<template>
  <div class="container">
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4 col-12">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="allOrganization"
                  item-text="name"
                  item-value="name"
                  v-model="orgName"
                  label="Select Organization"
                  @change="onChange"
                  :clearable="true"
                ></v-select>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="daterange"
                      label="Date Range"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="clearDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize grey lighten-2"
                      @click="menu = false"
                      >Cancel
                    </v-btn>
                    <v-btn
                      dark
                      color="primary"
                      class="text-capitalize"
                      @click="$refs.menu.save(dates), dateRangeText()"
                      >OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="logLoginActivity.records"
        :server-items-length="logLoginActivity.total"
        @update:options="updatePagination"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.logInDateTime`]="{ item }">
          <!-- <td>{{ new Date(item.logInDateTime).toLocaleString() }}</td> -->
          {{
            new Date(
              new Date(item.logInDateTime).getTime() -
                new Date(item.logInDateTime).getTimezoneOffset() * 60000
            ).toLocaleString()
          }}
        </template>
        <template v-slot:[`item.logOutDateTime`]="{ item }">
          <td v-if="item.logOutDateTime != null">
            <!-- {{ new Date(item.logOutDateTime).toDateString() }} -->
            {{
              new Date(
                new Date(item.logOutDateTime).getTime() -
                  new Date(item.logOutDateTime).getTimezoneOffset() * 60000
              ).toLocaleString()
            }}
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import emailService from "@/services/email.service.js";
import orgService from "@/services/organization.service.js";
export default {
  created() {
    this.getOrganization();
  },
  computed: {
    // getOList() {
    //   var arr = Object.assign([], this.allOrganization);
    //   arr.unshift({ id: "", name: "All" });
    //   return arr;
    // },
  },
  data: () => ({
    orgName: "",
    allOrganization: [],
    logLoginActivity: {},
    nowDate: new Date().toISOString(),
    menu: false,
    daterange: "",
    dates: [],
    fromdate: "",
    todate: "",
    pageIndex: 0,
    filter: {
      searchKey: "",
    },
    loading: false,
    headers: [
      {
        text: "User Name",
        align: "start",
        sortable: false,
        value: "firstName",
        width: "25%",
      },

      {
        text: "Organization",
        sortable: false,
        value: "orgName",
      },
      {
        text: "Email Id",
        sortable: false,
        value: "emailId",
      },
      {
        text: "Login Time",
        sortable: false,
        value: "logInDateTime",
      },
      { text: "Logout Time", sortable: false, value: "logOutDateTime" },
    ],
  }),
  methods: {
    async getOrganization() {
      let result = await orgService.getAllOrg();
      this.allOrganization = result;
    },
    onChange() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        StartDate: this.fromdate,
        EndDate: this.todate,
        OrgName: this.orgName,
      };
      this.getLogins(data);
    },
    clearDate() {
      this.dates = [];
      this.fromdate = "";
      this.todate = "";
      this.getLogins({
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        StartDate: this.fromdate,
        EndDate: this.todate,
        OrgName: this.orgName,
      });
    },
    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }

        this.getLogins({
          Skip: 0,
          Take: 10,
          SearchKey: this.filter.searchKey,
          StartDate: this.fromdate,
          EndDate: this.todate,
          OrgName: this.orgName,
        });
      } else {
        this.fromdate = "";
        this.todate = "";
      }
    },
    emaillist(email) {
      var nameArr = email.split(",");
      return nameArr;
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await emailService.getAllLogin({
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        StartDate: this.fromdate,
        EndDate: this.todate,
        OrgName: this.orgName,
      });
      this.loading = false;
      this.logLoginActivity = result;
    },
    async search() {
      this.loading = true;
      let result = await emailService.getAllLogin({
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        StartDate: this.fromdate,
        EndDate: this.todate,
        OrgName: this.orgName,
      });
      this.loading = false;
      this.logLoginActivity = result;
    },
    async getLogins(input) {
      this.loading = true;
      let result = await emailService.getAllLogin(input);
      this.loading = false;
      this.logLoginActivity = result;
    },
    async clear() {
      this.loading = true;
      this.filter.searchKey = "";
      let result = await emailService.getAllLogin({
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        StartDate: this.fromdate,
        EndDate: this.todate,
        OrgName: this.orgName,
      });
      this.loading = false;
      this.logLoginActivity = result;
    },
  },
};
</script>

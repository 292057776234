<template>
  <div class="container">
    <v-card>
      <v-card-title class="tabtitle">
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          dense
          @click:append="search"
          @click:clear="clear"
          class="col-md-4 col-12"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items-per-page="10"
        :items="userQueries.records"
        :server-items-length="userQueries.total"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
      >
        <template v-slot:[`item.submittedOn`]="{ item }">
          <td>{{ new Date(item.submittedOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.replyedOn`]="{ item }">
          <td v-if="item.replyedOn">
            {{ new Date(item.replyedOn).toDateString() }}
          </td>
        </template>
        <template v-slot:[`item.firstName`]="{ item }">
          <td>{{ item.firstName }} {{ item.lastName }}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="reply(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-reply-circle
              </v-icon>
            </template>
            <span>Reply</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogReply" max-width="500px">
      <v-card height="auto" class="text-center">
        <v-card-title class="justify-left">
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass text-left pl-0">
              Reply to
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-textarea
              v-model="editedItem.reply"
              required
              :rules="[
                (v) => !!v || 'Reply is required',
                (v) => (v && !!v.trim()) || 'Reply cannot be blank',
              ]"
            >
              <template #label>
                Reply <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="border"
            class="text-capitalize"
            depressed
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            depressed
            @click="onUpdate"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="statuss" type="success">
      {{ statuss }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script>
import userService from "@/services/user.service.js";
import authService from "@/services/auth.service.js";

export default {
  created() {},
  computed: {
    headers() {
      return [
        {
          text: "Email Id",
          align: "start",
          value: "emailId",
          sortable: false,
        },
        {
          text: "Name",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Message",
          value: "message",
          sortable: false,
        },
        {
          text: "Submitted on",
          value: "submittedOn",
          sortable: false,
        },
        {
          text: "Reply Sent",
          value: "reply",
          sortable: false,
        },
        {
          text: "Replyed on",
          value: "replyedOn",
          sortable: false,
        },
        {
          text: "Reply",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  data: () => ({
    loading: false,
    userType: authService.authData.userType,
    logo: authService.authData ? authService.authData.logoUrl : undefined,
    userQueries: {},
    filter: {
      searchKey: "",
    },
    statusE: "",
    statuss: "",
    valid: false,
    editedIndex: -1,

    editedItem: {
      id: 0,
      reply: "",
    },
    defaultItem: {
      id: 0,
      reply: "",
    },
    dialogDelete: false,
    dialogReply: false,
    pageIndex: 0,
  }),
  methods: {
    async updatePagination(pagination) {
      this.pageIndex = pagination.page || 0;
      this.loading = true;
      let result = await userService.getAllQuery({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      // console.log("userQueries", result);
      this.userQueries = result;
    },
    async search() {
      this.loading = true;
      let result = await userService.getAllQuery({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userQueries = result;
    },
    async clear() {
      this.filter.searchKey = "";
      this.loading = true;
      let result = await userService.getAllQuery({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userQueries = result;
    },

    reply(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.userQueries.records.indexOf(item);
      this.dialogReply = true;
    },

    async onChange() {
      this.loading = true;
      let result = await userService.getAllQuery({
        skip: 0,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.userQueries = result;
    },
    onUpdate() {
      const id = this.userQueries.records[this.editedIndex].id;
      this.editedItem.id = id;

      this.replyquery(
        {
          reply: this.editedItem.reply,
        },
        id
      );

      this.closeDialog();
    },
    async replyquery(item, id) {
      let result = await userService.replyQuery(item, id);

      if (result.status == 200) {
        this.statuss = "Reply sent successfully";
        setTimeout(() => {
          this.statuss = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    closeDialog() {
      this.dialogReply = false;
      this.editedIndex = -1;

      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/colors.scss";
/deep/.v-toolbar__content {
  padding: 0px;
}
.box-list {
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}
.active {
  border-color: #1a8ccf;
  border-width: 5px;
}
// /deep/.v-card__title {
//   justify-content: center;
// }
.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}
</style>
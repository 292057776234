<template>
  <div>
    <v-card>
      <!-- <v-card-title>
        <v-text-field
          v-model="filter.searchKey"
          label="Search"
          single-line
          hide-details
          v-on:keyup.enter="search"
          append-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          @click:append="search"
          @click:clear="clear"
        ></v-text-field>
      </v-card-title> -->
      <v-card-title class="tabtitle">
        <!-- Workflow History -->
        <v-spacer></v-spacer>

        <v-text-field v-model="filter.searchKey" label="Search" single-line hide-details v-on:keyup.enter="search"
          append-icon="mdi-magnify" clear-icon="mdi-close-circle" clearable outlined dense @click:append="search"
          @click:clear="clear" class="col-md-4 col-12"></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="tabtitle2 text-capitalize mx-2" @click="refreshLiveState()" v-bind="attrs" v-on="on">
              Refresh <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table :headers="headers" :items="mergeddata" :items-per-page="10" :server-items-length="allLeads.total"
        :loading="loading" @update:options="updatePagination" :footer-props="{
          'items-per-page-options': [10],
        }" item-key="id">
        <template v-slot:[`item.imageURL`]="{ item }">
          <img :src="item.imageURL" width="32px" />
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>
            {{ new Date(item.createdOn).toDateString() }}
          </td>
        </template>
        <template #[`item.description`]="{ item }">
          <td>{{ truncateText(item.description) }}</td>
        </template>
        <template #[`item.live_status`]="{ item }">
          <v-chip v-if="item.live_status" label small :color="getColorStatus(item)" :text-color="getTextColor(item)"
            dark>
            {{ item.live_status }}
          </v-chip>
        </template>
        <template #[`item.elapse`]="{ item }">
          <td v-if="item.live_status == 'Running'" :class="getColor(item)">
            {{ item.elapse }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom v-if="item.releaseProcessName">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="processDetails(item)" v-bind="attrs" v-on="on">
                mdi-robot
              </v-icon>
            </template>
            <span>Process details</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.releaseProcessName">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="processQueueDetails(item)" v-bind="attrs" v-on="on">
                mdi-tray-full
              </v-icon>
            </template>
            <span>Queue Data</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="processIssueOrEnhance(item)" v-bind="attrs" v-on="on">
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span>Report Issue/Enhancement or Feedback</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="historyItem(item)" v-bind="attrs" v-on="on">
                mdi-page-previous
              </v-icon>
            </template>
            <span>Process History</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="50" small class="mr-3" @click="taskHistoryItem(item)" v-bind="attrs" v-on="on">
                mdi-text-box-multiple
              </v-icon>
            </template>
            <span>Task History</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-bottom-sheet v-model="dialogBotData" scrollable>
      <!-- <v-sheet
        class="d-flex flex-column align-end"
        max-height="90%"
        min--height="90%"
      > -->
      <v-card outlined>
        <!-- <div > -->
        <v-toolbar flat class="container pl-0">
          <v-toolbar-title class="toolbarclass">
            Process Details
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeLogs()" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="container pl-0"></v-divider>
        <v-card-subtitle class="text-left textsubtitle d-flex container pl-0" v-if="processData.data">
          <p v-if="runningProcess" class="mr-2">
            {{ runningProcess.releaseName }} - {{ process.name }}
          </p>
          <p v-else class="mr-2">{{ releaseName }} - {{ process.name }}</p>

          <v-chip v-if="runningProcess" small label color="#FDEDE8" text-color="#E64A19">
            {{ runningProcess.state }}
          </v-chip>

          <v-chip v-else small label color="#E6F2F1" text-color="#00796B">
            Stopped
          </v-chip>
        </v-card-subtitle>

        <v-card-title class="tabtitle1 container pa-0">
          Process Log
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="tabtitle2 text-capitalize mr-2" @click="refresh()" v-bind="attrs" v-on="on">
                Refresh <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-card-title>
        <!-- <v-card-text class="pa-0"> -->
        <v-card-text v-if="roboLogsData && processData.data" height="auto" class="container pa-1">
          <v-data-table dense :headers="headersLogs" :items="roboLogsData" item-key="name" :loading="loadingbotlog"
            :page.sync="page" :items-per-page="rows" hide-default-footer class="elevation-1"
            @page-count="pagelength = $event">
            <template v-slot:[`item.timeStamp`]="{ item }">
              <td>{{ new Date(item.timeStamp).toLocaleString() }}</td>
            </template>
          </v-data-table>
          <!-- </v-card-text> -->
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse justify-center">
          <!-- <v-spacer></v-spacer> -->

          <div class="" v-if="roboLogsData.length > 0">
            <v-pagination v-model="page" :length="pagelength" :total-visible="7"></v-pagination>
          </div>
          <div class="" v-if="roboLogsData.length > 0">
            <v-select v-model="rows" :items="rowsPerTable" item-text="name" item-value="value" menu-props="auto"
              hide-details label="Select" single-line></v-select>
          </div>
        </v-card-actions>
      </v-card>
      <!-- </div> -->
      <!-- </v-sheet> -->
    </v-bottom-sheet>
    <!-- <v-dialog
      v-model="dialogBotData"
      width="1400"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card height="auto">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Process Data</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLogs()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          v-if="loadingprocess"
        ></v-progress-linear>
        <v-card-text>
          <div class="container" v-if="processData.data">
            <v-card class="my-4">
              <v-simple-table class="ttl">
                <template v-slot:default>
                  <thead>
                    <tr class="mb-1">
                      <th class="text-left">Process Name</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="mt-1">
                      <td v-if="runningProcess">
                        {{ runningProcess.releaseName }} - {{ process.name }}
                      </td>
                      <td v-else>{{ releaseName }} - {{ process.name }}</td>
                      <td>
                        <v-chip v-if="runningProcess" small color="green" dark>
                          {{ runningProcess.state }}
                        </v-chip>
                        <v-chip v-else small color="orange" dark>
                          Stopped
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <v-card v-if="processData.data">
              <v-data-table
                dense
                :headers="headersLogs"
                :items="roboLogsData"
                item-key="name"
                :loading="loadingbotlog"
                :page.sync="page"
                :items-per-page="rows"
                hide-default-footer
                class="elevation-1 ttl"
                @page-count="pagelength = $event"
              >
                <template v-slot:top>
                  <v-toolbar flat color="primary ">
                    <v-toolbar-title class="white--text"
                      >Process Log</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="white"
                          @click="refresh()"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.timeStamp`]="{ item }">
                  <td>{{ new Date(item.timeStamp).toLocaleString() }}</td>
                </template></v-data-table
              >
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse justify-center">
         

          <div class="" v-if="roboLogsData.length > 0">
            <v-pagination
              v-model="page"
              :length="pagelength"
              :total-visible="7"
            ></v-pagination>
          </div>
          <div class="" v-if="roboLogsData.length > 0">
            <v-select
              v-model="rows"
              :items="rowsPerTable"
              item-text="name"
              item-value="value"
              menu-props="auto"
              hide-details
              label="Select"
              single-line
            ></v-select>
          </div>
       
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialogBotQueue" scrollable max-width="500px">
      <v-card>
        <!-- <v-card-title>Process Queue</v-card-title>
        <v-divider></v-divider> -->
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass">
              Process Queue
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="dialogBotQueue = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear indeterminate v-if="loadingprocess"></v-progress-linear>
        <v-card-text style="height: 400px">
          <v-data-iterator v-if="processQueue.data" :items="processQueue.data" hide-default-footer>
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.name" cols="12">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Application Exceptions No:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.applicationExceptionsNo }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Business Exceptions No:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.businessExceptionsNo }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Items In Progress:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.itemsInProgress }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Items To Process:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.itemsToProcess }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Processing Mean Time:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.processingMeanTime }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Successful Transactions No:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.successfulTransactionsNo }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Successful Transactions Processing
                        Time:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.successfulTransactionsProcessingTime }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>Total Number of Transactions:</v-list-item-content>
                      <v-list-item-action class="align-end">
                        {{ item.totalNumberOfTransactions }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text-capitalize grey lighten-2" @click="dialogBotQueue = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <!-- <v-card-title> Report Issue or Request Enhancement </v-card-title> -->
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title class="toolbarclass">
              Report Issue/Request Enhancement or send a Feedback
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="closeDialog" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="editedItem.name" required :rules="[
              (v) => !!v || 'Summary is required',
              (v) => (v && !!v.trim()) || 'Summary cannot be blank',
            ]">
              <template #label>
                Summary <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>

            <v-select :items="type" item-text="name" item-value="value" v-model="editedItem.type" required
              :rules="[(v) => !!v || 'Type is required']"><template #label>
                Type <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-textarea v-model="editedItem.description" required :rules="[
              (v) => !!v || 'Description is required',
              (v) => (v && !!v.trim()) || 'Description cannot be blank',
            ]">
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize grey lighten-2" depressed @click="closeDialog">
            Close
          </v-btn>
          <v-btn dark color="buttonColor" class="text-capitalize" depressed @click="onUpdate">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="dialogHistory"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title> Process History</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogHistory">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-3 col-sm-3 col-md-3">
                  <v-select
                    :items="getPList"
                    item-text="name"
                    item-value="id"
                    v-model="selectedProcesses"
                    label="Select Process"
                    :clearable="false"
                    @change="onChange"
                    return-object
                  ></v-select>
                </div>
                <v-col cols="12" sm="3" md="3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="daterange"
                        label="Date Range"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      no-title
                      scrollable
                      range
                      :max="nowDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        class="text-capitalize grey lighten-2"
                        @click="menu = false"
                        >Cancel
                      </v-btn>
                      <v-btn
                        dark
                        @click="$refs.menu.save(dates), dateRangeText()"
                        >OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <div class="col-lg-3 col-sm-3 col-md-3">
                  <v-btn label color="primary" class="label" @click="getData()"
                    >Load Process History</v-btn
                  >
                </div>
                <div class="col-lg-3 col-sm-3 col-md-3 text-right">
                  <download-excel
                    :data="leadHistory"
                    worksheet="My Worksheet"
                    :name="organizationName + '_' + getTime()"
                    :fields="export_fields"
                    :footer="footer"
                  >
                    <v-btn outlined color="teal" @click="download()"
                      >Download Excel

                      <v-icon> mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </download-excel>
                </div>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-card-text
          class="text-justify1"
          color="white"
          style="min-height: 500px"
        >
          <v-data-table
            :headers="headersProcess"
            :items="leadHistory"
            item-key="name"
            class="elevation-1"
            :loading="loadingHistory"
            :sort-by="['processStartDate']"
            :sort-desc="[true]"
            :footer-props="{
              'items-per-page-options': [10],
            }"
          >
            <template #[`item.processStartDate`]="{ item }">
              <td>{{ new Date(item.processStartDate).toDateString() }}</td>
            </template>
            <template #[`item.processEndDate`]="{ item }">
              <td>{{ new Date(item.processEndDate).toDateString() }}</td>
            </template>
            <template #[`item.difference`]="{ item }">
              <td>
                {{ diff(item.processStartDate, item.processEndDate, item) }}
              </td>
            </template>
            <template v-slot:[`item.exception`]="{ item }">
              <p class="text-justify" v-if="item.exception">
                {{ truncateText(item.exception) }}
              </p>
            </template>
            <template v-slot:[`item.processName`]="{ item }">
              <td class="processname">
                {{ item.processName }}
              </td>
            </template>
            <template slot="body.append">
              <tr class="green--text">
                <th>Total time spent</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{{ totaltime() }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-bottom-sheet v-model="dialogHistory" scrollable>
      <v-card outlined>
        <v-toolbar flat class="px-16 pa-0">
          <v-toolbar-title class="toolbarclass">
            Process History
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeDialogHistory" color="#17181D">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="px-16"></v-divider>
        <div class="d-flex justify-space-between mt-2 px-16 pa-0">
          <v-card-subtitle class="pl-0">Filter Workflows with required date range
          </v-card-subtitle>

          <download-excel :data="leadHistory" worksheet="My Worksheet" :name="organizationName + '_' + getTime()"
            :fields="export_fields" :footer="footer">
            <v-btn outlined @click="download()" class="text-capitalize">Download Excel

              <v-icon> mdi-download</v-icon>
            </v-btn>
          </download-excel>
        </div>
        <div class="d-flex my-4 px-16 pa-0">
          <div class="col-lg-3 col-md-3 pl-0">
            <v-select dense outlined :items="getPList" item-text="name" item-value="id" v-model="selectedProcesses"
              label="Select Process" :clearable="false" @change="onChange" return-object></v-select>
          </div>
          <div class="col-lg-3 col-md-3">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined v-model="daterange" label="Date Range" append-icon="mdi-calendar" readonly
                  @click:append="on.click" v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dates" no-title scrollable range :max="nowDate">
                <v-spacer></v-spacer>
                <v-btn class="text-capitalize grey lighten-2" @click="menu = false">Cancel
                </v-btn>
                <v-btn dark @click="$refs.menu.save(dates), dateRangeText()">OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </div>
          <v-spacer></v-spacer>
          <v-switch v-model="processurl" :label="processurltext" @change="onchangeurl($event)"></v-switch>
          <div class="col-lg-3 col-md-3 text-right pr-0">
            <v-btn class="text-capitalize ml-auto load" @click="getData()" dark color="buttonColor">
              <v-icon left> mdi-arrow-down-bold-box-outline </v-icon>Load
              Process History</v-btn>
          </div>
        </div>
        <v-card-text class="px-16 pa-1 mb-5">
          <v-data-table dense :headers="headersProcess" :items="leadHistory" item-key="name" class="elevation-0"
            :loading="loadingHistory" :sort-by="['processStartDate']" :sort-desc="[true]" :footer-props="{
              'items-per-page-options': [10],
            }">
            <template #[`item.processStartDate`]="{ item }">
              <td>{{ new Date(item.processStartDate).toLocaleString() }}</td>
            </template>
            <template #[`item.processEndDate`]="{ item }">
              <td>{{ new Date(item.processEndDate).toLocaleString() }}</td>
            </template>
            <template #[`item.difference`]="{ item }">
              <td>
                {{ diff(item.processStartDate, item.processEndDate, item) }}
              </td>
            </template>
            <template v-slot:[`item.exception`]="{ item }">
              <p class="text-justify" v-if="item.exception">
                {{ truncateText(item.exception) }}
              </p>
            </template>
            <template v-slot:[`item.processName`]="{ item }">
              <td class="processname">
                {{ item.processName }}
              </td>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip v-if="item.status" label small :color="getTaskHistoryColor(item.status)"
                :text-color="getTaskHistoryTextColor(item.status)" dark>
                {{ item.status }}
              </v-chip>
            </template>
            <template slot="body.append">
              <tr class="total">
                <th class="total">Total time spent</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="total">{{ totaltime() }}</th>
                <th></th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- </div> -->
      </v-card>
    </v-bottom-sheet>
    <!-- <v-bottom-sheet v-model="dialogTaskHistory" scrollable>
      <v-card outlined>
        <v-toolbar flat class="px-16 pa-0">
          <v-toolbar-title class="toolbarclass"> Task History </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeDialogTaskHistory()" color="#17181D">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="px-16"></v-divider>
        <div class="d-flex justify-space-between mt-2 px-16 pa-0">
          <v-card-subtitle class="pl-0"
            >Filter Workflows with required date range
          </v-card-subtitle>
          <download-excel
            :data="taskHistoryItems"
            worksheet="My Worksheet"
            :name="organizationName + '_' + getTime()"
            :fields="taskHistoryExport_fields"
          >
            <v-btn outlined @click="downloadTask()" class="text-capitalize"
              >Download Excel

              <v-icon> mdi-download</v-icon>
            </v-btn>
          </download-excel>
        </div>
        <div class="d-flex justify-space-between my-4 px-16 pa-0">
          <div class="col-lg-4 col-md-4 pl-0">
            <v-select
              dense
              outlined
              :items="getPList"
              item-text="name"
              item-value="id"
              v-model="selectedProcesses"
              label="Select Process"
              :clearable="false"
              @change="onProcessChange"
              return-object
            ></v-select>
          </div>
          <div class="col-lg-4 col-md-4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="daterange"
                  label="Date Range"
                  append-icon="mdi-calendar"
                  readonly
                  @click:append="on.click"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" no-title scrollable range>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize grey lighten-2"
                  @click="menu1 = false"
                  >Cancel
                </v-btn>
                <v-btn dark @click="$refs.menu1.save(dates), dateRangeText()"
                  >OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </div>
          <v-spacer></v-spacer>
          <div class="col-lg-4 col-md-3 text-right pr-0">
            <v-btn
              class="text-capitalize ml-auto load"
              @click="getTaskHistoryData()"
              dark
              color="buttonColor"
            >
              <v-icon left> mdi-arrow-down-bold-box-outline </v-icon>Load Task
              History</v-btn
            >
          </div>
        </div>

        <v-card-text class="px-16 pa-1 mb-5">
          <v-data-table
            dense
            :headers="headersTaskHistory"
            :items="taskHistoryItems"
            :items-per-page="20"
            class="elevation-1"
            :loading="loadingTaskHistory"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, -1],
            }"
          >
            <template #[`item.createdOn`]="{ item }">
              <td>{{ new Date(item.createdOn).toDateString() }}</td>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                v-if="item.status"
                label
                small
                :color="getTaskHistoryColor(item.status)"
                :text-color="getTaskHistoryTextColor(item.status)"
                dark
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-bottom-sheet> -->
    <v-bottom-sheet v-model="dialogTaskHistory" scrollable>
      <v-card outlined>
        <v-toolbar flat class="px-16 pa-0">
          <v-toolbar-title class="toolbarclass"> Task History </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeDialogTaskHistory()" color="#17181D">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="px-16"></v-divider>
        <div class="d-flex justify-space-between mt-2 px-16 pa-0">
          <v-card-subtitle class="pl-0">Filter processes with required date range
          </v-card-subtitle>
          <download-excel :data="taskHistoryItems" worksheet="My Worksheet" :name="organizationName + '_' + getTime()"
            :fields="taskHistoryExport_fields">
            <v-btn outlined @click="downloadTask()" class="text-capitalize">Download Excel

              <v-icon> mdi-download</v-icon>
            </v-btn>
          </download-excel>
        </div>
        <div class="d-flex my-4 px-16 pa-0">
          <div class="col-lg-3 col-md-3 pl-0">
            <v-select dense outlined :items="allProcesses" item-text="name" item-value="id" v-model="selectedProcesses"
              label="Select Process" :clearable="false" @change="onProcessChange" return-object></v-select>
          </div>
          <div class="col-lg-3 col-md-3">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined v-model="daterange" label="Date Range" append-icon="mdi-calendar" readonly
                  @click:append="on.click" v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dates" no-title scrollable range :max="nowDate">
                <v-spacer></v-spacer>
                <v-btn class="text-capitalize grey lighten-2" @click="menu1 = false">Cancel
                </v-btn>
                <v-btn dark @click="$refs.menu1.save(dates), dateRangeText()" class="text-capitalize">OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </div>
          <div class="col-lg-3 col-md-3 text-right pr-0">
            <v-text-field v-model="uniqueId" label="Input" single-line hide-details v-on:keyup.enter="searchUniqueId"
              append-icon="mdi-magnify" clear-icon="mdi-close-circle" clearable outlined dense
              @click:append="searchUniqueId" @click:clear="clearUniqueId"></v-text-field>
          </div>
          <div class="col-lg-3 col-md-3 text-right pr-0">
            <v-btn class="text-capitalize ml-auto load" @click="getTaskHistoryData()" dark color="buttonColor">
              <v-icon left> mdi-text-search-variant </v-icon>Search</v-btn>
          </div>
        </div>

        <v-card-text class="px-16 pa-1 mb-5">
          <v-data-table dense :headers="headersTaskHistory" :items="taskHistoryItems" :items-per-page="20"
            class="elevation-1" :loading="loadingTaskHistory" :footer-props="{
              'items-per-page-options': [10, 20, 50, -1],
            }" :sort-by="['createdOn']" :sort-desc="[false]">
            <template #[`item.createdOn`]="{ item }">
              <td>{{ new Date(item.createdOn).toLocaleString() }}</td>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip v-if="item.status" label small :color="getTaskHistoryColor(item.status)"
                :text-color="getTaskHistoryTextColor(item.status)" dark>
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-alert v-if="status" type="warning">
      {{ status }}
    </v-alert>
    <v-alert v-if="statuss" type="success">
      {{ statuss }}
    </v-alert>
  </div>
</template>
<script >
import clientBotService from "@/services/clientBot.service.js";
import leadService from "@/services/lead.service.js";
import uiPathService from "@/services/uiPath.service.js";
import ticketService from "@/services/ticket.service.js";
import downloadExcel from "vue-json-excel";
import authService from "@/services/auth.service.js";
import data from "../../public/config/preferences.json";
export default {
  components: {
    downloadExcel,
  },
  mounted() {
    let data = {
      skip: 0,
      take: 1,
    };
    this.getClientBot(data);
  },
  created() {
    this.getProcessList();
  },

  data: () => ({
    refreshTime: data.refreshTime,
    processurl: false,
    processurltext: "Old URL",
    uniqueId: "",
    releasenamepop: "",
    editedIndex: 0,
    editedItemProcess: {},
    mergeddata: [],
    getting: "",
    releaseName: "",
    runningProcess: {},
    botClientList: {},
    processData: {},
    processQueue: {},
    loading: false,
    allLeads: {},
    pageIndex: 0,
    selectedBot: {},
    selectedProcesses: "",
    allProcesses: [],
    loadingbotlog: false,
    dialogBotData: false,
    dialogHistory: false,
    process: {},
    loadingprocess: false,
    dialogBotLog: false,
    dialogBotQueue: false,
    roboLogsData: [],
    overlay: false,
    absolute: true,
    killstatus: "",
    status: "",
    statuss: "",
    isReadMore: false,
    organizationName: authService.authData
      ? authService.authData.organization
      : undefined,
    daterange:
      new Date().toISOString().slice(0, 10) +
      " - " +
      new Date().toISOString().slice(0, 10),
    nowDate: new Date().toISOString().slice(0, 10),
    dates: [
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10),
    ],
    fromdate: new Date().toISOString().slice(0, 10),
    todate: new Date().toISOString().slice(0, 10),
    dialogTaskHistory: false,
    taskHistoryDates: [
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10),
    ],
    menu: false,
    menu1: false,
    leadHistory: [],
    loadingTaskHistory: false,
    taskHistoryItems: [],

    filter: {
      searchKey: "",
      sortBy: "",
    },
    loadingHistory: false,
    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "imageURL",
      },
      { text: "Name", value: "name", width: "200px", sortable: false },
      {
        text: "Ordered on",
        value: "createdOn",
        width: "150px",
        sortable: false,
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Status", value: "live_status", sortable: false },
      { text: "Time Elapsed", value: "elapse", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "200px" },
    ],
    headersLogs: [
      { text: "Time", sortable: false, value: "timeStamp" },
      { text: "Level", value: "level", sortable: false },
      { text: "Message", value: "message", sortable: false, width: "40%" },
    ],
    headersProcess: [
      {
        text: "Process Name",
        value: "processName",
        width: "20%",
      },
      { text: "Transaction Number", value: "transactionNumber" },
      { text: "Requestor", value: "requestor" },
      { text: "Process Status", value: "status" },
      { text: "Exception Type", value: "exceptionType" },
      { text: "Message", value: "exception" },
      { text: "Additional Details", value: "additionalData" },
      { text: "Process Start Date", value: "processStartDate" },
      { text: "Process End Date", value: "processEndDate" },
      { text: "Total Time Taken", value: "difference" },
      { text: "Agent Name", value: "botName" },
    ],
    headersTaskHistory: [
      { text: "Task Id", sortable: false, value: "taskID" },
      {
        text: "Description",
        sortable: false,
        value: "taskDescription",
        width: "50%",
      },
      { text: "Status", sortable: false, value: "status" },
      // {
      //   text: "AdddiionalDetails",
      //   sortable: false,
      //   value: "adddiionalDetails",
      // },
      {
        text: "Process Unique ID",
        sortable: false,
        value: "processUniqueID",
      },
      {
        text: "Input",
        sortable: false,
        value: "uniqueID",
      },
      { text: "Created On", sortable: true, value: "createdOn" },
    ],
    taskHistoryExport_fields: {
      "Task Id": "taskID",
      Description: "taskDescription",
      Status: "status",
      "Process Unique ID": "processUniqueID",
      "Transaction ID": "transactionID",
      Input: "uniqueID",
      "Created On": {
        field: "createdOn",
        callback: (value) => {
          return `${new Date(value).toDateString()}`;
        },
      },
    },
    json_fields: {},
    export_fields: {
      "Process Name": "processName",
      "Transaction Number": "transactionNumber",
      Requestor: "requestor",
      Status: "status",
      "Exception Type": "exceptionType",
      Exception: "exception",
      "Process Start Date": {
        field: "processStartDate",
        callback: (value) => {
          return `${new Date(value).toDateString()}`;
        },
      },
      "Process End Date": {
        field: "processEndDate",
        callback: (value) => {
          return `${new Date(value).toDateString()}`;
        },
      },
      "Agent Name": "botName",
      "Agent User Name": "botUserName",
      "Process Unique ID": "processUniqueID",
      "Total Time Taken": "difference",
      "Additional Data": "additionalData",
    },

    valid: false,
    dialog: false,
    editedItem: {
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    editedProcess: {
      id: "",
      name: "",
      serverProcessId: "",
      releaseProcessName: "",
    },
    defaultItem: {
      processId: 0,
      name: "",
      description: "",
      type: "",
    },
    type: [
      { name: "Issue", value: "Issue" },
      { name: "Enhancement", value: "Enhancement" },
      { name: "Feedback", value: "Feedback" },
    ],
    rowsPerTable: [
      { name: "10", value: 10 },
      { name: "25", value: 25 },
      { name: "50", value: 50 },
      { name: "100", value: 100 },
      { name: "All", value: -1 },
    ],
    rows: 10,
    pagelength: 0,
    page: 1,
    serverProcessId: "",
  }),

  beforeDestroy() {
    //clearInterval(this.getting);
  },
  destroyed() {
    //clearInterval(this.getting);
  },
  computed: {
    getPList() {
      var arr = Object.assign([], this.allProcesses);
      arr.unshift({ id: "", name: "All" });

      return arr;
    },
  },
  methods: {
    dateRangeText() {
      this.dates = this.dates.sort();
      this.daterange = this.dates.sort().join(" - ");
      if (this.dates.length > 0) {
        if (this.dates.length == 2) {
          this.fromdate = this.dates[0];
          this.todate = this.dates[1];
        } else {
          this.fromdate = this.dates[0];
          this.todate = this.dates[0];
        }
      } else {
        this.fromdate = new Date().toISOString().slice(0, 10);
        this.todate = new Date().toISOString().slice(0, 10);
      }
    },
    getData() {
      this.getLeadHistory(
        this.editedProcess.serverProcessId,
        this.fromdate,
        this.todate
      );
    },
    processIssueOrEnhance(item) {
      this.editedItem.processId = item.id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.create({
          clientProcessId: this.editedItem.processId,
          name: this.editedItem.name,
          description: this.editedItem.description,
          type: this.editedItem.type,
        });

        this.closeDialog();
      }
    },

    async create(item) {
      let result = await ticketService.create(item);

      if (result.status == 200) {
        this.statuss = "Request sent successfully";
        setTimeout(() => {
          this.statuss = "";
        }, 2000);
      }
    },

    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);
      this.loading = false;
      this.botClientList = result;
      if (this.allLeads.records) {
        if (
          this.botClientList.records[0].folderId &&
          this.botClientList.records[0].tenatName
        ) {
          this.getLiveData();
        }
      }
    },

    updatePagination(pagination) {
      //clearInterval(this.getting);
      this.pageIndex = pagination.page || 1;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: "Implemented",
      };
      this.getAllLeads(data);
    },
    async getAllLeads(input) {
      this.loading = true;
      let result = await leadService.getAllLeads(input);
      this.loading = false;
      this.allLeads = result;
      if (this.allLeads.records) {
        if (this.allLeads.records.length > 0) {
          if (this.botClientList.records) {
            if (this.botClientList.records.length > 0) {
              if (
                this.botClientList.records[0].folderId &&
                this.botClientList.records[0].tenatName
              ) {
                this.mergeddata = this.allLeads.records;

                this.getLiveData();
              } else {
                this.loading = false;
                this.mergeddata = this.allLeads.records;
              }
            } else {
              this.loading = false;
              this.mergeddata = this.allLeads.records;
            }
          } else {
            this.loading = false;
            this.mergeddata = this.allLeads.records;
          }
        } else {
          this.loading = false;
          this.mergeddata = this.allLeads.records;
        }
      } else {
        this.loading = false;
      }
    },
    refreshLiveState() {
      if (this.allLeads.records) {
        if (this.allLeads.records.length > 0) {
          if (this.botClientList.records) {
            if (this.botClientList.records.length > 0) {
              if (
                this.botClientList.records[0].folderId &&
                this.botClientList.records[0].tenatName
              ) {
                this.getLiveData();
              }
            }
          }
        }
      }
    },
    getLiveData() {
      //clearInterval(this.getting);
      this.loading = true;
      let j = 0;
      for (j = 0; j < this.allLeads.records.length; j++) {
        this.getProcessLiveData(
          {
            name: this.allLeads.records[j].releaseProcessName,
            folderId: this.botClientList.records[0].folderId,
            tenant: this.botClientList.records[0].tenatName,
          },
          this.allLeads.records[j]
        );
      }
      this.loading = false;
      // this.getting = setInterval(() => {
      //   let i = 0;
      //   for (i = 0; i < this.allLeads.records.length; i++) {
      //     this.getProcessLiveData(
      //       {
      //         name: this.allLeads.records[i].releaseProcessName,
      //         folderId: this.botClientList.records[0].folderId,
      //         tenant: this.botClientList.records[0].tenatName,
      //       },
      //       this.allLeads.records[i]
      //     );
      //   }
      //   if (this.processData && this.dialogBotData == true) {
      //     this.getProcessData({
      //       name: this.releasenamepop,
      //       folderId: this.botClientList.records[0].folderId,
      //       tenant: this.botClientList.records[0].tenatName,
      //     });
      //   } else {
      //     this.roboLogsData = [];
      //     this.processData = [];
      //   }
      // }, this.refreshTime);
    },
    search() {
      //clearInterval(this.getting);
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: "Implemented",
      };
      this.getAllLeads(data);
    },
    truncateText: (text) => {
      if (text.length > 125) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      //clearInterval(this.getting);
      this.filter.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        Status: "Implemented",
      };
      this.getAllLeads(data);
    },

    ProcessLiveStatus(item) {
      if (this.botClientList.records) {
        if (this.botClientList.records.length > 0) {
          if (
            this.botClientList.records[0].folderId &&
            this.botClientList.records[0].tenatName
          ) {
            uiPathService
              .getProcessData({
                name: item.releaseProcessName,
                folderId: this.botClientList.records[0].folderId,
                tenant: this.botClientList.records[0].tenatName,
              })
              .then((result) => {
                if (result.data.jobs.length > 0) {
                  let runningJobs = result.data.jobs.find(
                    (c) => c.state === "Running"
                  );
                  if (runningJobs) {
                    return "Running";
                  } else {
                    return "Free";
                  }
                } else {
                  return "Free";
                }
              });
          } else {
            return "No Folder Id/Tenant";
          }
        } else {
          return "No Agent Assigned";
        }
      } else {
        return "No Agent Assigned";
      }
    },
    async getProcessLiveData(data, item) {
      let result = await uiPathService.getProcessData(data);
      this.editedIndex = this.allLeads.records.indexOf(item);

      this.editedItemProcess = Object.assign({}, item);
      if (result.data.jobs.length > 0) {
        let runningJobs = result.data.jobs.find((c) => c.state === "Running");
        if (runningJobs) {
          let date1 = new Date();
          let date2 = "";

          date2 = new Date(runningJobs.startTime);

          const duration = date1 - date2;
          var seconds = parseInt((duration / 1000) % 60),
            minutes = parseInt((duration / (1000 * 60)) % 60),
            hours = parseInt((duration / (1000 * 60 * 60)) % 24),
            days = parseInt(duration / (1000 * 60 * 60 * 24));

          var hoursDays = parseInt(days * 24);
          hours += hoursDays;
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.editedItemProcess.elapse = hours + ":" + minutes + ":" + seconds;
          this.editedItemProcess.seconds = duration / 1000;
          this.editedItemProcess.live_status = "Running";
          this.editedItemProcess.runningJobs = runningJobs;
        } else {
          this.editedItemProcess.live_status = "Free";
        }
      } else {
        this.editedItemProcess.live_status = "Free";
      }
      this.editedItemProcess.avgTime = result.data.averageTime;

      Object.assign(
        this.allLeads.records[this.editedIndex],
        this.editedItemProcess
      );
      this.mergeddata = [];
      this.mergeddata = this.allLeads.records;
    },
    processDetails(item) {
      this.roboLogsData = [];
      this.processData = [];
      this.process = Object.assign({}, item);
      if (this.botClientList.records) {
        if (this.botClientList.records.length > 0) {
          if (
            this.botClientList.records[0].folderId &&
            this.botClientList.records[0].tenatName
          ) {
            this.getProcessData({
              name: item.releaseProcessName,
              folderId: this.botClientList.records[0].folderId,
              tenant: this.botClientList.records[0].tenatName,
            });
            this.dialogBotData = true;
            this.releasenamepop = item.releaseProcessName;
            this.selectedBot = this.botClientList.records[0];
          } else {
            this.status = "Please contact admin for process details";
            setTimeout(() => {
              this.status = "";
            }, 2000);
          }
        } else {
          this.status =
            "Agent not assigned! Please contact admin for process details ";
          setTimeout(() => {
            this.status = "";
          }, 2000);
        }
      }
    },

    processQueueDetails(item) {
      if (this.botClientList.records) {
        if (this.botClientList.records.length > 0) {
          if (
            this.botClientList.records[0].folderId &&
            this.botClientList.records[0].tenatName
          ) {
            this.dialogBotQueue = true;
            this.selectedBot = this.botClientList.records[0];
            this.getProcessQueue({
              releaseName: item.releaseProcessName,
              folderId: this.botClientList.records[0].folderId,
              tenant: this.botClientList.records[0].tenatName,
            });
          } else {
            this.status = "Please contact admin for process queues ";
            setTimeout(() => {
              this.status = "";
            }, 2000);
          }
        } else {
          this.status =
            "Agent not assigned! Please contact admin for process queues ";
          setTimeout(() => {
            this.status = "";
          }, 2000);
        }
      }
    },
    closeDialogHistory() {
      this.clearDate();
      this.dialogHistory = false;
    },
    closeDialogTaskHistory() {
      this.clearDate();
      this.dialogTaskHistory = false;
    },
    onChange(e) {
      this.editedProcess = Object.assign({}, e);
    },
    getElapsedTime(item) {
      let date1 = new Date();
      let date2 = "";
      date2 = new Date(item.runningJobs.startTime).toISOString().slice(0, 10);

      this.difftime(date2, date1);
    },
    refresh() {
      if (this.processData.data) {
        if (this.processData.data.jobs.length > 0) {
          this.getRobotLogs({
            processName: this.releaseName,
            folderId: this.selectedBot.folderId,
            tenant: this.selectedBot.tenatName,
          });
        }
      }
    },
    async getRobotLogs(data) {
      this.loadingbotlog = true;

      let result = await uiPathService.getRobotLogs(data);
      this.loadingbotlog = false;
      this.roboLogsData = result.value.data;
      this.pagelength = Math.ceil(result.value.data.length / this.rows);
    },
    async getProcessData(data) {
      this.loadingprocess = true;
      let result = await uiPathService.getProcessData(data);
      this.loadingprocess = false;

      this.processData = result;
      this.getRunningProcessData(data.name);
      this.refresh();
    },
    getRunningProcessData(name) {
      if (this.processData.data.jobs.length > 0) {
        let runningJobs = this.processData.data.jobs.find(
          (c) => c.state === "Running"
        );
        if (runningJobs) {
          this.runningProcess = runningJobs;
        } else {
          this.runningProcess = null;
        }
      } else {
        this.runningProcess = null;
      }
      this.releaseName = name;
    },
    async getProcessQueue(data) {
      this.processQueue = [];
      this.loadingprocess = true;
      let result = await uiPathService.getProcessQueue(data);
      this.loadingprocess = false;
      this.processQueue = result;
    },

    closeLogs() {
      this.roboLogsData = [];
      this.processData = [];
      this.dialogBotData = false;
    },
    closeQueue() {
      this.dialogBotQueue = false;
    },
    historyItem(item) {
      this.selectedProcesses = item.id;
      this.editedProcess = Object.assign({}, item);

      this.getLeadHistory(item.serverProcessId, this.fromdate, this.todate);
      this.dialogHistory = true;
    },
    async getLeadHistory(item, fromdate, todate) {
      this.leadHistory = [];
      this.loadingHistory = true;
      let result;
      if (this.processurl) {
        result = await uiPathService.getProcessHistoryElastic({
          processStartDate: fromdate,
          processEndDate: todate,
          processUniqueID: item,
        });
      } else {
        result = await uiPathService.getProcessHistory({
          StartDate: fromdate,
          EndDate: todate,
          ProcessId: item,
        });
      }

      this.loadingHistory = false;
      this.leadHistory = result.data;
    },
    clearDate() {
      this.dates = [
        new Date().toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ];
      this.fromdate = new Date().toISOString().slice(0, 10);
      this.todate = new Date().toISOString().slice(0, 10);
      this.daterange =
        new Date().toISOString().slice(0, 10) +
        " - " +
        new Date().toISOString().slice(0, 10);
    },

    async getProcessList() {
      let result = await leadService.getAllProcess();

      this.allProcesses = result;
    },
    // getColor(item) {
    //   if (item.avgTime >= item.elapse) return "green--text";
    //   else return "red--text";
    // },
    // getColorStatus(item) {
    //   if (item.live_status == "Running") return "green";
    //   else return "orange";
    // },
    getColor(item) {
      if (item.avgTime >= item.elapse) return "green--text";
      else return "red--text";
    },
    getColorStatus(item) {
      if (item.live_status == "Running") return "#FDEDE8";
      else if (item.live_status == "Free") return "#E6F2F1";
      else return "#E6F2F1";
    },
    getTextColor(item) {
      if (item.live_status == "Running") return "#E64A19";
      else if (item.live_status == "Free") return "#00796B";
      else return "#00796B";
    },
    download() {
      if (this.leadHistory.length == 0) {
        this.status = "No Process History ";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    downloadTask() {
      if (this.taskHistoryItems.length == 0) {
        this.status = "No Task History ";
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    getTime() {
      var today = new Date();
      var y = today.getFullYear();
      var m = today.getMonth() + 1;
      var d = today.getDate();
      var h = today.getHours();
      var mi = today.getMinutes();
      var s = today.getSeconds();
      return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
    },
    diff(from, to, item) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      item.duration = duration;
      item.difference = hours + ":" + minutes + ":" + seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    readMoreStateChange() {
      this.isReadMore = !this.isReadMore;
    },
    totaltime() {
      let dd = 0;
      this.leadHistory.forEach((item) => {
        dd = dd + this.diffduration(item.processStartDate, item.processEndDate);
      });
      return this.ddTime(dd);
    },
    ddTime(duration) {
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    diffduration(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      let dif = toDate - fromDate;
      return dif;
    },
    footer() {
      return "Total Time Spent : " + this.totaltime();
    },
    difftime(from, to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      const duration = toDate - fromDate;
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24),
        days = parseInt(duration / (1000 * 60 * 60 * 24));

      var hoursDays = parseInt(days * 24);
      hours += hoursDays;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },

    onProcessChange(e) {
      // this.serverProcessId = e.serverProcessId;
      // this.getTaskHistoryData();
      this.editedProcess = Object.assign({}, e);
    },
    getTaskHistoryData() {
      // let uniquekey = {
      //   processUniqueID: this.editedProcess.serverProcessId,
      // };
      let uniquekey;
      if (this.uniqueId == "") {
        uniquekey = {
          processUniqueID: this.editedProcess.serverProcessId,
        };
      } else {
        uniquekey = {
          processUniqueID: this.editedProcess.serverProcessId,
          uniqueID: this.uniqueId,
        };
      }
      let data = {
        searchData: uniquekey,
        startDate: this.fromdate,
        endDate: this.todate,
      };
      this.getTaskHistory(data);
    },
    taskHistoryItem(item) {
      this.selectedProcesses = item.id;
      this.editedProcess = Object.assign({}, item);
      this.dialogTaskHistory = true;
      let uniquekey;
      if (this.uniqueId == "") {
        uniquekey = {
          processUniqueID: item.serverProcessId,
        };
      } else {
        uniquekey = {
          processUniqueID: item.serverProcessId,
          uniqueID: this.uniqueId,
        };
      }
      // let uniquekey = {
      //   processUniqueID: item.serverProcessId,
      // };
      let data = {
        searchData: uniquekey,
        startDate: this.fromdate,

        endDate: this.todate,
      };
      this.getTaskHistory(data);
    },
    async getTaskHistory(data) {
      this.loadingTaskHistory = true;
      this.taskHistoryItems = [];

      let result = await uiPathService.getTaskHistory({
        searchData: data.searchData,
        startDate: data.startDate,
        endDate: data.endDate,
      });
      this.taskHistoryItems = result.data;
      this.loadingTaskHistory = false;
    },

    searchUniqueId() {
      let uniquekey;
      if (this.uniqueId == "") {
        uniquekey = {
          processUniqueID: this.editedProcess.serverProcessId,
        };
      } else {
        uniquekey = {
          processUniqueID: this.editedProcess.serverProcessId,
          uniqueID: this.uniqueId,
        };
      }
      let data = {
        startDate: this.fromdate,
        endDate: this.todate,

        searchData: uniquekey,
      };
      this.getTaskHistory(data);
    },

    clearUniqueId() {
      this.uniqueId = "";
      let data = {
        startDate: this.fromdate,
        endDate: this.todate,
        searchData: {
          processUniqueID: this.editedProcess.serverProcessId,
        },
      };
      this.getTaskHistory(data);
    },
    getTaskHistoryColor(status) {
      if (status == "Successful") return "#E6F2F1";
      else return "#FDEDE8";
    },
    getTaskHistoryTextColor(status) {
      if (status == "Successful") return "#00796B";
      else return "#E64A19";
    },

    onchangeurl() {
      if (this.processurl) {
        this.processurltext = "Elastic Search";
      } else {
        this.processurltext = "Old URL";
      }
    },
  },
};
</script>
<style scoped  lang="scss">
@import "./src/colors.scss";

/deep/.v-date-picker-table {
  height: 100% !important;
}

/deep/.ttl.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}

/deep/.ttl.v-data-table .v-data-table-header tr th {
  font-size: 16px !important;
  background-color: #9ff0e3 !important;
}

/deep/.processname {
  word-break: break-all !important;
  width: 20% !important;
}

::v-deep .v-toolbar__content {
  padding: 4px 0px !important;
}

.textsubtitle {
  font-weight: 500;
  font-size: 20px !important;
  line-height: 23px;
  /* Secondary/500 */
  color: $secondary !important;
}

.tabtitle1 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.25px !important;
  /* Primary/Primary - Indigo */
  color: $primary !important;
}

.tabtitle2 {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* identical to box height */
  /* Primary/Teal */
  color: $primary !important;
}

td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.v-card {
  box-shadow: none !important;
  background: $white;
  /* Grey/100 */

  border: 1px solid $border !important;
  border-radius: 4px !important;
}

.total {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  /* identical to box height, or 129% */

  text-align: center;
  letter-spacing: 0.5px;

  /* Primary/Teal */

  color: #009688 !important;
}
</style>

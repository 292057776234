<template>
  <div class="container text-center">
    <div class="box">
      <!-- <img v-if="logo" :src="logo" height="80" width="80" class="ma-4" />
      <img
        v-else
        class="ma-4"
        :src="require('@/assets/logoDA.svg')"
        height="80"
        width="80"
      /> -->
      <img :src="require('@/assets/success.svg')" height="180" width="180" />
      <h2 class="mt-5 carttext">Thank you for your order !</h2>
      <p class="orderdesc mt-2">
        Your order is complete. Please check your email for the details.
      </p>
      <v-btn
        color="primary"
        class="white--text mt-5 text-none"
        large
        depressed
        to="/processes"
      >
        Explore More Workflows
        <!-- <v-avatar right size="18" class="ml-2 text-right">
          <img src="../assets/Arrow.png" />
        </v-avatar> -->
      </v-btn>
      <div class="row mt-30">
        <div class="col-12">
          <v-list>
            <v-list-item
              class="py-2 list-box text-left"
              v-for="(item, i) in orderlist"
              :key="i"
            >
              <v-list-item-avatar size="52" class="rounded-lg">
                <v-img :src="item.imageURL" contain></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="cartitem"
                  >{{ item.name }} <br class="d-md-none" />
                  <!-- <v-chip
                    v-if="item.categoryName != ''"
                    label
                    small
                    color="#E6F2F1"
                    text-color="#00796B"
                    dark
                    class="ml-2"
                  >
                    {{ item.categoryName }}
                  </v-chip> -->
                  <!-- <span class="gree-icon" v-if="item.categoryName != ''">{{
                  item.categoryName
                }}</span> -->
                </v-list-item-title>

                <v-list-item-subtitle class="cartdesc">{{
                  truncateText(item.description)
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <p class="dateval">
                  {{ new Date(item.createdOn).toDateString() }}
                </p>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import orderService from "@/services/order.service.js";

import authService from "@/services/auth.service.js";
export default {
  data: () => ({
    orderlist: [],
    logo: authService.authData ? authService.authData.logoUrl : undefined,
  }),
  computed: {},
  created() {
    this.get(this.$route.params.id);
  },
  methods: {
    async get(id) {
      let result = await orderService.getAll({ groupId: id });

      this.orderlist = result;
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
  },
};
</script>
<style scoped>
.box {
  margin-top: 10%;
}
.mt-30 {
  margin-top: 60px;
}
.title {
  padding-top: 30px;
  text-align: left;
  padding-left: 30px;
}
.list-box {
  border: 1px solid #e4e7ea !important;
  border-radius: 10px !important;
  margin-right: 10px;
  margin-bottom: 10px;
}
.gree-icon {
  background: #06a10b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  color: #fff;
  font-size: 10px;
  padding: 4px 7px;
  position: relative;
  top: -4px;
}
.carttext {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #17181d !important;
}
.orderdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #45464a;
}
.cartitem {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #17181d !important;
}
.cartdesc {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #5f6463 !important;
}
.dateval {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  color: #5f6463;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9 col-md-5 col-lg-4 mx-auto log-box"},[_c('div',{staticClass:"card border-0 shadow rounded-3 my-5"},[_c('div',{staticClass:"card-body p-4 p-sm-5"},[_vm._m(0),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"type":_vm.show1 ? 'text' : 'password',"rules":_vm.passwordRules,"required":"","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","dense":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},nativeOn:{"keypress":function($event){return _vm.noSpace($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Old Password "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('v-text-field',{attrs:{"type":_vm.showN ? 'text' : 'password',"rules":_vm.passwordRules2,"required":"","maxlength":"20","outlined":"","dense":"","append-icon":_vm.showN ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showN = !_vm.showN}},nativeOn:{"keypress":function($event){return _vm.noSpace($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" New Password "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"type":_vm.showC ? 'text' : 'password',"maxlength":"20","outlined":"","dense":"","rules":[
                (v) => !!v.trim() || 'Password is required',
                (v) =>
                  (v.trim() && v.length <= 20) || 'Maximum 20 characters',
                _vm.passwordConfirmationRule,
                (v) => (v.trim() && v.length >= 5) || 'Minimum 5 characters',
              ],"required":"","append-icon":_vm.showC ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showC = !_vm.showC}},nativeOn:{"keypress":function($event){return _vm.noSpace($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm Password "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}]),model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('v-btn',{staticClass:"white--text btn-block mt-5 text-left",attrs:{"color":"black","depressed":""},on:{"click":_vm.validate}},[_vm._v(" Reset "),_c('v-avatar',{staticClass:"text-right",attrs:{"right":""}},[_c('img',{attrs:{"src":require("../assets/Arrow.png")}})])],1),(_vm.authStatus)?_c('div',{staticClass:"text-center mt-3 error-text"},[_vm._v(" Invalid Password ")]):_vm._e()],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5 mx-4"},[_c('img',{attrs:{"src":require("../assets/Pattern.png")}}),_c('h3',{},[_vm._v("Reset Password")]),_c('small',[_vm._v("Do you want to change your password")])])
}]

export { render, staticRenderFns }
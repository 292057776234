<template>
  <div>
    <v-app-bar
      app
      absolute
      elevate-on-scroll
      class="hidden-sm-and-down header1"
      style="border-bottom: 1px solid #d2d2d2 !important"
    >
      <div class="container d-flex">
        <!-- <v-img
        v-if="logo"
        class="cursor-point"
        :src="logo"
        max-height="40"
        max-width="40"
        contain
        @click="home()"
      ></v-img> -->

        <img
          class="cursor-point"
          :src="require('@/assets/headerlogo.svg')"
          max-height="28"
          contain
          @click="home()"
        />
        <!-- <v-toolbar-title v-if="isLoggedIn != undefined && userType !== 'admin'"
        >{{ organizationName }}
      </v-toolbar-title>
      <v-toolbar-title v-else>AlignXcel MarketPlace </v-toolbar-title> -->
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :ripple="false"
            to="/"
            title="Home - Base of Existence"
            text
            class="text-capitalize headerText"
            active-class="highlighted"
            >Home</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType == 'admin'"
            to="/log-dashboard"
            title="Dashboard"
            text
            class="text-capitalize headerText"
            active-class="highlighted"
            >Dashboard</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType == 'admin'"
            to="/savings-page"
            title="Savings"
            text
            class="text-capitalize headerText"
            active-class="highlighted"
            >Savings</v-btn
          >
          <v-menu
            content-class="elevation-0 cardrt"
            outlined
            offset-y
            v-if="isLoggedIn != undefined && userType == 'admin'"
            transition="scale-transition"
            active-class="highlighted"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                class="text-capitalize headerText"
                v-on="on"
                title="Preserved Statistics"
                active-class="highlighted"
              >
                Master Data
                <v-icon right size="20">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item router to="/masterdata/processes">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Workflows"
                    >Workflows</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/roles">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Workflows"
                    >Roles</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/categories">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Categories under which Workflows are unified"
                    >Categories</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/application-task">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Application and TaskIds for Alignxcel Logs"
                    >Application and TaskIds</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/alignxcel-release">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Application Releases"
                    >Alignxcel Releases</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/faqs">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage FAQs"
                    >FAQs</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/botsandimages">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Devise Bots and Images"
                    >Bots and Images</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/licenses">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Contrive the UI Path Licenses"
                    >Agent Licenses</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/clientorganization">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Client Organizations"
                    >Client Organization</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/log-activity">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Logged Statistics"
                    >Log Activity</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/alignapi-clients">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="AlignAPI Clients"
                    >Client Licenses</v-list-item-title
                  >
                </v-list-item>
                <!-- <v-list-item router to="/masterdata/license-expiry">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="License Details"
                    >License Details</v-list-item-title
                  >
                </v-list-item> -->
                <v-list-item router to="/masterdata/holidays">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Holidays"
                    >Holidays</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/masterdata/assets">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Assets"
                    >Assets</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined"
            to="/processes"
            title="List of Workflows we Offer"
            text
            class="text-capitalize headerText"
            active-class="highlighted"
            >Explore More Workflows</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType == 'admin'"
            to="/leads"
            title="Wheel of Workflows Governed"
            active-class="highlighted"
            text
            class="text-capitalize headerText"
            >Leads</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType == 'admin'"
            to="/newprocess"
            title="New Workflow requested by Clients"
            active-class="highlighted"
            text
            class="text-capitalize headerText"
            >Requests</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType == 'admin'"
            to="/admin-issue-enhance"
            title="New Tickets requested by Clients"
            active-class="highlighted"
            text
            class="text-capitalize headerText"
            >Tickets</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType !== 'admin'"
            to="/mybots"
            title="My Digital Staffs"
            active-class="highlighted"
            text
            class="text-capitalize headerText"
          >
            {{ organizationName }} Digital Staffs</v-btn
          >

          <v-menu
            content-class="elevation-0 cardrt"
            offset-y
            v-if="isLoggedIn != undefined && userType !== 'admin'"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                class="text-capitalize headerText"
                v-on="on"
                title="Get your Workflow status"
                active-class="highlighted"
              >
                {{ organizationName }} Workflows
                <v-icon right size="20">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item router to="/myprocesses/owned-processes">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Owned Workflows"
                    >Owned Workflows</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/myprocesses/requested-processes">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Requested Workflows"
                    >Requested Workflows</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/myprocesses/request-new-process">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Request new Workflows"
                    >New Requests</v-list-item-title
                  >
                </v-list-item>
                <v-list-item router to="/myprocesses/issue-enhance">
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Report issue or Request enhance"
                    >Issue/Enhancement</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <!-- <v-btn
          :ripple="false"
          v-if="isLoggedIn != undefined && userType !== null"
          to="/manageusers"
          title="Supervise the User Entreaties"
          active-class="highlighted"
          text
          class="text-capitalize headerText"
          >Users</v-btn
        > -->

          <v-btn
            :ripple="false"
            v-if="isLoggedIn == undefined"
            to="/register"
            title="Join Here"
            color="buttonColor"
            class="text-capitalize white--text headerText justify-center btnborder"
            max-height="40"
            >Get full access join now</v-btn
          >
          <v-btn
            :ripple="false"
            v-if="isLoggedIn == undefined"
            to="/login"
            title="Log In"
            active-class="highlighted"
            text
            class="text-capitalize headerText"
            ><v-icon left> mdi-account-circle </v-icon>Log In
          </v-btn>
          <v-btn
            :ripple="false"
            v-if="isLoggedIn != undefined && userType !== 'admin'"
            to="/cart"
            title="Add to Cart"
            active-class="highlighted"
            text
            class="text-capitalize headerText"
            ><v-badge
              overlap
              color="primary"
              size="12"
              :content="count.toString()"
            >
              <v-avatar tile size="32">
                <!-- <v-img :src="require('@/assets/Cart.png')"></v-img> -->
                <v-icon> mdi-cart </v-icon>
              </v-avatar>
            </v-badge>
          </v-btn>
          <v-menu
            content-class="elevation-0"
            offset-y
            v-if="isLoggedIn != undefined"
            transition="scale-transition"
            class="pr-0"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                width="22"
                title="To access account and manage orders. Click here!"
                class="text-capitalize headerText pr-0"
                v-on="on"
                active-class="highlighted"
              >
                <v-icon size="22">mdi-account-circle</v-icon>
              </v-btn>
            </template>
            <v-card outlined>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="account-name"
                      >{{ userdata.firstName + " " + userdata.lastName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="account-email">{{
                      userdata.emailId
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn small>
                      {{ organizationName }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-if="isLoggedIn != undefined && userType !== null"
                    router
                    to="/manageusers"
                  >
                    <v-icon size="15" class="mr-2"
                      >mdi-account-multiple-plus-outline</v-icon
                    >

                    <v-list-item-title
                      class="text-capitalize headerText"
                      active-class
                      title="Supervise the User Entreaties"
                    >
                      Manage Users
                    </v-list-item-title>

                    <v-icon size="12" class="mr-2">mdi-chevron-right</v-icon>
                  </v-list-item>
                  <v-list-item
                    v-if="isLoggedIn != undefined && userType == 'admin'"
                    router
                    to="/user-queries"
                  >
                    <v-icon size="15" class="mr-2"
                      >mdi-comment-question-outline</v-icon
                    >

                    <v-list-item-title
                      class="text-capitalize headerText"
                      active-class
                      title="List of user queries"
                    >
                      User Queries
                    </v-list-item-title>

                    <v-icon size="12" class="mr-2">mdi-chevron-right</v-icon>
                  </v-list-item>
                  <v-list-item router to="/myaccount">
                    <v-icon size="15" class="mr-2"
                      >mdi-account-box-outline</v-icon
                    >

                    <v-list-item-title
                      class="text-capitalize headerText"
                      active-class
                      title="Manage Personal Information, View List of Workflows and Implemented"
                      >My Accounts</v-list-item-title
                    >

                    <v-icon size="12" class="mr-2">mdi-chevron-right</v-icon>
                  </v-list-item>

                  <v-list-item
                    v-if="isLoggedIn != undefined && userType !== 'admin'"
                    router
                    to="/client-license"
                  >
                    <v-icon size="15" class="mr-2">mdi-license</v-icon>

                    <v-list-item-title
                      class="text-capitalize headerText"
                      active-class
                      title="Supervise the User Entreaties"
                    >
                      License Details
                    </v-list-item-title>

                    <v-icon size="12" class="mr-2">mdi-chevron-right</v-icon>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item class="list-logout" @click="logout">
                    <v-icon size="15" class="mr-2">mdi-export</v-icon>

                    <v-list-item-title
                      class="text-capitalize headerText"
                      active-class
                      title="Logout from account"
                      >Logout</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <!-- <v-list>
            <v-list-item-group color="primary">
              <v-list-item router to="/myaccount">
                <v-list-item-title
                  class="text-capitalize headerText"
                  active-class
                  title="Manage Personal Information, View List of processes and Implemented"
                  >Settings</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title
                  class="text-capitalize headerText"
                  active-class
                  title="Logout from account"
                  >Logout</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list> -->
          </v-menu>
        </v-toolbar-items>
      </div>
    </v-app-bar>

    <v-app-bar app color="white" class="hidden-md-and-up">
      <v-img
        :src="require('@/assets/logoDA.svg')"
        max-height="40"
        max-width="40"
        contain
        @click="home()"
      ></v-img>
      <v-toolbar-title>AlignXcel MarketPlace </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-card>
          <v-app-bar flat color="white">
            <v-img
              class="cursor-point"
              :src="require('@/assets/logoDA.svg')"
              max-height="40"
              max-width="40"
              contain
              @click="home()"
            ></v-img>
            <v-toolbar-title>AlignXcel MarketPlace </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>

          <v-list>
            <v-list-item @click.native="dialog = false" to="/">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="home">Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-if="isLoggedIn != undefined && userType == 'admin'">
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon> mdi-robot</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Master Data</v-list-item-title>
                </v-list-item-content>
              </template>
              <div class="px-14">
                <v-list-item
                  router
                  to="/masterdata/processes"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Workflows, thier descriptions and Images"
                    >Workflows</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/masterdata/categories"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage Categories under which Workflows are grouped"
                    >Categories</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/masterdata/botsandimages"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage thousands of bot Images created by DA"
                    >Bots and Images</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/masterdata/licenses"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Manage the UI Path licenses that are either owned by clients or create new licenses for new customer"
                    >Agent Licenses</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/masterdata/clientorganization"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Page where Client organizations can be managed"
                    >Client Organization</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/masterdata/log-activity"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Page where Client organizations can be managed"
                    >Log Activity</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/masterdata/alignapi-clients"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Page where AlignAPI Client can be managed"
                    >AlignAPI Clients</v-list-item-title
                  >
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-item
              v-if="isLoggedIn != undefined"
              to="/processes"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-format-list-text</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="Workflows List"
                  >Explore More Workflows</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-if="isLoggedIn != undefined && userType !== 'admin'"
            >
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon> mdi-robot</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ organizationName }} Workflows</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <div class="px-14">
                <v-list-item
                  router
                  to="/myprocesses/owned-processes"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Implemented Workflows"
                    >Owned Workflows</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  router
                  to="/myprocesses/requested-processes"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Ordered Workflows and thier status"
                    >Requested Workflows</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  router
                  to="/myprocesses/request-new-process"
                  @click.native="dialog = false"
                >
                  <v-list-item-title
                    class="text-capitalize headerText"
                    active-class="highlighted"
                    title="Request new Workflows"
                    >New Requests</v-list-item-title
                  >
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-item
              :ripple="false"
              v-if="isLoggedIn != undefined && userType == 'admin'"
              to="/leads"
              text
              class="text-capitalize headerText"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="User">Leads</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :ripple="false"
              v-if="isLoggedIn != undefined && userType == 'admin'"
              to="/newprocess"
              text
              class="text-capitalize headerText"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="New Workflow requested by Clients "
                  >Requests</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="isLoggedIn != undefined && userType !== null"
              to="/manageusers"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="User">Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="isLoggedIn == undefined"
              to="/login"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-login</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="Log In">Log In</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="isLoggedIn == undefined"
              to="/register"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="Join Here"
                  >Join Here</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="isLoggedIn != undefined && userType !== 'admin'"
              to="/mybots"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-icon>mdi-robot</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="My Digital Staffs">
                  {{ organizationName }} Digital Staffs</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="isLoggedIn != undefined && userType !== 'admin'"
              to="/cart"
              @click.native="dialog = false"
            >
              <v-list-item-action>
                <v-badge overlap color="#20D2B6" :content="count.toString()">
                  <v-avatar tile size="22">
                    <v-img :src="require('@/assets/Cart.png')"></v-img>
                  </v-avatar>
                </v-badge>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title title="Cart">Cart</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-if="isLoggedIn != undefined">
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>My Account</v-list-item-title>
                </v-list-item-content>
              </template>
              <div class="px-14">
                <v-list-item
                  v-if="isLoggedIn != undefined"
                  to="/myaccount"
                  @click.native="dialog = false"
                >
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isLoggedIn != undefined"
                  @click="logout()"
                  @click.native="dialog = false"
                >
                  <v-list-item-content>
                    <v-list-item-title title="Logout">Logout</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>
<script>
import authService from "@/services/auth.service.js";
import cartService from "@/services/cart.service.js";

import EventBus from "../plugins/eventBus";

export default {
  data() {
    return {
      count: "",
      dialog: false,
      isLoggedIn: authService.isLoggedIn ? true : undefined,
      userType: authService.authData
        ? authService.authData.userType
        : undefined,
      organizationName: authService.authData
        ? authService.authData.organization
        : undefined,
      logo: authService.authData ? authService.authData.logoUrl : undefined,
      userdata: authService.authData ? authService.authData : {},
    };
  },
  computed: {},
  created() {
    if (this.isLoggedIn && this.userType != "admin") {
      this.getAllCart();
    }
    EventBus.on("logo_changes", (x) => {
      this.status = x;

      this.logo = authService.authData
        ? authService.authData.logoUrl
        : undefined;

      if (this.isLoggedIn != undefined && this.userType != "admin") {
        this.getAllCart();
      }
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });
    EventBus.on("login_changes", (x) => {
      this.status = x;

      this.isLoggedIn = authService.isLoggedIn ? true : undefined;
      this.userType = authService.authData
        ? authService.authData.userType
        : undefined;
      this.organizationName = authService.authData
        ? authService.authData.organization
        : undefined;
      this.logo = authService.authData
        ? authService.authData.logoUrl
        : undefined;
      this.userdata = authService.authData ? authService.authData : {};
      if (this.isLoggedIn != undefined && this.userType != "admin") {
        this.getAllCart();
      }
      setTimeout(() => {
        this.status = "";
      }, 2000);
    });

    EventBus.on("cart_changes", () => {
      if (this.isLoggedIn != undefined && this.userType != "admin") {
        this.getAllCart();
      }
    });
  },
  methods: {
    home() {
      this.$router.push("/").catch(() => {});
      this.dialog = false;
    },
    async getAllCart() {
      let result = await cartService.getAll();
      this.count = result.length;
    },

    logout() {
      authService.logout();
    },
  },
};
</script>
<style scoped lang="scss">
@import "./src/colors.scss";
.v-btn::before {
  background-color: transparent;
}
.v-toolbar__title {
  font-weight: bold;
  font-size: 20px;
  padding-left: 10px;
}
.header1 {
  // padding-left: 10%;
  // padding-right: 10%;
  border-color: #2c2d2e !important;
}
header {
  background: #fff !important;
  border-color: 1px solid #424141 !important;
}
.cursor-point {
  cursor: pointer;
}
.highlighted {
  // font-weight: 900;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: $primary !important;
}
.headerText {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: $secondary;
}
.highlighted:after {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: $primary !important;
  // content: "";
  // position: absolute;
  // left: 50%;
  // right: 0;
  // margin-top: 25px;
  // width: 0;
  // height: 0;
  // border: 4px solid #20d2b6;
  // background-color: #20d2b6;
  // border-radius: 50%;
}
.highlighted1:after {
  content: "";
  position: absolute;
  left: 50%;
  right: 0;
  margin-top: 25px;
  width: 0;
  height: 0;
  border: 4px solid #20d2b6;
  background-color: #20d2b6;
  border-radius: 50%;
}
.v-toolbar__items > .v-btn {
  margin: auto;
}
.cardrt {
  border: gray !important;
  border-radius: 8px;
  border: 1px solid rgb(231, 221, 221) !important;
}
.btnborder {
  border-radius: 4px !important;
}
</style>

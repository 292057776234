<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-12 col-md-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-12 col-md-6">
                <v-select
                  :items="getOList"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="botClientListUsed.records"
        :items-per-page="10"
        :server-items-length="botClientListUsed.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.botImageURL`]="{ item }">
          <img :src="item.botImageURL" width="50px" />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td v-if="item.name">{{ item.name }}</td>
          <td v-else class="red--text">Agent name not selected by Cleint</td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import orgService from "@/services/organization.service.js";
import clientBotService from "@/services/clientBot.service.js";
export default {
  created() {
    this.getOrganization();
  },
  computed: {
    getOList() {
      var arr = Object.assign([], this.regOrganization);
      arr.unshift({ id: "", name: "All" });
      return arr;
    },
  },
  data: () => ({
    botClientListUsed: {},

    regOrganization: [],
    loading: false,
    status: "",
    filter: {
      searchKey: "",
      sortBy: "",
    },
    searchKey: "",
    headers: [
      { text: "", sortable: false, value: "botImageURL" },
      { text: "Agent Image Name", sortable: false, value: "name" },
      { text: "Organization", value: "organizationName", sortable: false },
      { text: "License", value: "license", sortable: false },
    ],
    pageIndex: 0,
    selectedOrg: "",
  }),
  methods: {
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.regOrganization = result;
    },
    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);
      this.loading = false;
      this.botClientListUsed = result;
    },
    onChange() {
      let data = {
        Skip: 0,
        Take: 10,
        status: "Inprogress",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 0;
      this.getClientBot({
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        status: "Inprogress",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      });
    },
    clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        status: "Inprogress",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      let data = {
        Skip: 0,
        Take: 10,
        status: "Inprogress",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-12 col-md-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-12 col-md-6">
                <v-select
                  :items="regOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  :clearable="true"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="deActivatedList.records"
        :items-per-page="10"
        :server-items-length="deActivatedList.total"
        :loading="loading"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.botImageURL`]="{ item }">
          <img :src="item.botImageURL" width="50px" />
        </template>
        <template v-slot:[`item.modifiedOn`]="{ item }">
          <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td v-if="item.name">{{ item.name }}</td>
          <td v-else class="red--text">Agent name not selected by Cleint</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                class="mr-3"
                @click="deleteBotLicense(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center pa-4">
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6" color="red">
            <v-icon color="white" size="30">mdi-minus-circle-outline</v-icon>
          </v-btn>
          Are you sure you want to delete this license?
        </v-card-title>
        <v-divider class="my-4 mx-11"></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import orgService from "@/services/organization.service.js";
import clientBotService from "@/services/clientBot.service.js";
import EventBus from "../plugins/eventBus";
export default {
  created() {
    this.getOrganization();
  },
  computed: {
    headers() {
      return [
        { text: "", sortable: false, value: "botImageURL" },
        { text: "Agent Name", sortable: false, value: "name" },
        {
          text: "Organization",
          value: "organizationName",
          sortable: false,
          filter: this.orgFilter,
        },
        { text: "License", value: "license", sortable: false },
        { text: "Server Name", value: "serverName", sortable: false },
        { text: "Agent Selected", value: "botSelectedOn", sortable: false },
        { text: "Created By", value: "createdBy", sortable: false },
        { text: "Deactivated On", value: "modifiedOn", sortable: false },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "130px",
        },
      ];
    },
  },
  data: () => ({
    filter: {
      searchKey: "",
    },
    loading: false,
    regOrganization: [],
    searchKey: "",
    selectedOrg: "",
    dialogDelete: false,
    status: "",
    deleteItem: {},
    deActivatedList: {},
  }),
  methods: {
    deleteBotLicense(item) {
      this.deleteItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.hardDelete({ clientBotId: this.deleteItem.id });
    },
    async hardDelete(item) {
      let result = await clientBotService.delete(item);
      if (result.status == 200) {
        EventBus.emit("license_add_delete", "deleted");
        this.dialogDelete = false;
        this.status = "Agent deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    async getClientBot(data) {
      this.loading = true;
      let result = await clientBotService.getAll(data);
      this.loading = false;
      this.deActivatedList = result;
    },
    onChange() {
      let data = {
        Skip: 0,
        Take: 10,
        status: "Delete",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 0;
      this.getClientBot({
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        status: "Delete",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      });
    },
    clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        status: "Delete",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      let data = {
        Skip: 0,
        Take: 10,
        status: "Delete",
        SearchKey: this.searchKey,
        organizationId: this.selectedOrg,
      };
      this.getClientBot(data);
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();
      this.regOrganization = result;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.v-card__title {
  justify-content: center;
}
</style>
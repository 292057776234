<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4 col-12">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <!---Add New Asset -->
              <div
                class="
                  col-lg-3 col-sm-6 col-md-3 col-12
                  text-lg-left text-center
                "
              >
                <v-btn
                  dark
                  class="mt-3"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Assets</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <!---Data Table -->
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items-per-page="10"
        :items="assetList.records"
        :server-items-length="assetList.total"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="25" small @click="deleteItem(item)" v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete Role</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.isGlobalValue`]="{ item }">
            <td v-if="item.isGlobalValue">
              <v-icon color="#00796B">mdi-check-all</v-icon>
            </td>
            <td v-else>
              <v-icon color="red">mdi-close-outline</v-icon>
            </td>
          </template>
      
      </v-data-table>
    </v-card>

    <!---Asset update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-card-subtitle class="text-center texttitle"
            >Note:Assets added from Marketplace are treated as Non-Sensitive, Please add sensitive datas from Alignxcel</v-card-subtitle
          >
            <v-text-field
              v-model="editedItem.name"
              label="Asset Name"
              required
              :rules="[
                (v) => !!v || 'Asset Name is required',
                (v) => (v && !!v.trim()) || 'Asset Name cannot be blank',
              ]"
            >
              <template #label>
                Asset Name<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="editedItem.description"
              required
              :rules="[
                (v) => !!v || 'Description is required',
                (v) => (v && !!v.trim()) || 'Description cannot be blank',
              ]"
            >
              <template #label>
                Description <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <v-select
              :items="type"
              item-text="name"
              item-value="value"
              v-model="editedItem.type"
              required
              :rules="[(v) => !!v || 'Type is required']"
              ><template #label>
                Type <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <v-text-field
            v-if="editedItem.type=='Text'"
              v-model="editedItem.value"
              label="Asset Value"
              required
              :rules="[
                (v) => !!v || 'Asset Value is required',
                (v) => (v && !!v.trim()) || 'Asset Value cannot be blank',
              ]"
            >
              <template #label>
                Asset Value<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
            v-else-if="editedItem.type=='Integer'"
              v-model="editedItem.value"
              label="Asset Value"
              required
              @keypress="onlyNumber($event), noSpace($event)"
              :rules="[
                (v) => !!v || 'Asset Value is required',
                (v) => (v && !!v.trim()) || 'Asset Value cannot be blank',
              ]"
            >
              <template #label>
                Asset Value<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-select
            v-else-if="editedItem.type=='Bool'"
              :items="boolval"
              item-text="name"
              item-value="value"
              v-model="editedItem.value"
              required
              :rules="[(v) => !!v || 'Asset Value is required']"
              ><template #label>
                Asset Value <span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
            <!-- <div class="row"  v-else-if="editedItem.type=='Credential'">
              <div class="col-lg-6 col-md-6 col-12">

                </div>
                <div class="col-lg-6 col-md-6 col-12">

</div>
            </div> -->
            <v-switch
              v-model="editedItem.isGlobalValue"
              :false-value="false"
              :true-value="true"
              inset
              label="Is Applicable to all Clients"
              class="offset-4 offset-lg-0"
            ></v-switch>
            <v-autocomplete
            v-if="!editedItem.isGlobalValue"
            :items="allOrganization"
            
            v-model="editedItem.clients"
                  item-text="name"
                  item-value="id"
                label="Client"
                
                required
                :rules="[(v) => !!v || 'Client are required']"
                small-chips
              >
                <template #label>
                  Client <span class="red--text"><strong>* </strong></span>
                </template></v-autocomplete
              >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Assets?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="text-capitalize grey lighten-2" @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import assetService from "@/services/asset.service.js";
import orgService from "@/services/organization.service.js";
export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Asset" : "Edit Asset";
    },
    getOList() {
      var arr = Object.assign([], this.allOrganization);
      arr.unshift({ id: 0, name: "All" });
      return arr;
    },
  },
  data: () => ({
    type: [
      { name: "Text", value: "Text" },
      { name: "Bool", value: "Bool" },
      { name: "Integer", value: "Integer" },
      // { name: "Credential", value: "Credential" },
      
    ],
    boolval: [
      { name: "True", value: "true" },
      { name: "False", value: "false" },
      
    ],
    allOrganization: [],
    assetList: {},
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Description",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Type",
        align: "start",
        sortable: false,
        value: "type",
      },
      {
        text: "Value",
        align: "start",
        sortable: false,
        value: "value",
      },
      {
        text: "Is Global Value",
        align: "start",
        sortable: false,
        value: "isGlobalValue",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    dialogDelete:false,
    filter: {
      searchKey: "",
    },
    editedItem: {
      id: 0,
      name: "",
      value:'',
      description:'',
      type:'',
      isGlobalValue:false,
      clients:''
    },
    defaultItem:{
      id: 0,
      name: "",
      value:'',
      description:'',
      type:'',
      isGlobalValue:false,
      clients:''
    },
    loading: false,
  }),
  created() {
    this.getOrganization();
  },
  methods: {
    // onChange() {
    //   console.log("this.editedItem.clients",this.editedItem.clients)
    //   if(this.editedItem.clients==0)
    //   {
    //     this.editedItem.isGlobalValue=true
    //   }else{
    //     this.editedItem.isGlobalValue=false
    //   }
      
    // },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.allOrganization = result;
    },
    noSpace($event) {
      if (
        $event.charCode === 0 ||
        /^\S+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      if (
        $event.charCode === 0 ||
        /^[0-9]+$/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    editItem(item) {
      this.editedIndex = this.assetList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      
      if(!item.isGlobalValue)
      {
        this.editedItem.clients=item.mappings[0]?.orgId
      }
      
      this.dialog = true;
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await assetService.getAll({
        skip: (pagination.page - 1) * 10,
        take: 10,
        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.assetList = result;
    },
    async search() {
      this.loading = true;
      let result = await assetService.getAll({
        skip: 0,
        take: 10,

        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.assetList = result;
    },
    async clear() {
      this.loading = true;
      this.filter.searchKey = "";
      let result = await assetService.getAll({
        skip: 0,
        take: 10,

        searchKey: this.filter.searchKey,
      });
      this.loading = false;
      this.assetList = result;
    },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          var list = [];
         
           if(this.editedItem.clients)
          {
         
          
          list.push(this.editedItem.clients);
          }
          
          this.create({
            name: this.editedItem.name,
            description: this.editedItem.description,
            type: this.editedItem.type,
            value: this.editedItem.value,
            isGlobalValue:this.editedItem.isGlobalValue,
            clientIdList:list});
        } else {
          let id = '';
          var list1 = []; 
          if(!this.editedItem.isGlobalValue && this.editedItem.clients!=this.assetList.records[this.editedIndex].mappings[0]?.orgId)
          {
            if(this.editedItem.clients)
          {
          
          
          list1.push(this.editedItem.clients);
          }
          }
         
         
      if (this.editedItem.id != 0) {
        id = this.assetList.records[this.editedIndex].id;
      } else {
        id = this.assetList.records[this.editedIndex].assetId;
      }
      this.update({
            
            name: this.editedItem.name,
            description: this.editedItem.description,
            type: this.editedItem.type,
            value: this.editedItem.value,
            isGlobalValue:this.editedItem.isGlobalValue,
            clientIdList:list1
          },id);
        }
        this.closeDialog();
      }
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.assetList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      let id = '';
      if (this.editedItem.id != 0) {
        id = this.assetList.records[this.editedIndex].id;
      } else {
        id = this.assetList.records[this.editedIndex].assetId;
      }

      // const id = this.assetList.records[this.editedIndex].id;
      // this.editedItem.id = id;

      this.delete( id);
      this.dialogDelete = false;
    },
    async delete(item) {
      let result = await assetService.delete(item);
      if (result.status == 200) {
        this.status = "Asset Deleted successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    async create(item) {
      let result = await assetService.create(item);

      if (result.status == 200) {
        this.editedIndex = -1;
      // this.$refs.form.reset();
      this.$refs.form.resetValidation();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.status = "Asset created successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item,id) {
      let result = await assetService.update(item,id);
      if (result.status == 200) {
        this.editedIndex = -1;
      // this.$refs.form.reset();
      this.$refs.form.resetValidation();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.status = "Asset updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.texttitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* Secondary/500 */

  color: red;

  transform: matrix(1, 0, 0, 1, 0, 0);
}
</style>
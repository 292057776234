<template>
  <div class="container">
    <v-card>
      <!---Search -->
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="allOrganization"
                  item-text="name"
                  item-value="name"
                  v-model="orgName"
                  label="Select Organization"
                  @change="onChange"
                  :clearable="true"
                ></v-select>
              </div>
              <div class="col-lg-2 col-sm-6 col-12 offset-lg-0 offset-xs-0">
                <v-btn
                  dark
                  class="mt-lg-3 mt-0"
                  @click="
                    dialog = true;
                    editedIndex = -1;
                  "
                >
                  <v-icon dark> mdi-plus </v-icon> Add Release Version</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="mainheaders"
        :server-items-length="releaseList.total"
        :items-per-page="10"
        :items="releaseList.records"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :loading="loading"
        class="elevation-1"
        :single-expand="singleExpand"
        show-expand
        @item-expanded="loadDetails"
      >
        <template v-slot:[`item.releaseOn`]="{ item }">
          <td>{{ new Date(item.releaseOn).toLocaleDateString() }}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-3 float-right"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4">
            <div v-html="item.description"></div>
            <v-row no-gutters justify="center" align="center">
              <v-col cols="8">
                <v-form ref="form1" v-model="valid" lazy-validation>
                  <v-file-input
                    multiple
                    show-size
                    v-model="selectedFiles"
                    label="Select File"
                    required
                    :rules="[
                      (v) => !!v || 'File is required',
                      (files) =>
                        !files ||
                        !files.some((file) => file.size > fileLimit) ||
                        'File size should be less than ' + limit,
                    ]"
                    @change="selectFiles"
                  ></v-file-input>
                </v-form>
              </v-col>

              <v-col cols="4" class="pl-2">
                <v-btn color="primary" dark small @click="upload">
                  Upload
                  <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-card v-if="fileInfos.length > 0">
              <v-subheader>List of Files</v-subheader>
              <div class="row mt-5 mb-2">
                <div
                  class="col-12 col-md-3 border text-center"
                  v-for="(file, index) in fileInfos"
                  :key="index"
                >
                  <v-img
                    height="200"
                    width="200"
                    v-bind:src="`data:image/png;base64,${file.file}`"
                    contain
                    :alt="'image' + file.id"
                    ><v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-3 float-right"
                          @click="remove(file.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip></v-img
                  >

                  <!-- <h5 class="text-center" v-if="(fname = strtrunk(file.name))">
                    <a :href="file.fileURL" target="_blank">{{ fname }}</a>
                  </h5> -->
                </div>
              </div>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!---Category update or Add -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-select
              :items="allOrganization"
              item-text="name"
              item-value="id"
              v-model="editedItem.clientId"
              :disabled="this.editedIndex !== -1 ? true : false"
              required
              :rules="[(v) => !!v || 'Client is required']"
              ><template #label>
                Client
                <span class="red--text"><strong>* </strong></span>
              </template></v-select
            >
            <v-text-field
              v-model="editedItem.title"
              label="Title"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Title is required',
                (v) => (v && !!v.trim()) || 'Title cannot be blank',
              ]"
            >
              <template #label>
                Title
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="editedItem.overview"
              required
              :rules="[
                (v) => !!v || 'Overview is required',
                (v) => (v && !!v.trim()) || 'Overview cannot be blank',
              ]"
            >
              <template #label>
                Overview <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
            <label class="title-login"
              >Description<span class="red--text"
                ><strong>&nbsp;* </strong></span
              ></label
            >
            <v-input required :error-messages="error">
              <vue-editor
                ref="editor"
                class="col-12"
                v-model="editedItem.description"
                :editorToolbar="customToolbar"
                @blur="onEditorBlur()"
                @selection-change="onSelectionChange"
              ></vue-editor>
            </v-input>

            <v-text-field
              v-model="editedItem.releaseBy"
              label="Release By"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Release By is required',
                (v) => (v && !!v.trim()) || 'Release By cannot be blank',
              ]"
            >
              <template #label>
                Release By
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="editedItem.releaseOn"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.releaseOn"
                  label="Release On"
                  append-icon="mdi-calendar"
                  @click:append="on.click"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Release On Required']"
                  ><template #label>
                    Release On
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
              </template>
              <v-date-picker v-model="editedItem.releaseOn" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu.save(editedItem.releaseOn), startdateText()
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="editedItem.releaseLink"
              label="Release Link"
              required
              :rules="[
                (v) => !!v || 'Release Link is required',
                (v) => (v && !!v.trim()) || 'Release Link cannot be blank',
              ]"
            >
              <template #label>
                Release Link
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="editedItem.externalId"
              label="External Id"
              required
              :rules="[
                (v) => !!v || 'External Id is required',
                (v) => (v && !!v.trim()) || 'External Id cannot be blank',
              ]"
            >
              <template #label>
                External Id
                <span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize grey lighten-2" @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!---Delete Confirm -->
    <v-dialog v-model="dialogFileDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img
          class="mt-7"
          :src="require('@/assets/logoDA.svg')"
          height="80"
          width="80"
        />
        <v-card-text>
          <v-card-title class="justify-center pt-0 pb-1"
            >Are you sure you want to delete this File?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end pb-3">
          <v-btn
            color="border"
            class="text-capitalize"
            @click="dialogFileDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="red"
            class="text-capitalize"
            depressed
            @click="deleteFileConfirm"
            >yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to delete this Release?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---Notification -->
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>

<script>
import orgService from "@/services/organization.service.js";
import releaseService from "@/services/releaseVersion.service.js";
import config from "../apiurl.js";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New License" : "Edit License";
    },
    getOList() {
      var arr = Object.assign([], this.allOrganization);
      arr.unshift({ id: "", name: "All" });
      return arr;
    },
    // gridData() {
    //   if (!(this.allOrganization && this.clientList)) return [];

    //   let res = [];
    //   res = this.allOrganization.filter((el) => {
    //     return !this.clientList.find((element) => {
    //       return element.name === el.name;
    //     });
    //   });
    //   return res;
    // },
    gridDataProduct() {
      if (!this.productList.data) return [];

      let res = [];
      res = this.productList.data.filter((el) => {
        return !this.client.find((element) => {
          return element.code === el.code;
        });
      });
      return res;
    },
  },
  data: () => ({
    content: "",
    error: "",
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["code-block"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ color: [] }],
      ["link"],
    ],
    fileLimit: config.releaseFileLimit,
    limit: config.releaseLimitText,
    allOrganization: [],
    releaseList: {},
    orgName: "",
    menu: false,
    selectedFiles: undefined,
    fileInfos: [],
    nowDate: new Date().toISOString().slice(0, 10),
    loading: false,
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    licenseType: [
      { name: "Ui Path Agent", value: "Ui Path Bot" },
      { name: "UI Path Studio", value: "UI Path Studio" },
    ],
    mainheaders: [
      {
        text: "Client",
        value: "orgName",
        sortable: false,
      },
      {
        text: "Title",
        value: "title",
        sortable: false,
      },
      {
        text: "Overview",
        value: "overview",
        sortable: false,
      },
      {
        text: "releaseBy",
        value: "releaseBy",
        sortable: false,
      },
      {
        text: "releaseOn",
        value: "releaseOn",
        sortable: false,
      },
      {
        text: "version",
        sortable: false,
        value: "version",
      },
      { text: "Actions", value: "actions", sortable: false, width: "100px" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
      },
    ],
    // childheaders: [
    //   {
    //     text: "License Added By",
    //     align: "start",
    //     sortable: false,
    //     value: "userName",
    //   },

    //   {
    //     text: "License key",
    //     value: "licenseKey",
    //     sortable: false,
    //   },
    //   {
    //     text: "License Type",
    //     value: "licenseType",
    //     sortable: false,
    //   },
    //   {
    //     text: "Start Date",
    //     sortable: false,
    //     value: "startDate",
    //   },
    //   {
    //     text: "End Date",
    //     value: "endDate",
    //     sortable: false,
    //   },
    //   {
    //     text: "Alert",
    //     value: "expiry",
    //     sortable: false,
    //   },
    //   { text: "Status", value: "isActive", width: "100px", sortable: false },
    //   { text: "Actions", value: "actions", sortable: false, width: "100px" },
    // ],
    singleExpand: true,
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    filter: {
      searchKey: "",
    },
    dialogFileDelete: false,
    deleteid: "",
    searchKey: "",
    editedItem: {
      id: 0,
      clientId: 0,
      title: "",
      overview: "",
      description: "",
      releaseBy: "",
      releaseOn: "",
      releaseLink: "",
      externalId: "",
      releasedate: "",
    },
    defaultItem: {
      id: 0,
      clientId: 0,
      title: "",
      overview: "",
      description: "",
      releaseBy: "",
      releaseOn: "",
      releaseLink: "",
      externalId: "",
      releasedate: "",
    },
  }),
  created() {
    this.getOrganization();

    // EventBus.on("request", (x) => {
    //   if (x == "Pending") {
    //     this.updatePagination({ page: this.pageIndex });
    //   }
    // });
  },

  methods: {
    onEditorBlur() {
      // console.log("editor blur!", this.$refs.editor._props.value);
      if (this.$refs.editor._props.value == "") {
        this.error = "Description Required";
      } else {
        this.error = "";
      }
    },
    onSelectionChange(range) {
      // console.log("selection change!", range);
      if (range) {
        if (range.index >= 0) {
          this.error = "";
        } else {
          this.error = "Description Required";
        }
      }
    },
    // getTextColor(item) {
    //   if (item >= 0 && item <= 7) return "orange--text";
    //   else if (item < 0) return "red--text";
    //   else return "black--text";
    // },
    //   gridData() {
    //   if (!(this.regOrganization && this.client)) return [];

    //   let res = [];
    //   res = this.regOrganization.filter((el) => {
    //     return !this.client.mapping.find((element) => {
    //       return element.clientId === el.id;
    //     });
    //   });
    //   return res;
    // },
    remove(id) {
      this.deleteid = id;
      this.dialogFileDelete = true;
    },
    deleteFileConfirm() {
      this.deleteFile(this.deleteid);
      this.dialogFileDelete = false;
    },
    async deleteFile(id) {
      let result = await releaseService.deleteFile(id);
      if (result.status == 200) {
        this.status = "File removed successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.releaseid);
      }
    },
    getTextColor(item) {
      if (item >= 0 && item <= 7) return "#FFA500";
      else if (item < 0) return "#E64A19";
      else return "#00796B";
    },
    getColorStatus(item) {
      if (item >= 0 && item <= 7) return "#fff6e6";
      else if (item < 0) return "#FDEDE8";
      else return "#E6F2F1";
    },
    calculate(date) {
      var date1 = new Date();
      var date2 = new Date(date);
      date1.setDate(date2.getDate() - 7);
      var date3 = new Date();
      const diffTime = date2 - date3;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log("diffTime", date2 - date3);
      // date > Date.now()
      // console.log(diffDays + " days");
      if (diffDays > 0) {
        return diffDays;
      } else if (diffDays == 0) {
        return Math.abs(diffDays);
      } else {
        return -1;
      }
    },

    startdateText() {
      if (this.editedItem.releaseOn) {
        this.editedItem.releasedate = new Date(
          this.editedItem.releaseOn
        ).toISOString();
      } else {
        this.editedItem.releasedate = "";
      }
    },
    selectFiles(files) {
      this.progressInfos = [];
      this.selectedFiles = files;
    },
    enddateText() {
      if (this.editedItem.endDate) {
        this.editedItem.enddate = new Date(
          this.editedItem.endDate
        ).toISOString();
      } else {
        this.editedItem.enddate = "";
      }
    },
    editItem(item) {
      this.editedIndex = this.releaseList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.releaseOn) {
        this.editedItem.releaseOn = item.releaseOn.slice(0, 10);

        this.editedItem.releasedate = item.releaseOn;
      } else {
        this.editedItem.releaseOn = "";
      }
      this.dialog = true;
    },
    // editFileItem(item, id) {
    //   var valObj = this.releaseList.records.filter(function (elem) {
    //     if (elem.id == id) return elem;
    //   });
    //   if (valObj.length > 0) {
    //     this.license = valObj[0].license;
    //   }
    //   this.editedIndex = this.license.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   if (item.startDate) {
    //     this.editedItem.startDate = item.startDate.slice(0, 10);

    //     this.editedItem.startdate = item.startDate;
    //   } else {
    //     this.editedItem.startDate = "";
    //   }
    //   if (item.endDate) {
    //     this.editedItem.endDate = item.endDate.slice(0, 10);

    //     this.editedItem.enddate = item.endDate;
    //   } else {
    //     this.editedItem.endDate = "";
    //   }
    //   this.dialog = true;
    // },
    closeDialog() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    resetStatus() {
      // console.log("this.editedIndex1", this.editedIndex);
      this.license[this.editedIndex].isActive =
        this.license[this.editedIndex].isActive == true ? false : true;
    },
    upload() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.uploadFiles();
      }
    },
    async uploadFiles() {
      this.message = "";
      let formData = new FormData();

      for (var i = 0; i < this.selectedFiles.length; i++) {
        let file = this.selectedFiles[i];
        formData.append("file", file);
      }

      let result = await releaseService.updateFile(formData, this.releaseid);

      if (result.status == 200) {
        this.status = "File uploaded successfully";
        this.$refs.form1.reset();
        this.$refs.form1.resetValidation();
        this.getAllNotes(this.releaseid);
        setTimeout(() => {
          this.status = "";
        }, 2000);
      }
    },
    // deleteItem(item, id) {
    //   var valObj = this.releaseList.records.filter(function (elem) {
    //     if (elem.id == id) return elem;
    //   });
    //   if (valObj.length > 0) {
    //     this.license = valObj[0].license;
    //   }
    //   console.log("item", item);
    //   this.editedItem = Object.assign({}, item);
    //   console.log("this.editedItem", this.editedItem);
    //   console.log("this.license", this.license);
    //   this.editedIndex = this.license.indexOf(item);
    //   console.log("this.editedIndex", this.editedIndex);
    //   this.dialogDelete = true;
    // },
    // deleteConfirm() {
    //   console.log("this.editedIndex2", this.editedIndex);
    //   const id = this.license[this.editedIndex].id;

    //   this.editedItem.id = id;

    //   this.delete({ isActive: this.editedItem.isActive }, id);
    //   this.dialogDelete = false;
    // },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.releaseList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.releaseList.records[this.editedIndex].id;
      this.editedItem.id = id;

      this.delete({ id: id });
      this.dialogDelete = false;
    },
    async delete(item) {
      let result = await releaseService.delete(item);
      if (result.status == 200) {
        this.status = "Release Version deleted successfully";

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    onChange() {
      if (this.orgName == null) {
        this.orgName = "";
      }
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.orgName,
      };
      this.getAllRelease(data);
    },

    loadDetails({ item, value }) {
      if (value) {
        this.releaseid = item.id;
        this.getAllNotes(item.id);
      }
    },
    async getAllNotes(id) {
      this.fileInfos = [];
      let result = await releaseService.getAllNotes({ releaseId: id });
      this.fileInfos = result.records;
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      this.loading = true;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.orgName,
      };
      this.getAllRelease(data);
    },

    onUpdate() {
      let valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedIndex == -1) {
          // var releaseList = [];
          // releaseList.push({
          //   clientId: this.editedItem.clientId,
          //   licenseType: this.editedItem.licenseType,
          //   licenseKey: this.editedItem.licenseKey,
          //   startDate: this.editedItem.startdate,
          //   endDate: this.editedItem.enddate,
          // });
          // console.log("releaseList", releaseList);
          this.create({
            clientId: this.editedItem.clientId,
            title: this.editedItem.title,
            overview: this.editedItem.overview,
            description: this.editedItem.description,
            releaseBy: this.editedItem.releaseBy,
            releaseOn: this.editedItem.releasedate,
            releaseLink: this.editedItem.releaseLink,
            externalId: this.editedItem.externalId,
          });
          // this.create({ releaseList: releaseList });
        } else {
          const id = this.releaseList.records[this.editedIndex].id;
          this.editedItem.id = id;
          this.update(
            {
              title: this.editedItem.title,
              overview: this.editedItem.overview,
              description: this.editedItem.description,
              releaseBy: this.editedItem.releaseBy,
              releaseOn: this.editedItem.releasedate,
              releaseLink: this.editedItem.releaseLink,
              externalId: this.editedItem.externalId,
              isDeleted: false,
            },
            this.editedItem.id
          );
        }
        this.closeDialog();
      }
    },

    async create(item) {
      let result = await releaseService.create(item);

      if (result.status == 200) {
        this.status = "License created successfully";
        this.editedItem = Object.assign({}, this.defaultItem);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    async update(item, id) {
      let result = await releaseService.update(item, id);
      if (result.status == 200) {
        this.status = "License updated successfully";
        this.editedItem = Object.assign({}, this.defaultItem);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    // async delete(item, id) {
    //   let result = await releaseService.update(item, id);
    //   if (result.status == 200) {
    //     if (item.isActive == true) {
    //       this.status = "License activated successfully";
    //     } else {
    //       this.status = "License deactivated successfully";
    //     }

    //     setTimeout(() => {
    //       this.status = "";
    //     }, 2000);
    //     this.updatePagination({ page: this.pageIndex });
    //   }
    // },

    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.orgName,
      };
      this.getAllRelease(data);
    },
    async clear() {
      this.filter.searchKey = "";
      this.searchKey = "";
      this.loading = true;
      this.loading = true;
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        // OrgnizationId: this.orgName,
      };
      this.getAllRelease(data);
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.allOrganization = result;
    },
    async getAllRelease(input) {
      this.loading = true;
      let result = await releaseService.getAllRelease(input);
      this.loading = false;

      this.releaseList = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
/deep/.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>

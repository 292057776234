<template>
  <div class="container-fluid">
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-3 col-sm-6">
                <v-select
                  :items="categoryList"
                  v-on:change="categoryChange"
                  item-text="name"
                  item-value="value"
                  v-model="filter.category"
                >
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :server-items-length="faqList.total"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :items="faqList.records"
        :loading="loading"
        @update:options="updatePagination"
        class="elevation-1"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-switch
            @change="deleteItem(item, $event)"
            v-model="item.isActive"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.isApplicableToAllClients`]="{ item }">
          <v-switch
            @change="deleteItem1(item, $event)"
            v-model="item.isApplicableToAllClients"
            :false-value="false"
            :true-value="true"
            inset
            class="offset-4 offset-lg-0"
          ></v-switch>
        </template>
        <template v-slot:[`item.addedOn`]="{ item }">
          <td>{{ new Date(item.addedOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="25"
                small
                @click="features(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dialpad
              </v-icon>
            </template>
            <span>Map Clients</span>
          </v-tooltip> -->
        </template>

        <template v-slot:[`item.answer`]="{ item }">
          <td class="text-justify">
            {{ truncate(item.answer, 200) }}
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="mt-7" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="text-center">
          <v-card-title class="text-justify1"
            >Are you sure you want to activate this FAQ?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            @click="
              dialogDelete = false;
              resetStatus();
            "
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
    <v-alert v-if="statusE" type="error">
      {{ statusE }}
    </v-alert>
  </div>
</template>
<script>
import faqService from "@/services/faq.service.js";

export default {
  components: {},
  computed: {},
  data: () => ({
    dialogDelete: false,
    dialogFeatures: false,
    valid: false,
    dialog: false,
    headers: [
      {
        text: "Question",
        align: "start",
        sortable: false,
        value: "question",
      },
      { text: "Answer", value: "answer", sortable: false, width: "40%" },
      {
        text: "Category",
        value: "category",
        sortable: false,
      },

      // { text: "Description", value: "description", sortable: false },
      { text: "addedOn", value: "addedOn", sortable: false },

      { text: "Status", value: "isActive", sortable: false },
    ],
    editedIndex: -1,
    pageIndex: 0,
    status: "",
    statusE: "",
    activationStatus: 1,
    filter: {
      searchKey: "",
      category: "",
    },
    searchKey: "",
    category: [
      { name: "Process", value: "Process" },
      { name: "Agent", value: "Bot" },
      { name: "Logs", value: "Logs" },
      { name: "Marketplace", value: "Marketplace" },
      { name: "Alignxcel", value: "Alignxcel" },
    ],
    applicableTo: [
      { name: "Marketplace", value: "MP" },
      { name: "Alignxcel", value: "AX" },
      { name: "All", value: "All" },
    ],
    categoryList: [
      { name: "All Categories", value: "" },
      { name: "Process", value: "Process" },
      { name: "Agent", value: "Bot" },
      { name: "Logs", value: "Logs" },
      { name: "Marketplace", value: "Marketplace" },
      { name: "Alignxcel", value: "Alignxcel" },
    ],
    editedItem: {
      id: 0,
      question: "",
      answer: "",
      category: "",
      applicableTo: "",
    },
    defaultItem: {
      id: 0,
      question: "",
      answer: "",
      category: "",
      applicableTo: "",
    },
    faqList: {},
    // categoryList: {},
    loading: false,
    product: {},
  }),
  created() {
    // this.getAllCategory({ skip: 0, take: 25000 });
  },

  methods: {
    // categoryChange() {
    //   this.pageIndex = 0;
    //   this.search();
    // },
    async categoryChange() {
      this.pageIndex = 0;
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: false,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    truncate(source, size) {
      if (source == null) {
        return source;
      } else {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
    },
    editItem(item) {
      this.editedIndex = this.faqList.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.activationStatus = item.isActive;
      this.editedIndex = this.faqList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItem1(item) {
      this.activationStatus = item.isActive;
      this.editedIndex = this.faqList.records.indexOf(item);
      this.dialogDelete = true;
    },
    deleteConfirm() {
      const id = this.faqList.records[this.editedIndex].id;

      this.delete({ isActive: true }, id);

      this.dialogDelete = false;
    },
    resetStatus() {
      this.faqList.records[this.editedIndex].isActive =
        this.faqList.records[this.editedIndex].isActive == true ? false : true;
    },
    async updatePagination(pagination) {
      this.pageIndex = pagination.page;
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: (pagination.page - 1) * 10,
        take: 10,
        isActive: false,
        category: this.filter.category,
        question: this.searchKey,
      });
      // console.log("result", result);
      this.loading = false;
      this.faqList = result;
    },
    features(item) {
      this.editedIndex = item.id;
      this.product = item;
      this.dialogFeatures = true;
    },

    async update(item, id) {
      let result = await faqService.update(item, id);
      if (result.status == 200) {
        this.status = "FAQ updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      } else {
        this.statusE = result.data;
        setTimeout(() => {
          this.statusE = "";
        }, 2000);
      }
    },
    escapeHtml(str) {
      return str.replace(/'/g, "&#039;");
    },
    async delete(item, id) {
      let result = await faqService.update(item, id);
      if (result.status == 200) {
        if (item.isActive == true) {
          this.status = "FAQ activated successfully";
        } else {
          this.status = "FAQ deactivated successfully";
        }

        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },

    async search() {
      this.searchKey = this.escapeHtml(this.filter.searchKey);
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: false,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    async clear() {
      this.searchKey = "";
      this.filter.searchKey = "";
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: false,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    async modifyOthers() {
      this.loading = true;
      let result = await faqService.getAllFAQs({
        skip: 0,
        take: 10,
        isActive: false,
        category: this.filter.category,
        question: this.searchKey,
      });
      this.loading = false;
      this.faqList = result;
    },
    // async getAllCategory(input) {
    //   let result = await catService.getAll(input);

    //   this.categoryList = result;
    // },

    newStatus(id) {
      let newstatus = "";
      if (id == "0") {
        newstatus = "Low";
      } else if (id == "1") {
        newstatus = "Medium";
      } else if (id == "2") {
        newstatus = "High";
      }
      return newstatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.custom-bg {
  color: gray;
}
/deep/.style-1 {
  background: #e4e7ea !important;
  opacity: 0.5;
}
</style>

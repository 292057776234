
import { mainAxios } from '../axios-instance';
export default {


  async getBotImageNames() {
    let result = await mainAxios.get(`/BotImage/images/available`);
    return result.data;
  },

  async getAdminBotImageNames() {
    let result = await mainAxios.get(`/BotImage/used/names`);
    return result.data;
  },
  async getAllBots(input) {
    let result = await mainAxios.get(`/BotImage`, {
      params: input,
    });
    return result.data;

  },

  async create(input) {
    let result = await mainAxios.post(`/BotImage`, input);

    return result;
  },
  async update(input, id) {

    let result = await mainAxios.put(`/BotImage`, input, { params: { botImageId: id } });
    return result;
  },


  async delete(input) {
    let result = await mainAxios.delete(`/BotImage?botImageId=${input.botImageId}`);
    return result;
  },
  async getById(id) {
    let result = await mainAxios.get(`/BotImage/${id}`);


    return result.data;

  },
}
import { mainAxios } from '../axios-instance';
export default {



  async getAll(input) {
    // commit("update", { loading: true });
    let result = await mainAxios.get(`/Mail`, {
      params: input,
    });
    return result.data;

  },
  async getAllLogin(input) {
    // commit("update", { loading: true });
    let result = await mainAxios.get(`/LoginActivity`, {
      params: input,
    });
    return result.data;

  },

}
<template>
  <div class="container mb-12">
    <v-tabs
      background-color="transparent"
      color="primary"
      v-resize="onResize"
      v-model="currentItem"
      row
      wrap
      class="mb-3 tabborder"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        :href="'#tab-' + item.name"
        :title="item.title"
      >
        {{ item.name }}
      </v-tab>
      <v-menu v-if="more.length" bottom class="ml-3" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="align-self-center" v-on="on">
            more
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="(item, index) in more"
            :key="index"
            @click="addItem(item)"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-tabs-items v-model="currentItem" height="auto">
      <v-tab-item key="Existing Users" value="tab-Existing Users" height="auto">
        <ExistingUser></ExistingUser>
      </v-tab-item>

      <v-tab-item
        key="Deactivated Users"
        value="tab-Deactivated Users"
        height="auto"
      >
        <DeactivatedUser></DeactivatedUser>
      </v-tab-item>

      <v-tab-item key="Pending Users" value="tab-Pending Users">
        <PendingUser></PendingUser>
      </v-tab-item>

      <v-tab-item key="Rejected Users" value="tab-Rejected Users">
        <RejectedUser></RejectedUser>
      </v-tab-item>

      <v-tab-item key="Invite Users" value="tab-Invite Users">
        <InviteUser></InviteUser>
      </v-tab-item>

      <v-tab-item key="Invited Users" value="tab-Invited Users">
        <InvitedUser></InvitedUser>
      </v-tab-item>

      <v-tab-item
        key="Client Super Users"
        v-if="userType == 'admin'"
        value="tab-Client Super Users"
      >
        <ClientSuperUsers></ClientSuperUsers>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import InvitedUser from "../components/InvitedUser.vue";
import InviteUser from "../components/InviteUser.vue";
import ExistingUser from "../components/ExistingUser.vue";
import PendingUser from "../components/PendingUser.vue";
import RejectedUser from "../components/RejectedUser.vue";
import ClientSuperUsers from "../components/ClientSuperUsers.vue";
import DeactivatedUser from "../components/DeactivatedUser.vue";
import authService from "@/services/auth.service.js";
export default {
  components: {
    InvitedUser,
    InviteUser,
    ExistingUser,
    RejectedUser,
    PendingUser,
    ClientSuperUsers,
    DeactivatedUser,
  },
  computed: {},
  data: () => ({
    tab: null,
    tabItems: [],
    tabs: [],
    more: [],
    currentItem: "tab-Existing Users",
    userType: authService.authData.userType,
  }),
  created() {
    if (this.userType == "admin") {
      this.tabItems = [
        {
          name: "Existing Users",
          title: "List of Users who are currently Active",
        },
        {
          name: "Deactivated Users",
          title: "List of Users who are  Inactive",
        },
        {
          name: "Pending Users",
          title: "User Entreaties that are yet to be approved",
        },
        {
          name: "Rejected Users",
          title: "List of Users repudiated",
        },
        {
          name: "Invite Users",
          title: "Invite new users to Roll in",
        },
        {
          name: "Invited Users",
          title: "List of Invited Users who are yet to register",
        },
        {
          name: "Client Super Users",
          title: "Preside over Client Super Users",
        },
      ];
    } else if (this.userType == "supuser") {
      this.tabItems = [
        {
          name: "Existing Users",
          title: "List of Users who are currently Active",
        },
        {
          name: "Deactivated Users",
          title: "List of Users who are  Inactive",
        },
        {
          name: "Pending Users",
          title: "User Entreaties that are yet to be approved",
        },
        {
          name: "Rejected Users",
          title: "List of Users repudiated",
        },
        {
          name: "Invite Users",
          title: "Invite new users to Roll in",
        },
        {
          name: "Invited Users",
          title: "List of Invited Users who are yet to register",
        },
      ];
    }
  },
  methods: {
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 1);
      this.more = temp.splice(0);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: contents;
  visibility: hidden;
}
// /deep/.v-tab {
//   padding: 0px 8px;
// }
/deep/.v-menu__content {
  min-width: 99px;
  top: 120px;
  left: 190px;
}
</style>


<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-text-field
                  v-model="filter.searchKey"
                  label="Search"
                  single-line
                  hide-details
                  v-on:keyup.enter="search"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:append="search"
                  @click:clear="clear"
                ></v-text-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-4">
                <v-select
                  :items="allOrganization"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrg"
                  label="Select Organization"
                  :clearable="true"
                  @change="onChange"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="allRequest.records"
        :server-items-length="allRequest.total"
        @update:options="updatePagination"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :single-expand="singleExpand"
        show-expand
        @item-expanded="loadDetails"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          <td>{{ new Date(item.createdOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.modifiedOn`]="{ item }">
          <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
        </template>
        <template v-slot:[`item.manualDuration`]="{ item }">
          <td>{{ item.manualDuration }} {{ item.manualDurationType }}</td>
        </template>
        <template v-slot:[`item.noRuns`]="{ item }">
          <td>{{ item.noRuns }} {{ item.runCountType }}</td>
        </template>
        <template v-slot:[`item.manualAverageCost`]="{ item }">
          <td>${{ item.manualAverageCost }}</td>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td
                class="cursor-point"
                @click="statusItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ newStatus(item.status) }}
              </td>
            </template>
            <span>Edit Status</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="50"
                small
                class="mr-3"
                @click="viewProcess(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-view-sequential-outline
              </v-icon>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-0 py-0" :colspan="headers.length">
            <div class="col-12 offset-lg-0 offset-xs-0 text-right">
              <v-btn
                dark
                class="ma-3"
                @click="
                  dialog = true;
                  editedNoteIndex = -1;
                  editedNote.clientProcessId = item.id;
                "
              >
                <v-icon dark> mdi-plus </v-icon> Add Note</v-btn
              >
            </div>

            <v-data-table
              :headers="headersChild"
              :items="allNotes"
              item-key="id"
              class="elevation-1 grey lighten-3"
            >
              <template #[`item.modifiedOn`]="{ item }">
                <td>{{ new Date(item.modifiedOn).toDateString() }}</td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      @click="remove(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogStatus" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Status</span>
        </v-card-title>
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-select
                  :items="statuslist"
                  item-text="name"
                  item-value="value"
                  v-model="editedStatus.status"
                  label="Select Status"
                  :clearable="false"
                ></v-select>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize grey lighten-2" @click="close">
            Cancel
          </v-btn>
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card height="auto">
        <v-card-title>
          <span class="text-h6">{{ formTitle }}</span>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-textarea
              v-model="editedNote.message"
              required
              maxlength="100"
              :rules="[
                (v) => !!v || 'Note is required',
                (v) => (v && !!v.trim()) || 'Note cannot be blank',
                (v) =>
                  (v || '').length <= 100 ||
                  'Note must be 100 characters or less',
              ]"
            >
              <template #label>
                Note <span class="red--text"><strong>* </strong></span>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize grey lighten-2"
              depressed
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              dark
              color="black"
              class="text-capitalize"
              depressed
              @click="onUpdate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card height="auto" class="text-center">
        <img class="ma-4" :src="require('@/assets/logoDA.svg')" />
        <v-card-text class="justify-center">
          <v-card-title
            >Are you sure you want to delete this note?</v-card-title
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize grey lighten-2"
            depressed
            @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            dark
            color="black"
            class="text-capitalize"
            depressed
            @click="deleteConfirm"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProcess" persistent max-width="800px">
      <v-card>
        <v-card-title>Process Details</v-card-title>
        <v-card-text>
          <div class="font-weight-normal">
            <strong>Name of the Process : </strong>
            {{ editedProcess.processName }}
          </div>
          <div class="font-weight-normal">
            <strong>Description : </strong> {{ editedProcess.description }}
          </div>
          <div class="font-weight-normal">
            <strong>Your Role : </strong> {{ editedProcess.role }}
          </div>
          <div class="font-weight-normal">
            <strong>Your Department : </strong> {{ editedProcess.department }}
          </div>
          <div class="font-weight-normal">
            <strong>How long does it take to do it manually? : </strong>
            {{ editedProcess.manualDuration }}
            {{ editedProcess.manualDurationType }}
          </div>
          <div class="font-weight-normal">
            <strong
              >How many times a day/week/month do you this process? :
            </strong>
            {{ editedProcess.noRuns }} {{ editedProcess.runCountType }}
          </div>
          <div class="font-weight-normal">
            <strong
              >What is the average salary per month of a person doing this job
              manually? :
            </strong>
            ${{ editedProcess.manualAverageCost }}
          </div>
          <div class="font-weight-normal">
            <strong
              >How many people are performing this process manually? :
            </strong>
            {{ editedProcess.peopleCount }}
          </div>
          <div class="font-weight-normal">
            <strong
              >Is the risk HIGH/Medium/Low if this job is not performed? :
            </strong>
            {{ editedProcess.riskType }}
          </div>
          <div class="font-weight-normal">
            <strong
              >Do you have budget approval to automate this process? :
            </strong>
            {{ editedProcess.bugetApproval }}
          </div>
          <div class="font-weight-normal">
            <strong>Status : </strong>
            <strong>{{ newStatus(editedProcess.status) }}</strong>
          </div>
          <v-btn
            color="text-capitalize grey lighten-2"
            class="mr-4"
            @click="closeDialog1"
          >
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert v-if="status" type="success">
      {{ status }}
    </v-alert>
  </div>
</template>
<script>
import EventBus from "../plugins/eventBus";
import processRequestService from "@/services/processRequest.service.js";
import orgService from "@/services/organization.service.js";

export default {
  created() {
    this.getOrganization();

    this.editedStatus.status = this.statusNo;

    EventBus.on("request", (x) => {
      if (x == "Approved") {
        this.updatePagination({ page: this.pageIndex });
      }
    });
  },
  computed: {
    formTitle() {
      return this.editedNoteIndex === -1 ? "New Note" : "Edit Note";
    },

    headers() {
      let headers = [
        {
          text: "Process Name",
          sortable: false,
          value: "processName",
        },

        {
          text: "Manual Duration",
          align: "start",
          sortable: false,
          value: "manualDuration",
        },
        { text: "No of Runs", value: "noRuns", sortable: false },
        {
          text: "Risk Type",
          value: "riskType",

          sortable: false,
        },
        { text: "Organization", value: "organization", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Details", value: "actions", sortable: false },
        { text: "Ordered on", value: "createdOn", sortable: false },
        { text: "Modified on", value: "modifiedOn", sortable: false },
      ];

      headers.push({
        text: "",
        value: "data-table-expand",
        sortable: false,
      });

      return headers;
    },
  },
  watch: {
    dialogStatus(val) {
      val || this.close();
    },
  },
  destroyed() {
    EventBus.off("request_note_changes");
    EventBus.off("reqstatus_changes");
  },
  data: () => ({
    statusNo: "Approved",
    allOrganization: [],
    allRequest: {},
    loading: false,
    daterange: "",
    dialogProcess: false,
    nowDate: new Date().toISOString().slice(0, 10),
    dates: [],
    fromdate: "",
    todate: "",
    menu: false,
    singleExpand: true,

    dialog: false,
    dialogStatus: false,
    dialogDelete: false,
    selectedOrg: "",
    valid: false,
    valid1: false,
    status: "",
    noteid: 0,
    items: "",

    statuslist: [
      { name: "New", value: "Pending" },
      { name: "In Progress", value: "InProgress" },
      { name: "Approved", value: "Approved" },
      { name: "Cancelled", value: "Cancelled" },
    ],
    filter: {
      searchKey: "",
      sortBy: "",
    },
    editedProcess: {
      id: "",
      role: "",
      status: "",
      processName: "",
      description: "",
      manualDuration: "",
      manualDurationType: "",
      noRuns: "",
      runCountType: "",
      manualAverageCost: "",
      peopleCount: "",
      riskType: "",
      bugetApproval: "",
      department: "",
    },
    expanded: [],

    headersChild: [
      {
        text: "Notes",
        align: "start",
        sortable: false,
        value: "message",
      },
      {
        text: "Created On",
        value: "modifiedOn",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false, width: "130px" },
    ],
    pageIndex: 0,
    editedNoteIndex: -1,
    editedStatusIndex: -1,

    editedNote: {
      id: 0,
      message: "",
      clientProcessId: 0,
    },
    removeId: -1,
    allNotes: [],

    editedStatus: {
      id: "",
      status: 0,
    },
    defaultStatus: {
      id: "",
      status: 0,
    },
  }),

  methods: {
    onChange() {
      if (this.selectedOrg == null) {
        this.selectedOrg = "";
      }
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrgnizationId: this.selectedOrg,
        status: this.statusNo,
      };
      this.getAllRequest(data);
    },
    updatePagination(pagination) {
      this.pageIndex = pagination.page || 1;
      let data = {
        Skip: (pagination.page - 1) * 10,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrgnizationId: this.selectedOrg,
        status: this.statusNo,
      };
      this.getAllRequest(data);
    },
    viewProcess(item) {
      this.editedProcess = Object.assign({}, item);
      this.dialogProcess = true;
    },
    closeDialog1() {
      this.dialogProcess = false;
    },
    save() {
      let valid = this.$refs.form1.validate();
      if (valid) {
        this.updateStatus({
          inputs: this.editedStatus,
          status: this.editedStatus.status,
          clientProcessId: this.editedStatus.id,
        });

        this.close();
      }
    },
    async updateStatus(input) {
      let result = await processRequestService.changeStatus(input);
      if (result.status == 200) {
        this.status = "Status updated successfully";
        EventBus.emit("request", input.status);
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.updatePagination({ page: this.pageIndex });
      }
    },
    cancel() {},
    search() {
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrgnizationId: this.selectedOrg,
        status: this.statusNo,
      };
      this.getAllRequest(data);
    },
    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
    clear() {
      this.filter.searchKey = "";
      let data = {
        Skip: 0,
        Take: 10,
        SearchKey: this.filter.searchKey,
        OrgnizationId: this.selectedOrg,
        status: this.statusNo,
      };
      this.getAllRequest(data);
    },
    clearDate() {
      this.dates = [];
      this.fromdate = "";
      this.todate = "";
      let data = {
        status: this.statusNo,
      };
      this.getAllRequest(data);
    },

    loadDetails({ item, value }) {
      if (value) {
        this.noteid = item.id;
        this.getAllNotes(item.id);
      }
    },
    async getAllNotes(id) {
      this.allNotes = [];
      let result = await processRequestService.getAllNotes({
        clientProcessId: id,
      });
      this.allNotes = result;
    },
    editItem(item) {
      this.editedNoteIndex = this.allNotes.indexOf(item);
      this.editedNote = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedNoteIndex = -1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    onUpdate() {
      let valid = this.$refs.form.validate();

      if (valid) {
        if (this.editedNoteIndex == -1) {
          this.createNote(
            this.editedNote.message,
            this.editedNote.clientProcessId
          );
        } else {
          const id = this.allNotes[this.editedNoteIndex].id;
          this.editedNote.id = id;
          this.updateNote(this.editedNote);
        }
        this.closeDialog();
      }
    },
    async createNote(note, id) {
      let result = await processRequestService.create(note, id);

      if (result.status == 200) {
        this.status = "Note added successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },
    async updateNote(item) {
      let result = await processRequestService.update(item);
      if (result.status == 200) {
        this.status = "Note updated successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },
    async deleteNote(item) {
      let result = await processRequestService.delete(item);
      if (result.status == 200) {
        this.status = "Note deleted successfully";
        setTimeout(() => {
          this.status = "";
        }, 2000);
        this.getAllNotes(this.noteid);
      }
    },

    remove(item) {
      this.removeId = item.id;
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.deleteNote({ id: this.removeId });
      this.dialogDelete = false;
    },
    statusItem(item) {
      this.editedStatusIndex = this.allRequest.records.indexOf(item);
      this.editedStatus = Object.assign({}, item);
      this.dialogStatus = true;
    },
    close() {
      this.dialogStatus = false;

      this.editedStatusIndex = -1;
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    newStatus(id) {
      let newstatus = "";
      if (id == "Pending") {
        newstatus = "New";
      } else if (id == "Approved") {
        newstatus = "Approved";
      } else if (id == "InProgress") {
        newstatus = "In Progress";
      } else if (id == "Cancelled") {
        newstatus = "Cancelled";
      }
      return newstatus;
    },
    async getOrganization() {
      let result = await orgService.getAllOrg();

      this.allOrganization = result;
    },
    async getAllRequest(input) {
      this.loading = true;
      let result = await processRequestService.getAllRequest(input);
      this.loading = false;
      this.allRequest = result;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 20px !important;
}
.text-justify1 {
  word-break: normal !important;
}
.v-card__title {
  display: block !important;
}
.cursor-point {
  cursor: pointer;
}
</style>
<template>
  <BaseCart></BaseCart>
</template>

<script>
import BaseCart from "../components/BaseCart.vue";

export default {
  components: {
    BaseCart,
  },
};
</script>
